import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "src/common/Pagination";
import DiscountService from "src/services/DiscountService";
import UpdateDiscountPanier from "./UpdateDiscountPanier";
import { useNavigate } from "react-router";

function AllPromotionActifs() {
  const navigate = useNavigate();
  const [PromotionActifsList, setPromotionActifsList] = useState({});
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [index, setIndex] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [PromotionActifstexte, setPromotionActifstexte] = useState({});
  const [nbPromotionActifs, setNbPromotionActifs] = useState(0);

  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [isModalArchiverOrVisibleOpen, setIsModalArchiverOrVisibleOpen] =
    useState(false);
  const [value, setValue] = useState(null);
  const [PromotionActifsItem, setPromotionActifsItem] = useState("");

  useEffect(() => {
    allPromotionsDispo();
  }, [currentPage, itemsPerPage]);

  const allPromotionsDispo = () => {
    DiscountService.allPromotionsDispo(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setPromotionActifsList(response.data.success.content);
          console.log("Data : ", response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //**************for edit  modal

  const archiverById = (id) => {
    DiscountService.archivedPromotions(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledById = (id) => {
    DiscountService.visibledPromotions(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const openDetailsPromotion = (id) => {
    localStorage.setItem("idPromotionToUpdate", id);
    navigate("/updatePromotion");
  };

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les Promotions </b>
                    </h5>
                    <button
                      className="btn btn-dark float-right"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={() => (window.location.href = "/addPromotion")}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">Les Promotions disponibles</h3>
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th># Libellé</th>
                            <th>Type Promotion</th>
                            {/* <th>Modifier</th> */}
                            <th>Visibilité dans le site</th>
                            <th>archiver</th>
                          </tr>
                        </thead>
                        <tbody>
                          {PromotionActifsList &&
                          PromotionActifsList.length > 0 &&
                          Array.isArray(PromotionActifsList) ? (
                            PromotionActifsList.map(
                              (PromotionActifs, index) => (
                                <tr key={index}>
                                  <td>{PromotionActifs?.name || ""}</td>
                                  <td>
                                    {PromotionActifs?.type || ""}{" "}
                                    {PromotionActifs?.nameType && (
                                      <>
                                        <b>{PromotionActifs?.nameType || ""}</b>
                                      </>
                                    )}
                                  </td>
                                  {/* <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-sm btn-warning"
                                      onClick={() => {
                                        openDetailsPromotion(
                                          PromotionActifs?.id
                                        );
                                      }}
                                    >
                                      Modifier
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="ml-2"
                                      />
                                    </button>
                                  </td> */}
                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className={
                                        PromotionActifs?.visible
                                          ? "btn btn-success btn-sm"
                                          : "btn btn-secondary btn-sm"
                                      }
                                      data-toggle="modal"
                                      data-target="#exampleModalArchiverOrVisible"
                                      onClick={() =>
                                        visibledById(PromotionActifs?.id)
                                      }
                                    >
                                      {PromotionActifs?.visible ? (
                                        <>
                                          Rendre InVisible{" "}
                                          <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          Rendre Visible{" "}
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="ml-2"
                                          />
                                        </>
                                      )}
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className={
                                        PromotionActifs?.archived
                                          ? "btn btn-danger btn-sm"
                                          : "btn btn-secondary btn-sm"
                                      }
                                      data-toggle="modal"
                                      data-target="#exampleModalArchiverOrVisible"
                                      onClick={() =>
                                        archiverById(PromotionActifs?.id)
                                      }
                                    >
                                      {PromotionActifs?.archived ? (
                                        <>
                                          Restaurer{" "}
                                          <FontAwesomeIcon
                                            icon={faRecycle}
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          Rendre Archived{" "}
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            className="ml-2"
                                          />
                                        </>
                                      )}
                                    </button>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan="5">
                                Pas de Promotions pour le moment
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {PromotionActifsList.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* {isModalOpenEdit && (
          <ModalPromotionActifsUpdate
            setIsModalOpenEdit={setIsModalOpenEdit}
            PromotionActifs={PromotionActifsItem}
          />
        )} */}
      </div>
    </>
  );
}

export default AllPromotionActifs;
