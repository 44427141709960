import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class VenteFlashService {
  addVenteFlash(model, image) {
    const formData = new FormData();
    formData.append("venteFlashModel", JSON.stringify(model));
    formData.append("file", image);

    return api.post(urlsCommon.BASE_VENTE_FLASH_URL + "/add", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  updateVenteFlash(model, image) {
    const formData = new FormData();
    formData.append("venteFlashUpdateModel", JSON.stringify(model));
    formData.append("file", image);

    return api.put(urlsCommon.BASE_VENTE_FLASH_URL + "/update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getVenteFlashById(Id) {
    return api.get(urlsCommon.BASE_VENTE_FLASH_URL + "/get/" + Id);
  }
  getAllVenteFlash(page, size) {
    return api.get(urlsCommon.BASE_VENTE_FLASH_URL + "/get/all", {
      params: {
        page: page,
        size: size,
      },
    });
  }
  getPromotionsInDeclencheur() {
    return api.get(
      urlsCommon.BASE_VENTE_FLASH_URL + "/getPromotionsInDeclencheur"
    );
  }
  archiverVenteFlashById(id) {
    return api.put(urlsCommon.BASE_VENTE_FLASH_URL + "/archiver/" + id);
  }
  visibledVenteFlashById(id) {
    return api.put(urlsCommon.BASE_VENTE_FLASH_URL + "/visibled/" + id);
  }

  getVentesPromotions(id, page, size) {
    return api.get(
      urlsCommon.BASE_VENTE_FLASH_URL + "/getVentesPromotions/" + id,
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }
  AllproduitsInPromotionsVenteFlash(idventeFlash, idPromotion) {
    return api.get(
      urlsCommon.BASE_VENTE_FLASH_URL +
        "/produitsInPromotionsVenteFlash/" +
        idventeFlash,
      {
        params: {
          idPromotion: idPromotion,
        },
      }
    );
  }

  updatePromotionsVenteFlash(idventeFlash, idsPromotions) {
    return api.put(
      urlsCommon.BASE_VENTE_FLASH_URL +
        "/updatePromotionsVenteFlash/" +
        idventeFlash,
      idsPromotions
    );
  }
  searchPromotionsVenteFlash(id, page, size, searchData) {
    return api.get(
      urlsCommon.BASE_VENTE_FLASH_URL + "/searchPromotionsVenteFlash/" + id,
      {
        params: {
          page: page,
          size: size,
          searchData: searchData,
        },
      }
    );
  }
  deletePromotionFromVenteFlash(idventeFlash, idPromotion) {
    return api.put(
      urlsCommon.BASE_VENTE_FLASH_URL +
        "/deletePromotionFromVenteFlash/" +
        idventeFlash,
      idPromotion
    );
  }
}
export default new VenteFlashService();
