import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class PictureService {
  addOrderToImagesVariant(idsImages) {
    return api.put(
      urlsCommon.BASE_PRODUCT_PICTURE_URL + "/addOrderToImagesVariant",
      idsImages
    );
  }
}
export default new PictureService();
