import React, { useState } from "react";
import ProgressBar from "./ProgressBar";

const withLoader = (WrappedComponent) => {
  const WithLoader = (props) => {
    const [loading, setLoading] = useState(false);

    const startLoading = () => {
      setLoading(true);
    };

    const stopLoading = () => {
      setLoading(false);
    };

    return (
      <div>
        {loading && <ProgressBar />}
        <WrappedComponent
          {...props}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      </div>
    );
  };
  return WithLoader;
};

export default withLoader;
