import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LivraisonService from "src/services/LivraisonService";

function ModalLivraisonUpdate(props) {
  const { setIsModalOpenEdit, livraison } = props;
  const [livraisonToUpdate, setlivraisonToUpdate] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setlivraisonToUpdate({ ...livraisonToUpdate, [name]: value });
  };

  const updatelivraison = () => {
    const livraisonModel = {
      id: livraison?.id,
      name: livraisonToUpdate.name || livraison?.name,
      price: livraisonToUpdate.price || livraison?.price,
      montant_gratuit:
        livraisonToUpdate.montant_gratuit || livraison?.montant_gratuit,
    };

    LivraisonService.updateLivraison(livraisonModel)
      .then((response) => {
        if (response.data.success) {
          setIsModalOpenEdit(false);
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModalLivraisonUpdate"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLivraisonUpdate"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLivraisonUpdate">
                Update livraison <b>{livraison?.titre || ""}</b>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>Libellé :</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control float-right"
                    placeholder="libelle"
                    name="name"
                    onChange={handleChange}
                    value={livraisonToUpdate?.name || livraison?.name}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Prix de livraison : (example : 8 dt)</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control float-right"
                    placeholder="prix de livraison"
                    name="price"
                    onChange={handleChange}
                    value={livraisonToUpdate?.price || livraison?.price}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Montant Gratuit : (example : 250 dt)</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control float-right"
                    placeholder="montant gratuit à partir de ..."
                    name="montant_gratuit"
                    onChange={handleChange}
                    value={
                      livraisonToUpdate?.montant_gratuit ||
                      livraison?.montant_gratuit
                    }
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsModalOpenEdit(false);
                  window.location.href = "/allLivraisons";
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-dark"
                onClick={updatelivraison}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalLivraisonUpdate;
