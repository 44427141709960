import { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import Files from "react-files";
import { TextField } from "@material-ui/core";
import ProduitJourService from "../services/ProduitJourService";
export default class AddProduitJour extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
          titre: "",
          texteNormal: "",
          titreBouton: "",
          urlBouton:"",
          dateDebut: "",
          dateFin: "",
          produitJour:[],
          files: [],
          livrable: false,
          redirect: false,
          visible: false,
        };
        this.changeTitre = this.changeTitre.bind(this);
        this.changeTexteNormal = this.changeTexteNormal.bind(this);
        this.changeTitreBouton = this.changeTitreBouton.bind(this);
        this.changeUrlBouton = this.changeUrlBouton.bind(this);
        this.changeDateDebut = this.changeDateDebut.bind(this);
        this.changeDateFin = this.changeDateFin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.saveFamilyProduct = this.saveFamilyProduct.bind(this);
      
      }
      addProduitJour= (e) => {
        e.preventDefault();
        const dataFile = new FormData();
        dataFile.append("picture", this.state.files[0]);
    
        console.log("picture", dataFile);
    
        let produitJourModel = {
          titre: this.state.titre,
          texteNormal: this.state.texteNormal,
          titreBouton: this.state.titreBouton,
          urlBouton: this.state.urlBouton,
          dateDebut: this.state.dateDebut,
          dateFin: this.state.dateFin,
          visible: this.state.visible,
          // files: this.state.files[0],
        };
    
        ProduitJourService.addProduitJour(
          dataFile,
          JSON.stringify(produitJourModel)
        )
        .then((response) => {
          console.log("response", response.data);
          if (response.data["success"] ) {
            this.setState({ produitJour: response.data["success"] });
          } else {
            console.log("response", "error");
          }
        })
        .catch((error) => {

          console.log("response", error);
        });
      };
      handleChange(event) {
        if (this.state.visible) {
          this.setState({ visible: false });
        } else {
          this.setState({ visible: true });
        }
    
        // console.log("visible", this.state.visible);
      }
      changeTitre(event) {
        this.setState({ titre: event.target.value });
      }
      changeTexteNormal(event) {
        this.setState({ texteNormal: event.target.value });
      }
      changeTitreBouton(event) {
        this.setState({ titreBouton: event.target.value });
      }
      changeUrlBouton(event) {
        this.setState({ urlBouton: event.target.value });
      }
      changeDateDebut(date) {
        this.setState({ dateDebut: date });
      }
      changeDateFin(date) {
        this.setState({ dateFin: date });
      }
      onFilesChange = (files) => {
        this.setState(
          {
            files,
          },
          () => {
            // console.log(this.state.files);
          }
        );
      };
      onFilesError = (error, file) => {
        // console.log("error code " + error.code + ": " + error.message);
      };
    
      filesRemoveOne = (file) => {
        this.refs.files.removeFile(file);
      };
    
      filesRemoveAll = () => {
        this.refs.files.removeFiles();
      };
   
     
      render() {
        const animatedComponents = makeAnimated();
        
        if (this.state.redirect) {
          return <Navigate replace to="/allProduitJour" />;
        } else {
          return (
            <div class="content-wrapper">
              <ToastContainer />
              <section class="content-header">
                <div class="container-fluid">
                  <div class="callout callout-success">
                    <h5>Ajouter un produit de jour </h5>
                    Deux étapes:
                    <br /> 1-Ajouter une image (1920px * 170px)
                    <br /> 2-Remplir le formulaire
                  </div>
                </div>
              </section>
              <div className="row">
                <div className="col-md-6">
                  <section class="content">
                    <div class="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-outline card-success">
                            <div className="card-header">
                              <h3 className="card-title">
                                <b>1-AJOUTER UNE IMAGE</b>
                              </h3>
                            </div>
    
                            <form>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div>
                                      <Files
                                        ref="files"
                                        className="files-dropzone-list"
                                        // style={{ height: "100px" }}
                                        onChange={this.onFilesChange}
                                        onError={this.onFilesError}
                                        multiple
                                        maxFiles={1}
                                       // maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                      >
                                        <label>
                                          Cliquer pour Ajouter une image
                                        </label>
                                      </Files>
    
                                      {this.state.files.length > 0 ? (
                                        <div className="files-list">
                                          <ul>
                                            {this.state.files.map((file) => (
                                              <li
                                                className="files-list-item"
                                                key={file.id}
                                              >
                                                <div className="files-list-item-preview">
                                                  {file.preview.type === "image" ? (
                                                    <div className="row">
                                                      <img
                                                        className="files-list-item-preview-image"
                                                        src={file.preview.url}
                                                        style={{
                                                          width: "70%",
                                                          height: "70%",
                                                        }}
                                                      />
                                                      <button
                                                        id={file.id}
                                                        class="btn btn-navbar"
                                                        type="button"
                                                        // data-widget="navbar-search"
                                                        onClick={this.filesRemoveOne.bind(
                                                          this,
                                                          file
                                                        )}
                                                      >
                                                        <i class="fas fa-times"></i>
                                                      </button>
                                                    </div>
                                                  ) : (
                                                    <div className="files-list-item-preview-extension">
                                                      {file.extension}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="files-list-item-content">
                                                  <div className="files-list-item-content-item files-list-item-content-item-1">
                                                    {file.name}
                                                  </div>
                                                  <div className="files-list-item-content-item files-list-item-content-item-2">
                                                    {file.sizeReadable}
                                                  </div>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ) : (
                                        <h1></h1>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-6">
                  <section class="content">
                    <div class="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-outline card-success">
                            <div className="card-header">
                              <h3 className="card-title">
                                <b>2-AJOUTER UN PRODUIT DE JOUR</b>
                              </h3>
                            </div>
    
                            <form>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-2">
                                    <label>TITRE</label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="libelle"
                                      className="form-control"
                                      placeholder="Ajouter titre"
                                      value={this.state.titre}
                                      onChange={this.changeTitre}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-2">
                                    <label>Text normal </label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="libelle"
                                      className="form-control"
                                      placeholder="Text normal"
                                      value={this.state.texteNormal}
                                      onChange={this.changeTexteNormal}
                                    />
                                  </div>
                                  </div>
                                <br/>
                                <div className="row">
                                  <div className="col-md-2">
                                    <label>titre de button</label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="libelle"
                                      className="form-control"
                                      placeholder="titre de button"
                                      value={this.state.titreBouton}
                                      onChange={this.changeTitreBouton}
                                    />
                                  </div>
                                </div>
                                {/* <br/>
                                <div className="row">
                                  <div className="col-md-2">
                                    <label> date </label>
                                  </div>
                                  <div className="col-md-10">
                                  <TextField
                                                style={{ width: "100%" }}
                                                className="col-sm-12"
                                                variant="outlined"
                                                value={this.state.startDate}
                                                onChange={this.changeStartDate}
                                                id="datetime-local"
                                                type="datetime-local"
                                                defaultValue="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                            />
                                  </div>
                                </div> */}
                                <br/>
                                <div className="row">
                                  <div className="col-md-2">
                                    <label>url de button</label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="libelle"
                                      className="form-control"
                                      placeholder="url de button"
                                      value={this.state.urlBouton}
                                      onChange={this.changeUrlBouton}
                                    />
                                  </div>
                                </div>
                                <br/>
                                <div className="row">
                  <div class="col-md-6">
                    <label>Date Début </label>
                    <br />

                    <DatePicker
                      className="form-control"
                      selected={this.state.dateDebut}
                      onChange={this.changeDateDebut}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Date Fin</label>
                    <br />

                    <DatePicker
                      className="form-control"
                      selected={this.state.dateFin}
                      onChange={this.changeDateFin}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <br/>
                  <div className="col-md-12 ">
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={this.state.visible}
                        onChange={this.handleChange}
                      />{" "}
                      Rendre visible dans le site
                    </label>
                  </div>
                </div>


                </div>
                                <br/>
                               </div>
                              <div className="card-footer">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  onClick={this.addProduitJour}
                                >
                                  Valider
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          );
        }
      }

}