import { Component } from "react";
import CurrencyInput from "react-currency-input";
import NumericInput from "react-numeric-input";
import ProductService from "../../services/ProductService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class ProductPopupUpdateAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: "",
      libelle: "",
      stock: "",
      price: "",
      descritption: "",
    };
    this.changeLibelle = this.changeLibelle.bind(this);
    this.changeStock = this.changeStock.bind(this);
    this.changePrice = this.changePrice.bind(this);
    this.changeDescritption = this.changeDescritption.bind(this);
  }

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }
  changeStock(value) {
    this.setState({ stock: value });
    console.log("stock", this.state.stock);
  }
  changePrice(event) {
    this.setState({ price: event.target.value });
  }
  changeDescritption(event) {
    this.setState({ descritption: event.target.value });
  }
  componentDidMount() {
    this.forceUpdate();
    ProductService.getProductById(this.props.idProduct)
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ product: response.data["object"] });
          this.setState({ libelle: response.data["object"]["name"] });
          this.setState({ stock: response.data["object"]["stock"] });
          this.setState({ price: response.data["object"]["price"] });
          this.setState({
            descritption: response.data["object"]["short_desc"],
          });
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        // console.log("brand", this.state.brand);

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  }

  saveProduct = (e) => {
    e.preventDefault();

    let Product = {
      id: this.state.product.id,
      name: this.state.libelle,
      stock: this.state.stock,
      price: this.state.price,
      shortDesc: this.state.descritption,
    };
    ProductService.updateProduct(Product)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Mise à jour du produit avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Modifier le produit {this.state.product.name}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Libellé</label>

                    <input
                      type="libelle"
                      className="form-control"
                      placeholder="Ajouter libellé"
                      value={this.state.libelle}
                      onChange={this.changeLibelle}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Stock</label>
                    <NumericInput
                      className="form-control"
                      value={this.state.stock}
                      onChange={this.changeStock}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Prix Initial : </label>
                    <CurrencyInput
                      suffix=" DNT"
                      class="form-control"
                      precision="3"
                      value={this.state.price}
                      onChangeEvent={this.changePrice}
                    />
                  </div>
                </div>
                <label>Description</label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Saisir..."
                  value={this.state.descritption}
                  onChange={this.changeDescritption}
                ></textarea>

                <br />
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={this.saveProduct}
            >
              Modifier
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
    );
  }
}
