import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "src/common/Pagination";
import { useEffect, useState } from "react";
import NouveauteService from "src/services/NouveauteService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DiscountService from "src/services/DiscountService";
import { toast } from "react-toastify";
import ProductService from "src/services/ProductService";
const VoirProductsDiscount = (props) => {
  const {
    idPromotion,
    promotionName,
    availableProducts,
    setAvailableProducts,
  } = props;
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  //****les produits à ajouter */
  const [listAllProducts, setListAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const animatedComponents = makeAnimated();
  const [searchData, setSearchData] = useState("");

  const handleInputChange = (searchData) => {
    if (searchData) {
      setSearchData(searchData);
      getProductsToAdd(searchData);
    } else {
      setListAllProducts([]);
    }
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
  };

  const getProductsToAdd = () => {
    ProductService.getAllProductsForAddWithSearch(searchData)
      .then((response) => {
        if (response.data.success) {
          setListAllProducts(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  };
  const addProduitsToPromotion = () => {
    const idsProducts = JSON.stringify(
      selectedProducts.map((product) => product.value)
    );
    DiscountService.addProduitsToPromotion(idPromotion, idsProducts)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          window.location.reload();
        }
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };

  const archiverById = (idPromotion, idProduit) => {
    DiscountService.removeProduitFromPromotion(idPromotion, idProduit)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          window.location.reload();
        } else {
          toast.success(response.data.error);
        }
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };

  return (
    <>
      <section class="content">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">
              Les Produits de cette promotion : {promotionName || ""}
            </h3>

            <div class="card-tools">
              <button
                type="button"
                class="btn btn-tool"
                data-card-widget="collapse"
                title="Collapse"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div className="mt-3"></div>

            <div className="d-flex">
              <Select
                defaultValue={selectedProducts}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={listAllProducts}
                onInputChange={handleInputChange}
                onChange={handleProductChange}
                value={selectedProducts}
                className="col-md-6"
                placeholder="Rechercher des produits..."
              />

              <button className="btn btn-dark" onClick={addProduitsToPromotion}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div className="mb-3"></div>

            <table class="table  projects">
              <thead>
                <tr>
                  <th>Code article</th>
                  <th>Libellé</th>
                  <th>prix avant</th>
                  <th>prix apres</th>
                  <th>remise %</th>
                  <th>Famille</th>
                  <th>Sous famille</th>
                  <th>Categorie</th>
                  <th className="text-center">archiver</th>
                </tr>
              </thead>
              <tbody>
                {availableProducts &&
                availableProducts.length > 0 &&
                Array.isArray(availableProducts) ? (
                  <>
                    {availableProducts.map((element, index) => (
                      <tr key={element.id}>
                        <td>{element.codeArticle || ""}</td>
                        <td>{element.name || ""}</td>
                        <td>{element.price?.toFixed(3) || ""} TND</td>
                        <td>{element.sale_price?.toFixed(3) || ""} TND</td>
                        <td>{element.percent || ""}</td>
                        {/* <td>{element.brand.name || ""}</td> */}
                        <td>{element.famille.name || ""}</td>
                        <td>{element.sousFamille.name || ""}</td>
                        <td>{element.category.name || ""}</td>

                        <td className="text-center">
                          <button
                            className="btn btn-danger btn"
                            onClick={() =>
                              archiverById(idPromotion, element?.id)
                            }
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr className="text-center">
                      <td colSpan="8">Pas de Produits pour le moment</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {availableProducts && availableProducts.length > 0 && (
              <>
                <div className="d-flex" style={{ padding: "15px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => setCurrentPage(newPage)}
                  />
                  <div className="ml-3">
                    <select
                      className="form-control pagination-select float-right"
                      id="itemsPerPageSelect"
                      value={itemsPerPage}
                      onChange={(e) =>
                        setItemsPerPage(parseInt(e.target.value, 10))
                      }
                    >
                      {[4, 7, 10, 15, 20].map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default VoirProductsDiscount;
