import { Component } from "react";
import { Link } from "react-router-dom";
import CarrousselTrendingHomeService from "../../services/CarrousselTrendingHomeService";
export default class BanniereTrending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carroussels: [],
    };
  }
  componentDidMount() {
    this.forceUpdate();
    CarrousselTrendingHomeService.allCarrousselTrendingHome()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ carroussels: response.data["object"] });
          console.log("carroussels", this.state.carroussels);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>La bannière trending</h5>
              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addBanniereTrending" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    <i class="fas fa-plus"></i>
                    Ajouter
                  </Link>
                </button>
              </p>
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-success card-outline ">
            <div class="card-header">
              <h3 class="card-title">
                {" "}
                <b>Les bannières trending </b>
              </h3>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Image </th>
                    <th>Les produits Trending</th>
                    <th>Modifier</th>
                    <th>Archiver</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.carroussels.map((carroussel) => (
                    <tr>
                      <td>{carroussel.id}</td>
                      <td>
                        {" "}
                        <button
                          class="btn btn-warning btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          // onClick={() => {
                          //   this.setState({ idBrand: brand.id });
                          //   this.showFormImage();
                          // }}
                        >
                          <i class="fas fa-images"></i> Image
                        </button>
                        {/* {this.state.showFormImage && (
                        <BrandImage idBrand={this.state.idBrand} />
                      )} */}
                      </td>
                      <td>
                        <button
                          class="btn  btn-success btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          onClick={() => {
                            sessionStorage.setItem(
                              "idCarroussel",
                              carroussel.id
                            );
                          }}
                        >
                          <Link
                            to={{ pathname: "/allProduitsTrending" }}
                            style={{
                              color: "white",
                              "text-decoration": "none",
                            }}
                          >
                            <i class="fas fa-edit"></i> Voir
                          </Link>
                        </button>
                        {/* {this.state.showFormImage && (
                        <BrandImage idBrand={this.state.idBrand} />
                      )} */}
                        {/* <a href="/detailsCommande" style={{ color: "black" }}>
                        <i className="fas fa-edit" /> Modifier
                      </a> */}
                      </td>
                      <td>
                        <button
                          class="btn  btn-primary btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          // onClick={() => {
                          //   this.setState({ idBrand: brand.id });
                          //   this.showFormImage();
                          // }}
                        >
                          <i class="fas fa-edit"></i> Modifier
                        </button>
                        {/* {this.state.showFormImage && (
                        <BrandImage idBrand={this.state.idBrand} />
                      )} */}
                        {/* <a href="/detailsCommande" style={{ color: "black" }}>
                        <i className="fas fa-edit" /> Modifier
                      </a> */}
                      </td>
                      <td>
                        <button
                          class="btn  btn-danger btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          // onClick={() => {
                          //   this.setState({ idBrand: brand.id });
                          //   this.showFormImage();
                          // }}
                        >
                          <i className="fas fa-ban" /> Supprimer
                        </button>
                        {/* {this.state.showFormImage && (
                        <BrandImage idBrand={this.state.idBrand} />
                      )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
