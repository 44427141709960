import axios from "axios";
import { Component } from "react";
import Files from "react-files";
import CategoryService from "../../services/CategoryService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class CategoryImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      files: [],
    };
  }

  componentDidMount() {
    this.forceUpdate();
    CategoryService.getCategoryById(this.props.idCategory)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ category: response.data["object"] });
          console.log("category", this.state.category);
          // this.setState({ alert_message: "success" });
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  filesUpload = () => {
    const formData = new FormData();
    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      formData.append(
        key,
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });

    axios
      .post(`/files`, formData)
      .then((response) =>
        window.alert(`${this.state.files.length} files uploaded succesfully!`)
      )
      .catch((err) => window.alert("Error uploading files :("));
  };

  saveCategory = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);
    CategoryService.updatePicture(dataFile, this.state.category.id)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Mise à jour de l'image avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data.object);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div className="modal fade" id="modal-Image">
        <ToastContainer />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                L'image de la catégorie <b>{this.state.category.name}</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <label>L'image actuelle</label>
                    </div>
                    <div className="mb-5"></div>
                    <div class="w-100"></div>
                    <div className="col">
                      <img
                        className="files-list-item-preview-image"
                        src={this.state.category.url}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="mb-5"></div>
                    <div class="w-100"></div>
                    <div className="mt-3"></div>
                    <div className="col">
                      <Files
                        ref="files"
                        className="files-dropzone-list"
                        // style={{ height: "100px" }}
                        onChange={this.onFilesChange}
                        onError={this.onFilesError}
                        multiple
                        maxFiles={1}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        {this.state.files.length === 0 ? (
                          <label>Cliquer pour modifier l'image</label>
                        ) : (
                          <label>l'image apres la modification</label>
                        )}
                      </Files>
                    </div>
                    <div className="mb-5"></div>
                    <div class="w-100"></div>
                    <div className="col">
                      {this.state.files.length > 0 ? (
                        <div className="files-list">
                          {this.state.files.map((file) => (
                            <div className="files-list-item" key={file.id}>
                              <div className="files-list-item-preview">
                                {file.preview.type === "image" ? (
                                  <img
                                    className="files-list-item-preview-image"
                                    src={file.preview.url}
                                    /*   style={{
                                            width: "10%",
                                            height: "10%",
                                         }} */
                                    style={{ width: "100%" }}
                                  />
                                ) : (
                                  <ul>
                                    <li>
                                      <div className="files-list-item-preview-extension">
                                        {file.extension}
                                      </div>
                                    </li>
                                  </ul>
                                )}
                              </div>
                              <div className="mb-5"></div>
                              <div class="w-100"></div>
                              <div className="row">
                                <div className="col">
                                  <div className="files-list-item-content">
                                    <ul>
                                      <li>
                                        <div className="files-list-item-content-item files-list-item-content-item-1">
                                          <b>Name :</b> {file.name}
                                        </div>
                                      </li>

                                      <li>
                                        <div className="files-list-item-content-item files-list-item-content-item-2">
                                          <b>Size :</b> {file.sizeReadable}
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="col">
                                  <button
                                    id={file.id}
                                    class="btn btn-danger"
                                    type="button"
                                    // data-widget="navbar-search"
                                    onClick={this.filesRemoveOne.bind(
                                      this,
                                      file
                                    )}
                                  >
                                    <i class="fas fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.saveCategory}
              >
                Valider
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
      </div>
    );
  }
}
