import axios from "axios";
import { Component } from "react";
import Files from "react-files";
import BrandService from "../../services/BrandService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class BrandImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: "",
      imageUrl: "",
      files: [],
    };
    // this.changeLibelle = this.changeLibelle.bind(this);
  }

  // changeLibelle(event) {
  //   this.setState({ libelle: event.target.value });
  //   console.log("libelle", this.state.libelle);
  // }
  componentDidMount() {
    this.forceUpdate();
    BrandService.getBrandById(this.props.idBrand)
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ brand: response.data["object"] });
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("brand", this.state.brand);

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  filesUpload = () => {
    const formData = new FormData();
    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      formData.append(
        key,
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });

    axios
      .post(`/files`, formData)
      .then((response) =>
        window.alert(`${this.state.files.length} files uploaded succesfully!`)
      )
      .catch((err) => window.alert("Error uploading files :("));
  };

  saveBrand = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);
    BrandService.updatePicture(dataFile, this.state.brand.id)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Mise à jour de l'image avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div className="modal fade" id="modal-Image">
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                L'image de {this.state.brand.name}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <label>L'image actuelle</label>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-10">
                      <img
                        className="files-list-item-preview-image"
                        src={this.state.brand.url}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <Files
                          ref="files"
                          className="files-dropzone-list"
                          onChange={this.onFilesChange}
                          onError={this.onFilesError}
                          multiple
                          maxFiles={1}
                          maxFileSize={10000000}
                          minFileSize={0}
                          clickable
                        >
                          <label>Cliquer pour modifier l'image</label>
                        </Files>

                        {this.state.files.length > 0 ? (
                          <div className="files-list">
                            <ul>
                              {this.state.files.map((file) => (
                                <li className="files-list-item" key={file.id}>
                                  <div className="files-list-item-preview">
                                    {file.preview.type === "image" ? (
                                      <div className="row">
                                        <img
                                          className="files-list-item-preview-image"
                                          src={file.preview.url}
                                        />
                                        <button
                                          id={file.id}
                                          class="btn btn-navbar"
                                          type="button"
                                          // data-widget="navbar-search"
                                          onClick={this.filesRemoveOne.bind(
                                            this,
                                            file
                                          )}
                                        >
                                          <i class="fas fa-times"></i>
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="files-list-item-preview-extension">
                                        {file.extension}
                                      </div>
                                    )}
                                  </div>
                                  <div className="files-list-item-content">
                                    <div className="files-list-item-content-item files-list-item-content-item-1">
                                      {file.name}
                                    </div>
                                    <div className="files-list-item-content-item files-list-item-content-item-2">
                                      {file.sizeReadable}
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <h1></h1>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.saveBrand}
              >
                Valider
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
      </div>
    );
  }
}
