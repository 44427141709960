import { Component } from "react";
import FamilyProductService from "../../services/FamilyProductService";
import ProductService from "../../services/ProductService";
import BrandDelete from "../BrandPopupModel/BrandDelete";
import ProductPopupBigPictures from "../ProductPopupModel/ProductPopupBigPictures";
import ProductPopupSmallPictures from "../ProductPopupModel/ProductPopupSmallPictures";
import ProductPopupStock from "../ProductPopupModel/ProductPopupStock";
import ProductPopupUpdateAll from "../ProductPopupModel/ProductPopupUpdateAll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faNotEqual,
  faNoteSticky,
  faRecycle,
  faShapes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default class AllProductByFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      family: "",
      products: [],
    };
  }
  componentDidMount() {
    this.forceUpdate();
    FamilyProductService.getFamilyById(
      sessionStorage.getItem("idFamilyProduct")
    )
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ family: response.data["object"] });
          console.log("family", this.state.family);
          // this.setState({ alert_message: "success" });
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
    ProductService.getProductsByFamily(
      sessionStorage.getItem("idFamilyProduct")
    )
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ products: response.data["object"] });
          console.log("products", this.state.products);
          // this.setState({ alert_message: "success" });
          //   this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  onAddProductsHandler = () => {
    window.location.href = "/addProduct";
  };
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };

  render() {
    return (
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="card  mt-3">
                  <div className="card-body">
                    <div className="card-title">
                      <div>
                        {this.state.products.length === 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <FontAwesomeIcon
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  color: "red",
                                }}
                                icon={faExclamationTriangle}
                              />
                              <div className="mr-3"></div>
                              <h5>
                                Pas de produits pour la famille{" "}
                                <b>{this.state.family.name}</b>
                              </h5>
                            </div>
                          </>
                        ) : (
                          <p>
                            Produits pour la famille{" "}
                            <h5>{this.state.family.name}</h5>
                          </p>
                        )}
                      </div>
                    </div>

                    <button
                      className="btn btn-dark float-right"
                      data-toggle="modal"
                      data-target="#add-brand"
                      onClick={this.onAddProductsHandler}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                {this.state.products.length != 0 ? (
                  <section className="content">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Les Produits disponibles</h3>

                        <div className="card-tools">
                          <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                            title="Collapse"
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <table className="table  projects">
                          <thead>
                            <tr>
                              <th>Code Article</th>
                              <th>Libellé </th>
                              <th>Description</th>
                              <th
                                className="text-center"
                                style={{ width: "12%" }}
                              >
                                Modifier
                              </th>
                              {/* <th
                                className="text-center"
                                style={{ width: "12%" }}
                              >
                                Image
                              </th> */}
                              <th
                                className="text-center"
                                style={{ width: "12%" }}
                              >
                                Activer
                              </th>
                              <th
                                className="text-center"
                                style={{ width: "12%" }}
                              >
                                Archiver
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.products.map((product) => (
                              <tr>
                                <td>{product.ref || ""}</td>
                                <td>{product.name || ""}</td>
                                <td>{product.short_desc || ""}</td>
                                <td className="text-center">
                                  <button
                                    disabled
                                    style={{ width: 40 }}
                                    className="btn bg-warning btn-sm"
                                    data-toggle="modal"
                                    data-target="#modal-update"
                                    // onClick={() => {
                                    //   this.setState({ idProduct: product.id });
                                    //   this.showFormImage();
                                    // }}
                                  >
                                    <i class="fas fa-pencil-alt"></i>
                                  </button>
                                </td>
                                {/* <td className="text-center">
                                  <button
                                    style={{ width: 40 }}
                                    className="btn bg-info btn-sm"
                                    data-toggle="modal"
                                    data-target="#modal-Image"
                                    onClick={() => {
                                      this.setState({ idProduct: product.id });
                                      this.showFormImage();
                                    }}
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </button>
                                </td> */}

                                <td className="text-center">
                                  <button
                                    data-toggle="modal"
                                    data-target="#modal-visible"
                                    disabled
                                    style={{ width: 40 }}
                                    className={`btn btn-sm ${
                                      product?.visible
                                        ? "btn-secondary"
                                        : "btn-primary"
                                    }`}
                                    // onClick={() => {
                                    //   this.setState({
                                    //     idProduct: product.id,
                                    //   });
                                    //   this.showFormImage();
                                    // }}
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        product?.visible ? faEyeSlash : faEye
                                      }
                                    />
                                  </button>
                                </td>

                                <td className="text-center">
                                  <button
                                    disabled
                                    style={{ width: 40 }}
                                    className={`btn btn-sm ${
                                      product?.isArchived
                                        ? "btn-success"
                                        : "btn-danger"
                                    }`}
                                    // onClick={() => {
                                    //   this.setState({
                                    //     idProduct: product.id,
                                    //   });
                                    //   this.showFormImage();
                                    // }}
                                    data-toggle="modal"
                                    data-target="#modal-default"
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        product?.isArchived
                                          ? faRecycle
                                          : faTrash
                                      }
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {this.state.showFormImage && (
                            <div className="modal fade" id="modal-Image">
                              <ProductPopupBigPictures
                                idProduct={this.state.idProduct}
                              />
                            </div>
                          )}
                          {this.state.showFormImage && (
                            <div className="modal fade" id="modal-update">
                              <ProductPopupUpdateAll
                                idProduct={this.state.idProduct}
                              />
                            </div>
                          )}
                        </table>
                      </div>
                    </div>
                  </section>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
