import { Component } from "react";
import { Link } from "react-router-dom";
import ActiviteService from "../services/ActiviteService";
export default class AllActivite extends Component {
  state = {
    idActivite: "",
    activites: [],
    showFormImage: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      idActivite: "",
      activites: [],
      showFormImage: false,
    };
    // this.changenom = this.changenom.bind(this);
    // this.changePrenom = this.changePrenom.bind(this);
    // // this.changePrenom = this.changePrenom.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.saveActivite = this.saveActivite.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    ActiviteService.getAllActivite()
      .then((response) => {
        console.log("response", response.data.success);
        if (response.data.success) {
          this.setState({ activites: response.data.success });
          console.log("activites", this.state.activites);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  archiver(id, archiver) {
    ActiviteService.deleteActivite(id, archiver).then((response) => {
      if (response.data.success) {
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    });
  }
  buttonArchive(id, isArchived) {
    if (isArchived === 0) {
      return (
        <button
          class="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          disabled
          onClick={() => {
            this.setState({ idActivite: id });
            this.archiver(id, 1);
            // this.showFormImage();
          }}
        >
          <i class="fas fa-trash"></i> Archiver
        </button>
      );
    } else {
      return (
        <button
          class="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          disabled
          onClick={() => {
            this.setState({ idActivite: id });
            this.archiver(id, 0);

            // this.showFormImage();
          }}
        >
          <i class="fas fa-check"></i>Restaurer
        </button>
      );
    }
  }
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les activités</h5>
                <p className="float-right">
                  <button class="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/AddActivite" }}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <i className="fas fa-plus" /> Ajouter
                    </Link>
                  </button>
                </p>
              </div>{" "}
            </div>
          </div>
        </section>

        <div className="col-md-12">
          <section class="content">
            <div className="col-12">
              <div className="card">
                <div className="card-header"></div>

                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Libellé</th>
                        <th>Modifier</th>
                        <th>Archiver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.activites.map((activite) => (
                        <tr>
                          <td>{activite.name}</td>
                          <td>
                            <button
                              class="btn btn-info btn-sm"
                              data-toggle="modal"
                              data-target="#modal-update"
                              disabled
                              //   onClick={() => {
                              //     this.setState({ idFamilyProduct: family.id });
                              //     this.showFormImage();
                              //   }}
                            >
                              <i class="fas fa-pencil-alt"></i> Modifier
                            </button>
                            {/* {this.state.showFormImage && (
                                  <div className="modal fade" id="modal-update">
                                    <FamilyUpdateLibelle
                                      idFamilyProduct={this.state.idFamilyProduct}
                                    />
                                  </div>
                                )} */}
                          </td>
                          <td>
                            {this.buttonArchive(
                              activite.id,
                              activite.isArchived
                            )}

                            {/* {this.state.showFormImage && (
                                  <div className="modal fade" id="modal-default">
                                    <FamilyProductDelete
                                      idFamilyProduct={this.state.idFamilyProduct}
                                    />
                                  </div>
                                )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
