import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import BanniereCategoryHomeService from "src/services/BanniereCategoryHomeService";

function UpdateBanniereCategoryHome(props) {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { isModalOpenEdit, setIsModalOpenEdit, BanniereCategoryGet } = props;
  const [BanniereCategory, setBanniereCategory] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBanniereCategory({ ...BanniereCategory, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const updateBanniereCategory = (event) => {
    event.preventDefault();
    const updateModal = {
      type: BanniereCategory.type || BanniereCategoryGet.type,
      ...BanniereCategoryGet,
      ...BanniereCategory,
    };
    console.log(updateModal);
    BanniereCategoryHomeService.updateCategory(imageFile, updateModal)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <Modal
        show={isModalOpenEdit}
        onHide={() => setIsModalOpenEdit(false)}
        size="xl"
      >
        <Modal.Header style={{ backgroundColor: "gray" }}>
          <Modal.Title>
            Modifier carroussel :{" "}
            {(BanniereCategoryGet && BanniereCategoryGet?.titre) || ""}
          </Modal.Title>
          <button
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsModalOpenEdit(false)}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ToastContainer />
            <>
              <div className="form-group">
                <label>Titre :</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control float-right"
                    placeholder="libellé"
                    name="name"
                    onChange={handleChange}
                    value={BanniereCategory.name || BanniereCategoryGet.name}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="w-100"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Titre button :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="titre button"
                        name="titreBouton"
                        onChange={handleChange}
                        value={
                          BanniereCategory.titreBouton ||
                          BanniereCategoryGet.titreBouton
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Lien button :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="https://www.exemple.com"
                        name="urlBouton"
                        onChange={handleChange}
                        value={
                          BanniereCategory.urlBouton ||
                          BanniereCategoryGet.urlBouton ||
                          ""
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">
                      Image Background (574 px L * 380 px H)
                    </label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          onChange={handleFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                          id="fileInputLabel"
                        >
                          Choose file
                        </label>
                      </div>
                      {imageFile && (
                        <>
                          <div className="input-group-append">
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={handleDeleteClick}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-100"></div>
                <div className="col-sm-6">
                  {BanniereCategoryGet && BanniereCategoryGet.urlImage && (
                    <>
                      <label>Image avant : </label>
                      <div className="input-group-append">
                        <img
                          src={
                            (BanniereCategoryGet &&
                              BanniereCategoryGet?.urlImage) ||
                            ""
                          }
                          alt="Selected"
                          style={{ width: "40%" }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="col-sm-6">
                  {imageFile && (
                    <>
                      <label>Image apres : </label>
                      <div className="input-group-append">
                        <img
                          src={imagePreview}
                          alt="Selected"
                          style={{ width: "40%" }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <br />
            </>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpenEdit(false)}>
            Annuler
          </Button>
          <Button variant="warning" onClick={updateBanniereCategory}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateBanniereCategoryHome;
