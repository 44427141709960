import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import VenteFlashService from "../services/VenteFlashService";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";

function UpdateVentesFlash(props) {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { isModalOpenEdit, setIsModalOpenEdit, venteFlashGet } = props;
  const [venteFlash, setVenteFlash] = useState({});
  //date
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const onChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setVenteFlash({ ...venteFlash, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const updateVenteFlash = (event) => {
    event.preventDefault();
    const updateModal = {
      id: venteFlashGet.id,
      name: venteFlash.name || venteFlashGet.name,
      titreBouton: venteFlash.titreBouton || venteFlashGet.titreBouton,
      urlBouton: venteFlash.urlBouton || venteFlashGet.urlBouton,
      dateDebut: startDate != null ? new Date(startDate) : null,
      dateFin: endDate != null ? new Date(endDate) : null,
    };
    // console.log(updateModal);
    VenteFlashService.updateVenteFlash(updateModal, imageFile)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <Modal
        show={isModalOpenEdit}
        onHide={() => setIsModalOpenEdit(false)}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            Modifier vente flash :{" "}
            {(venteFlashGet && venteFlashGet?.name) || ""}
          </Modal.Title>
          <button
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsModalOpenEdit(false)}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ToastContainer />
            <>
              <div className="form-group">
                <label>Libellé :</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control float-right"
                    placeholder="libellé"
                    name="name"
                    onChange={handleChange}
                    value={venteFlash.name || venteFlashGet.name}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="w-100"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Titre button :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="titre button"
                        name="titreBouton"
                        onChange={handleChange}
                        value={
                          venteFlash.titreBouton || venteFlashGet.titreBouton
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6">
                  <div className="form-group">
                    <label>Lien button :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="https://www.exemple.com"
                        name="urlBouton"
                        onChange={handleChange}
                        value={
                          venteFlash.urlBouton || venteFlashGet.urlBouton || ""
                        }
                        required
                      />
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="inputName">Date début</label>

                      <TextField
                        style={{ width: "100%" }}
                        // className="col-sm-12"
                        variant="outlined"
                        value={
                          startDate != null
                            ? startDate
                            : new Date(venteFlashGet?.dateDebut)
                                .toISOString()
                                .slice(0, 16)
                        }
                        onChange={onChangeStartDate}
                        id="datetime-local"
                        type="datetime-local"
                        // type="date"
                        name="dateDebut"
                        defaultValue="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="inputName">Date fin</label>

                      <TextField
                        style={{ width: "100%" }}
                        // className="col-sm-12"
                        variant="outlined"
                        value={
                          endDate != null
                            ? endDate
                            : new Date(venteFlashGet?.dateFin)
                                .toISOString()
                                .slice(0, 16)
                        }
                        onChange={onChangeEndDate}
                        id="datetime-local"
                        type="datetime-local"
                        defaultValue="date"
                        name="dateFin"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">
                        Image Background carrée
                      </label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="exampleInputFile"
                            onChange={handleFileChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                            id="fileInputLabel"
                          >
                            Choose file
                          </label>
                        </div>
                        {imageFile && (
                          <>
                            <div className="input-group-append">
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={handleDeleteClick}
                              >
                                Delete
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col-sm-6">
                    {venteFlashGet && venteFlashGet.urlImage && (
                      <>
                        <label>Image avant : </label>
                        <div className="input-group-append">
                          <img
                            src={
                              (venteFlashGet && venteFlashGet?.urlImage) || ""
                            }
                            alt="Selected"
                            style={{ width: "40%" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-sm-6">
                    {imageFile && (
                      <>
                        <label>Image apres : </label>
                        <div className="input-group-append">
                          <img
                            src={imagePreview}
                            alt="Selected"
                            style={{ width: "40%" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <br />
              {/* <div className="form-group">
                <label>Sélectionner promotion</label>
                <select
                  className="form-control"
                  name="idPriorities"
                  onChange={(e) => {
                    setPromotionSelected(e.target.value);
                  }}
                  value={venteFlash.idPriorities || venteFlashGet.idPriorities}
                  required
                >
                  <option value="">Sélectionnez une promotion</option>
                  {promotionList.map((promotion, index) => (
                    <option key={index} value={promotion?.id || ""}>
                      {promotion?.name || ""}
                    </option>
                  ))}
                </select>
              </div> */}
            </>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpenEdit(false)}>
            Fermer
          </Button>
          <Button variant="warning" onClick={updateVenteFlash}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateVentesFlash;
