import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const displayRange = 3;
  let startPage = Math.max(1, currentPage - displayRange);
  let endPage = Math.min(totalPages, currentPage + displayRange);

  if (startPage <= displayRange) {
    endPage = Math.min(startPage + 2 * displayRange, totalPages);
  } else if (endPage >= totalPages - displayRange) {
    startPage = Math.max(endPage - 2 * displayRange, 1);
  }

  const displayPages = [];
  for (let i = startPage; i <= endPage; i++) {
    displayPages.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {startPage > 1 && (
          <>
            <li className="page-item disabled">
              <button className="page-link">...</button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => onPageChange(startPage - 1)}
              >
                {startPage - 1}
              </button>
            </li>
          </>
        )}

        {displayPages.map((page) => (
          <li
            key={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        ))}

        {endPage < totalPages && (
          <>
            <li className="page-item disabled">
              <button className="page-link">...</button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => onPageChange(endPage + 1)}
              >
                {endPage + 1}
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
