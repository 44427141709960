import { Component } from "react";
import FamilyProductService from "../../services/FamilyProductService";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router";
import SubFamilyProductService from "../../services/SubFamilyProductService";
import CategoryService from "../../services/CategoryService";
import MenuCategoryService from "../../services/MenuCategoryService";
export default class AddMenuCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeElement: "",
      nameElement: "",
      familyProduct: "",
      idFamily: "",
      //   idSubFamily: "",
      //   idCategory: "",
      selectedOptionType: "",
      selectedOptionFamily: "",
      selectedOptionSubFamily: "",
      selectedOptionCategory: "",
      families: "",
      subfamilies: "",
      categories: "",
      optionsFamilies: [],
      optionsSubFamilies: [],
      optionsCategories: [],
      files: [],
    };
    // this.handleChange = this.handleChange.bind(this);
    this.changeNameElement = this.changeNameElement.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.addMenuCategory = this.addMenuCategory.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();

    FamilyProductService.getAllFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ families: response.data["object"] });
          console.log("families", this.state.families);
          this.remplissageOptionsFamilies();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
    SubFamilyProductService.getAllSubFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ subfamilies: response.data["object"] });
          console.log("subfamilies", this.state.subfamilies);
          this.remplissageOptionsSubFamilies();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });

    CategoryService.getAllCategories()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ categories: response.data["object"] });
          console.log("categories", this.state.categories);
          this.remplissageOptionsCategories();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  remplissageOptionsFamilies() {
    this.state.families.map((cat) =>
      this.setState({
        optionsFamilies: [
          ...this.state.optionsFamilies,
          { value: cat.id, label: cat.name },
        ],
      })
    );
  }
  remplissageOptionsSubFamilies() {
    this.state.subfamilies.map((cat) =>
      this.setState({
        optionsSubFamilies: [
          ...this.state.optionsSubFamilies,
          { value: cat.id, label: cat.name },
        ],
      })
    );
  }
  remplissageOptionsCategories() {
    this.state.categories.map((cat) =>
      this.setState({
        optionsCategories: [
          ...this.state.optionsCategories,
          { value: cat.id, label: cat.name },
        ],
      })
    );
  }
  handleChangeFamily = (selectedOptionFamily) => {
    this.setState({ selectedOptionFamily }, () =>
      console.log(`Option selected:`, this.state.selectedOptionFamily)
    );
    this.setState({ idFamily: selectedOptionFamily.value });
    console.log("idFamily", this.state.idFamily);
  };
  handleChangeSubFamily = (selectedOptionSubFamily) => {
    this.setState({ selectedOptionSubFamily }, () =>
      console.log(`Option selected:`, this.state.selectedOptionSubFamily)
    );
    this.setState({ idFamily: selectedOptionSubFamily.value });
    console.log("idFamily", this.state.idFamily);
  };
  handleChangeCategory = (selectedOptionCategory) => {
    this.setState({ selectedOptionCategory }, () =>
      console.log(`Option selected:`, this.state.selectedOptionCategory)
    );
    this.setState({ idFamily: selectedOptionCategory.value });
    console.log("idFamily", this.state.idFamily);
  };
  handleChangeType = (selectedOptionType) => {
    this.setState({ selectedOptionType }, () =>
      console.log(`Option selected:`, this.state.selectedOptionType)
    );
    this.setState({ typeElement: selectedOptionType.value });
    console.log("typeElement", this.state.typeElement);
  };

  menuFamilyForm() {
    const animatedComponents = makeAnimated();
    console.log("typeElement", this.state.typeElement);
    if (this.state.typeElement === 1) {
      return (
        // <div className="row">
        //   {console.log("menuFamilyForm")}
        <div class="col-md-6">
          <label>Choisir la famille</label>
          <Select
            id={"SelectCategory"}
            value={this.state.selectedOptionFamily}
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={this.state.optionsFamilies}
            onChange={this.handleChangeFamily}
            placeholder="Choisir..."
          />
        </div>
        // </div>
      );
    } else if (this.state.typeElement === 2) {
      return (
        // <div className="row">
        //   {console.log("menuFamilyForm")}
        <div class="col-md-6">
          <label>Choisir la sous famille</label>
          <Select
            id={"SelectCategory"}
            value={this.state.selectedOptionSubFamily}
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={this.state.optionsSubFamilies}
            onChange={this.handleChangeSubFamily}
            placeholder="Choisir..."
          />
        </div>
        // </div>
      );
    } else if (this.state.typeElement === 3) {
      return (
        // <div className="row">
        //   {console.log("menuFamilyForm")}
        <div class="col-md-6">
          <label>Choisir la catégorie</label>
          <Select
            id={"SelectCategory"}
            value={this.state.selectedOptionCategory}
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={this.state.optionsCategories}
            onChange={this.handleChangeCategory}
            placeholder="Choisir..."
          />
        </div>
        // </div>
      );
    }
  }

  changeNameElement(event) {
    this.setState({ nameElement: event.target.value });
    console.log("nameElement", this.state.nameElement);
  }

  addMenuCategory = (e) => {
    e.preventDefault();

    MenuCategoryService.addMenuCategory(
      this.state.typeElement,
      this.state.idFamily
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de l'element de catégorie avec succée");
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
    // }
  };

  render() {
    const animatedComponents = makeAnimated();
    const options = [
      { value: 1, label: "family" },
      { value: 2, label: "subfamily" },
      { value: 3, label: "category" },
    ];
    if (this.state.redirect) {
      return <Navigate replace to="/allMenuCategory" />;
    } else {
      return (
        <div class="content-wrapper">
          <ToastContainer />
          <section class="content-header">
            <div class="container-fluid">
              <div class="callout callout-success">
                <h5>Ajouter L'élement de menu categorie</h5>
              </div>
            </div>
          </section>

          <div className="col-12 col-sm-12">
            <div className="card card-success card-outline ">
              <form>
                <div className="card-header">
                  <h3 className="card-title">
                    <b>Ajouter l'élement de menu</b>
                  </h3>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Choisir la type de l'element</label>
                      <Select
                        id={"SelectCategory"}
                        value={this.state.selectedOptionType}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={options}
                        onChange={this.handleChangeType}
                        placeholder="Choisir..."
                      />
                    </div>

                    {this.menuFamilyForm()}
                  </div>
                  <br />
                </div>

                <br />
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-success float-right"
                    onClick={this.addMenuCategory}
                  >
                    Ajouter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}
