import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import CarrousselHomeService from "src/services/CarrousselHomeService";

function AddCarrousselHome() {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [carroussel, setcarroussel] = useState({});
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisible = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setcarroussel({ ...carroussel, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const Addcarroussel = (event) => {
    event.preventDefault();
    const AddModel = {
      titre: carroussel.titre,
      titreBouton: carroussel.titreBouton,
      urlBouton: carroussel.urlBouton,
      visible: isVisible,
    };
    CarrousselHomeService.addCarrousselHome(imageFile, AddModel)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setcarroussel({});
          handleDeleteClick();
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Carroussel Home</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Titre :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="libellé"
                        name="titre"
                        onChange={handleChange}
                        value={carroussel.titre || ""}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="w-100"></div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Titre button :</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control float-right"
                            placeholder="titre button"
                            name="titreBouton"
                            onChange={handleChange}
                            value={carroussel.titreBouton || ""}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Lien button :</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control float-right"
                            placeholder="https://www.exemple.com"
                            name="urlBouton"
                            onChange={handleChange}
                            value={carroussel.urlBouton || ""}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          Carroussel Image (1519 px L * 500 px H)
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={handleFileChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                              id="fileInputLabel"
                            >
                              Choose file
                            </label>
                          </div>
                          {imageFile && (
                            <>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={handleDeleteClick}
                                >
                                  Delete
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm-6">
                      {imageFile && (
                        <>
                          <div className="input-group-append">
                            <img
                              src={imagePreview}
                              alt="Selected"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="checkbox">
                          <label>
                            <input
                              className="mr-2"
                              type="checkbox"
                              defaultChecked={carroussel?.visible || ""}
                              onChange={handleChangeVisible}
                            />
                            Rendre visible dans le site
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default">
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={Addcarroussel}
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddCarrousselHome;
