import React, { useState, useEffect } from "react";
import syhncroCsvReadService from "src/services/SyhncroCsvReadService";
import Pagination from "src/common/Pagination";
import UtilsFormule from "src/utils/UtilsFormule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import getCurrentUserId from "src/common/currentUserId";
import Loading from "src/common/loading";

function AllSyhncroCsvRead() {
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [onClickToRun, setOnClickToRun] = useState(false);
  const user = getCurrentUserId();
  useEffect(() => {
    getAllSynchroReadCsv();
  }, [currentPage, itemsPerPage]);

  const getAllSynchroReadCsv = () => {
    syhncroCsvReadService
      .getAllSynchroReadCsv(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setData(response.data.success.content);
          console.log(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const readCSVFile = () => {
    setOnClickToRun(true);
    syhncroCsvReadService
      .readCSVFile(user)
      .then((response) => {
       
        console.log("response.data.success :" + response.data.success);
        console.log("response :" + response);

        if (response.data.success) {
          setOnClickToRun(false);
          getAllSynchroReadCsv();
        } else {
          setOnClickToRun(false);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les Syhncro </b> :
                    </h5>
                    <button
                      class="btn btn-dark float-right"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={readCSVFile}
                    >
                      Add Syhncro
                      <FontAwesomeIcon icon={faPlus} className="ml-2" />
                    </button>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">Les Syhncro disponibles</h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      {!onClickToRun ? (
                        <>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Date de lancement</th>
                                <th>Date modification fichier</th>
                                <th>Nombre de produits</th>

                                <th>Nom Utilisateur</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data &&
                              data &&
                              data.length > 0 &&
                              Array.isArray(data) ? (
                                data.map((syhncroCsvRead, index) => (
                                  <tr key={index}>
                                    <td>
                                      {UtilsFormule.formatDateHours(
                                        syhncroCsvRead?.uploadDate
                                      ) || ""}
                                    </td>
                                    <td>
                                      {UtilsFormule.formatDateHours(
                                        syhncroCsvRead?.lastUpdateFile
                                      ) || ""}
                                    </td>
                                    <td>{syhncroCsvRead?.nbProducts || ""}</td>
                                    <td>{syhncroCsvRead?.nameUser || ""}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr className="text-center">
                                  <td colSpan="4">
                                    Pas de synchro aujourd'hui
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {data && data.length > 0 && (
                            <>
                              <div
                                className="d-flex"
                                style={{ padding: "15px" }}
                              >
                                <Pagination
                                  currentPage={currentPage}
                                  totalPages={totalPages}
                                  onPageChange={(newPage) =>
                                    setCurrentPage(newPage)
                                  }
                                />
                                <div className="ml-3">
                                  <select
                                    className="form-control pagination-select float-right "
                                    id="itemsPerPageSelect"
                                    value={itemsPerPage}
                                    onChange={(e) =>
                                      setItemsPerPage(
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                  >
                                    {[4, 7, 10, 15, 20].map((value) => (
                                      <option key={value} value={value}>
                                        {value}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Loading />
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllSyhncroCsvRead;
