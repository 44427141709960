import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class MenuImageService {
  addMenuImage(picture, menuImageModel) {
    return api.post(urlsCommon.BASE_MENU_Image_URL + "/addMenuImage", picture, {
      params: {
        menuImageModel,
      },
    });
  }
  allMenuImage() {
    return api.get(urlsCommon.BASE_MENU_Image_URL + "/allMenuImage");
  }
}
export default new MenuImageService();
