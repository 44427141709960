import { Component } from "react";
import { Link } from "react-router-dom";
import CommandeService from "../services/CommandeService";
import Utils from "../utils/Utils";

export default class PreparationCommande extends Component {
  state = {
    preparationCommande: [],
    numberCommande: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      preparationCommande: [],
      numberCommande: "",
    };
  }

  componentDidMount() {
    this.forceUpdate();

    CommandeService.getAllCommandeByState(2)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ preparationCommande: response.data["object"] });
          console.log("preparationCommande", this.state.preparationCommande);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });

    CommandeService.getNumberCommandeByState(2)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ numberCommande: response.data["object"] });
          console.log("numberCommande", this.state.numberCommande);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  getCommandeState(state) {
    if (state === 1) {
      return <span class="badge bg-warning">Commande En préparation</span>;
    } else if (state === 2) {
      return <span class="badge bg-success">Commande préparée </span>;
    } else if (state === 3) {
      return <span class="badge bg-pink">Commande En cours de livraison</span>;
    } else if (state === 4) {
      return <span class="badge bg-maroon">Commande Livrée</span>;
    } else if (state === 5) {
      return <span class="badge bg-danger">Commande Annulée</span>;
    } else if (state === 6) {
      return <span class="badge bg-navy">Commande Retournée</span>;
    } else if (state === 7) {
      return <span class="badge bg-info">Commande Réservée</span>;
    }
  }
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Les commandes en cours de préparation</h5>
              {this.state.numberCommande} commandes
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card">
            <div className="card-header border-0">
              <h3 className="card-title">Commandes en cours de préparation</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Ref</th>
                    <th>Client</th>
                    <th>Montant</th>
                    <th>Etat </th>
                    <th>Date</th>
                    <th>Détails</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.preparationCommande.map((commande) => (
                    <tr>
                      <td>{commande.refCommande}</td>
                      <td>
                        {commande.user.firstName} {commande.user.lastName}
                      </td>
                      <td>{commande.priceTotal} DNT</td>
                      <td>{this.getCommandeState(commande.state)}</td>
                      <td>{Utils.formatDate(commande.commandeDate)}</td>
                      <td>
                        <button
                          class="btn  btn-sm"
                          onClick={() => {
                            sessionStorage.setItem("idCommande", commande.id);
                          }}
                        >
                          <Link
                            to={{
                              pathname: "/detailsCommande",
                            }}
                            style={{ color: "black" }}
                          >
                            <i className="fas fa-search" /> Détails
                          </Link>
                        </button>
                        {/* <a href="/detailsCommande" style={{ color: "black" }}>
                          Détails
                        </a> */}
                      </td>
                      <td>
                        <a href="#" style={{ color: "black" }}>
                          <i className="fas fa-ban" /> Supprimer
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
