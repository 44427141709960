import { Component, useState } from "react";
import AuthentificationService from "../services/AuthentificationService";
import { Button, Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const logOut = (e) => {
    e.preventDefault();
    AuthentificationService.logout();
    window.location.href = "/";
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="/" className="nav-link">
              Home
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="#" className="nav-link"></a>
          </li>
        </ul>
        {/* Right navbar links */}

        <ul className="navbar-nav ml-auto">
          {/* Navbar Logout */}
          <li className="nav-item d-none d-sm-inline-block">
            <a className="nav-link" onClick={handleShow}>
              Logout
            </a>
          </li>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
              <Modal.Title>Confirmation de déconnexion</Modal.Title>
              <button className="close">
                <FontAwesomeIcon
                  icon={faTimes}
                  closeButton
                  onClick={handleClose}
                />
              </button>
            </Modal.Header>
            <Modal.Body>Êtes-vous sûr(e) de vouloir logout ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Annuler
              </Button>
              <Button variant="danger" onClick={logOut}>
                Logout
              </Button>
            </Modal.Footer>
          </Modal>
        </ul>
      </nav>
    </div>
  );
}
export default Header;
