import { toast } from "react-toastify";

function TestValidationDateBetween(DateDebut, DateFin) {
  const currentDate = new Date();
  if (
    DateDebut < currentDate ||
    DateFin < currentDate ||
    DateFin < DateDebut ||
    DateFin == "any" ||
    DateDebut == "any"
  ) {
    return true;
  }
}

function TestInputVenteFlash(
  startDate,
  endDate,
  idsPromotions,
  libelle,
  titreBouton,
  urlBouton,
  imageFile
) {
  if (!libelle || !titreBouton) {
    toast.error("il faut remplir les champs");
    return true;
  }

  if (imageFile == null) {
    toast.error("il faut ajouter image");
    return true;
  }
  if (!idsPromotions || idsPromotions.length === 0) {
    toast.error("Il faut sélectionner une promotion");
    return true;
  }

  if (TestValidationDateBetween(startDate, endDate)) {
    toast.error("il faut selectionner date correcte");
    return true;
  }
}

function TestInputTotalLook(model, imageFile, produitsLook) {
  if (
    !model ||
    Object.values(model).some((value) => value === undefined) ||
    Object.values(model).some((value) => !value)
  ) {
    toast.error("Il faut remplir tous les champs");
    return true;
  }

  if (imageFile == null) {
    toast.error("Il faut ajouter une image de ce look");
    return true;
  }

  if (!produitsLook || produitsLook.length < 2 || produitsLook.length > 4) {
    toast.error("Il faut ajouter entre 2 et 4 produits");
    return true;
  }
}
function TestInput(model) {
  if (
    !model ||
    Object.values(model).some((value) => value === undefined) ||
    Object.values(model).some((value) => !value)
  ) {
    toast.error("Il faut remplir tous les champs");
    return true;
  }
}
export {
  TestValidationDateBetween,
  TestInputVenteFlash,
  TestInputTotalLook,
  TestInput,
};
