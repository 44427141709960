import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEyeSlash,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import TopBarService from "src/services/TopBarService";
import Pagination from "src/common/Pagination";
import UpdateTopBar from "./UpdateTopBar";
import ArchiverAndVisibleTopBar from "./ArchiverAndVisibleTopBar";

function AllTopBar() {
  const [TopBarList, setTopBarList] = useState({});
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [index, setIndex] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [TopBartexte, setTopBartexte] = useState({});
  const [nbTopBar, setNbTopBar] = useState(0);

  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [isModalArchiverOrVisibleOpen, setIsModalArchiverOrVisibleOpen] =
    useState(false);
  const [value, setValue] = useState(null);
  const [topbarItem, setTopbarItem] = useState("");

  useEffect(() => {
    getAllTopBar();
    getNombreTopBar();
  }, [currentPage, itemsPerPage]);

  const getAllTopBar = () => {
    TopBarService.getAllTopBar(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setTopBarList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getNombreTopBar = () => {
    TopBarService.getNombreTopBar()
      .then((response) => {
        if (response.data.success) {
          setNbTopBar(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  //**************for edit top bar modal
  const openModalConfirmation = (index, texte) => {
    setIsModalConfirmationOpen(true);
    setIndex(TopBarList[index]?.id);
    setTopBartexte(texte);
  };

  //**************for archiver or visibled top bar modal
  const openModalArchiverOrVisible = (index, topbar, value) => {
    setIsModalArchiverOrVisibleOpen(true);
    setIndex(TopBarList[index]?.id);
    setValue(value);
    setTopbarItem(topbar);
  };

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les TopBar </b> : {nbTopBar}
                    </h5>
                    <button
                      className="btn btn-dark float-right"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={() => (window.location.href = "/addTopBar")}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">Les Top Bar disponibles</h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th># texte</th>
                            <th>etat visible</th>
                            <th>etat archiver</th>

                            <th>visible</th>
                            <th>archiver</th>
                            <th>Modifier</th>
                          </tr>
                        </thead>
                        <tbody>
                          {TopBarList &&
                          TopBarList.length > 0 &&
                          Array.isArray(TopBarList) ? (
                            TopBarList.map((topbar, index) => (
                              <tr key={index}>
                                <td>{topbar?.texte || ""}</td>
                                <td>{topbar?.visible ? "oui" : "non"}</td>
                                <td>{topbar?.archived ? "oui" : "non"}</td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-secondary"
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() =>
                                      openModalArchiverOrVisible(
                                        index,
                                        topbar,
                                        "visible"
                                      )
                                    }
                                  >
                                    Visible
                                    <FontAwesomeIcon
                                      icon={faEyeSlash}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-secondary"
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() =>
                                      openModalArchiverOrVisible(
                                        index,
                                        topbar,
                                        "archiver"
                                      )
                                    }
                                  >
                                    Archiver
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-secondary"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() =>
                                      openModalConfirmation(
                                        index,
                                        topbar?.texte
                                      )
                                    }
                                  >
                                    Modifier
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="4">Pas de Topbar pour le moment</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {TopBarList.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalConfirmationOpen && (
        <UpdateTopBar
          isModalConfirmationOpen={isModalConfirmationOpen}
          setIsModalConfirmationOpen={setIsModalConfirmationOpen}
          idTopBar={index}
          topBarTexte={TopBartexte}
        />
      )}
      {isModalArchiverOrVisibleOpen && (
        <ArchiverAndVisibleTopBar
          isModalArchiverOrVisibleOpen={isModalArchiverOrVisibleOpen}
          setIsModalArchiverOrVisibleOpen={setIsModalArchiverOrVisibleOpen}
          idTopBar={index}
          value={value}
          topbarItem={topbarItem}
        />
      )}
    </>
  );
}

export default AllTopBar;
