import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import WarehouseService from "../../../services/WarehouseService";
// import getCurrentUserId from "../../../reduxSaga/sagas/requests/currentUserId";
import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import CurrencyInput from "react-currency-input";
import DiscountService from "../services/DiscountService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import getCurrentUserId from "src/common/currentUserId";

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};
const AddDiscountPanier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const animatedComponents = makeAnimated();
  const [warehouseSelectPlaceHolder, setWarehouseSelectPlaceHolder] =
    useState(null);
  const [listWarehouseId, setListWarehouseId] = useState([]);
  const [selectedOptionWarehouse, setSelectedOptionWarehouse] = useState(null);
  const [allWarehouse, setAllWarehouse] = useState([{}]);
  const [warehouses, setWarehouses] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState();
  // const [openInfoAlert, setOpenInfoAlert] = useState(false);
  // const [infoAlertText, setInfoAlertText] = useState();
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [successAlertText, setSuccessAlertText] = useState();
  const [startDate, setStartDate] = useState("any");
  const [endDate, setEndDate] = useState("any");
  const [type, setType] = useState(1);
  const [typeProduit, setTypeProduit] = useState(1);

  const [extrats, setExtrats] = useState([]);
  const [idPromotion, setIdPromotion] = useState([]);

  // const [extratsProduct, setExtratsProduct] = useState([]);
  // const [extratsParProduct, setExtratsParProduct] = useState([]);

  const [valueMin, setValueMin] = useState(0);
  const [ordreProduct, setOrdreProduct] = useState(1);

  const [valueMax, setValueMax] = useState(0);
  // const [valueAction, setValueAction] = useState(0);
  const [amount, setAmount] = useState(0);
  const [percent, setPercent] = useState(0);

  const [nbrProducts, setNbrProducts] = useState(0);

  // useEffect(() => {
  //     WarehouseService.getAllRealWarehouse(getCurrentUserId(), 0)
  //         .then((response) => {
  //             if (response.data.success) {
  //                 setWarehouses(response.data.success);
  //             } else {
  //                 setOpenErrorAlert(true);
  //                 setErrorAlertText("Erreur : pas de dépôt");
  //             }
  //         })
  //         .catch((e) => {
  //             console.log("error in getAllRealWarehouse", e);
  //         });
  // }, []);
  // useEffect(() => {
  //   warehouses &&
  //     warehouses.map((element) => {
  //       let exist = false;
  //       if (allWarehouse.length !== 0) {
  //         for (let index = 0; index < allWarehouse.length; index++) {
  //           if (allWarehouse[index].value === element?.id) {
  //             exist = true;
  //             break;
  //           }
  //         }
  //       }
  //       if (element?.id !== undefined && exist === false) {
  //         setAllWarehouse((oldArray) => [
  //           ...oldArray,
  //           { value: element.id, label: element.name },
  //         ]);
  //       }
  //       //here
  //     });
  // }, [warehouses]);

  const handleEroorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessAlert(false);
  };

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
  };
  const onChangeDescriptionHandler = (event) => {
    setDescription(event.target.value);
  };
  const onChangeSelectedWarehouse = (selectedOption) => {
    setWarehouseSelectPlaceHolder();
    console.log(selectedOption);
    setListWarehouseId(selectedOption);
  };

  const onChangeStartDate = (event) => {
    setStartDate(event.target.value.concat(":59"));
  };
  const onChangeEndDate = (event) => {
    setEndDate(event.target.value.concat(":59"));
  };
  const onValueChange = (event) => {
    if (event.target.value === "1") {
      setType(1);
    } else if (event.target.value === "2") {
      setType(2);
    }
  };
  const onValueChangeTypeProduit = (event) => {
    if (event.target.value === "1") {
      setTypeProduit(1);
    } else if (event.target.value === "2") {
      setTypeProduit(2);
    }
  };
  const changeValeurMin = (event) => {
    setValueMin(event.target.value);
  };
  const changeValeurMax = (event) => {
    setValueMax(event.target.value);
  };
  // const changeValeurAction = (event) => {
  //     setValueAction(event.target.value);
  // };
  function createUI() {
    // const animatedComponents = makeAnimated();

    return (
      <div class="row">
        <div class="col-md-2">
          <label>Min </label>
          <div className="input-group">
            <CurrencyInput
              id="montantDevise"
              className="form-control"
              precision="3"
              value={valueMin}
              onChangeEvent={changeValeurMin}
            />
          </div>
        </div>
        <div class="col-md-2">
          <label>Max </label>
          <div className="input-group">
            <CurrencyInput
              id="montantDevise"
              className="form-control"
              precision="3"
              value={valueMax}
              onChangeEvent={changeValeurMax}
            />
          </div>
        </div>
        {/* <div class="col-md-3">
                    <label>Action </label>
                    <div className="input-group">
                        <CurrencyInput
                            id="montantDevise"
                            className="form-control"
                            precision="3"
                            value={valueAction}
                            onChangeEvent={changeValeurAction}
                        />
                    </div>
                </div> */}
        <div className="col-sm-3">
          <div className="input-group">
            <label>Pourcentage Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="percent"
                className="form-control"
                precision="3"
                value={percent}
                onChangeEvent={onChangePercentHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-solid fa-percent"></i>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-1">
          <div className="input-group">
            <label>Ou</label>{" "}
          </div>
        </div>
        <div className="col-sm-3">
          <div className="input-group">
            <label>Montant Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="amount"
                className="form-control"
                precision="3"
                value={amount}
                onChangeEvent={onChangeAmountHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-dollar-sign"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const onChangePercentHandler = (event) => {
    setPercent(event.target.value);
  };
  const onChangeAmountHandler = (event) => {
    setAmount(event.target.value);
  };
  function createUIProducts() {
    return (
      <div class="row">
        <div className="col-sm-3">
          <label>Choisir le nombre de produits</label>
          <input
            onChange={onChangeNbrProductsHandler}
            value={nbrProducts}
            type="number"
            min="0"
            id="nbrProducts"
            className="form-control"
          />
        </div>

        {/* <div class="col-md-6">
                    <label>Action </label>
                    <div className="input-group">
                        <CurrencyInput
                            id="montantDevise"
                            className="form-control"
                            precision="3"
                            value={valueAction}
                            onChangeEvent={changeValeurAction}
                        />
                    </div>
                </div> */}

        <div className="col-sm-4">
          <div className="input-group">
            <label>Pourcentage Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="percent"
                className="form-control"
                precision="3"
                value={percent}
                onChangeEvent={onChangePercentHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-solid fa-percent"></i>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-1">
          <div className="input-group">
            <label>Ou</label>{" "}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="input-group">
            <label>Montant Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="amount"
                className="form-control"
                precision="3"
                value={amount}
                onChangeEvent={onChangeAmountHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-dollar-sign"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function createUIParProducts() {
    return (
      <div class="row">
        <div className="col-sm-3">
          <label>L'ordre des produits</label>
          <br />
          <label>{ordreProduct}</label>
        </div>

        {/* <div class="col-md-6">
                    <label>Pourcentage </label>
                    <div className="input-group">
                        <CurrencyInput
                            id="montantDevise"
                            className="form-control"
                            precision="3"
                            value={valueAction}
                            onChangeEvent={changeValeurAction}
                        />
                    </div>
                </div> */}
        <div className="col-sm-4">
          <div className="input-group">
            <label>Pourcentage Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "85%" }}>
              <CurrencyInput
                id="percent"
                className="form-control"
                precision="3"
                value={percent}
                onChangeEvent={onChangePercentHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-solid fa-percent"></i>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-1">
          <div className="input-group">
            <label>Ou</label>{" "}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="input-group">
            <label>Montant Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "85%" }}>
              <CurrencyInput
                id="amount"
                className="form-control"
                precision="3"
                value={amount}
                onChangeEvent={onChangeAmountHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-dollar-sign"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  console.log("*********");
  console.log("valueMin :", valueMin);
  console.log("valueMax :", valueMax);
  console.log("amount :", amount);
  console.log("percent :", percent);
  console.log("*********");

  function addClick() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        valueMin: valueMin,
        valueMax: valueMax,
        amount: amount,
        percent: percent,
      },
    ]);
    setAmount(0);
    setPercent(0);
    setValueMin(0);
    setValueMax(0);
  }
  function addClickProduct() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        nbrProducts: nbrProducts,
        amount: amount,
        percent: percent,
        // valueAction: valueAction,
      },
    ]);
    setPercent(0);
    setAmount(0);
    setNbrProducts(0);
  }
  function addClickParProduct() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        ordreProduct: ordreProduct,
        amount: amount,
        percent: percent,
      },
    ]);
    setOrdreProduct(ordreProduct + 1);
    setPercent(0);
    setAmount(0);
  }
  const removeExtrat = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
    }
  };
  const removeExtratProduct = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
    }
  };
  const removeExtratParProduct = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
    }
  };
  function addExtratToView() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["valueMin"] === "" &&
      extrats[0]["valueMax"] === "" &&
      extrats[0]["amount"] === "" &&
      extrats[0]["percent"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>Valeur Min</td>
              <td>Valeur Max</td>
              <td>pourcentage Remise</td>
              <td>Montant Remise</td>
              <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.valueMin}</td>
                  <td>{extrat.valueMax}</td>
                  <td>{extrat.percent}</td>
                  <td>{extrat.amount}</td>

                  <td>
                    <button
                      type="button"
                      class="btn btn-danger float-right"
                      onClick={() => removeExtrat(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  function addExtratToViewProduct() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["amount"] === "" &&
      extrats[0]["percent"] === "" &&
      extrats[0]["amount"] === "" &&
      extrats[0]["nbrProducts"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>Nombre d'articles</td>
              <td>Promotion Pourcentage</td>
              <td>Promotion Montant</td>
              <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.nbrProducts}</td>
                  <td>{extrat.percent}</td>
                  <td>{extrat.amount}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={() => removeExtratProduct(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  function addExtratToViewParProduct() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["ordreProduct"] === "" &&
      extrats[0]["amount"] === "" &&
      extrats[0]["percent"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>L'article le moin cher</td>
              <td>Promotion Pourcentage</td>
              <td>Promotion Montant</td> <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.ordreProduct}</td>
                  <td>{extrat.percent}</td>
                  <td>{extrat.amount}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={() => removeExtratParProduct(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  const onChangeNbrProductsHandler = (event) => {
    setNbrProducts(event.target.value);
  };
  const actionProductsTable = () => {
    if (typeProduit === 1) {
      return <>{productsTable()}</>;
    } else if (typeProduit === 2) {
      return <>{parProductsTable()}</>;
    }
  };
  const parProductsTable = () => {
    return (
      <>
        {createUIParProducts()}
        <br />
        <label></label>
        <button
          style={{ height: "40 px", width: "40 px" }}
          type="button"
          className="btn btn-dark float-right"
          onClick={addClickParProduct}
        >
          <i class="fa fa-plus"></i>
        </button>
        {addExtratToViewParProduct()}
      </>
    );
  };

  const productsTable = () => {
    return (
      <>
        {createUIProducts()}
        <br />
        <label></label>
        <button
          style={{ height: "40 px", width: "40 px" }}
          type="button"
          className="btn btn-dark float-right"
          onClick={addClickProduct}
        >
          <i class="fa fa-plus"></i>
        </button>
        {addExtratToViewProduct()}
      </>
    );
  };
  const typePromotionCart = () => {
    if (type === 1) {
      return (
        <div>
          <br />
          <div class="form-group  clearfix">
            <div class="icheck-asbestos d-inline">
              <input
                id="totaliteproduit"
                type="radio"
                name="totaliteproduit"
                value="1"
                checked={typeProduit === 1}
                onChange={onValueChangeTypeProduit}
              />
              <label for="totaliteproduit">
                Action sur la totalité des produits{" "}
              </label>
            </div>

            <div class="icheck-asbestos d-inline">
              <input
                id="produit"
                type="radio"
                name="produit"
                value="2"
                checked={typeProduit === 2}
                onChange={onValueChangeTypeProduit}
              />

              <label for="produit">Action par produit</label>
            </div>
          </div>
          {actionProductsTable()}
        </div>
      );
    } else if (type === 2) {
      return (
        <div className="form-group">
          <label>La valeur du panier</label>
          {createUI()}
          <br />
          <button
            type="button"
            class="btn btn-dark float-right"
            onClick={addClick}
          >
            <i class="fa fa-plus"></i>
          </button>
          {addExtratToView()}
        </div>
      );
    }
  };
  const onValidHandler = () => {
    if (name == null) {
      setOpenErrorAlert(true);
      setErrorAlertText("Erreur :le nom est vide ! ");
    } else if (description == null) {
      setOpenErrorAlert(true);
      setErrorAlertText("Erreur :la description est vide ! ");
    }

    let promotionCartAddModel = {
      libelle: name,
      description: description,
      idUser: getCurrentUserId(),
      type: type,
      listRules: extrats,
      typeConfigPromotion: typeProduit,
    };
    console.log("promotionCartAddModel :", promotionCartAddModel);
    console.log("listRules :", extrats);

    DiscountService.addPromotionCart(promotionCartAddModel).then((result) => {
      if (result.data.success) {
        setOpenSuccessAlert(true);
        setSuccessAlertText("Enregistrement avec succées");
        setTimeout(() => {
          navigate(`/allPromotions`);
        }, 2000);
      } else {
        setOpenErrorAlert(true);
        setErrorAlertText("Erreur :erreur serveur ");
      }
    });
  };
  return (
    <section className="content" style={{ marginTop: "1%" }}>
      <div className="container-fluid">
        {/* <div className="row">
                <div className="col-md-12">
                    <div className="card card-dark">
                        <div className="card-header">
                            <h5 className="card-title">Promotion sur panier</h5>
                        </div>
                        <div className="card-body">
                            
                        </div>
                    </div>
                </div>
            </div> */}
        <div className="timeline">
          <div className="time-label">
            <span style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>Règle Sur Panier</span>
            </span>
          </div>
          <div>
            <i className="fas fa-1 " style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>1</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 12:05
              </span>
              <h3 className="timeline-header">
                <b>Informations Générales</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="inputName">Libellé</label>
                      <input
                        onChange={onChangeNameHandler}
                        type="text"
                        id="inputName"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="inputDescription">Description</label>
                      <textarea
                        value={description}
                        placeholder="Saisir..."
                        onChange={onChangeDescriptionHandler}
                        id="inputDescription"
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <i className="fas fa-2 " style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>2</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 12:05
              </span>
              <h3 className="timeline-header">
                <b>Choisir le dépot</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Choisir le/ les dépôt</label>
                      <Select
                        styles={styles}
                        isMulti
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={selectedOptionWarehouse}
                        onChange={onChangeSelectedWarehouse}
                        options={allWarehouse}
                        placeholder={warehouseSelectPlaceHolder}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <i className="fas fa-3 " style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>3</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 5 mins ago
              </span>
              <h3 className="timeline-header no-border">
                <b>Choisir le type de la promotion</b>{" "}
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Promotion sur : </label>{" "}
                    <div class="form-group  clearfix">
                      <div class="icheck-asbestos d-inline">
                        <input
                          id="brand"
                          type="radio"
                          name="Marque"
                          value="1"
                          checked={type === 1}
                          onChange={onValueChange}
                        />
                        <label for="brand">Nombre de produits </label>{" "}
                      </div>

                      <div class="icheck-asbestos d-inline">
                        <input
                          id="familly"
                          type="radio"
                          name="Famille"
                          value="2"
                          checked={type === 2}
                          onChange={onValueChange}
                        />

                        <label for="familly">Valeur Panier</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <i className="fas fa-4" style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>4</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 27 mins ago
              </span>
              <h3 className="timeline-header">
                <b> Configurer Promotion</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12"> {typePromotionCart()}</div>
                </div>
              </div>
            </div>
          </div>
          {/* date debut et fin */}

          {/* <div>
                        <i className="fas fa-6" style={{ backgroundColor: "black" }}>
                            <span style={{ color: "white" }}>5</span>
                        </i>
                        <div className="timeline-item">
                            <span className="time">
                                <i className="fas fa-clock" /> 27 mins ago
                            </span>
                            <h3 className="timeline-header">
                                <b> Choisir la période de la promotion</b>
                            </h3>
                            <div className="timeline-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="inputName">Date début</label>

                                            <TextField
                                                style={{ width: "100%" }}
                                                className="col-sm-12"
                                                variant="outlined"
                                                value={startDate}
                                                onChange={onChangeStartDate}
                                                id="datetime-local"
                                                type="datetime-local"
                                                defaultValue="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="inputName">Date fin</label>

                                            <TextField
                                                style={{ width: "100%" }}
                                                className="col-sm-12"
                                                variant="outlined"
                                                value={endDate}
                                                onChange={onChangeEndDate}
                                                id="datetime-local"
                                                type="datetime-local"
                                                defaultValue="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

          {/* <div>
                        <i className="fas fa-6" style={{ backgroundColor: "black" }}>
                            <span style={{ color: "white" }}>6</span>
                        </i>
                        <div className="timeline-item">
                            <span className="time">
                                <i className="fas fa-clock" /> 27 mins ago
                            </span>
                            <h3 className="timeline-header">
                                <b> Priorité</b>
                            </h3>
                            <div className="timeline-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="inputName">Priorité</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div>
            <button
              class="btn btn-dark"
              style={{ marginTop: "1%" }}
              onClick={onValidHandler}
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={openErrorAlert}
        autoHideDuration={2000}
        onClose={handleEroorClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert variant="filled" onClose={handleEroorClose} severity="error">
          {errorAlertText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccessAlert}
        autoHideDuration={2000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert variant="filled" onClose={handleSuccessClose} severity="success">
          {successAlertText}
        </Alert>
      </Snackbar>
    </section>
  );
};
export default AddDiscountPanier;
