import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class PromotionService {
  addPromotionProduct(model, file) {
    const formData = new FormData();
    formData.append("promotionProductAddModel", JSON.stringify(model));
    formData.append("file", file);

    return api.post(
      urlsCommon.BASE_PROMOTION_URL + "/addPromotionProduct",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  allPromotions() {
    return api.get(urlsCommon.BASE_PROMOTION_URL + "/allpromotions");
  }
  getpromotionCartDetails(idPromotion) {
    return api.get(
      urlsCommon.BASE_PROMOTION_URL + "/getpromotionCartDetails/" + idPromotion
    );
  }

  updatePromotionCart(model) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL + "/updatePromotionCart",
      model
    );
  }

  addPromotionCart(promotionCartAddModel) {
    return api.post(
      urlsCommon.BASE_PROMOTION_URL + "/addPromotionCart",
      promotionCartAddModel
    );
  }
  //*****************Declencheur */

  addDeclencheur(addDeclencheurModel) {
    return api.post(
      urlsCommon.BASE_PROMOTION_URL + "/addDeclencheur",
      addDeclencheurModel
    );
  }

  updateDeclencheur(model) {
    return api.put(urlsCommon.BASE_PROMOTION_URL + "/updateDeclencheur", model);
  }

  updatePrioritiesByDeclencheurId(id, model) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL + "/updatePrioritiesByDeclencheurId/" + id,
      model
    );
  }

  desactiverOrActivatePrioritiesByDeclencheurId(activate, id, model) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL +
        "/desactiverOrActivatePrioritiesByDeclencheurId/" +
        id +
        "/" +
        activate,
      model
    );
  }

  allDeclencheurs(page, size) {
    return api.get(urlsCommon.BASE_PROMOTION_URL + "/allDeclencheurs", {
      params: {
        page: page,
        size: size,
      },
    });
  }

  allPromotionsTypePromotion(idBrands, type) {
    return api.get(
      urlsCommon.BASE_PROMOTION_URL +
        "/allPromotionsTypePromotion/" +
        idBrands +
        "/" +
        type
    );
  }
  allRulesByWarehouse(rule) {
    return api.get(urlsCommon.BASE_PROMOTION_URL + "/allRules", rule);
  }
  addPriorityRules(priorityRule) {
    return api.get(
      urlsCommon.BASE_PROMOTION_URL + "/addPriorityRules",
      priorityRule
    );
  }
  ArchiverPromotionFromDeclencheur(id, orderPriority) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL +
        "/declencheur/ArchiverPromotionFromDeclencheur/" +
        id,
      null,
      {
        params: {
          orderPriority: orderPriority,
        },
      }
    );
  }
  VisibledPromotionFromDeclencheur(id, orderPriority) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL +
        "/declencheur/VisibledPromotionFromDeclencheur/" +
        id,
      null,
      {
        params: {
          orderPriority: orderPriority,
        },
      }
    );
  }
  allProduitsInPromotions(id, orderPriority) {
    return api.get(
      urlsCommon.BASE_PROMOTION_URL +
        "/declencheur/allProduitsInPromotions/" +
        id,
      {
        params: {
          orderPriority: orderPriority,
        },
      }
    );
  }

  getPrioritiesbyDeclencheur(id, page, size) {
    return api.get(
      urlsCommon.BASE_PROMOTION_URL + "/getPrioritiesbyDeclencheur/" + id,
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }
  searchPrioritieInDeclencheur(id, searchData, page, size) {
    return api.get(
      urlsCommon.BASE_PROMOTION_URL + "/searchPrioritieInDeclencheur/" + id,
      {
        params: {
          page: page,
          size: size,
          searchData: searchData,
        },
      }
    );
  }

  //*************Promotions Api added
  allPromotionsDispo(page, size) {
    return api.get(urlsCommon.BASE_PROMOTION_URL + "/allpromotions", {
      params: {
        page: page,
        size: size,
      },
    });
  }

  visibledPromotions(id) {
    return api.put(urlsCommon.BASE_PROMOTION_URL + "/visibled/" + id);
  }
  archivedPromotions(id) {
    return api.put(urlsCommon.BASE_PROMOTION_URL + "/archiver/" + id);
  }

  /*****for declencheur */
  allpromotionsForAddToDeclencheur(searchData) {
    return api.get(
      urlsCommon.BASE_PROMOTION_URL +
        "/declencheur/allpromotionsForAddToDeclencheur",
      {
        params: {
          searchData: searchData,
        },
      }
    );
  }
  getDeclencheurById(id) {
    return api.get(urlsCommon.BASE_PROMOTION_URL + "/getDeclencheurById/" + id);
  }

  DeletePrioritiesByIdFromDeclencheur(idPriorities) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL +
        "/DeletePrioritiesByIdFromDeclencheur/" +
        idPriorities
    );
  }
  VisibledPrioritiesByIdFromDeclencheur(idPriorities) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL +
        "/VisibledPrioritiesByIdFromDeclencheur/" +
        idPriorities
    );
  }

  visibleDeclencheur(id) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL + "/declencheur/visible/" + id
    );
  }

  archiverDeclencheur(id) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL + "/declencheur/archiver/" + id
    );
  }

  addProduitsToPromotion(idPromotion, produits) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL + "/addProduitsToPromotion/" + idPromotion,
      produits
    );
  }
  removeProduitFromPromotion(idPromotion, idProduit) {
    return api.put(
      urlsCommon.BASE_PROMOTION_URL +
        "/removeProduitFromPromotion/" +
        idPromotion +
        "/" +
        idProduit
    );
  }
}

export default new PromotionService();
