import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class TotalLookService {
  createTotalLook(model, refProduits, picture) {
    const formData = new FormData();
    formData.append("model", JSON.stringify(model));
    formData.append("references_produits", refProduits);
    formData.append("picture", picture);

    return api.post(urlsCommon.BASE_TOTALlOOK_URL + "/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  updateTotalLook(model, refProduits, picture) {
    const formData = new FormData();
    formData.append("model", JSON.stringify(model));
    formData.append("references_produits", refProduits);
    formData.append("picture", picture);

    return api.post(urlsCommon.BASE_TOTALlOOK_URL + "/update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  getAllTotalLook(page, size, search_totalLook, search_produits) {
    return api.get(urlsCommon.BASE_TOTALlOOK_URL + "/getAllTotalLook", {
      params: {
        page: page,
        size: size,
        search_totalLook: search_totalLook,
        search_produits: search_produits,
      },
    });
  }

  getTotalLoolById(id) {
    return api.get(urlsCommon.BASE_TOTALlOOK_URL + "/getTotalLoolById/" + id);
  }

  //visible and archive
  archive(id) {
    return api.put(urlsCommon.BASE_TOTALlOOK_URL + "/archive/" + id);
  }
  visible(id) {
    return api.put(urlsCommon.BASE_TOTALlOOK_URL + "/visible/" + id);
  }

  getAllProduitsByLook(id) {
    return api.get(
      urlsCommon.BASE_TOTALlOOK_URL + "/getAllProduitsByLook/" + id
    );
  }

  getProduitDetailsByRef(ref) {
    return api.get(
      urlsCommon.BASE_TOTALlOOK_URL + "/getProduitDetailsByRef/" + ref
    );
  }
}

export default new TotalLookService();
