import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class CarrousselHomeService {
  addCarrousselHome(image, model) {
    const formData = new FormData();
    formData.append("carrousselHomeModel", JSON.stringify(model));
    formData.append("file", image);

    return api.post(
      urlsCommon.BASE_CARROUSSEL_URL + "/addCarrousselHome",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  updateCarrousselHome(image, model) {
    const formData = new FormData();
    formData.append("carrousselHomeModel", JSON.stringify(model));
    formData.append("file", image);

    return api.put(
      urlsCommon.BASE_CARROUSSEL_URL + "/updateCarrousselHome",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  allCarrousselHome(page, size) {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_URL + "/allCarrousselHome/",

      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  getCarrouselHomeById(id) {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_URL + "/getCarrousselHomeById/" + id
    );
  }

  //visible and archive
  archiver(id) {
    return api.put(urlsCommon.BASE_CARROUSSEL_URL + "/archiver/" + id);
  }
  visibled(id) {
    return api.put(urlsCommon.BASE_CARROUSSEL_URL + "/visibled/" + id);
  }
}
export default new CarrousselHomeService();
