import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Files from "react-files";
import { toast } from "react-toastify";
import VariantsService from "../../services/VariantsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function UpdateImagesVariantPopup(props) {
  const {
    isModalOpenEdit,
    setIsModalOpenEdit,
    variantImages,
    idVariant,
    setIsSaved,
  } = props;
  const [files, setFiles] = useState([]);

  const onFilesChange = (files) => {
    setFiles(files);
  };

  const onFilesError = (error, file) => {
    console.error(`Error code ${error.code}: ${error.message}`);
  };

  const filesRemoveOne = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const filesRemoveAll = () => {
    setFiles([]);
  };

  const filesUpload = () => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });

    axios
      .post(`/files`, formData)
      .then((response) =>
        window.alert(`${files.length} files uploaded successfully!`)
      )
      .catch((err) => window.alert("Error uploading files :("));
  };

  const updateImages = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(files).forEach((key) => {
      const file = files[key];
      formData.append(
        "pictures",
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });

    console.log("picture", formData);
    VariantsService.updatePicture(formData, idVariant).then((response) => {
      console.log(idVariant);
      if (response.data["code"] === "201") {
        toast.success("Mise à jour de l'image avec succée");
        setIsModalOpenEdit(false);
        setIsSaved(true);
      } else if (response.data["code"] === "400") {
        toast.error(response.data["object"]);
      }

      console.log("response", response);
    });
  };

  return (
    <>
      <Modal show={isModalOpenEdit} onHide={() => setIsModalOpenEdit(false)}>
        <Modal.Header>
          <Modal.Title>Modifier les images (1200 L * 1800H)</Modal.Title>
          <button
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsModalOpenEdit(false)}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <label>Les images actuelles</label>
                </div>
                <div className="mb-5"></div>
                <div className="w-100"></div>
                <div className="col">
                  {variantImages && variantImages.length > 0 ? (
                    <div className="row">
                      {variantImages.map((file) => (
                        <div className="col" key={file.id}>
                          <img
                            alt="image"
                            src={file.urlImage}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    "Pas d'images"
                  )}
                </div>
                <div className="mb-5"></div>
                <div className="w-100"></div>
                <div className="col">
                  <Files
                    onChange={onFilesChange}
                    onError={onFilesError}
                    multiple
                    maxFiles={4}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    {files.length === 0 ? (
                      <label>Cliquer pour modifier l'image</label>
                    ) : (
                      <label>Les images après la modification</label>
                    )}
                  </Files>
                </div>{" "}
                <div className="mb-5"></div>
                <div className="w-100"></div>
                <div className="col">
                  {files.length > 0 ? (
                    <div className="row">
                      {files.map((file, index) => (
                        <div className="col" key={index}>
                          <img
                            alt="image"
                            src={file.preview.url}
                            onClick={() => filesRemoveOne(file)}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpenEdit(false)}>
            Fermer
          </Button>
          <Button variant="primary" onClick={updateImages}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateImagesVariantPopup;
