import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ProduitJourService {
  addProduitJour(picture, produitJourModel) {
    return api.post(
      urlsCommon.BASE_PRODUCT_JOUR_URL + "/addProduitJour",
      picture,
      {
        params: {
          produitJourModel,
        },
      }
    );
  }
  getProduitJour() {
    return api.get(urlsCommon.BASE_PRODUCT_JOUR_URL + "/allProduitJour");
  }
  deleteProduitJour(id, isArchive) {
    return api.get(
      urlsCommon.BASE_PRODUCT_JOUR_URL +
        "/deleteProduitjour/" +
        id +
        "/" +
        isArchive
    );
  }
}
export default new ProduitJourService();
