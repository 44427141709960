import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import ShopThematiqueService from "src/services/ShopThematiqueService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import urlsCommon from "src/common/urls-common";

export default function AddBanniereThematiqueHome() {
  const [thematique, setThematique] = useState({});
  const [typeBanniere, setTypeBanniere] = useState(0);
  //image
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [checkbox, setCheckbox] = useState(false);
  //for select page thematique
  const [listAllPageThematiqueExiste, setListAllPageThematiqueExiste] =
    useState([]);
  const [selectedlistAllPageThematique, setSelectedlistAllPageThematique] =
    useState([]);
  // const [selectedProductsDefault, setSelectedProductsDefault] = useState([]);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    getAllPagesThematiqueForSelectInUrlButtonBanniere();
  }, []);

  const handlePageThematiqueSelectedChange = (selectedOptions) => {
    setSelectedlistAllPageThematique(selectedOptions);
  };

  const handleChangeCheckbox = (event) => {
    setCheckbox(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setThematique({ ...thematique, [name]: value });
  };

  //*************************Chekbox */
  function onValueChange(event) {
    if (event.target.value === "0") {
      setTypeBanniere(0);
    } else if (event.target.value === "1") {
      setTypeBanniere(1);
    }
  }

  //******for image */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const addThematiqueBanniere = (e) => {
    e.preventDefault();
    const thematiqueModel = {
      titre: thematique.titre,
      texte: thematique.description,
      type: typeBanniere,
      titreBouton: thematique.titreBouton || "",
      urlBouton:
        urlsCommon.ADRESS_Site +
        "thematique/vetements/?page=1&theme=" +
        selectedlistAllPageThematique.label,
      visible: checkbox,
    };

    ShopThematiqueService.addThematiqueBanniere(thematiqueModel, imageFile)
      .then((response) => {
        if (response.data.success) {
          setCheckbox(false);
          setTypeBanniere(0);
          setThematique({});
          handleDeleteClick();
          setSelectedlistAllPageThematique("");
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.href = "/allBanniereThematiqueHome";
          }, 2000);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };
  const getAllPagesThematiqueForSelectInUrlButtonBanniere = () => {
    ShopThematiqueService.getAllPagesThematiqueForSelectInUrlButtonBanniere()
      .then((response) => {
        if (response.data.success) {
          setListAllPageThematiqueExiste(response.data.success);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <ToastContainer />
          <div className="col-md-12">
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Ajouter Banniere Thematique</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Titre :</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-clock"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="titre"
                      name="titre"
                      value={thematique?.titre || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="description"
                      value={thematique?.description || ""}
                      name="description"
                      onChange={handleChange}
                      rows={3}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">
                        Image Banniere {""}
                        {typeBanniere == 1 ? "(ratio 0.20)" : null}
                      </label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="exampleInputFile"
                            onChange={handleFileChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                            id="fileInputLabel"
                          >
                            Choose file
                          </label>
                        </div>
                        {imageFile && (
                          <>
                            <div className="input-group-append">
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={handleDeleteClick}
                              >
                                Delete
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100"></div>

                  <div className="col-sm-6">
                    {imageFile ? (
                      <>
                        <div className="input-group-append">
                          <img
                            src={imagePreview}
                            alt="Selected"
                            style={{ width: "80%" }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {thematique?.urlImage ? (
                          <div className="input-group-append">
                            <img
                              src={thematique?.urlImage || ""}
                              alt="Selected"
                              style={{ width: "80%" }}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div className="mb-4"></div>

                <div className="row ">
                  <div className="col-md-6">
                    <div className="form-group clearfix">
                      <div className="icheck-asbestos d-inline">
                        <input
                          id="section1"
                          type="radio"
                          name="section1"
                          value="0"
                          checked={typeBanniere === 0}
                          onChange={onValueChange}
                        />
                        <label htmlFor="section1">
                          Bannière de page d'accueil
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group clearfix">
                      <div className="icheck-asbestos d-inline">
                        <input
                          id="section2"
                          type="radio"
                          name="section2"
                          value="1"
                          checked={typeBanniere === 1}
                          onChange={onValueChange}
                        />
                        <label htmlFor="section2">
                          Bannière de page Thematique
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3"></div>
                {typeBanniere == 0 && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Button Titre</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="far fa-clock"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="title Button Banniere"
                              value={thematique?.titreBouton || ""}
                              onChange={handleChange}
                              name="titreBouton"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* <div className="form-group">
                          <label>Lien Button</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="far fa-clock"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Lien Button Banniere"
                              value={thematique?.urlBouton || ""}
                              onChange={handleChange}
                              name="urlBouton"
                            />
                          </div>
                        </div> */}

                        <div className="form-group">
                          <label>Sélectionner Page Thematique</label>
                          <Select
                            defaultValue={selectedlistAllPageThematique || ""}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={listAllPageThematiqueExiste}
                            onChange={handlePageThematiqueSelectedChange}
                            value={selectedlistAllPageThematique}
                            placeholder="Sélectionner Les produits..."
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-12 mt-3">
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={thematique.visible}
                        onChange={handleChangeCheckbox}
                        name="visible"
                      />
                      Rendre visible dans le site
                    </label>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-default"
                  onClick={() => {
                    window.location.href = "/allBanniereThematiqueHome";
                  }}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-secondary float-right"
                  onClick={addThematiqueBanniere}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
