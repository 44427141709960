import { Component } from "react";
import FamilyProductService from "../../services/FamilyProductService";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import Files from "react-files";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuElementService from "../../services/MenuElementService";
import { Navigate } from "react-router";
export default class AddMenuElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeElement: "",
      nameElement: "",
      familyProduct: "",
      idFamily: "",
      selectedOptionType: "",
      selectedOption: "",
      families: "",
      options: [],
      files: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeNameElement = this.changeNameElement.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.addMenuElement = this.addMenuElement.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();

    FamilyProductService.getAllFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ families: response.data["object"] });
          console.log("families", this.state.families);
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  remplissageOptions() {
    this.state.families.map((cat) =>
      this.setState({
        options: [...this.state.options, { value: cat.id, label: cat.name }],
      })
    );
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ idFamily: selectedOption.value });
    console.log("idFamily", this.state.idFamily);
  };
  handleChangeType = (selectedOptionType) => {
    this.setState({ selectedOptionType }, () =>
      console.log(`Option selected:`, this.state.selectedOptionType)
    );
    this.setState({ typeElement: selectedOptionType.label });
    console.log("typeElement", this.state.typeElement);
  };

  menuFamilyForm() {
    const animatedComponents = makeAnimated();
    console.log("typeElement", this.state.typeElement);
    if (this.state.typeElement === "Famille produits") {
      return (
        <div className="row">
          {console.log("menuFamilyForm")}
          <div class="col-md-6">
            <label>Choisir la famille</label>
            <Select
              id={"SelectCategory"}
              value={this.state.selectedOption}
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={this.state.options}
              onChange={this.handleChange}
              placeholder="Choisir..."
            />
          </div>
          <div className="col-md-6">
            <label>Choisir l'image()</label>
            <Files
              ref="files"
              className="files-dropzone-list"
              // style={{ height: "100px" }}
              onChange={this.onFilesChange}
              onError={this.onFilesError}
              multiple
              maxFiles={1}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <label>Cliquer pour Ajouter une image</label>
            </Files>

            {this.state.files.length > 0 ? (
              <div className="files-list">
                <ul>
                  {this.state.files.map((file) => (
                    <li className="files-list-item" key={file.id}>
                      <div className="files-list-item-preview">
                        {file.preview.type === "image" ? (
                          <div className="row">
                            <img
                              className="files-list-item-preview-image"
                              src={file.preview.url}
                              style={{
                                width: "700px",
                                height: "300px",
                              }}
                            />
                            <button
                              id={file.id}
                              class="btn btn-navbar"
                              type="button"
                              // data-widget="navbar-search"
                              onClick={this.filesRemoveOne.bind(this, file)}
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        ) : (
                          <div className="files-list-item-preview-extension">
                            {file.extension}
                          </div>
                        )}
                      </div>
                      <div className="files-list-item-content">
                        <div className="files-list-item-content-item files-list-item-content-item-1">
                          {file.name}
                        </div>
                        <div className="files-list-item-content-item files-list-item-content-item-2">
                          {file.sizeReadable}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      );
    }
  }

  changeNameElement(event) {
    this.setState({ nameElement: event.target.value });
    console.log("nameElement", this.state.nameElement);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log("onFilesChange", this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  addMenuElement = (e) => {
    e.preventDefault();

    let type = this.state.typeElement;
    console.log("type element in add", this.state.typeElement);
    if (this.state.typeElement === "Famille produits") {
      const dataFile = new FormData();
      dataFile.append("picture", this.state.files[0]);

      console.log("picture", dataFile);

      let menuElementModel = {
        typeElement: this.state.typeElement,
        nameElement: this.state.nameElement,
        idFamily: this.state.idFamily,
      };

      MenuElementService.addMenuElementFamily(
        dataFile,
        JSON.stringify(menuElementModel)
      )
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Ajout de l'element de menu avec succée");
            setTimeout(() => {
              this.setState({ redirect: true });
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }
          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    } else {
      console.log("in else addMenuElement");
      // let menuElementModel = {
      //   typeElement: this.state.typeElement,
      //   nameElement: this.state.nameElement,
      // };
      console.log(" addMenuElement name", this.state.nameElement);
      console.log(" addMenuElement type", this.state.typeElement);
      MenuElementService.addMenuElement(
        this.state.nameElement,
        this.state.typeElement
      )
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Ajout de l'element de menu avec succée");
            setTimeout(() => {
              this.setState({ redirect: true });
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }
          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    }
  };

  render() {
    const animatedComponents = makeAnimated();
    const options = [
      { value: 1, label: "Famille produits" },
      { value: 2, label: "Bon Plan" },
      { value: 3, label: "Promotion" },
      { value: 4, label: "Nos Marques" },
    ];
    if (this.state.redirect) {
      return <Navigate replace to="/allMenuElement" />;
    } else {
      return (
        <div class="content-wrapper">
          <ToastContainer />
          <section class="content-header">
            <div class="container-fluid">
              <div class="callout callout-success">
                <h5>Ajouter L'élement de menu</h5>
                {/* Deux étapes:
              <br /> 1-Ajouter le libellé
              <br /> 2-L'images de la caroussel (1366px * 607px) */}
              </div>
            </div>
          </section>

          <div className="col-12 col-sm-12">
            <div className="card card-success card-outline ">
              <form>
                <div className="card-header">
                  <h3 className="card-title">
                    <b>Ajouter l'élement de menu</b>
                  </h3>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Titre de l'element de menu</label>
                      <input
                        type="title"
                        className="form-control"
                        placeholder="Ajouter Sous Titre"
                        value={this.state.nameElement}
                        onChange={this.changeNameElement}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Choisir la type de l'element</label>
                      <Select
                        id={"SelectCategory"}
                        value={this.state.selectedOptionType}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={options}
                        onChange={this.handleChangeType}
                        placeholder="Choisir..."
                      />
                    </div>
                  </div>
                  {this.menuFamilyForm()}

                  <br />
                </div>

                {/* </div> */}
                <br />
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-success float-right"
                    onClick={this.addMenuElement}
                  >
                    Ajouter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}
