import axios from "axios";
import { Component } from "react";
import Files from "react-files";
import BrandService from "../../services/BrandService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class BrandPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      files: [],
      visible: false,
      closePopupBrand: "",
    };
    this.changeLibelle = this.changeLibelle.bind(this);
    this.handleVisible = this.handleVisible.bind(this);
  }
  handleVisible(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }
  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  filesUpload = () => {
    const formData = new FormData();
    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      formData.append(
        key,
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });

    axios
      .post(`/files`, formData)
      .then((response) =>
        window.alert(`${this.state.files.length} files uploaded succesfully!`)
      )
      .catch((err) => window.alert("Error uploading files :("));
  };

  saveBrand = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    //console.log("picture", dataFile);
    BrandService.addBrand(dataFile, this.state.libelle, this.state.visible)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la marque avec succée");
          //window.location.reload(false);
          //this.setState({ closePopupBrand: true });
          this.props.closePopupBrand(true);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };
  render() {
    return (
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Ajouter une marque</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2">
                    <label>Libellé</label>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="libelle"
                      className="form-control"
                      placeholder="Saisir..."
                      value={this.state.libelle}
                      onChange={this.changeLibelle}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <Files
                        ref="files"
                        className="files-dropzone-list"
                        // style={{ height: "100px" }}
                        onChange={this.onFilesChange}
                        onError={this.onFilesError}
                        multiple
                        maxFiles={1}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <label>Cliquer pour Ajouter une image</label>
                      </Files>

                      {this.state.files.length > 0 ? (
                        <div className="files-list">
                          <ul>
                            {this.state.files.map((file) => (
                              <li className="files-list-item" key={file.id}>
                                <div className="files-list-item-preview">
                                  {file.preview.type === "image" ? (
                                    <div className="row">
                                      <img
                                        className="files-list-item-preview-image"
                                        src={file.preview.url}
                                        // style={{
                                        //   width: "10%",
                                        //   height: "10%",
                                        // }}
                                      />
                                      <button
                                        id={file.id}
                                        class="btn btn-navbar"
                                        type="button"
                                        // data-widget="navbar-search"
                                        onClick={this.filesRemoveOne.bind(
                                          this,
                                          file
                                        )}
                                      >
                                        <i class="fas fa-times"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="files-list-item-preview-extension">
                                      {file.extension}
                                    </div>
                                  )}
                                </div>
                                <div className="files-list-item-content">
                                  <div className="files-list-item-content-item files-list-item-content-item-1">
                                    {file.name}
                                  </div>
                                  <div className="files-list-item-content-item files-list-item-content-item-2">
                                    {file.sizeReadable}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <h1></h1>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          defaultChecked={this.state.visible}
                          onChange={this.handleVisible}
                        />{" "}
                        Visible sur le site web
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-success"
              onClick={this.saveBrand}
            >
              Valider
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
    );
  }
}
