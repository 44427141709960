import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import getCurrentUserId from "../../../reduxSaga/sagas/requests/currentUserId";
// import SellingService from "../../../services/SellingService";
// import WarehouseService from "../../../services/WarehouseService";
import UtilsFormule from "../utils/UtilsFormule";
import update from "immutability-helper";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { FaTable, FaCalendar } from "react-icons/fa";
// import Divider from "@material-ui/core/Divider";
import { Card, Button } from "react-bootstrap";
// import "../../../utils/Css/Campagne.css";
import {
  getAllBrands,
  getAllFamilies,
  getAllSubFamiliesByFamilyId,
  getAllCategoriesBySubFamilyId,
} from "../reduxSaga/actions";
import AddDiscountProduct from "./AddDiscountProduct";
import AddDiscountPanier from "./AddDiscountPanier";
const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepIcon-root.MuiStepIcon-active ": {
      width: "100%",
      color: "#000000",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#000000",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: "#000",
  },
}));
function getSteps() {
  return ["Choisir type promo ", "Créer un devis ", "Finaliser"];
}
const AddDiscount = () => {
  const dispatch = useDispatch();

  const animatedComponents = makeAnimated();
  const [selectedOption, setSelectedOption] = useState(null);
  const [warehouseSourceId, setWarehouseSourceId] = useState(0);
  const [warehouseSelectPlaceHolder, setWarehouseSelectPlaceHolder] =
    useState("Choisir...");
  const [productBloc, setProductBloc] = useState(false);
  const [allProducts, setAllProducts] = useState([{}]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState();
  const [allWarehouseSource, setAllWarehouseSource] = useState([{}]);
  const [warehousesSource, setWarehousesSource] = useState([]);
  const [productId, setProductId] = useState(0);
  const [productSelectPlaceHolder, setProductSelectPlaceHolder] =
    useState("Choisir...");
  const [estimUnitPrice, setEstimUnitPrice] = useState("");
  const [estimQte, setEstimQte] = useState("");
  const [estimTva, setEstimTva] = useState("");
  const [estimDevise, setEstimDevise] = useState("");
  const [unitPrice, setUnitPrice] = useState(0);
  const [montantDevis, setMontantDevis] = useState(0);
  const [tva, setTva] = useState(0);
  const [labelDevise, setLabelDevise] = useState("Prix en DNT");
  const [quantity, setQuantity] = useState(0);
  const [productMapById, setProductMapById] = useState([]);
  const [modeProduct, setModeProduct] = useState(null);
  const [promotionType, setPromotionType] = useState("pourcentage");

  const [productTableData, setProductTableData] = useState([]);

  const [promotionPercentage, setPromotionPercentage] = useState(0);
  const [selectedOptionMode, setSelectedOptionMode] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [familyId, setFamilyId] = useState(0);
  const [allFamilly, setAllFamilly] = useState([]);
  const [allSubFamilies, setAllSubFamilies] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [typeFamily, setTypeFamily] = useState("");
  const [subFamilyId, setSubFamilyId] = useState(0);
  const [categoryId, setCategoryId] = useState(0);

  const availableBrands = useSelector((state) => state.staff.availableBrands);
  const availableFamilies = useSelector(
    (state) => state.staff.availableFamilies
  );
  const availableSubFamilies = useSelector(
    (state) => state.staff.availableSubFamilies
  );

  const availableCategories = useSelector(
    (state) => state.staff.availableCategories
  );

  const [panierClicked, setPanierClicked] = useState(false);
  const [productClicked, setProductClicked] = useState(true);
  const classes = useStyles();
  const steps = getSteps();
  const [type, setType] = useState(0);
  useEffect(() => {
    dispatch(getAllFamilies(0));
    dispatch(getAllBrands(0));
  }, []);
  useEffect(() => {
    familyId && dispatch(getAllSubFamiliesByFamilyId(familyId));
  }, [familyId]);
  useEffect(() => {
    subFamilyId && dispatch(getAllCategoriesBySubFamilyId(subFamilyId));
  }, [subFamilyId]);
  useEffect(() => {
    setAllFamilly([]);
    availableFamilies &&
      availableFamilies.map((element, i) => {
        if (i === 0) {
          setFamilyId(element.id);
          setTypeFamily(element.type);
        }
        setAllFamilly((oldArray) => [
          ...oldArray,
          {
            value: element.id,
            label: element.name,
            customElements: element.type,
          },
        ]);
      });
  }, [availableFamilies]);

  useEffect(() => {
    if (availableSubFamilies.length === 0) {
      setSubFamilyId(0);
      setCategoryId(0);
    }
    setAllSubFamilies([]);

    availableSubFamilies &&
      availableSubFamilies.map((element, i) => {
        if (i === 0) {
          setSubFamilyId(element.id);
        }
        setAllSubFamilies((oldArray) => [
          ...oldArray,
          {
            value: element.id,
            label: element.name,
            // customElements: element.type,
          },
        ]);
      });
  }, [availableSubFamilies]);

  useEffect(() => {
    subFamilyId && dispatch(getAllCategoriesBySubFamilyId(subFamilyId));
  }, [subFamilyId]);

  const handleClickPanier = () => {
    setPanierClicked(true);
    setProductClicked(false);
  };

  const handleClickProduct = () => {
    setPanierClicked(false);
    setProductClicked(true);
  };
  const onChangeSelectedWarehouseSource = (selectedOptionn) => {
    setSelectedOption();
    setWarehouseSourceId(selectedOptionn.value);
    setWarehouseSelectPlaceHolder(selectedOptionn.label);
  };

  const onChangeSelectedProduct = (selectedOptionn) => {
    console.log("selectedOptionn", selectedOptionn);
    setSelectedOption();
    setProductId(selectedOptionn.value);
    setProductSelectPlaceHolder(selectedOptionn.label);
    setEstimUnitPrice(selectedOptionn.customElements.price);
    setEstimQte(selectedOptionn.customElements.quantity);
    setEstimTva(selectedOptionn.customElements.tva + "%");
    setUnitPrice(selectedOptionn.customElements.price);
    setMontantDevis(selectedOptionn.customElements.price);
    setTva(selectedOptionn.customElements.tva);
    setEstimDevise(selectedOptionn.customElements.devise);
  };
  const onChangeUnitPriceHandler = (event) => {
    setUnitPrice(event.target.value);
    setLabelDevise("Prix U en DNT");
    setMontantDevis(event.target.value);
  };
  const onChangeTvaHandler = (event) => {
    setTva(event.target.value);
  };
  const onChangeQuantityHandler = (event) => {
    setQuantity(event.target.value);
  };
  const columns = [
    {
      dataField: "productRef",
      text: "Libellé",
      headerAlign: "center",
    },

    // {
    //   dataField: "promotionPercentage",
    //   text: "Remise",
    //   align: "center",
    //   headerAlign: "center",
    //   headerStyle: () => {
    //     return { width: "8%" };
    //   },
    // },
    {
      dataField: "idProduct",
      text: "Supprimer",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row, rowIndex, extraData) => (
        <span>
          <button className="btn btn-danger">
            <i class="fas fa-trash"></i>
          </button>
        </span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("productTableData", productTableData);

          setProductTableData(
            productTableData.filter((item) => item.idProduct !== row.idProduct)
          );
        },
      },
    },
  ];
  function etatInitial() {
    setSelectedOption(null);
    setProductSelectPlaceHolder("Choisir...");
    setUnitPrice(0);
    setQuantity(0);
    setTva(0);
    setSelectedOptionMode(null);
    setPromotionType("pourcentage");
    setPromotionPercentage(0);
    // setSelectedOptionLot(null);
    // setClientSelectedOption(null);
    // setLotSelectPlaceHolder("Choisir...");
  }
  const onAddProductToList = (event) => {
    let productRef = null;
    let exist = false;
    let indexInDataTable;
    try {
      productMapById.forEach((element, index) => {
        if (element.id === productId) {
          indexInDataTable = index;
          exist = true;
          throw "Break";
        }
      });
    } catch (e) {
      if (e !== "Break") throw e;
    }

    try {
      availableProducts.forEach((element) => {
        if (element.id === productId) {
          productRef = element.name;
          throw "Break";
        }
      });
    } catch (e) {
      if (e !== "Break") throw e;
    }
    if (productId === 0) {
      setOpenErrorAlert(true);
      setErrorAlertText("Erreur : Sélectionnez un produit ");
    } else {
      setProductMapById((previoustate) => [
        ...previoustate,
        {
          productRef: productRef,
          idProduct: productId,
        },
      ]);
      setProductTableData((previoustate) => [
        ...previoustate,
        {
          productRef: productRef,
          idProduct: productId,
        },
      ]);
    }

    etatInitial();
  };
  function handleTableChange({ productTableData }) {
    return productTableData;
  }

  function blocProducts() {
    if (productBloc && warehouseSourceId !== 0) {
      return (
        <div>
          <div class="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Choisir un produit</label>
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  defaultValue={selectedOption}
                  onChange={onChangeSelectedProduct}
                  options={allProducts}
                  placeholder={productSelectPlaceHolder}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label>Choisir une spécificité</label>
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  defaultValue={selectedOption}
                  onChange={onChangeSelectedProduct}
                  options={allProducts}
                  placeholder={productSelectPlaceHolder}
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div className="col-sm-12  text-center">
              <button
                style={{ marginBottom: 20 }}
                className="btn btn-dark"
                onClick={onAddProductToList}
              >
                <i class="fas fa-plus"></i>
                Liste des achats{" "}
              </button>
            </div>
          </div>
          <div class="row">
            <div className="col-sm-12">
              <div className="form-group">
                {productTableData && (
                  <ToolkitProvider
                    keyField="idProduct"
                    data={productTableData}
                    columns={columns}
                    onTableChange={handleTableChange}
                  >
                    {(props) => (
                      <div className="row">
                        <BootstrapTable
                          {...props.baseProps}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  const addPack = () => {
    // if (name == null) {
    //     setOpenErrorAlert(true);
    //     setErrorAlertText("Erreur :le nom est vide ! ");
    // } else if (type === 2) {
    //     if (sku === undefined || sku === null || sku === "") {
    //         setOpenErrorAlert(true);
    //         setErrorAlertText("Erreur :la référence est vide ! ");
    //     } else {
    //         let exist = UtilsFormule.testSKUExist(sku, listSkus);
    //         if (exist) {
    //             setOpenErrorAlert(true);
    //             setErrorAlertText("Erreur :la référence existe déjà ! ");
    //         } else {
    //             if (sku.length !== 2) {
    //                 setOpenErrorAlert(true);
    //                 setErrorAlertText("Erreur :la référence doit être deux lettres ! ");
    //             } else {
    //                 let pattern = UtilsFormule.testSKUPattern(sku);
    //                 if (!pattern) {
    //                     setOpenErrorAlert(true);
    //                     setErrorAlertText(
    //                         "Erreur :la référence doit contenir deux lettres majuscules ! "
    //                     );
    //                 }
    //             }
    //         }
    //     }
    // }
    // let familyModel = {
    //     familyName: name,
    //     description: desc,
    //     idUserCreation: getCurrentUserId(),
    //     type: type,
    //     sku: sku,
    // };
    // console.log("familyModel", familyModel);
    // ProductService.addProductFamily(familyModel).then((result) => {
    //     console.log("result", result.data["success"]);
    //     if (result.data["success"]) {
    //         setOpenSuccessAlert(true);
    //         setSuccessAlertText("Enregistrement avec succées");
    //         setTimeout(() => {
    //             navigate(`/families`);
    //         }, 2000);
    //     } else {
    //         setOpenErrorAlert(true);
    //         setErrorAlertText("Erreur :erreur serveur ");
    //     }
    // });
  };
  const handleReset = () => {
    window.location.reload(false);
  };
  const onValueChange = (event) => {
    if (event.target.value === "1") {
      setType(1);
    } else if (event.target.value === "2") {
      setType(2);
    }
  };
  const blocPromotion = () => {};

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="row">
              {/* Button vers la page Tableau */}
              <div className="col">
                {" "}
                <Button
                  className="my-custom-class-btn-style mr-2"
                  variant="secondary"
                  disabled={panierClicked}
                  onClick={handleClickPanier}
                >
                  <i class="fas fa-shopping-cart"></i> Promotion Panier
                </Button>
              </div>

              <div className="col">
                <Button
                  className="my-custom-class-btn-style mr-2"
                  variant="secondary"
                  disabled={productClicked}
                  onClick={handleClickProduct}
                >
                  <i class=" fab fa-brands fa-product-hunt"></i> Promotion
                  produit
                </Button>
              </div>
            </div>
            <div className="row">
              <div class="form-group  clearfix">
                <div class="icheck-asbestos d-inline">
                  <input
                    id="produit"
                    type="radio"
                    name="produit"
                    value="1"
                    checked={type === 1}
                    onChange={onValueChange}
                  />
                  <label for="produit">Promotion produit </label>{" "}
                </div>
                <div class="icheck-asbestos d-inline">
                  <input
                    id="service"
                    type="radio"
                    name="service"
                    value="2"
                    checked={type === 2}
                    onChange={onValueChange}
                  />
                  <label for="service"> Promotion Panier</label>
                </div>
              </div>
              {blocPromotion()}
            </div>
          </div>
        );
      case 1:
        return <div></div>;
      case 2:
        return (
          <div className="row">
            <span class="text-dark">
              Avant de cliquer sur terminé, vous devez être sûr des informations
              saisies
            </span>
          </div>
        );
      default:
        return "Unknown step";
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active">Ajouter promotion</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content ">
        <div className="container-fluid">
          <div className="row d-flex justify-content-cente">
            <div className="col">
              <Button
                className="my-custom-class-btn-style  mr-2"
                variant="secondary"
                disabled={productClicked}
                onClick={handleClickProduct}
              >
                <i class="fab fa-brands fa-product-hunt"></i> Régle sur Produit
              </Button>
            </div>
            <div className="col">
              <Button
                className="my-custom-class-btn-style  mr-2 "
                variant="secondary"
                disabled={panierClicked}
                onClick={handleClickPanier}
              >
                <i class="fas fa-shopping-cart"></i> Régle sur Panier
              </Button>
            </div>
          </div>
        </div>
      </section>
      {productClicked && <AddDiscountProduct />}

      {panierClicked && <AddDiscountPanier />}
    </div>
  );
};
export default AddDiscount;
