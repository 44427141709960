import api from "../common/api";
import http from "../common/http-common.jsx";
import currentUserId from "../common/currentUserId.jsx";
import getCurrentRefreshToken from "../common/currentRefreshToken.jsx";
class AuthentificationService {
  login(login, password) {
    return api.post("/authenticate", { login, password });
  }

  logout() {
    let currentUser = currentUserId();
    let currentRefreshToken = getCurrentRefreshToken();

    localStorage.removeItem("user");
    localStorage.removeItem("accessIsDenied");
    localStorage.removeItem("status");

    return api.put(`/logout/${currentRefreshToken}/${currentUser}`);
  }
  register(username, email, password) {
    return api.post("signup", { username, email, password });
  }

  getAllBanks() {
    return api.get("/finance/Banque/getAllBanque");
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}
export default new AuthentificationService();
