import { Component } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CarrousselTrendingHomeService from "../../services/CarrousselTrendingHomeService";
export default class RemoveProductTrendingPopup extends Component {
  state = {
    productTrending: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      productTrending: "",
    };
    this.RemoveProductTrending = this.RemoveProductTrending.bind(this);
    // this.closeWindow = this.closeWindow.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    CarrousselTrendingHomeService.getProduitTrendingById(
      this.props.idProductTrending
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ productTrending: response.data["object"] });
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("productTrending", this.state.productTrending);

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  }
  RemoveProductTrending() {
    // if (this.state.brand.isArchived === 0) {
    CarrousselTrendingHomeService.archiveProduitTrendingById(
      this.props.idProductTrending,
      1
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Suppression avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
    // }
    console.log("removeBrand");
  }

  render() {
    return (
      <div className="modal fade" id="modal-removeTrending">
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Retirer le produit des trending</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Voulez-vous vraiment retirer ce produit
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={this.RemoveProductTrending}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
