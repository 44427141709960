class UtilsFormule {
  formatDate(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth() + 1).padStart(2, "0");
    let year = cdate.getFullYear();
    return day + "/" + month + "/" + year;
  }
  formatDateFilter(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth() + 1).padStart(2, "0");
    let year = cdate.getFullYear();
    return year + "-" + month + "-" + day;
  }
  formatDateHours(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth() + 1).padStart(2, "0");
    let year = cdate.getFullYear();
    let hours = String(cdate.getHours()).padStart(2, "0");
    let minutes = String(cdate.getMinutes()).padStart(2, "0");
    return day + "/" + month + "/" + year + " " + hours + ":" + minutes;
  }
  //discount sur tt devis
  CalculateNewPurchasingPriceAfterDiscount = (
    productTableData,
    discount,
    discountType
  ) => {
    console.log("productTableData", productTableData);
    let sumTt = 0;
    // let sumHt = 0;
    let sumPromotionHt = 0;
    let sumPromotionTt = 0;
    let sumPriceHt = 0;
    for (let index = 0; index < productTableData.length; index++) {
      // console.log("product in calcul", productTableData[index]);
      sumPriceHt =
        productTableData[index].initialPriceHtc *
          productTableData[index].quantity +
        sumPriceHt;

      sumPromotionTt = productTableData[index].ttPrice + sumPromotionTt;
      sumPromotionHt = productTableData[index].htPrice + sumPromotionHt;
    }

    if (discount === 0) {
    } else if (discount !== 0) {
      if (discountType === "montant") {
        sumPriceHt = 0;
        sumPromotionHt = 0;
        sumPromotionTt = 0;
        // for (let index = 0; index < productTableData.length; index++) {
        //   sumPriceHt =
        //     productTableData[index].initialPriceHtc *
        //       productTableData[index].quantity +
        //     sumPriceHt;
        // }

        let pourcentAmount = (discount / sumPriceHt) * 100;
        for (let index = 0; index < productTableData.length; index++) {
          let x =
            productTableData[index].htPrice -
            (productTableData[index].htPrice * pourcentAmount) / 100;
          let xtc = x + (x * productTableData[index].tva) / 100;
          sumPromotionHt = x + sumPromotionHt;
          sumPromotionTt = xtc + sumPromotionTt;
        }
        discount = pourcentAmount;
      } else {
        sumPromotionHt = 0;
        sumPromotionTt = 0;
        for (let index = 0; index < productTableData.length; index++) {
          // sumPriceHt = productTableData[index].htPrice + sumPriceHt;
          let x =
            productTableData[index].htPrice -
            (productTableData[index].htPrice * discount) / 100;
          let xtc = x + (x * productTableData[index].tva) / 100;
          sumPromotionHt = x + sumPromotionHt;
          sumPromotionTt = xtc + sumPromotionTt;
        }
      }
    }

    return [sumTt, sumPromotionHt, sumPromotionTt, sumPriceHt, discount];
  };
  CalculateNewPurchasingDataTableAfterDiscount = (
    productTableData,
    discount
  ) => {
    if (discount === 0) {
    } else if (discount !== 0) {
      // sumPromotionHt = 0;
      // sumPromotionTt = 0;
      for (let index = 0; index < productTableData.length; index++) {
        // sumPriceHt = productTableData[index].htPrice + sumPriceHt;
        let x =
          productTableData[index].buyingPriceU -
          (productTableData[index].buyingPriceU * discount) / 100;
        let percent = 100 - (x / productTableData[index].initialPriceHtc) * 100;

        // let xtc = x + (x * productTableData[index].tva) / 100;
        // sumPromotionHt = x + sumPromotionHt;
        // sumPromotionTt = xtc + sumPromotionTt;
        productTableData[index].buyingPriceU = x;
        // productTableData[index].ttPrice = xtc;
        productTableData[index].promotionPercentage = percent;
      }
    }

    return productTableData;
  };

  CalculatePromotionProductPurchase = (
    promotion,
    promotionType,
    unitPrice,
    quantity,
    tva
  ) => {
    let promotionAmountOrPersentage = 0;
    let promotionpercent = 0;
    let buyingPriceU = 0;
    let ttPrice = 0;
    if (promotion !== 0) {
      let htPrice = unitPrice * quantity;
      if (promotionType === "montant") {
        promotionAmountOrPersentage =
          Math.round((htPrice - promotion * quantity) * 100) / 100;
        promotionpercent = (promotion / unitPrice) * 100;
        buyingPriceU = Math.round((unitPrice - promotion) * 100) / 100;
        ttPrice =
          promotionAmountOrPersentage +
          (promotionAmountOrPersentage / 100) * tva;
      } else {
        promotionAmountOrPersentage =
          Math.round((htPrice - (htPrice / 100) * promotion) * 100) / 100;
        buyingPriceU =
          Math.round((unitPrice - (unitPrice / 100) * promotion) * 100) / 100;

        promotionpercent = promotion;
        ttPrice =
          promotionAmountOrPersentage +
          (promotionAmountOrPersentage / 100) * tva;
      }
    } else {
      promotionAmountOrPersentage = unitPrice * quantity;
      buyingPriceU = unitPrice;
      promotionpercent = 0;
      ttPrice =
        promotionAmountOrPersentage + (promotionAmountOrPersentage / 100) * tva;
    }

    return [
      promotionAmountOrPersentage,
      buyingPriceU,
      promotionpercent,
      ttPrice,
    ];
  };
  testSKUExist(sku, listSKU) {
    let exist = false;
    if (listSKU !== undefined && listSKU !== null && listSKU.length > 0) {
      listSKU.forEach((element, index) => {
        if (element === sku) {
          exist = true;
        }
      });
    }

    return exist;
  }

  testSKUPattern(sku) {
    let exist = false;
    let pattern = /[A-Z][A-Z]/;

    if (pattern.test(sku)) {
      exist = true;
    }
    return exist;
  }

  generateSKU(name, listSKU) {
    if (name !== undefined && name !== null && name !== "") {
      let SKU = "";
      let arrayName = this.testElementVideinArray(name.split(" "));
      let exist = false;
      // let arraySize=arrayName.length;
      if (arrayName.length >= 2) {
        let firstWord = arrayName[0];
        let secondWord = arrayName[1];
        SKU = (
          Array.from(firstWord)[0] + Array.from(secondWord)[0]
        ).toUpperCase();
      } else if (arrayName.length === 1) {
        let firstWord = arrayName[0];
        SKU = (
          Array.from(firstWord)[0] + Array.from(firstWord)[1]
        ).toUpperCase();
      }
      exist = this.testSKUExist(SKU, listSKU);

      if (exist) {
        return "";
      } else {
        return "(On propose la référence : " + SKU + " )";
      }
    } else {
      return "";
    }
  }
  testElementVideinArray(arrayName) {
    arrayName.forEach((element, index) => {
      if (element === "" || element === " ") {
        arrayName.splice(index, 1);
      }
    });
    const existe = arrayName.indexOf("");
    if (existe > -1) {
      this.testElementVideinArray(arrayName);
    }
    return arrayName;
  }
  generateSKUCategory(name, listSKU) {
    if (name !== undefined && name !== null && name !== "") {
      let SKU = "";
      let arrayName = this.testElementVideinArray(name.split(" "));
      let exist = false;
      // let arraySize=arrayName.length;
      if (arrayName.length >= 3) {
        let firstWord = arrayName[0];
        let secondWord = arrayName[1];
        let thirdWord = arrayName[2];
        // console.log("name vide", "-" + thirdWord + "-");
        SKU = (
          Array.from(firstWord)[0] +
          Array.from(secondWord)[0] +
          Array.from(thirdWord)[0]
        ).toUpperCase();
      } else if (arrayName.length === 2) {
        let firstWord = arrayName[0];
        let secondWord = arrayName[1];

        SKU = (
          Array.from(firstWord)[0] +
          Array.from(firstWord)[1] +
          Array.from(secondWord)[0]
        ).toUpperCase();
      } else if (arrayName.length === 1) {
        let firstWord = arrayName[0];
        // let secondWord = arrayName[1];
        if (firstWord.length >= 3) {
          SKU = (
            Array.from(firstWord)[0] +
            Array.from(firstWord)[1] +
            Array.from(firstWord)[2]
          ).toUpperCase();
        }
      }
      if (SKU !== "") {
        exist = this.testSKUExist(SKU, listSKU);

        if (exist) {
          return "";
        } else {
          return "(On propose la référence : " + SKU + " )";
        }
      }
    } else {
      return "";
    }
  }
}
export default new UtilsFormule();
