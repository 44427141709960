import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BrandService from "../../services/BrandService";



export default class BrandVisible extends Component {
    state = {
        family: "",
    };
    constructor(props) {
        super(props);
        this.state = {
            family: "",
        };
        this.visibleFamily = this.visibleFamily.bind(this);
        // this.closeWindow = this.closeWindow.bind(this);
    }

    componentDidMount() {
        this.forceUpdate();
        BrandService.getBrandById(this.props.idBrand)
            .then((response) => {
                console.log("response", response.data["code"]);
                if (response.data["code"] === "201") {
                    // console.log("In Societe else");
                    this.setState({ family: response.data["object"] });
                    console.log("family", this.state.family);
                    // this.setState({ alert_message: "success" });
                } else {
                    console.log("response", "error");
                }
            })
            .catch((error) => {
                console.log("response", "error");
            });
    }
    visibleFamily() {
        if (this.state.family.visible === 0) {
            BrandService.visibleBrand(this.state.family.id, 1)
                .then((response) => {
                    if (response.data["code"] === "201") {
                        toast.success("Marque visible");
                        setTimeout(() => {
                            window.location.reload(false);
                        }, 3000);
                    } else if (response.data["code"] === "400") {
                        toast.error(response.data["object"]);
                    }

                    console.log("response", response);
                })
                .catch((error) => {
                    toast.error("une erreur a survenue");
                });
        } else if (this.state.family.visible === 1) {
            BrandService.visibleBrand(this.state.family.id, 0)
                .then((response) => {
                    if (response.data["code"] === "201") {
                        toast.success("Marque invisible");
                        setTimeout(() => {
                            window.location.reload(false);
                        }, 3000);
                    } else if (response.data["code"] === "400") {
                        toast.error(response.data["object"]);
                    }

                    console.log("response", response);
                })
                .catch((error) => {
                    toast.error("une erreur a survenue");
                });
        }

        console.log("removeBrand");
    }

    render() {
        if (this.state.family.visible === 1) {
            return (
                <div className="modal-dialog">
                    <ToastContainer />
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                La marque {this.state.family.name} est visible
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    window.location.reload(false);
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Rendre la marque {this.state.family.name} invisible
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    window.location.reload(false);
                                }}
                            >
                                Annuler
                            </button>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={this.visibleFamily}
                            >
                                Rendre invisible
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="modal-dialog">
                    <ToastContainer />
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                La marque {this.state.family.name} est invisible
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    window.location.reload(false);
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Rendre la marque {this.state.family.name} visible
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={() => {
                                    window.location.reload(false);
                                }}
                            >
                                Annuler
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={this.visibleFamily}
                            >
                                Rendre visible
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}