import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class VariantsService {
  addVariants(picture, variantsModel) {
    return api.post(
      urlsCommon.BASE_VARIANT_URL + "/addVariantToProduct",
      picture,
      {
        params: {
          variantsModel,
        },
      }
    );
  }

  addSizeToVariants(variantsSizeModel) {
    return api.post(
      urlsCommon.BASE_VARIANT_URL + "/addSizeToVariant",
      variantsSizeModel,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  getVariantById(idVariant) {
    return api.get(
      urlsCommon.BASE_VARIANT_URL + "/getVariantById/" + idVariant
    );
  }

  archiveVariant(idVariant, deed) {
    return api.get(
      urlsCommon.BASE_VARIANT_URL + "/archiveVariant/" + idVariant + "/" + deed
    );
  }
  visibleVariant(idVariant, deed) {
    return api.get(
      urlsCommon.BASE_VARIANT_URL + "/visibleVariant/" + idVariant + "/" + deed
    );
  }
  updatePicture(pictures, id) {
    return api.put(
      urlsCommon.BASE_VARIANT_URL + "/updateImageOfVariant/" + id,
      pictures
    );
  }

  updateVariantDefaultProductToShowInFo(idVariant, idProduct) {
    return api.put(
      urlsCommon.BASE_VARIANT_URL +
        "/updateVariantDefaultProductToShowInFo/" +
        idVariant +
        "/" +
        idProduct
    );
  }

  //****for archive and visible variant of product
  archiver(idVariant) {
    return api.put(urlsCommon.BASE_VARIANT_URL + "/archiver/" + idVariant);
  }
  visible(idVariant) {
    return api.put(urlsCommon.BASE_VARIANT_URL + "/visible/" + idVariant);
  }
}
export default new VariantsService();
