// Circle.jsx
import React from "react";

const Circle = ({ colors }) => {
  const sectionStyle = {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    overflow: "hidden",
    position: "relative",
  };

  const getSectionStyle = (color, index) => ({
    background: color,
    flex: "1",
    position: "absolute",
    width: "100%",
    height: `${(1 / colors.length) * 100}%`,
    top: `${(index / colors.length) * 100}%`,
    border: "2px solid rgb(0 0 0 / 13%)",
    borderRadius:
      colors.length === 1
        ? "50%"
        : colors.length === 2 && index === 0
        ? "50px 50px 0 0"
        : colors.length === 2 && index === 1
        ? "0 0 50px 50px"
        : colors.length === 3 && index === 0
        ? "50px 50px 0 0"
        : colors.length === 3 && index === 2
        ? "0 0 50px 50px"
        : colors.length === 4 && index === 0
        ? "50px 50px 0 0"
        : colors.length === 4 && index === 3
        ? "0 0 50px 50px"
        : "0",
  });

  return (
    <div style={sectionStyle}>
      {colors.map((color, index) => (
        <div key={index} style={getSectionStyle(color, index)}></div>
      ))}
    </div>
  );
};

export default Circle;
