import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import PictureService from "src/services/PictureService";

function UpdateOrderImagesVariant(props) {
  const { setIsModalOpenEditOrderImagesVariant, variantSelected, setIsSaved } =
    props;

  //****for Edit order images variants */

  //for edit OrderImagesOne product
  const [selectedOrders, setSelectedOrders] = useState([]);

  const handleEditSelected = (order, e) => {
    e.preventDefault();
    const selectedValue = e.target.value;

    setSelectedOrders((prevOrders) => {
      const updatedOrders = [...prevOrders];
      updatedOrders[order - 1] = selectedValue;
      return updatedOrders;
    });
  };

  const handleEditOrderImagesOne = async (e) => {
    e.preventDefault();
    console.log(selectedOrders);

    if (selectedOrders) {
      try {
        console.log("yes1");

        const response = await PictureService.addOrderToImagesVariant(
          JSON.stringify(selectedOrders)
        );

        if (response.data.success) {
          console.log("data");

          toast.success(response.data.success);
          setIsModalOpenEditOrderImagesVariant(false);
          setIsSaved(true);
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        console.error("Error editing OrderImagesOne:", error);
        toast.error("An error occurred while editing the OrderImagesOne.");
      }
    } else {
      toast.error("If faut selectionner OrderImagesOne");
    }
  };
  console.log(variantSelected);
  return (
    <>
      <div className="card card-secondary">
        <ToastContainer />
        <div
          className="card-header"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h3 className="card-title">
            Modifier les ordres des images de variant{" "}
            <h4 className="my-3">{variantSelected?.codeABarre || ""}</h4>
          </h3>

          <div style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-dark mr-2"
              onClick={() => setIsModalOpenEditOrderImagesVariant(false)}
            >
              Close
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="col-12 product-image-thumbs">
                {variantSelected &&
                  variantSelected?.imagesVariant.map((image, index) => (
                    <div
                      key={image?.orderToFo}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p className="mt-2">
                        <b>{image?.orderToFo || ""}</b>
                      </p>
                      <div
                        className="product-image-thumb"
                        key={image?.orderToFo}
                      >
                        <img src={image?.urlImage || ""} alt="Product Image" />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <h4 className="my-3">Modifier l'ordre des images</h4>

              {variantSelected &&
                variantSelected?.imagesVariant.map((image, index) => (
                  <div key={index}>
                    <label htmlFor={`Ordre-${index}`} className="mt-2">
                      Ordre {index + 1} :
                    </label>
                    <div className="edit-category-form">
                      <select
                        id={`Ordre-${index}`}
                        className="form-control"
                        value={selectedOrders[index] || image?.id}
                        onChange={(e) => handleEditSelected(index + 1, e)}
                      >
                        <option value="">-- Pas d'ordre --</option>
                        {variantSelected.imagesVariant.map(
                          (element, subIndex) => (
                            <option key={subIndex} value={element.id}>
                              Image - {subIndex + 1 || ""}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                ))}

              <div className="float-right mt-2">
                <button
                  className="btn btn-sm btn-warning mr-2"
                  onClick={(e) => handleEditOrderImagesOne(e)}
                >
                  Modifier
                </button>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => setIsModalOpenEditOrderImagesVariant(false)}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateOrderImagesVariant;
