import { Component } from "react";
import FamilyProductService from "../../services/FamilyProductService";
import { Link } from "react-router-dom";
import FamilyProductImage from "./FamilyProductImage";
import FamilyUpdateLibelle from "./FamilyUpdateLibelle";
import FamilyProductDelete from "./FamilyProductDelete";
import FamilyProductVisible from "./FamilyProductVisible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faImage,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default class AllFamilyProducts extends Component {
  state = {
    idFamilyProduct: "",
    familyProducts: [],
    showFormImage: false,
    showFormUpdateImage: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      idFamilyProduct: "",
      familyProducts: [],
      showForm: false,
      showFormImage: false,
      showFormUpdateImage: false,
    };
    // this.open = this.open.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    FamilyProductService.getAllFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ familyProducts: response.data["object"] });
          console.log("familyProducts", this.state.familyProducts);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  showFormUpdateImage = () => {
    this.setState({ showFormUpdateImage: !this.state.showFormUpdateImage });
  };
  onAddFamilleHandler = () => {
    // navigate(`/addFamilyProduct`);
    window.location.href = "/addFamilyProduct";
  };

  render() {
    return (
      <>
        <div className="content-wrapper" style={{ marginTop: "0" }}>
          <div className="content">
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="card  mt-3">
                  <div className="card-body">
                    <div className="card-title">
                      <h5>Les Familles</h5>
                    </div>
                    <button
                      className="btn btn-dark float-right"
                      data-toggle="modal"
                      data-target="#add-brand"
                      onClick={this.onAddFamilleHandler}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>

                <div class="card card-dark">
                  <div className="card-header">
                    <h3 className="card-title">Les familles disponibles</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <table className="table  projects">
                      <thead>
                        <tr>
                          <th>Libellé </th>
                          <th className="text-center" style={{ width: "12%" }}>
                            Modifier
                          </th>
                          <th className="text-center" style={{ width: "12%" }}>
                            Image
                          </th>
                          <th className="text-center" style={{ width: "12%" }}>
                            Voir produits
                          </th>
                          <th className="text-center" style={{ width: "12%" }}>
                            Activer
                          </th>
                          <th className="text-center" style={{ width: "12%" }}>
                            Archiver
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.familyProducts.length != 0 ? (
                          <>
                            {this.state.familyProducts.map((family) => (
                              <tr>
                                <td>{family.name}</td>
                                <td className="text-center">
                                  <button
                                    // style={{ width: 40 }}
                                    className="btn bg-warning btn-sm"
                                    data-toggle="modal"
                                    data-target="#modal-update"
                                    onClick={() => {
                                      this.setState({
                                        idFamilyProduct: family.id,
                                      });
                                      this.showFormImage();
                                      this.setState({
                                        showFormUpdateImage: false,
                                      });
                                    }}
                                    disabled
                                  >
                                    Modifier{" "}
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn bg-dark btn-sm"
                                    data-toggle="modal"
                                    data-target="#modal-Image"
                                    onClick={() => {
                                      this.setState({
                                        idFamilyProduct: family.id,
                                      });
                                      this.showFormUpdateImage();
                                    }}
                                  >
                                    Voir Image
                                    <FontAwesomeIcon
                                      icon={faImage}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn bg-black btn-sm"
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "idFamilyProduct",
                                        family.id
                                      );
                                      this.showFormImage();
                                      this.setState({
                                        showFormUpdateImage: false,
                                      });
                                      // window.location.href =
                                      //   "/allProductByFamily";
                                    }}
                                    disabled
                                  >
                                    Voir Produits{" "}
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>

                                <td className="text-center">
                                  <button
                                    data-toggle="modal"
                                    data-target="#modal-visible"
                                    style={{ width: 150 }}
                                    className={`btn btn-sm ${
                                      family?.visible
                                        ? "btn-secondary"
                                        : "btn-primary"
                                    }`}
                                    onClick={() => {
                                      this.setState({
                                        idFamilyProduct: family.id,
                                      });
                                      this.setState({
                                        showFormUpdateImage: false,
                                      });
                                      this.showFormImage();
                                    }}
                                  >
                                    {family?.visible ? (
                                      <>
                                        Rendre Invisible
                                        <FontAwesomeIcon
                                          icon={
                                            family?.visible ? faEyeSlash : faEye
                                          }
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Visible
                                        <FontAwesomeIcon
                                          icon={
                                            family?.visible ? faEyeSlash : faEye
                                          }
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>

                                <td className="text-center">
                                  <button
                                    className={`btn btn-sm ${
                                      family?.isArchived
                                        ? "btn-success"
                                        : "btn-danger"
                                    }`}
                                    onClick={() => {
                                      this.setState({
                                        idFamilyProduct: family.id,
                                      });
                                      this.setState({
                                        showFormUpdateImage: false,
                                      });
                                      this.showFormImage();
                                    }}
                                    data-toggle="modal"
                                    data-target="#modal-default"
                                  >
                                    {family?.isArchived ? (
                                      <>
                                        Archiver
                                        <FontAwesomeIcon
                                          icon={
                                            family?.isArchived
                                              ? faRecycle
                                              : faTrash
                                          }
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Désarchiver
                                        <FontAwesomeIcon
                                          icon={
                                            family?.isArchived
                                              ? faRecycle
                                              : faTrash
                                          }
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr className="text-center">
                            <td colSpan="7">
                              <b>pas de famille pour le moment</b>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {this.state.showFormUpdateImage && (
                  <FamilyProductImage
                    idFamilyProduct={this.state.idFamilyProduct}
                  />
                )}
                {this.state.showFormImage && (
                  <div className="modal fade" id="modal-update">
                    <FamilyUpdateLibelle
                      idFamilyProduct={this.state.idFamilyProduct}
                    />
                  </div>
                )}
                {this.state.showFormImage && (
                  <div className="modal fade" id="modal-visible">
                    <FamilyProductVisible
                      idFamilyProduct={this.state.idFamilyProduct}
                    />
                  </div>
                )}
                {this.state.showFormImage && (
                  <div className="modal fade" id="modal-default">
                    <FamilyProductDelete
                      idFamilyProduct={this.state.idFamilyProduct}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
