import { Component } from "react";
import { Link } from "react-router-dom";
import CarrousselTrendingHomeService from "../../services/CarrousselTrendingHomeService";
import ProductTrending from "./ProductTrending";
import RemoveProductTrendingPopup from "./RemoveProductTrendingPopup";

export default class ProduitsTrending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: "",
      products: [],
      product: null,
      pictureUrl: "",
      showFormImage: false,
      showFormRemove: false,
      idProductTrending: "",
    };
    this.changeRef = this.changeRef.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    sessionStorage.removeItem("ref");
    this.setState({ ref: "" });
    CarrousselTrendingHomeService.allProductsTrendingHome(
      sessionStorage.getItem("idCarroussel")
    )
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ products: response.data["object"] });
          console.log("products", this.state.products);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  changeRef(event) {
    this.setState({ ref: event.target.value });
    console.log("ref", this.state.ref);
  }

  // findProduct = (e) => {
  //   e.preventDefault();
  //   sessionStorage.setItem("ref", this.state.ref);
  //   // console.log("ref", ref);
  // };
  // productFromDBByRef() {
  //   if (sessionStorage.getItem("ref") !== "") {
  //     return <ProductTrending />;
  //   }
  //   // } else {
  //   // return <h1>productFromDBByRef</h1>;
  //   // // }
  // }
  ListProductsTrending() {
    if (this.state.products.length !== 0) {
      return (
        <section class="content">
          <div className="card card-success card-outline ">
            <div class="card-header">
              <h3 class="card-title">
                {" "}
                <b>Les produits trending</b>
              </h3>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Ref </th>
                    <th>Libellé</th>
                    {/* <th>Image</th> */}
                    {/* <th>Etat</th> */}
                    <th>Retirer des trending</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.products.map((prod) => (
                    <tr>
                      <td>{prod.id}</td>
                      <td>{prod["product"]["ref"]}</td>
                      <td>{prod["product"]["name"]}</td>
                      {/* <td>{prod.name}</td> */}

                      <td>
                        <button
                          class="btn  btn-danger btn-sm"
                          data-toggle="modal"
                          data-target="#modal-removeTrending"
                          onClick={() => {
                            this.setState({ idProductTrending: prod.id });
                            this.showFormRemove();
                          }}
                        >
                          <i className="fas fa-ban" /> Supprimer
                        </button>
                        {this.state.showFormRemove && (
                          <RemoveProductTrendingPopup
                            idProductTrending={this.state.idProductTrending}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      );
    }
  }
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  showFormRemove = () => {
    this.setState({ showFormRemove: !this.state.showFormRemove });
  };

  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>La produits trending</h5>
            </div>
          </div>
        </section>

        {this.ListProductsTrending()}

        <section class="content">
          <div className="card card-success card-outline ">
            <div class="card-header">
              <h3 class="card-title">
                {" "}
                Choisir un produit pour l'ajouter aux produits trending
              </h3>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-2">
                    <label>Ref Produit</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="Ref"
                      className="form-control"
                      placeholder="Choisir un référence"
                      value={this.state.ref}
                      onChange={this.changeRef}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="card-footer">
              <button
                data-toggle="modal"
                data-target="#modal-Trending"
                class="btn btn-success float-right"
                onClick={() => {
                  this.showFormImage();
                }}
              >
                Voir
              </button>
              {this.state.showFormImage && (
                <ProductTrending reference={this.state.ref} />
              )}
            </div>
          </div>
        </section>

        {/* {this.productFromDBByRef()} */}
      </div>
    );
  }
}
