import { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Files from "react-files";
import SubFamilyProductService from "../services/SubFamilyProductService";
import makeAnimated from "react-select/animated";
import ActiviteService from "../services/ActiviteService";
export default class AddActivite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: "",
      prenom: "",
      files: [],
      livrable: false,
      redirect: false,
      visible: false,
      idSubFamilyProduct: "",
      subFamilyProducts: [],
      options: [],
    };
    this.changenom = this.changenom.bind(this);
    this.changePrenom = this.changePrenom.bind(this);
    // this.changePrenom = this.changePrenom.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveActivite = this.saveActivite.bind(this);
  }

  handleChange(event) {
    if (this.state.livrable) {
      this.setState({ livrable: false });
    } else {
      this.setState({ livrable: true });
    }

    console.log("livrable", this.state.livrable);
  }
  componentDidMount() {
    this.forceUpdate();
    SubFamilyProductService.getAllSubFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ subFamilyProducts: response.data["object"] });
          console.log("subFamilyProducts", this.state.subFamilyProducts);
          // this.setState({ alert_message: "success" });
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  handleVisible(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }

  changenom(event) {
    this.setState({ nom: event.target.value });
    console.log("nom", this.state.nom);
  }
  changePrenom(event) {
    this.setState({ prenom: event.target.value });
    console.log("prenom", this.state.prenom);
  }
  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };
  saveActivite = (e) => {
    e.preventDefault();
    let activteModel = {
      name: this.state.nom,
    };
    ActiviteService.addActivite(activteModel)
      .then((response) => {
        if (response.data.success) {
          toast.success("Ajout de l'activité avec succée");
          this.setState({ nom: "" });
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 3000);
        } else {
          toast.error(response.data["object"]);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  render() {
    const animatedComponents = makeAnimated();

    if (this.state.redirect) {
      return <Navigate replace to="/allActivite" />;
    } else {
      return (
        <div class="content-wrapper">
          <ToastContainer />
          <section class="content-header">
            <div class="container-fluid">
              <div class="callout callout-success">
                <h5>Ajouter un secteur d'activité </h5>
                Une étape:
                <br /> 1-Ajouter le nom secteur d'activité
              </div>
            </div>
          </section>

          <div className="col-md-12">
            <section class="content">
              <div class="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-outline card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          <b>2-AJOUTER UNE ACTIVITE</b>
                        </h3>
                      </div>

                      <form>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-2">
                              <label>Nom d'activité</label>
                            </div>
                            <div className="col-md-10">
                              <input
                                type="libelle"
                                className="form-control"
                                placeholder="Ajouter nom"
                                value={this.state.nom}
                                onChange={this.changenom}
                              />
                            </div>
                          </div>
                          <br />
                        </div>
                        <div className="card-footer">
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => this.setState({ redirect: true })}
                          >
                            Annuler
                          </button>

                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={this.saveActivite}
                            disabled={this.state.nom === "" ? true : false}
                            style={{ float: "right" }}
                          >
                            Valider
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }
  }
}
