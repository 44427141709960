import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SketchPicker } from "react-color";
import ColorService from "src/services/ColorService";
import { TestInput } from "src/common/staticMethodes";

function AddColor() {
  const navigate = useNavigate();
  const [newColor, setNewColor] = useState("");
  const [background, setBackground] = useState("#944B4B");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewColor({ ...newColor, [name]: value });
  };
  const handleChangeComplete = (color) => {
    setBackground(color.hex);
  };

  const createCouleur = () => {
    const model = {
      colorName: newColor?.libelle,
      color: background,
    };
    TestInput(model);
    console.log("model :", model);
    ColorService.createColor(model)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setNewColor("");
          setBackground("#944B4B");
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Ajouter Couleur</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Libelle</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="libelle"
                        value={newColor?.libelle || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Sélectionne une couleur</label>

                    <SketchPicker
                      color={background}
                      onChangeComplete={handleChangeComplete}
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-default"
                    onClick={() => navigate("/AllColorsVariant")}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={createCouleur}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddColor;
