import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import Pagination from "src/common/Pagination";
import ProductService from "src/services/ProductService";
import SelectOptionsService from "src/services/SelectOptionsService";

function AllProducts() {
  const [produitsList, setProduitsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [categorieList, setCategorieList] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [search, setSearch] = useState(false);

  const [listVideMsg, setListVideMsg] = useState("Pas de Produits ...");

  /*for pagination*/
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    getAllCategoriesAndSousFamilles();
    getAllBrands();
  }, []);

  useEffect(() => {
    if (searchData == "") {
      setSearchData(null);
    }
    if (
      searchData != null ||
      selectedBrand.value != null ||
      selectedCategorie.value != null
    ) {
      setSearch(true);
    } else {
      setSearch(false);
    }
    getAllProducts(
      currentPage - 1,
      itemsPerPage,
      searchData,
      selectedBrand.value,
      selectedCategorie.value
    );
  }, [
    currentPage - 1,
    itemsPerPage,
    searchData,
    selectedBrand,
    selectedCategorie,
  ]);

  //*********filtre produits by brands and category
  const getAllProducts = (
    currentPage,
    itemsPerPage,
    searchData,
    idBrand,
    idCategory
  ) => {
    ProductService.getAllProducts(
      currentPage,
      itemsPerPage,
      searchData,
      idBrand,
      idCategory
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setProduitsList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  ///***************select Options
  const handleSelectBrand = (selectedOption) => {
    setSelectedBrand(selectedOption);
  };
  const handleSelectCategorie = (selectedOption) => {
    setSelectedCategorie(selectedOption);
  };

  const getAllCategoriesAndSousFamilles = () => {
    SelectOptionsService.getAllCategoriesAndSousFamilles()
      .then((response) => {
        if (response.data.success) {
          setCategorieList(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getAllBrands = () => {
    SelectOptionsService.getAllBrands()
      .then((response) => {
        if (response.data.success) {
          setBrandsList(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  ///***************end select Options

  const voirDetailsProducts = (isProduct) => {
    localStorage.setItem("isProductDetails", isProduct);
    const url = "/detailsProduct";
    window.open(url, "_blank");
  };

  const clearFilter = () => {
    setSelectedBrand("");
    setSelectedCategorie("");
    setSearchData("");
    setSearch(false);
  };

  const allProductsSectionByFiltre = () => {
    return (
      <>
        <section class="content">
          <div class="card card-dark">
            <div class="card-header">
              <h3 class="card-title">Les Produits disponibles</h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                  title="Collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body p-0">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Référence</th>
                    <th>Libellé</th>
                    <th>Famille</th>
                    <th>Sous famille</th>
                    <th>Category</th>
                    <th>Prix</th>
                    <th>% promotion</th>
                    <th>nb variants images</th>
                    <th>Voir les détails</th>
                  </tr>
                </thead>
                <tbody>
                  {produitsList && produitsList.length > 0 ? (
                    produitsList &&
                    Array.isArray(produitsList) &&
                    produitsList.map((product, index) => (
                      <tr key={index}>
                        <td>{product?.codeArticle || ""} </td>
                        <td>{product?.name || ""}</td>
                        <td>{product?.famille?.name || ""}</td>
                        <td>{product?.sousFamille?.name || ""}</td>
                        <td>{product?.category?.name || ""}</td>
                        <td>{product?.price || ""}</td>
                        <td>{product?.sold ? product?.percent : ""}</td>
                        <td>
                          {product?.numberOfVariantsInProductWithImage || ""}
                        </td>
                        <td>
                          <div>
                            <button
                              className="btn btn-sm btn-dark"
                              onClick={() => {
                                voirDetailsProducts(product?.id);
                              }}
                            >
                              Voir détails
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr className="text-center">
                        <td colSpan="10">
                          <b>{listVideMsg}</b>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              {produitsList && produitsList.length > 0 && (
                <>
                  <div className="d-flex" style={{ padding: "15px" }}>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={(newPage) => setCurrentPage(newPage)}
                    />
                    <div className="ml-3">
                      <select
                        className="form-control pagination-select float-right "
                        id="itemsPerPageSelect"
                        value={itemsPerPage}
                        onChange={(e) =>
                          setItemsPerPage(parseInt(e.target.value, 10))
                        }
                      >
                        {[4, 7, 10, 15, 20].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <div class="content-wrapper" style={{ marginTop: "0" }}>
        <section class="content-header">
          <div class="container-fluid">
            <ToastContainer />
            <div class="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les Produits</h5>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label>Filtre par marque</label>
                  <Select
                    value={selectedBrand}
                    options={brandsList}
                    onChange={handleSelectBrand}
                    placeholder="Choisir la marque"
                  />
                </div>
                <div className="col-6">
                  <label>Filtre par categorie</label>
                  <Select
                    value={selectedCategorie}
                    options={categorieList}
                    onChange={handleSelectCategorie}
                    placeholder="Choisir la categorie"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Chercher Produit ..."
                    aria-label="Search"
                    value={searchData}
                    onChange={(event) =>
                      setSearchData(
                        event.target.value === "" ? null : event.target.value
                      )
                    }
                  />
                </div>
                <div className="col">
                  <button
                    className="btn btn-sm btn-dark float-right"
                    onClick={() => {
                      clearFilter();
                    }}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>
            {search && allProductsSectionByFiltre()}
          </div>
        </section>
      </div>
    </>
  );
}

export default AllProducts;
