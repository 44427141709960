import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ProductService {
  addProduct(productModel) {
    return api.post(urlsCommon.BASE_PRODUCT_URL + "/addProduct", productModel);
  }

  addBigPictures(pictures, idProduct) {
    return api.post(
      urlsCommon.BASE_PRODUCT_PICTURE_URL + "/uploadPictures",
      pictures,
      {
        params: {
          idProduct,
        },
      }
    );
  }

  addSmallPictures(smPictures, idProduct) {
    return api.post(
      urlsCommon.BASE_PRODUCT_PICTURE_URL + "/uploadSmPictures",
      smPictures,
      {
        params: {
          idProduct,
        },
      }
    );
  }
  getProductByIdBrands(idBrand) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/getProductByIdBrands/" + idBrand
    );
  }
  getProductById(idProduct) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/getProductById/" + idProduct
    );
  }
  updateStock(idProduct, stock) {
    return api.put(
      urlsCommon.BASE_PRODUCT_URL + "/updateStock/" + idProduct + "/" + stock
    );
  }

  getProductsByFamily(idFamilyProduct) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/getProductsByIdFamily/" + idFamilyProduct
    );
  }
  getProductsBySubFamily(idSubFamilyProduct) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL +
        "/getProductsByIdSubFamily/" +
        idSubFamilyProduct
    );
  }

  getProductsByCategory(idCategory) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/getProductsByIdCategory/" + idCategory
    );
  }

  getProductDetailsById(idProduct) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/getProductDetailsById/" + idProduct
    );
  }

  archiveProduct(idProduct, deed) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/archivProduct/" + idProduct + "/" + deed
    );
  }
  visibleProduct(idProduct, deed) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/visibleProduct/" + idProduct + "/" + deed
    );
  }
  getProductByRef(ref) {
    return api.get(urlsCommon.BASE_PRODUCT_URL + "/getProductByRef/" + ref);
  }

  getImageVariantProductByRef(ref_product, idColor) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/getImageVariantProductByRef",
      {
        params: {
          ref_product: ref_product,
          idColor: idColor,
        },
      }
    );
  }

  getAllProducts(page, size, search, idBrand, idCategory) {
    return api.get(urlsCommon.BASE_PRODUCT_URL + "/getAll", {
      params: {
        page: page,
        size: size,
        search: search,
        idBrand: idBrand,
        idCategory: idCategory,
      },
    });
  }
  searchProduct(page, size, dataToSearch) {
    return api.get(urlsCommon.BASE_PRODUCT_URL + "/search", {
      params: {
        page: page,
        size: size,
        dataToSearch: dataToSearch,
      },
    });
  }

  updateProduct(model) {
    return api.put(urlsCommon.BASE_PRODUCT_URL + "/update", model);
  }
  //*********for similaire products ************/

  updateSimilarProductsforProduct(idProduct, productsSimilaires) {
    return api.put(
      urlsCommon.BASE_PRODUCT_URL +
        "/similarProducts/updateSimilarProductsforProduct/" +
        idProduct,
      productsSimilaires
    );
  }

  addSimilarProducts(idProduct, productsSimilaires) {
    return api.post(
      urlsCommon.BASE_PRODUCT_URL +
        "/similarProducts/addSimilarProducts/" +
        idProduct,
      productsSimilaires
    );
  }

  getSimilarProductsforProduct(idProduct) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL +
        "/similarProducts/getSimilarProductsforProduct/" +
        idProduct
    );
  }

  //********for Idee look and similaire products /****/

  getAllProductsForSimilarProductsOrIdeeLook() {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL +
        "/similarProducts/getAllProductsForSimilarProductsOrIdeeLook"
    );
  }
  //*********for Idee Look products ************/

  updateIdeeLookProductsforProduct(idProduct, products) {
    return api.put(
      urlsCommon.BASE_PRODUCT_URL +
        "/IdeesLook/updateIdeeLookProductsforProduct/" +
        idProduct,
      products
    );
  }

  addIdeeLookProducts(idProduct, products) {
    return api.post(
      urlsCommon.BASE_PRODUCT_URL +
        "/IdeesLook/addIdeeLookProducts/" +
        idProduct,
      products
    );
  }

  getIdeeLookProductsforProduct(idProduct) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL +
        "/IdeesLook/getIdeeLookProductsforProduct/" +
        idProduct
    );
  }

  /*********list products of select */
  getAllProductsByList() {
    return api.get(urlsCommon.BASE_PRODUCT_URL + "/getAllProductsByList");
  }

  /*********list products of select by search*/
  getAllProductsForAddWithSearch(searchData) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/search/getAllProductsForAddWithSearch",
      {
        params: {
          searchData: searchData,
        },
      }
    );
  }
  //update libelle
  updateDescriptionProduct(idProduct, description) {
    return api.put(
      urlsCommon.BASE_PRODUCT_URL + "/updateDescriptionProduct/" + idProduct,
      null,
      {
        params: {
          description: description,
        },
      }
    );
  }
  updateLibelleSiteProduct(idProduct, libelle) {
    return api.put(
      urlsCommon.BASE_PRODUCT_URL + "/updateLibelleSiteProduct/" + idProduct,
      null,
      {
        params: {
          libelle: libelle,
        },
      }
    );
  }
  updateCategoryProduct(idProduct, idCategory) {
    return api.put(
      urlsCommon.BASE_PRODUCT_URL +
        "/updateCategoryProduct/" +
        idProduct +
        "/" +
        idCategory
    );
  }
}

export default new ProductService();
