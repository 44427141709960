import { Component } from "react";
import { BlockPicker } from "react-color";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ColorService from "../../services/ColorService";
export default class ColorPopup extends Component {
  state = {
    libelle: "",
    background: "#FF5733",
  };

  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      background: "#FF5733",
    };
    this.changeLibelle = this.changeLibelle.bind(this);
  }
  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }
  handleChangeComplete = (color, event) => {
    this.setState({ background: color.hex });
  };

  addColor = (e) => {
    e.preventDefault();
    let colorModel = {
      colorName: this.state.libelle,
      color: this.state.background,
    };
    console.log("colorModel", colorModel);
    ColorService.addColor(colorModel)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout du couleur avec succée");
          // window.location.reload(false);
        } else {
          toast.error(response.data["code"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("Une erreur a survenue");
      });
  };
  render() {
    return (
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Ajouter une couleur</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              // onClick={() => {
              //   window.location.reload(false);
              // }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <label>Libellé de la couleur:</label>
                    <input
                      type="libelle"
                      className="form-control"
                      placeholder="Ajouter libellé"
                      value={this.state.libelle}
                      onChange={this.changeLibelle}
                    />
                  </div>

                  <div className="col-md-6">
                    <label>Choisir la couleur : </label>
                    <BlockPicker
                      color={this.state.background}
                      onChangeComplete={this.handleChangeComplete}
                    />
                  </div>
                </div>

                <br />
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              /* onClick={() => {
                window.location.reload(false);
              }} */
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-success float-right"
              onClick={this.addColor}
            >
              Ajouter
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
    );
  }
}
