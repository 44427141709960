import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class SubFamilyProductService {
  saveSubFamilyProduct(subFamilyProductModel, picture) {
    const formData = new FormData();
    formData.append(
      "subFamilyProductModel",
      JSON.stringify(subFamilyProductModel)
    );
    formData.append("picture", picture);

    return api.post(
      urlsCommon.BASE_SUB_FAMILY_URL + "/addSubFamilyProduct",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getAllSubFamilyProducts() {
    return api.get(urlsCommon.BASE_SUB_FAMILY_URL + "/getAllSubFamilyProducts");
  }

  getSubFamilyById(idFamilyProduct) {
    return api.get(
      urlsCommon.BASE_SUB_FAMILY_URL +
        "/getSubFamilyProductsById/" +
        idFamilyProduct
    );
  }

  getAllSubFamiliesByFamilyId(idFamily) {
    return api.get(
      urlsCommon.BASE_SUB_FAMILY_URL +
        "/getAllSubFamilyProductsByIdFamily/" +
        idFamily
    );
  }

  visibleSubFamily(idFamilyProduct, deed) {
    return api.get(
      urlsCommon.BASE_SUB_FAMILY_URL +
        "/visibleSubFamilyProduct/" +
        idFamilyProduct +
        "/" +
        deed
    );
  }
  archiveFamily(idFamilyProduct, deed) {
    return api.get(
      urlsCommon.BASE_SUB_FAMILY_URL +
        "/archiveSubFamilyProduct/" +
        idFamilyProduct +
        "/" +
        deed
    );
  }

  updateSubFamilyProductName(idFamilyProduct, name) {
    return api.put(
      urlsCommon.BASE_SUB_FAMILY_URL +
        "/updateSubFamilyProductName/" +
        idFamilyProduct +
        "/" +
        name
    );
  }

  updatePicture(picture, idSubFamilyProduct) {
    return api.put(
      urlsCommon.BASE_SUB_FAMILY_URL + "/updateSubFamilyProductPicture",
      picture,
      {
        params: {
          idSubFamilyProduct,
        },
      }
    );
  }
}
export default new SubFamilyProductService();
