import { Component } from "react";
import SubFamilyProductService from "../../services/SubFamilyProductService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class SubFamilyUpdateLibelle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      family: "",
      libelle: "",
    };
    this.changeLibelle = this.changeLibelle.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    SubFamilyProductService.getSubFamilyById(this.props.idFamilyProduct)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ family: response.data["object"] });
          console.log("family", this.state.family);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }
  saveFamilyProduct = (e) => {
    e.preventDefault();

    SubFamilyProductService.updateSubFamilyProductName(
      this.state.family.id,
      this.state.libelle
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Mise à jour du libellé avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Modifier le libellé de <b>{this.state.family.name}</b>
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2">
                    <label>Libellé</label>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="libelle"
                      className="form-control"
                      placeholder="Saisir..."
                      value={this.state.libelle || this.state.family.name}
                      onChange={this.changeLibelle}
                    />
                  </div>
                </div>
                <br />
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={this.saveFamilyProduct}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    );
  }
}
