import { Component } from "react";
import { Link } from "react-router-dom";
import SubFamilyProductService from "../../services/SubFamilyProductService";
import SubFamilyProductDelete from "./SubFamilyProductDelete";
import SubFamilyProductImage from "./SubFamilyProductImage";
import SubFamilyProductVisible from "./SubFamilyProductVisible";
import SubFamilyUpdateLibelle from "./SubFamilyUpdateLibelle";

export default class AllSubFamilyProducts extends Component {
  state = {
    idSubFamilyProduct: "",
    subFamilyProducts: [],
    showFormImage: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      idSubFamilyProduct: "",
      subFamilyProducts: [],
      showForm: false,
    };
    // this.open = this.open.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    SubFamilyProductService.getAllSubFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ subFamilyProducts: response.data["object"] });
          console.log("subFamilyProducts", this.state.subFamilyProducts);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  buttonArchive(id, isArchived) {
    if (isArchived === 0) {
      return (
        <button
          class="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idSubFamilyProduct: id });
            this.showFormImage();
          }}
        >
          <i class="fas fa-trash"></i> Archiver
        </button>
      );
    } else {
      return (
        <button
          class="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idSubFamilyProduct: id });
            this.showFormImage();
          }}
        >
          <i class="fas fa-check"></i>Restaurer
        </button>
      );
    }
  }
  labelVisible(id, visible) {
    if (visible === 1) {
      return <label>Rendre Invisible</label>;
    } else {
      return <label>Rendre Visible</label>;
    }
  }

  buttonVisible(id, visible) {
    if (visible === 1) {
      return (
        <button
          class="btn btn-danger btn-sm float-center"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idSubFamilyProduct: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-recycle"></i>
        </button>
      );
    } else {
      return (
        <button
          class="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idSubFamilyProduct: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-recycle"></i>
        </button>
      );
    }
  }
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les sous familles des produits</h5>
                <p className="float-right">
                  <button class="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/addSubFamilyProduct" }}
                      style={{ color: "white", "text-decoration": "none" }}
                    >
                      <i className="fas fa-plus" /> Ajouter
                    </Link>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="col-md-12">
          <section class="content">
            <div className="col-12">
              <div className="card">
                <div className="card-header"></div>

                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Libellé</th>

                        <th>Famille</th>
                        <th>Voir l'image</th>
                        <th>Modifier</th>
                        <th>Visibilité dans le site</th>
                        <th>Archiver</th>
                        <th>Voir plus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subFamilyProducts.map((family) => (
                        <tr>
                          <td>{family.name}</td>
                          <td>{family.familyProduct.name}</td>
                          <td>
                            {" "}
                            <button
                              class="btn btn-warning btn-sm"
                              data-toggle="modal"
                              data-target="#modal-Image"
                              onClick={() => {
                                this.setState({
                                  idSubFamilyProduct: family.id,
                                });
                                this.showFormImage();
                              }}
                            >
                              <i class="fas fa-images"></i> Voir l'image
                            </button>
                            {this.state.showFormImage && (
                              <SubFamilyProductImage
                                idFamilyProduct={this.state.idSubFamilyProduct}
                              />
                            )}
                          </td>
                          <td>
                            <button
                              class="btn btn-info btn-sm"
                              data-toggle="modal"
                              data-target="#modal-update"
                              onClick={() => {
                                this.setState({
                                  idSubFamilyProduct: family.id,
                                });
                                this.showFormImage();
                              }}
                            >
                              <i class="fas fa-pencil-alt"></i> Modifier
                            </button>
                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-update">
                                <SubFamilyUpdateLibelle
                                  idFamilyProduct={
                                    this.state.idSubFamilyProduct
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {this.labelVisible(family.id, family.visible)}
                            {this.buttonVisible(family.id, family.visible)}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-visible">
                                <SubFamilyProductVisible
                                  idFamilyProduct={
                                    this.state.idSubFamilyProduct
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {this.buttonArchive(family.id, family.isArchived)}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-default">
                                <SubFamilyProductDelete
                                  idFamilyProduct={
                                    this.state.idSubFamilyProduct
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            <button
                              class="btn btn-primary btn-sm"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "idSubFamilyProduct",
                                  family.id
                                );
                                this.showFormImage();
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/allProductBySubFamily",
                                }}
                                style={{ color: "white" }}
                              >
                                <i class="fas fa-folder"></i> Voir les produits
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
