import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "src/common/Pagination";
import CarrousselHomeService from "src/services/CarrousselHomeService";
import BanniereCategoryHomeService from "src/services/BanniereCategoryHomeService";
import UpdateBanniereCategoryHome from "./UpdateBanniereCategoryHome";

function BanniereCategoryHomePage() {
  const [carrousselHomeList, setcarrousselHomeList] = useState({});
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [index, setIndex] = useState("");
  //for paging
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    allCarrousselHome();
  }, [currentPage - 1, itemsPerPage]);

  const allCarrousselHome = () => {
    BanniereCategoryHomeService.getAllCategoryHomeBannnieres(
      currentPage - 1,
      itemsPerPage
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setcarrousselHomeList(response.data.success.content);
          console.log(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const archiverCarrousselHomeById = (idCarrousselHome) => {
    CarrousselHomeService.archiver(idCarrousselHome)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledCarrousselHomeById = (idCarrousselHome) => {
    console.log("id" + idCarrousselHome);
    BanniereCategoryHomeService.visibled(idCarrousselHome)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          console.log(response.data.success);

          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //**************for edit vente flash modal
  const openModalEdit = (index) => {
    setIsModalOpenEdit(true);
    setIndex(index);
  };
  return (
    <>
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <ToastContainer />
            <div class="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les Categories de page home</h5>
                <p className="float-right">
                  <button class="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/addBanniereCategoryHome" }}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <i className="fas fa-plus" /> Ajouter
                    </Link>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="col-md-12">
          <section class="content">
            <div className="col-12">
              <div className="card card-secondary">
                {/* <div className="card-header">
                  <h3 className="card-title">Variants du produit</h3>
                </div> */}
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th># Titre</th>
                          {/* <th>Titre</th> */}
                          <th>Button</th>
                          <th>url button</th>
                          <th>type</th>
                          <th>visible</th>
                          <th>archived</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {carrousselHomeList &&
                          Array.isArray(carrousselHomeList) &&
                          carrousselHomeList.map((CarrousselHome, index) => (
                            <tr key={index}>
                              {/* <td>{index}</td> */}
                              <td>{CarrousselHome.name}</td>
                              <td>{CarrousselHome.titreBouton}</td>
                              <td>
                                <button
                                  className="btn btn-sm btn-dark"
                                  style={{ width: "150px" }}
                                  onClick={() =>
                                    (window.location.href =
                                      CarrousselHome.urlBouton)
                                  }
                                >
                                  {CarrousselHome.titreBouton}
                                </button>
                              </td>
                              <td>
                                {CarrousselHome.type == 1
                                  ? "categorie big"
                                  : "categorie small"}
                              </td>
                              <td>{CarrousselHome.visibled ? "oui" : "non"}</td>
                              <td>{CarrousselHome.archived ? "oui" : "non"}</td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignContent: "center",
                                  }}
                                >
                                  <button
                                    className={`btn btn-sm ${
                                      CarrousselHome?.visibled
                                        ? "btn-primary"
                                        : "btn-secondary"
                                    }`}
                                    onClick={() =>
                                      visibledCarrousselHomeById(
                                        carrousselHomeList[index].id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        CarrousselHome?.visibled
                                          ? faEye
                                          : faEyeSlash
                                      }
                                    />
                                  </button>
                                  <div className="mr-3"></div>
                                  <button
                                    className="btn btn-sm btn-warning"
                                    onClick={() => openModalEdit(index)}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                  <div className="mr-3"></div>
                                  <button
                                    className={`btn btn-sm ${
                                      CarrousselHome?.archived
                                        ? "btn-secondary"
                                        : "btn-danger"
                                    }`}
                                    onClick={() =>
                                      archiverCarrousselHomeById(
                                        carrousselHomeList[index].id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        CarrousselHome?.archived
                                          ? faRecycle
                                          : faTrash
                                      }
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {isModalOpenEdit && (
                          <UpdateBanniereCategoryHome
                            isModalOpenEdit={isModalOpenEdit}
                            setIsModalOpenEdit={setIsModalOpenEdit}
                            BanniereCategoryGet={carrousselHomeList[index]}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer clearfix">
                  <div className="d-flex">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={(newPage) => setCurrentPage(newPage)}
                    />
                    <div className="ml-3">
                      <select
                        className="form-control pagination-select float-right "
                        id="itemsPerPageSelect"
                        value={itemsPerPage}
                        onChange={(e) =>
                          setItemsPerPage(parseInt(e.target.value, 10))
                        }
                      >
                        {[4, 7, 10, 15, 20].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default BanniereCategoryHomePage;
