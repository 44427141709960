import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import VenteFlashService from "src/services/VenteFlashService";

function AllProduitsByPromotionVenteFlash() {
  //pagination
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const [totalPages, setTotalPages] = useState(1);
  // const [totalElements, setTotalElements] = useState(0);
  // const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  // const [index, setIndex] = useState("");
  const [produitsVenteFlashList, setproduitsVenteFlashList] = useState({});

  useEffect(() => {
    AllproduitsInPromotionsVenteFlash(
      localStorage.getItem("idVenteFlash"),
      localStorage.getItem("idPromotionVenteFlash")
    );
  }, []);

  const AllproduitsInPromotionsVenteFlash = (idVenteFlash, idPromotion) => {
    VenteFlashService.AllproduitsInPromotionsVenteFlash(
      idVenteFlash,
      idPromotion
    )
      .then((response) => {
        console.log("response :", response.data);
        if (response.data.success) {
          setproduitsVenteFlashList(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //**************for edit vente flash modal

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les produits disponibles :</b>
                      <br />
                      <br />
                      <b>Promotion : </b>
                      {localStorage.getItem("namePromotionVenteFlash") || ""}
                      <br />
                      <br />
                      <b> Vente flash : </b>
                      {localStorage.getItem("NameVenteFlash") || ""}
                    </h5>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">
                        Les produits disponibles de cette promotion :{" "}
                        {localStorage.getItem("namePromotionVenteFlash") || ""}{" "}
                      </h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th>Code article</th>
                            <th>Libellé</th>
                            <th>prix avant</th>
                            <th>prix apres</th>
                            <th>remise %</th>
                            <th>Famille</th>
                            <th>Sous famille</th>
                            <th>Categorie</th>
                          </tr>
                        </thead>
                        <tbody>
                          {produitsVenteFlashList &&
                          produitsVenteFlashList.length > 0 ? (
                            produitsVenteFlashList.map((element, index) => (
                              <tr key={index}>
                                <td>{element.codeArticle || ""}</td>
                                <td>{element.name || ""}</td>
                                <td>{element.price?.toFixed(3) || ""} TND</td>
                                <td>
                                  {element.sale_price?.toFixed(3) || ""} TND
                                </td>
                                <td>{element.percent || ""}</td>
                                {/* <td>{element.brand.name || ""}</td> */}
                                <td>{element.famille.name || ""}</td>
                                <td>{element.sousFamille.name || ""}</td>
                                <td>{element.category.name || ""}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                <b>
                                  Pas de produits de cette promotion de Vente
                                  Flash Pages
                                </b>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* {produitsVenteFlashList &&
                        produitsVenteFlashList.length > 0 && (
                          <>
                            <div className="d-flex" style={{ padding: "15px" }}>
                              <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(newPage) =>
                                  setCurrentPage(newPage)
                                }
                              />
                              <div className="ml-3">
                                <select
                                  className="form-control pagination-select float-right"
                                  id="itemsPerPageSelect"
                                  value={itemsPerPage}
                                  onChange={(e) =>
                                    setItemsPerPage(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                >
                                  {[4, 7, 10, 15, 20].map((value) => (
                                    <option key={value} value={value}>
                                      {value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </>
                        )} */}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllProduitsByPromotionVenteFlash;
