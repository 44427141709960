import { React, Component } from "react";
import Files from "react-files";
import axios from "axios";
import CurrencyInput from "react-currency-input";
import NumericInput from "react-numeric-input";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import BrandPopup from "./BrandPopupModel/BrandPopup";
import AddBigImagesProduct from "./Models/AddBigImagesProduct";
import AddSmallImagesProduct from "./Models/AddSmallImagesProduct";
import CategoriePopup from "./CategoryPopupModel/CategoriePopup";
import BrandService from "../services/BrandService";
import CategoryService from "../services/CategoryService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProductService from "../services/ProductService";
import AddColorProduct from "./Models/AddColorProduct";
import AddTagsToProduct from "./Tags/AddTagsToProduct";
import AddTagsProduct from "./Models/AddTagsProduct";
import { Link } from "react-router-dom";
import { Navigate } from "react-router";
import MultivendeurService from "../services/MultivendeurService";

export default class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idProduct: "",
      idBrand: "",
      listCategory: [],
      listMultivendeur: [],
      idCategory: "",
      idMultivendeur: "",
      libelle: "",
      files: [],

      ref: "",
      brands: [],
      categories: [],
      multivendeurs: [],
      options: [],
      optionCategories: [],
      optionMultivendeur: [],
      selectedOption: null,
      selectedOptionCategory: null,
      selectedOptionMultivendeur: null,
      price: "",
      descritption: "",
      // idCategory: [],
      idBrandss: [],
      visible: false,
      livrable: false,
      newProduct: false,
      stockMinimal: 0,
      closePopupBrand: false,
    };
    this.changeLibelle = this.changeLibelle.bind(this);
    this.changeStockMinimal = this.changeStockMinimal.bind(this);
    this.changePrice = this.changePrice.bind(this);
    this.changeRef = this.changeRef.bind(this);
    this.changeDescritption = this.changeDescritption.bind(this);
    this.handleChangeVisible = this.handleChangeVisible.bind(this);
    this.handleChangeLivrable = this.handleChangeLivrable.bind(this);
    this.handleChangeNewProduct = this.handleChangeNewProduct.bind(this);
  }

  handleChangeNewProduct(event) {
    if (this.state.newProduct) {
      this.setState({ newProduct: false });
    } else {
      this.setState({ newProduct: true });
    }

    console.log("newProduct", this.state.newProduct);
  }

  handleChangeVisible(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }

  handleChangeLivrable(event) {
    if (this.state.livrable) {
      this.setState({ livrable: false });
    } else {
      this.setState({ livrable: true });
    }

    console.log("livrable", this.state.livrable);
  }

  loadBrands() {
    BrandService.getAllBrand()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ brands: response.data["object"] });
          console.log("brands", this.state.brands);
          // this.setState({ alert_message: "success" });
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  updateClosePopupBrand = (value) => {
    this.setState({ closePopupBrand: value }, () => {
      if (value === true) {
        //this.loadBrands();
        this.state.brands.map((brand) =>
          this.setState({
            options: [
              ...this.state.options,
              { value: brand.id, label: brand.name },
            ],
          })
        );
      }
      console.log("closePopupBrand" + this.state.closePopupBrand);
    });
  };

  componentDidMount() {
    this.forceUpdate();
    this.loadBrands();

    CategoryService.getAllCategories()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ categories: response.data["object"] });
          console.log("categories", this.state.categories);
          this.remplissageOptionsCategory();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
    MultivendeurService.getAllMultivendeur()
      .then((response) => {
        console.log("response", response.data["success"]);
        if (response.data["success"]) {
          this.setState({ multivendeurs: response.data["success"] });
          console.log("multivendeurs", this.state.multivendeurs);
          this.remplissageOptionsMultivendeur();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  changePrice(event) {
    this.setState({ price: event.target.value });
  }

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }

  changeRef(event) {
    this.setState({ ref: event.target.value });
    console.log("ref", this.state.ref);
  }

  changeDescritption(event) {
    this.setState({ descritption: event.target.value });
  }

  //Select Brand
  remplissageOptions() {
    this.state.brands.map((brand) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: brand.id, label: brand.name },
        ],
      })
    );
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ idBrand: selectedOption.value });
    console.log("idBrand", this.state.idBrand);
  };

  //Select Category
  remplissageOptionsCategory() {
    this.state.categories.map((cat) =>
      this.setState({
        optionCategories: [
          ...this.state.optionCategories,
          { value: cat.id, label: cat.name },
        ],
      })
    );
  }

  //Select multivendeur

  remplissageOptionsMultivendeur() {
    console.log("multivendeur", this.state.multivendeurs);
    this.state.multivendeurs.map((multivendeur) =>
      this.setState({
        optionMultivendeur: [
          ...this.state.optionMultivendeur,
          { value: multivendeur.id, label: multivendeur.name },
        ],
      })
    );
  }
  handleChangeCategory = (selectedOptionCategory) => {
    this.setState({ selectedOptionCategory }, () =>
      console.log(`Option selected:`, this.state.selectedOptionCategory)
    );
    this.setState({ idCategory: selectedOptionCategory.value });
    console.log("idCategory", this.state.idCategory);
  };

  handleChangeMultivendeur = (selectedOptionMultivendeur) => {
    this.setState({ selectedOptionMultivendeur }, () =>
      console.log(`Option selected:`, this.state.selectedOptionMultivendeur)
    );
    this.setState({ idMultivendeur: selectedOptionMultivendeur.value });
  };

  addProduct = (e) => {
    e.preventDefault();
    console.log(
      "selectedOptionCategory in add product",
      this.state.selectedOptionCategory,
      this.state.selectedOptionMultivendeur
    );

    let pric = this.state.price.replace(" DNT", "");
    let productModel = {
      name: this.state.libelle,
      shortDesc: this.state.descritption,
      price: pric.replace(",", ""),
      ref: this.state.ref,
      stockMinimal: this.state.stockMinimal,
      idCategory: this.state.idCategory,
      idBrands: this.state.idBrand,
      idMultivendeur: this.state.idMultivendeur,
      livrable: this.state.livrable,
      visible: this.state.visible,
      newProduct: this.state.newProduct,
    };
    console.log("productModel", productModel);
    ProductService.addProduct(productModel)
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ idProduct: response.data["object"] });
          toast.success("Ajout du produit avec succée");
          console.log("idProduct", this.state.idProduct);
        } else {
          toast.error(response.data["code"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("Une erreur a survenue");
      });
  };

  addColorVarient() {
    if (this.state.idProduct !== "") {
      return (
        <div
          className="tab-pane fade"
          id="custom-tabs-four-color"
          role="tabpanel"
          aria-labelledby="custom-tabs-three-color-tab"
        >
          <AddColorProduct idProduct={this.state.idProduct} />
        </div>
      );
    } else {
      return (
        <div
          className="tab-pane fade"
          id="custom-tabs-four-color"
          role="tabpanel"
          aria-labelledby="custom-tabs-three-color-tab"
        >
          <label>
            Vous devez ajouter les informations du produit avant de choisir les
            couleurs et les images{" "}
          </label>
        </div>
      );
    }
  }

  changeStockMinimal(value) {
    this.setState({ stockMinimal: value });
    console.log("stockMinimal", this.state.stockMinimal);
  }

  /*   addTags() {
    if (this.state.idProduct !== "") {
      return (
        <div
          className="tab-pane fade"
          id="custom-tabs-four-tags"
          role="tabpanel"
          aria-labelledby="custom-tabs-three-tags-tab"
        >
          <AddTagsProduct idProduct={this.state.idProduct} />
        </div>
      );
    } else {
      return (
        <div
          className="tab-pane fade"
          id="custom-tabs-four-tags"
          role="tabpanel"
          aria-labelledby="custom-tabs-three-tags-tab"
        >
          <label>
            Vous devez ajouter les informations du produit avant de choisir les
            tags
          </label>
        </div>
      );
    }
  } */
  // handleChangeLivrable(event) {
  //   if (this.state.livrable) {
  //     this.setState({ livrable: false });
  //   } else {
  //     this.setState({ livrable: true });
  //   }

  //   console.log("livrable", this.state.livrable);
  // }
  // handleChangeVisible(event) {
  //   if (this.state.visible) {
  //     this.setState({ visible: false });
  //   } else {
  //     this.setState({ visible: true });
  //   }

  //   console.log("visible", this.state.visible);
  // }

  // endAddProduct = (e) => {
  //   return <Navigate replace to="/allProducts" />;
  // };
  render() {
    const animatedComponents = makeAnimated();
    const animatedComponentsCategory = makeAnimated();
    const animatedComponentsMultivendeur = makeAnimated();

    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter un produit</h5>
              Deux étapes:
              <br /> 1-Ajouter les informations du produit
              <br /> 2-Ajouter des varients du produit
              {/* <br /> 3-Ajouter des tags */}
            </div>
          </div>
        </section>

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    style={{ color: "green" }}
                    className="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    <b>1-Ajouter les informations</b>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    style={{ color: "green" }}
                    class="nav-link"
                    id="custom-tabs-four-color-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-color"
                    role="tab"
                    aria-controls="custom-tabs-four-color"
                    aria-selected="false"
                  >
                    <b>2-Ajouter les varients </b>
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <form>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <label>Libellé</label>

                          <input
                            type="libelle"
                            className="form-control"
                            placeholder="Saisir..."
                            value={this.state.libelle}
                            onChange={this.changeLibelle}
                          />
                        </div>
                        <div className="col-md-3">
                          <label>Code article</label>

                          <input
                            type="ref"
                            className="form-control"
                            placeholder="Saisir..."
                            value={this.state.ref}
                            onChange={this.changeRef}
                          />
                        </div>
                        <div className="col-md-3">
                          <label>Prix Initial : </label>
                          <CurrencyInput
                            suffix=" DNT"
                            class="form-control"
                            precision="3"
                            value={this.state.price}
                            onChangeEvent={this.changePrice}
                          />
                        </div>
                        <div className="col-md-3">
                          <label>Stock minimal: </label>
                          <NumericInput
                            className="form-control"
                            value={this.state.stockMinimal}
                            onChange={this.changeStockMinimal}
                          />
                        </div>
                      </div>

                      <label>Description</label>
                      <textarea
                        class="form-control"
                        rows="3"
                        placeholder="Saisir..."
                        value={this.state.descritption}
                        onChange={this.changeDescritption}
                      ></textarea>
                      <div className="row">
                        <div className="col-md-8">
                          <div class="form-group">
                            <label>Choisir la marque</label>
                            <Select
                              id={"SelectMarque"}
                              value={this.state.selectedOption}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              options={this.state.options}
                              onChange={this.handleChange}
                              placeholder="Saisir..."
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>Ajouter une nouvelle marque</label>
                          <br />
                          <button
                            type="button"
                            class="btn btn-default"
                            data-toggle="modal"
                            data-target="#modal-default"
                          >
                            Ajouter
                          </button>
                        </div>
                      </div>
                      {
                        <div className="modal fade" id="modal-default">
                          <BrandPopup
                            closePopupBrand={this.updateClosePopupBrand} // Passer la fonction pour mettre à jour closePopupBrand
                          />
                        </div>
                      }

                      <div className="row">
                        <div className="col-md-8">
                          <div class="form-group">
                            <label>Choisir vendeur</label>
                            <Select
                              id={"SelectMultivendeur"}
                              value={this.state.selectedOptionMultivendeur}
                              closeMenuOnSelect={true}
                              components={animatedComponentsMultivendeur}
                              options={this.state.optionMultivendeur}
                              onChange={this.handleChangeMultivendeur}
                              placeholder="Saisir..."
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div class="form-group">
                            <label>Choisir categorie</label>
                            <Select
                              id={"SelectCategory"}
                              value={this.state.selectedOptionCategory}
                              closeMenuOnSelect={true}
                              components={animatedComponentsCategory}
                              options={this.state.optionCategories}
                              onChange={this.handleChangeCategory}
                              placeholder="Saisir..."
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>Ajouter une nouvelle catégorie</label>
                          <br />
                          <button
                            type="button"
                            class="btn btn-default"
                            data-toggle="modal"
                            data-target="#modal-categorie"
                          >
                            Ajouter
                          </button>
                        </div>
                      </div>
                      {
                        <div className="modal fade" id="modal-categorie">
                          <CategoriePopup />
                        </div>
                      }
                      <div className="col-md-12 float-right">
                        <div>
                          <div class="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                defaultChecked={this.state.newProduct}
                                onChange={this.handleChangeNewProduct}
                              />{" "}
                              New
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 float-right">
                        <div onChange={this.onChangeValue}>
                          <div class="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                defaultChecked={this.state.livrable}
                                onChange={this.handleChangeLivrable}
                              />{" "}
                              Livrable
                            </label>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="col-md-12 float-right">
                        <div class="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              defaultChecked={this.state.visible}
                              onChange={this.handleChangeVisible}
                            />{" "}
                            Rendre visible dans le site
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        type="submit"
                        className="btn btn-success float-right"
                        onClick={this.addProduct}
                      >
                        Ajouter
                      </button>
                      <Link
                        to={{
                          pathname: "/allProducts",
                          // idFamilyProduct: family.id,
                        }}
                      >
                        <button
                          type="submit"
                          className="btn btn-default"
                          // onClick={this.endAddProduct}
                        >
                          Annuler
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
                {/* {this.addTags()} */}
                {this.addColorVarient()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
