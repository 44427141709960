import { useEffect, useState } from "react";
// import CurrencyInput from "react-currency-input";
import DiscountService from "../services/DiscountService";
// import getCurrentUserId from "../../../reduxSaga/sagas/requests/currentUserId";
import { Snackbar } from "@material-ui/core";
import Select from "react-select";
import { TextField } from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import makeAnimated from "react-select/animated";
// import WarehouseService from "../../../services/WarehouseService";
import { useNavigate } from "react-router-dom";
import UtilsFormule from "../utils/UtilsFormule";
import { ToastContainer, toast } from "react-toastify";
import getCurrentUserId from "src/common/currentUserId";

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};
const AddDeclencheur = () => {
  const navigate = useNavigate();
  const [priorities, setPriorities] = useState([]);
  const [ordreProduct, setOrdreProduct] = useState(1);
  const [extrats, setExtrats] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [allDiscounts, setAllDiscounts] = useState([]);

  const animatedComponents = makeAnimated();
  const [selectedOptionDiscount, setSelectedOptionDiscount] = useState(null);
  const [discountSelectPlaceHolder, setDiscountSelectPlaceHolder] =
    useState(null);
  const [promotionId, setDiscountId] = useState(0);
  const [discountLabel, setDiscountLabel] = useState("");

  const [startDate, setStartDate] = useState("any");
  const [endDate, setEndDate] = useState("any");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [successAlertText, setSuccessAlertText] = useState();
  const [openInfoAlert, setOpenInfoAlert] = useState(false);
  const [infoAlertText, setInfoAlertText] = useState();
  const [searchData, setSearchData] = useState("");

  function TestValidationDateBettween(DateDebut, DateFin) {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    if (
      DateDebut < formattedCurrentDate ||
      DateFin < formattedCurrentDate ||
      DateFin < DateDebut
    ) {
      return true;
    }
  }

  const handleInputChange = (searchData) => {
    if (searchData) {
      setSearchData(searchData);
      allpromotionsForAddToDeclencheur(searchData);
    } else {
      setAllDiscounts([]);
    }
  };

  const allpromotionsForAddToDeclencheur = async (searchData) => {
    try {
      const response = await DiscountService.allpromotionsForAddToDeclencheur(
        searchData
      );
      if (response.data.success) {
        setAllDiscounts(response.data.success);
      }
      // console.log("data", response.data.success);
    } catch (error) {
      console.log("response", error);
    }
  };
  useEffect(() => {
    discounts &&
      discounts.map((element) => {
        let exist = false;
        if (allDiscounts.length !== 0) {
          for (let index = 0; index < allDiscounts.length; index++) {
            if (allDiscounts[index].value === element?.id) {
              exist = true;
              break;
            }
          }
        }
        if (element?.id !== undefined && exist === false) {
          setAllDiscounts((oldArray) => [
            ...oldArray,
            { value: element, label: element.libelle },
          ]);
        }
      });
  }, [discounts]);

  function addClickParProduct() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        ordert: ordreProduct,
        promotionName: discountLabel,
        promotionId: promotionId,
        // percent: percent,
      },
    ]);
    setOrdreProduct(ordreProduct + 1);
  }
  const onChangeSelectedDiscount = (selectedOption) => {
    setDiscountSelectPlaceHolder();
    setDiscountId(selectedOption.value);
    setDiscountLabel(selectedOption.label);
  };

  function createUIParProducts() {
    return (
      <div class="row">
        <div className="col-sm-5">
          <label>Priorité</label>
          <br />
          <label>{ordreProduct}</label>
        </div>
        <div className="col-sm-7">
          <label>Choisir une règle</label>

          <Select
            styles={styles}
            closeMenuOnSelect={true}
            components={animatedComponents}
            defaultValue={selectedOptionDiscount}
            onChange={onChangeSelectedDiscount}
            onInputChange={handleInputChange}
            options={allDiscounts}
            placeholder={discountSelectPlaceHolder}
          />
        </div>
      </div>
    );
  }
  function addExtratToViewParProduct() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["ordert"] === "" &&
      extrats[0]["promotionName"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>Priorité</td>
              <td>promotion Libellé</td>
              <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.ordert}</td>
                  <td>{extrat.promotionName}</td>

                  <td>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={() => removeExtratParProduct(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  const removeExtratParProduct = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
    }
  };

  const onChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const onChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };
  const onChangeNameHandler = (event) => {
    setName(event.target.value);
  };
  const onChangeDescriptionHandler = (event) => {
    setDescription(event.target.value);
  };

  const onValidHandler = () => {
    if (TestValidationDateBettween(startDate, endDate)) {
      toast.error("il faut selectionner date correcte");
      return;
    }

    let addDeclencheurModel = {
      libelle: name,
      description: description,
      idUser: getCurrentUserId(),
      startDate: startDate.concat("T05:05:59"),
      endDate: endDate.concat("T07:05:59"),
      prioritiesAddModel: extrats,
    };

    if (
      !addDeclencheurModel.libelle ||
      !addDeclencheurModel.prioritiesAddModel
    ) {
      toast.error("completez les info de declencheur");
      return;
    }
    DiscountService.addDeclencheur(addDeclencheurModel).then((result) => {
      if (result.data.success) {
        toast.success(result.data.success);
        setTimeout(() => {
          navigate(`/allDeclencheurs`);
        }, 1000);
      } else if (result.data.error) {
        toast.error("error : " + result.data.error);
      } else {
        toast.error("error");
      }
    });
  };

  const ListPromotionsExiste = () => {
    return (
      <div className="col-sm-12">
        {createUIParProducts()}
        <br />
        <label></label>
        <button
          style={{ height: "40 px", width: "40 px" }}
          type="button"
          className="btn btn-dark float-right"
          onClick={addClickParProduct}
        >
          <i class="fa fa-plus"></i>
        </button>
        {addExtratToViewParProduct()}
      </div>
    );
  };
  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <section className="content-header">
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active">Ajouter Priorité</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content ">
        <div className="container-fluid">
          <div className="timeline">
            <div className="time-label">
              <span style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>Ajouter déclencheur</span>
              </span>
            </div>
            <div>
              <i className="fas fa-1 " style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>1</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 12:05
                </span>
                <h3 className="timeline-header">
                  <b>Informations Générales</b>
                </h3>
                <div className="timeline-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="inputName">Libellé</label>
                        <input
                          onChange={onChangeNameHandler}
                          type="text"
                          id="inputName"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <textarea
                          value={description}
                          placeholder="Saisir..."
                          onChange={onChangeDescriptionHandler}
                          id="inputDescription"
                          className="form-control"
                          rows={4}
                          defaultValue={""}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <i className="fas fa-3 " style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>3</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 5 mins ago
                </span>
                <h3 className="timeline-header no-bordert">
                  <b>Choisir le type de la promotion</b>{" "}
                </h3>
                <div className="timeline-body">
                  <div className="row">{ListPromotionsExiste()}</div>
                </div>
              </div>
            </div>
            <div>
              <i className="fas fa-6" style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>4</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 27 mins ago
                </span>
                <h3 className="timeline-header">
                  <b> Choisir la période de la promotion</b>
                </h3>
                <div className="timeline-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="inputName">Date début</label>

                        <TextField
                          style={{ width: "100%" }}
                          className="col-sm-12"
                          variant="outlined"
                          value={startDate}
                          onChange={onChangeStartDate}
                          id="datetime-local"
                          // type="datetime-local"
                          type="date"
                          defaultValue="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="inputName">Date fin</label>

                        <TextField
                          style={{ width: "100%" }}
                          className="col-sm-12"
                          variant="outlined"
                          value={endDate}
                          onChange={onChangeEndDate}
                          id="datetime-local"
                          // type="datetime-local"
                          type="date"
                          defaultValue="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                class="btn btn-dark"
                style={{ marginTop: "1%" }}
                onClick={onValidHandler}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AddDeclencheur;
