import { Component } from "react";
// import FamilyProductService from "../../services/FamilyProductService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuElementService from "../../services/MenuElementService";
export default class MenuElementDelete extends Component {
  state = {
    menuelement: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      menuelement: "",
    };
    this.removeMenuElement = this.removeMenuElement.bind(this);
    // this.closeWindow = this.closeWindow.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    MenuElementService.getMenuElementById(this.props.idMenuElement)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ menuelement: response.data["object"] });
          console.log("menuelement", this.state.menuelement);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  removeMenuElement() {
    if (this.state.menuelement.isArchived === 0) {
      MenuElementService.archiveMenuElement(this.state.menuelement.id, 1)
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Suppression avec succée");
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }

          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    } else if (this.state.menuelement.isArchived === 1) {
      MenuElementService.archiveMenuElement(this.state.menuelement.id, 0)
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Restauration avec succée");
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }

          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    }
  }

  render() {
    if (this.state.menuelement.isArchived === 1) {
      return (
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Restaurer l'élement {this.state.menuelement.nameElement}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Voulez-vous vraiment restaurer{" "}
              {this.state.menuelement.nameElement}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.removeMenuElement}
              >
                Restaurer
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Supprimer l'élement {this.state.menuelement.nameElement}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Voulez-vous vraiment supprimer{" "}
              {this.state.menuelement.nameElement}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={this.removeMenuElement}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}
