import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faNoteSticky,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import ColorService from "src/services/ColorService";
import UpdateColorsVariant from "./UpdateColorsVariant";
import Pagination from "../../../common/Pagination.jsx";
import AllProductByColor from "./AllProductByColor";

function AllColorsVariant() {
  const [colorsVariantList, setColorsVariantList] = useState({});
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [index, setIndex] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  //*************** voir products ****************/
  const [isProductsColorList, setIsProductsColorList] = useState(false);
  const [colorSelectedProducts, setColorSelectedProducts] = useState("");

  const openSectionProductsColorAffichage = (colorSelected) => {
    setIsProductsColorList(true);
    setColorSelectedProducts(colorSelected);
  };
  //***************/.voir products****************/

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchValue]);

  const fetchData = () => {
    if (searchValue === "") {
      getAllColorsVariant();
    } else {
      searchColor();
    }
  };

  const getAllColorsVariant = () => {
    // If searchValue is empty, fetch all colors
    ColorService.getAllColors(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setColorsVariantList(response.data.success.content);
          console.log(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  //***************Search ****************/
  const searchColor = () => {
    ColorService.searchColor(currentPage - 1, itemsPerPage, searchValue)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setColorsVariantList(response.data.success.content);
          console.log(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const clearSearchValue = () => {
    setSearchValue("");
    getAllColorsVariant();
  };
  //***************Search ****************/

  const archiverColorsVariantById = (idColorsVariant) => {
    ColorService.archiverColorById(idColorsVariant)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  // const visibledColorsVariantById = (idColorsVariant) => {
  //   ColorsVariantService.visibledColorsVariantById(idColorsVariant)
  //     .then((response) => {
  //       if (response.data.success) {
  //         toast.success(response.data.success);
  //         setTimeout(function () {
  //           window.location.reload();
  //         }, 3000);
  //       } else {
  //         toast.error(response.data.error);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Erreur lors de la requête API:", error);
  //     });
  // };

  //***************Modal Edit / update ****************/
  const openModalEdit = (index) => {
    setIsModalOpenEdit(true);
    setIndex(index);
  };
  //***************Modal Edit / update ****************/

  return (
    <>
      {isProductsColorList ? (
        <>
          <AllProductByColor
            isProductsColorList={isProductsColorList}
            setIsProductsColorList={setIsProductsColorList}
            colorSelected={colorSelectedProducts}
          />
        </>
      ) : (
        <>
          <div className="content-wrapper" style={{ marginTop: "0" }}>
            <ToastContainer />
            <div className="content">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <div className="card  mt-3">
                      <div className="card-body">
                        <div className="card-title">
                          <h5>Les couleurs disponibles</h5>
                        </div>
                        <button className="btn btn-dark float-right">
                          <Link
                            to={{ pathname: "/addColor" }}
                            style={{
                              color: "white",
                              "text-decoration": "none",
                            }}
                          >
                            <i className="fas fa-plus"></i>
                          </Link>
                        </button>
                      </div>
                    </div>

                    <>
                      <section className="content">
                        <div className="card">
                          <div className="card-header">
                            <div
                              className="card-title"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <div className="mr-2">
                                Les couleurs disponibles :
                              </div>
                              <b>{totalElements || 0} Couleurs</b>
                              <div>
                                <input
                                  className="form-control mr-sm-2 ml-3"
                                  type="search"
                                  placeholder="Search"
                                  aria-label="Search"
                                  value={searchValue}
                                  onChange={(event) =>
                                    setSearchValue(event.target.value)
                                  }
                                />
                                <i
                                  className="icon-class"
                                  onClick={clearSearchValue}
                                ></i>
                              </div>
                            </div>

                            <div className="card-tools">
                              <button
                                type="button"
                                className="btn btn-tool"
                                data-card-widget="collapse"
                                title="Collapse"
                              >
                                <i className="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>

                          <div className="card-body p-0">
                            <table className="table  projects">
                              <thead>
                                <tr>
                                  <th>Nom </th>
                                  <th>Code Hexa </th>
                                  <th
                                    className="text-center"
                                    style={{ width: "12%" }}
                                  >
                                    Modifier
                                  </th>
                                  <th
                                    className="text-center"
                                    style={{ width: "12%" }}
                                  >
                                    Archiver
                                  </th>
                                  <th
                                    className="text-center"
                                    style={{ width: "12%" }}
                                  >
                                    Voir Produits
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {colorsVariantList &&
                                colorsVariantList.length == 0 ? (
                                  <>
                                    {searchValue != "" ? (
                                      <>
                                        <td>Cette couleur n'existe pas</td>
                                      </>
                                    ) : (
                                      <>
                                        <td>Pas de couleur.</td>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  Array.isArray(colorsVariantList) &&
                                  colorsVariantList.map((element, index) => (
                                    <>
                                      <tr key={index}>
                                        <td>{element?.colorName || ""}</td>
                                        <td>{element?.colorHexa || ""}</td>

                                        <td className="text-center">
                                          <button
                                            style={{ width: 40 }}
                                            data-toggle="modal"
                                            data-target="#update-color"
                                            className="btn btn-sm  btn-warning"
                                            onClick={() => openModalEdit(index)}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            style={{ width: 40 }}
                                            className={`btn btn-sm ${
                                              element?.archived
                                                ? "btn-success"
                                                : "btn-danger"
                                            }`}
                                            onClick={() =>
                                              archiverColorsVariantById(
                                                colorsVariantList[index].id
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                element?.archived
                                                  ? faRecycle
                                                  : faTrash
                                              }
                                            />
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            style={{ width: 40 }}
                                            className={`btn btn-sm ${
                                              element?.archived
                                                ? "btn-dark"
                                                : "btn-dark"
                                            }`}
                                            onClick={() =>
                                              openSectionProductsColorAffichage(
                                                element
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                element?.archived
                                                  ? faEye
                                                  : faEye
                                              }
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                )}

                                {isModalOpenEdit && (
                                  <UpdateColorsVariant
                                    isModalOpenEdit={isModalOpenEdit}
                                    setIsModalOpenEdit={setIsModalOpenEdit}
                                    idColorVariant={colorsVariantList[index].id}
                                  />
                                )}
                              </tbody>
                            </table>
                            <div className="d-flex" style={{ padding: "15px" }}>
                              <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(newPage) =>
                                  setCurrentPage(newPage)
                                }
                              />
                              <div className="ml-3">
                                <select
                                  className="form-control pagination-select float-right "
                                  id="itemsPerPageSelect"
                                  value={itemsPerPage}
                                  onChange={(e) =>
                                    setItemsPerPage(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                >
                                  {[4, 7, 10, 15, 20].map((value) => (
                                    <option key={value} value={value}>
                                      {value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AllColorsVariant;
