import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ColorService from "../../../services/ColorService";
import { BlockPicker } from "react-color";

function UpdateColorsVariant(props) {
  const { isModalOpenEdit, setIsModalOpenEdit, idColorVariant } = props;
  const [colorVariant, setColorVariant] = useState({});
  const [colorVariantGet, setColorVariantGet] = useState({});

  useEffect(() => {
    getColorVariantById(idColorVariant);
  }, [idColorVariant]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setColorVariant({ ...colorVariant, [name]: value });
  };

  const getColorVariantById = (idColorVariant) => {
    ColorService.getColorById(idColorVariant)
      .then((response) => {
        if (response.data.success) {
          setColorVariantGet(response.data.success);
          console.log(response.data.success);
        } else if (response.data.error) {
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const updateColorVariant = (event) => {
    event.preventDefault();
    const updateModal = {
      colorName: colorVariant.colorName || colorVariantGet?.colorName,
      colorHexa: colorVariant.colorHexa || colorVariantGet?.colorHexa,
      numberColors: colorVariant.numberColors || colorVariantGet?.numberColors,
    };

    console.log(updateModal);

    ColorService.updateColorById(colorVariantGet.id, updateModal)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChangeComplete = (color, index) => {
    const updatedColorHexa = colorVariant?.colorHexa
      ? [...colorVariant.colorHexa]
      : [...colorVariantGet?.colorHexa];
    updatedColorHexa[index] = color.hex;
    setColorVariant({ ...colorVariant, colorHexa: updatedColorHexa });
  };

  const handleChangeColorName = (e, index) => {
    const { value } = e.target;

    // Créer une copie du tableau colorName pour éviter la mutation directe
    const updatedColorName = colorVariant.colorName
      ? [...colorVariant.colorName]
      : [...colorVariantGet?.colorName];

    // Mettre à jour la valeur correspondant à l'index donné
    updatedColorName[index] = value;

    // Mettre à jour l'état avec la nouvelle valeur
    setColorVariant({
      ...colorVariant,
      colorName: updatedColorName,
    });
  };

  return (
    <>
      <Modal
        show={isModalOpenEdit}
        onHide={() => setIsModalOpenEdit(false)}
        size="xl"
        id="update-color"
      >
        <Modal.Header style={{ backgroundColor: "gray" }}>
          <Modal.Title>
            Modifier {(colorVariantGet && colorVariantGet?.name) || ""}
          </Modal.Title>
          <button
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsModalOpenEdit(false)}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ToastContainer />
            <>
              <div className="form-group">
                <label>Nombre de couleurs :</label>
                <input
                  type="number"
                  className="form-control float-right"
                  placeholder="nombre de couleurs"
                  name="numberColors"
                  onChange={handleChange}
                  value={
                    colorVariant.numberColors || colorVariantGet?.numberColors
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label>Code Hexa :</label>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {Array.from(
                    {
                      length:
                        (colorVariant && colorVariant.numberColors) ||
                        (colorVariantGet && colorVariantGet.numberColors) ||
                        0,
                    },
                    (_, index) => (
                      <div className="input-group m-3" key={index}>
                        <BlockPicker
                          color={
                            (colorVariant &&
                              colorVariant.colorHexa &&
                              colorVariant.colorHexa[index]) ||
                            (colorVariantGet &&
                              colorVariantGet.colorHexa &&
                              colorVariantGet.colorHexa[index]) ||
                            ""
                          }
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, index)
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="form-group">
                <label>Nom de la couleur :</label>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {Array.from(
                    {
                      length:
                        (colorVariant && colorVariant.numberColors) ||
                        (colorVariantGet && colorVariantGet.numberColors) ||
                        0,
                    },
                    (_, index) => (
                      <div key={index} className="input-group m-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control float-right"
                          placeholder="Nom de la couleur"
                          name="colorName"
                          onChange={(e) => handleChangeColorName(e, index)}
                          value={
                            (colorVariant &&
                              colorVariant.colorName &&
                              colorVariant.colorName[index] &&
                              colorVariant.colorName[index]) ||
                            (colorVariantGet &&
                              colorVariantGet.colorName &&
                              colorVariantGet.colorName[index]) ||
                            ""
                          }
                          required
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsModalOpenEdit(false);
              window.location.href = "AllColorsVariant";
            }}
          >
            Fermer
          </Button>
          <Button variant="warning" onClick={updateColorVariant}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateColorsVariant;
