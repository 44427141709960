import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Files from "react-files";
import CarrousselTrendingHomeService from "../../services/CarrousselTrendingHomeService";

export default class AddBanniereTrending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      files: [],
    };
  }

  handleChange(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };
  addCarrousselHome = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);

    let carrousselHomeModel = {
      visible: this.state.visible,
      // files: this.state.files[0],
    };

    CarrousselTrendingHomeService.addCarrousselTrendingHome(
      dataFile,
      JSON.stringify(carrousselHomeModel)
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la carroussel avec succée");
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter La bannière trending</h5>
              Deux étapes:
              <br /> L'images de la caroussel (456px * 814px)
              <br /> Rendre visible sur le site
            </div>
          </div>
        </section>

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline ">
            <div class="card-header">
              <h3 class="card-title">
                {" "}
                <b>Ajouter la bannière trending</b>
              </h3>
            </div>

            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <Files
                      ref="files"
                      className="files-dropzone-list"
                      // style={{ height: "100px" }}
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      multiple
                      maxFiles={1}
                      maxFileSize={10000000}
                      minFileSize={0}
                      clickable
                    >
                      <label>Cliquer pour Ajouter une image</label>
                    </Files>

                    {this.state.files.length > 0 ? (
                      <div className="files-list">
                        <ul>
                          {this.state.files.map((file) => (
                            <li className="files-list-item" key={file.id}>
                              <div className="files-list-item-preview">
                                {file.preview.type === "image" ? (
                                  <div className="row">
                                    <img
                                      className="files-list-item-preview-image"
                                      src={file.preview.url}
                                      // style={{
                                      //   width: "700px",
                                      //   height: "300px",
                                      // }}
                                    />
                                    <button
                                      id={file.id}
                                      class="btn btn-navbar"
                                      type="button"
                                      // data-widget="navbar-search"
                                      onClick={this.filesRemoveOne.bind(
                                        this,
                                        file
                                      )}
                                    >
                                      <i class="fas fa-times"></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="files-list-item-preview-extension">
                                    {file.extension}
                                  </div>
                                )}
                              </div>
                              <div className="files-list-item-content">
                                <div className="files-list-item-content-item files-list-item-content-item-1">
                                  {file.name}
                                </div>
                                <div className="files-list-item-content-item files-list-item-content-item-2">
                                  {file.sizeReadable}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <h1></h1>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={this.state.visible}
                        onChange={this.handleChange}
                      />{" "}
                      Rendre visible dans le site
                    </label>
                  </div>
                </div>
                {/* </div> */}
                <br />
                <button
                  type="submit"
                  className="btn btn-success float-right"
                  onClick={this.addCarrousselHome}
                >
                  Ajouter
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
