import { useNavigate, useParams } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import AuthentificationService from "./services/AuthentificationService";

const Login = () => {
  const [openInfoAlert, setOpenInfoAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const handleEroorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  useEffect(() => {
    if (localStorage.getItem("accessIsDenied") === "denied") {
      setOpenInfoAlert(true);
    }
  }, []);

  const handleInfoClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenInfoAlert(false);
  };

  const onChangePasswordHandler = (e) => {
    setUserPassword(e.target.value);
  };

  const onChangeUserNameHandler = (e) => {
    setUserName(e.target.value);
  };

  const onLoginHandler = (e) => {
    e.preventDefault();
    AuthentificationService.login(userName, userPassword).then((response) => {
      console.log("response login", response);
      if (response.data["token"]) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem(
          "status",
          JSON.stringify(response.data.token.nameRole)
        );
        localStorage.setItem("accessIsDenied", "connected");

        window.location.href = "/";
      }

      if (Object.keys(response.data) === "failed") {
        setOpenErrorAlert(true);
      }
    });
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box" style={{ marginTop: -100 }}>
        <div className="login-logo">
          <a href="../../index2.html">
            <b>Kastelo</b>BackOffice
          </a>
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">
              Connectez-vous pour démarrer votre session.
            </p>
            {/* <form> */}
            <form className="form" role="form">
              <div className="form-group">
                <div className="input-group mb-3">
                  <input
                    onChange={onChangeUserNameHandler}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="my_unique_id"
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    onChange={onChangePasswordHandler}
                    type="password"
                    className="form-control"
                    placeholder="Mot de passe"
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4"></div>

                  <div className="col-4">
                    <button
                      type="submit"
                      className="btn btn-dark "
                      onClick={onLoginHandler}
                    >
                      Connexion
                    </button>
                  </div>

                  <div className="col-4"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Snackbar
        open={openInfoAlert}
        autoHideDuration={2000}
        onClose={handleInfoClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert variant="filled" onClose={handleInfoClose} severity="info">
          votre session est expiré
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={2000}
        onClose={handleEroorClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert variant="filled" onClose={handleEroorClose} severity="error">
          L’adresse e-mail et le mot de passe que vous avez saisi(e) n’est pas
          associé(e) à un compte.
        </Alert>
      </Snackbar>
    </div>
  );
};
export default Login;
