import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BonPlanService from "../services/BonPlanService";

export default class RemoveProductBonPlanPopup extends Component {
  state = {
    productBonPlan: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      productBonPlan: "",
    };
    this.RemoveProductBonPlan = this.RemoveProductBonPlan.bind(this);
    // this.closeWindow = this.closeWindow.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    BonPlanService.getProduitBonPlanById(this.props.idProductBonPlan)
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ productBonPlan: response.data["object"] });
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("productTrending", this.state.productTrending);

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  }
  RemoveProductBonPlan() {
    // if (this.state.brand.isArchived === 0) {
    BonPlanService.archiveProduitBonPlanById(this.props.idProductBonPlan, 1)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Suppression avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
    // }
    console.log("removeProductBonPlan");
  }

  render() {
    return (
      <div className="modal fade" id="modal-removeBonPlan">
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Retirer le produit du bon plan</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Voulez-vous vraiment retirer ce produit du bon plan
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={this.RemoveProductBonPlan}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
