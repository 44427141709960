import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class SaisonService {
  //add
  addSaison(SaisonModel) {
    return api.post(urlsCommon.BASE_SAISON_URL + "/addSaison", SaisonModel);
  }

  //get
  getSaisonById(id) {
    return api.get(urlsCommon.BASE_SAISON_URL + "/getSaisonById/" + id);
  }
  getAllSaisons() {
    return api.get(urlsCommon.BASE_SAISON_URL + "/getAllSaisons");
  }
  getAllSaisonsPredefini() {
    return api.get(urlsCommon.BASE_SAISON_URL + "/getAllSaisonsPredefini");
  }

  //put
  update(id, saisonModel) {
    return api.put(urlsCommon.BASE_SAISON_URL + "/update/" + id, saisonModel);
  }
  visible(id, deed) {
    return api.put(urlsCommon.BASE_SAISON_URL + "/visible/" + id + "/" + deed);
  }
  archive(id, deed) {
    return api.put(urlsCommon.BASE_SAISON_URL + "/archive/" + id + "/" + deed);
  }
}
export default new SaisonService();
