import { Component } from "react";
import { Link } from "react-router-dom";
import NouveauteService from "../../services/NouveauteService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NouveauteBanniereDelete from "./NouveauteBanniereDelete";
import NouveauteBanniereVisible from "./NouveauteBanniereVisible";

export default class NouveauteBanniere extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nouveautes: [],
      idnouveaute: "",
      showFormImage: false,
    };
  }
  componentDidMount() {
    this.forceUpdate();
    NouveauteService.allNouveauteBanniere()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ nouveautes: response.data["object"] });
          console.log("resonse");
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  formatDate(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth()).padStart(2, "0");
    let year = cdate.getFullYear();
    return day + "/" + month + "/" + year;
  }
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Banniere nouveaute</h5>
              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addNouveauteBanniere" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    <i class="fas fa-plus"></i>
                    Ajouter
                  </Link>
                </button>
              </p>
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Banniere de la page nouveaute</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Titre </th>
                    <th>visible</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.nouveautes.map((nouveaute) => (
                    <tr>
                      <td>{nouveaute.id}</td>
                      <td>{nouveaute.titre}</td>
                      {/* <td>{this.formatDate(nouveaute.dateDebut)}</td>
                      <td>{this.formatDate(nouveaute.dateFin)}</td> */}
                      <td>
                        <button
                          data-toggle="modal"
                          data-target="#modal-visible"
                          // style={{ width: 40 }}
                          className={`btn btn-sm ${
                            nouveaute?.visible ? "btn-secondary" : "btn-primary"
                          }`}
                          onClick={() => {
                            this.setState({
                              idnouveaute: nouveaute.id,
                            });
                            this.showFormImage();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={nouveaute?.visible ? faEyeSlash : faEye}
                          />
                        </button>
                      </td>
                      <td>
                        <button
                          // style={{ width: 40 }}
                          className={`btn btn-sm ${
                            nouveaute?.isArchived ? "btn-success" : "btn-danger"
                          }`}
                          onClick={() => {
                            this.setState({
                              idnouveaute: nouveaute.id,
                            });
                            this.showFormImage();
                          }}
                          data-toggle="modal"
                          data-target="#modal-default"
                        >
                          <FontAwesomeIcon
                            icon={nouveaute?.isArchived ? faRecycle : faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}

                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-visible">
                      <NouveauteBanniereVisible
                        idnouveaute={this.state.idnouveaute}
                      />
                    </div>
                  )}
                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-default">
                      <NouveauteBanniereDelete
                        idThemaidnouveautetique={this.state.idnouveaute}
                      />
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
