import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import TotalLookService from "src/services/TotalLookService";

export default function UpdateTotalLook({ look }) {
  const [produitsLook, setProduitsLook] = useState([]);
  const [lookUpdate, setLookUpdate] = useState("");
  //image look
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  //update produits
  const [produit, setProduit] = useState("");
  const [refProduit, setRefProduit] = useState("");

  // const [refProduitList, setRefProduitList] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLookUpdate({ ...lookUpdate, [name]: value });
  };

  useEffect(() => {
    getProduitsLook();
  }, [look]);

  const getProduitsLook = () => {
    TotalLookService.getAllProduitsByLook(look?.id)
      .then((response) => {
        if (response.data.success) {
          setProduitsLook(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getProduitDetailsByRef = (reference) => {
    TotalLookService.getProduitDetailsByRef(reference)
      .then((response) => {
        if (response.data.success) {
          setProduit(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //image
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };
  const addToList = () => {
    if (refProduit.trim() !== "" && produitsLook.length < 4) {
      getProduitDetailsByRef(refProduit);
      setProduitsLook((prev) => [...prev, produit]);
      setRefProduit("");
    } else {
      if (refProduit.trim() == "") {
        toast.error("pas de reference de produit");
      }
      if (produitsLook.length > 3) {
        toast.error("il faut ajouter 4 produits max");
      }
      if (produitsLook.length < 2) {
        toast.error("il faut ajouter 2 produits min");
      }
    }
  };

  const deleteProduct = (index) => {
    const updatedList = [...produitsLook];
    updatedList.splice(index, 1);
    setProduitsLook(updatedList);
  };

  const updateTotalLook = () => {
    const model = {
      id: look?.id,
      name: look?.name,
      titre: lookUpdate?.titre || look?.titre,
      subTitle: lookUpdate?.subTitle || look?.sousTitre,
      description: lookUpdate?.description || look?.description,
      titreBouton: lookUpdate?.titreBouton || look?.buttonTitre,
      visible: look?.visibled,
    };
    const produitsReferences = produitsLook.map(
      (produit) => produit.codeArticle
    );
    // console.log("model :", model);
    // console.log("produitsReferences :" + produitsReferences);
    // console.log("imageFile :" + imageFile);

    TotalLookService.updateTotalLook(model, produitsReferences, imageFile)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <div class="modal-dialog modal-xl">
      <div className="card card-secondary">
        <ToastContainer />
        <div class="modal-content">
          <div className="card-header">
            <h3 className="card-title">
              Modifier Ce Look <b>{look?.titre || ""}</b>
            </h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Titre</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-clock"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="titre"
                      // value={
                      //   lookUpdate && lookUpdate.titre !== null
                      //     ? lookUpdate.titre
                      //     : look && look.titre
                      //     ? look.titre
                      //     : ""
                      // }
                      value={lookUpdate?.titre || look?.titre}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Sous Titre</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-clock"></i>
                      </span>
                    </div>
                    <input
                      name="subTitle"
                      type="text"
                      className="form-control"
                      value={lookUpdate?.subTitle || look?.sousTitre}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <div className="input-group">
                <textarea
                  type="text"
                  className="form-control"
                  name="description"
                  placeholder="..."
                  value={lookUpdate.description || look.description}
                  onChange={handleChange}
                  rows={3}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Titre button</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="far fa-clock"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="titreBouton"
                  value={lookUpdate?.titreBouton || look?.buttonTitre}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">
                    Image Background carrée
                  </label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="exampleInputFile"
                        onChange={handleFileChange}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                        id="fileInputLabel"
                      >
                        Choose file
                      </label>
                    </div>
                    {imageFile && (
                      <>
                        <div className="input-group-append">
                          <button
                            className="btn btn-danger"
                            type="button"
                            onClick={handleDeleteClick}
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100"></div>
              <div className="col-sm-6">
                {look && look.image && (
                  <>
                    <label>Image avant : </label>
                    <div className="input-group-append">
                      <img
                        src={(look && look?.image) || ""}
                        alt="Selected"
                        style={{ width: "20%" }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-6">
                {imageFile && (
                  <>
                    <label>Image apres : </label>
                    <div className="input-group-append">
                      <img
                        src={imagePreview}
                        alt="Selected"
                        style={{ width: "20%" }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="form-group">
                <label htmlFor="produit">
                  Modifier ou ajouter des produits par code article (entre 2 et
                  4 produits)
                </label>
                <div className="input-group">
                  <div className="input-group-prepend" style={{ width: "90%" }}>
                    <input
                      value={refProduit}
                      onChange={(e) => {
                        setRefProduit(e.target.value);
                      }}
                      type="text"
                      id="produit"
                      className="form-control"
                    />
                  </div>
                  <div className=" input-group-append">
                    <button
                      onClick={addToList}
                      type="button"
                      class="btn btn-dark"
                    >
                      <i class="fas  fa-solid fa-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>

              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>#</th>
                    <th>Reference</th>
                    <th>Libelle </th>
                    {/* <th>Famille</th> */}
                    <th>Sous Famille</th>
                    <th>Categorie</th>
                    {/* <th>Prix</th>
                      <th>Promo</th>
                      <th>Prix apres Promotion</th> */}
                    <th>Supprimer</th>
                  </tr>
                </thead>
                <tbody>
                  {produitsLook && produitsLook.length > 0 ? (
                    <>
                      {produitsLook &&
                        produitsLook.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                <Image
                                  src={item.image || ""}
                                  width={40}
                                  height={60}
                                  loading="lazy"
                                />
                              </div>
                            </td>
                            <td>{item.codeArticle || ""}</td>
                            <td>{item.nameToFo || ""}</td>
                            <td>{item.sousFamille || ""}</td>
                            <td>{item.category || ""}</td>
                            {/* <td>{item.price || ""}</td> */}

                            <td>
                              <button
                                onClick={() => deleteProduct(index)}
                                type="button"
                                className="btn btn-danger btn-sm"
                              >
                                Supprimer
                                <i class="fas fa-trash ml-2"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                      <tr className="text-center">
                        <td colSpan="4">
                          Pas de Produits dans ce Look pour le moment
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            <button className="btn btn-default" data-dismiss="modal">
              Annuler
            </button>
            <button
              className="btn btn-warning float-right"
              onClick={updateTotalLook}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
