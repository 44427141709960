import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class SectionHomeService {
  addSectionHome(sectionHomeModel) {
    return api.post(
      urlsCommon.BASE_SECTION_HOME_URL + "/add",
      sectionHomeModel
    );
  }

  getAllSectionHome(page, size) {
    return api.get(urlsCommon.BASE_SECTION_HOME_URL + "/get", {
      params: {
        page: page,
        size: size,
      },
    });
  }
  //visible and archive
  visibleSectionHome(id, deed) {
    return api.put(
      urlsCommon.BASE_SECTION_HOME_URL +
        "/visibleSectionHome/" +
        id +
        "/" +
        deed
    );
  }
  archivedSectionHome(id, deed) {
    return api.put(
      urlsCommon.BASE_SECTION_HOME_URL +
        "/archivedSectionHome/" +
        id +
        "/" +
        deed
    );
  }
  getSectionHomeById(id) {
    return api.get(
      urlsCommon.BASE_SECTION_HOME_URL + "/getSectionHomeById/" + id
    );
  }
}

export default new SectionHomeService();
