import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DiscountService from "../services/DiscountService";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
  faRecycle,
  faReorder,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "src/common/Pagination";
import UtilsFormule from "src/utils/UtilsFormule";

const Discounts = () => {
  const navigate = useNavigate();
  const [availableDeclencheurs, setAvailableDeclencheurs] = useState();
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    allDeclencheurs();
  }, [currentPage, itemsPerPage]);

  const allDeclencheurs = () => {
    DiscountService.allDeclencheurs(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setAvailableDeclencheurs(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const onAddPriorityHandler = () => {
    navigate(`/addDeclencheur`);
  };
  const onUpdatePriorityHandler = (idDeclencheur) => {
    navigate(`/UpdateDeclencheur`);
    localStorage.setItem("idDeclencheurForUpdate", idDeclencheur);
  };
  const voirPrioritesByDeclencheur = (idDeclencheur) => {
    localStorage.setItem("idDeclencheur", idDeclencheur);
    const url = "/voirPrioritesByDeclencheur";
    window.open(url, "_blank");
  };

  const archiverById = (id) => {
    DiscountService.archiverDeclencheur(id)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledById = (id) => {
    DiscountService.visibleDeclencheur(id)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div className="row mb-2">
            <div className="col-sm-12">
              <div class="card  mt-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <b>Les Declencheurs </b>
                  </h5>
                  <button
                    className="btn btn-dark float-right"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={onAddPriorityHandler}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>

              <section class="content">
                <div class="card card-dark">
                  <div class="card-header">
                    <h3 class="card-title">Les Declencheurs disponibles</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <table class="table  projects">
                      <thead>
                        <tr>
                          <th># Date de creation</th>
                          <th>Libellé</th>
                          <th>Ref</th>
                          <th># Date debut</th>
                          <th># Date fin</th>
                          <th> Voir Priorities</th>
                          <th>Modifier</th>
                          <th>Visibilité</th>
                          <th>archiver</th>
                        </tr>
                      </thead>
                      <tbody>
                        {availableDeclencheurs &&
                        availableDeclencheurs.length > 0 &&
                        Array.isArray(availableDeclencheurs) ? (
                          availableDeclencheurs.map(
                            (declencheurItem, index) => (
                              <tr key={index}>
                                <td>
                                  {UtilsFormule.formatDateHours(
                                    declencheurItem?.creationDate || ""
                                  )}
                                </td>
                                <td>{declencheurItem?.nameCC || ""}</td>
                                <td>{declencheurItem?.refCC || ""}</td>
                                <td>
                                  {UtilsFormule.formatDateHours(
                                    declencheurItem?.startDate || ""
                                  )}
                                </td>
                                <td>
                                  {UtilsFormule.formatDateHours(
                                    declencheurItem?.endDate || ""
                                  )}
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className="btn btn-sm btn-info"
                                    onClick={() => {
                                      voirPrioritesByDeclencheur(
                                        declencheurItem?.id
                                      );
                                    }}
                                  >
                                    Voir Priorities
                                    <FontAwesomeIcon
                                      icon={faReorder}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className="btn btn-sm btn-warning"
                                    // data-toggle="modal"
                                    // data-target="#exampleModaldeclencheurItemUpdate"
                                    onClick={() => {
                                      onUpdatePriorityHandler(
                                        declencheurItem?.id
                                      );
                                    }}
                                  >
                                    Modifier
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      declencheurItem?.visible
                                        ? "btn btn-success btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() =>
                                      visibledById(declencheurItem?.id)
                                    }
                                  >
                                    {declencheurItem?.visible ? (
                                      <>
                                        Rendre InVisible{" "}
                                        <FontAwesomeIcon
                                          icon={faEyeSlash}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Visible{" "}
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      declencheurItem?.isArchived
                                        ? "btn btn-danger btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() =>
                                      archiverById(declencheurItem?.id)
                                    }
                                  >
                                    {declencheurItem?.isArchived ? (
                                      <>
                                        Restaurer{" "}
                                        <FontAwesomeIcon
                                          icon={faRecycle}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Archived{" "}
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr className="text-center">
                            <td colSpan="7">
                              Pas de Declencheurs pour le moment
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {availableDeclencheurs &&
                      availableDeclencheurs.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Discounts;
