import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ShopThematiqueService from "src/services/ShopThematiqueService";
import urlsCommon from "src/common/urls-common";

function BanniereThematiqueUpdateModal({ banniereItem }) {
  const [thematiqueUpdate, setThematiqueUpdate] = useState({});
  const [typeBanniere, setTypeBanniere] = useState(0);
  //image
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  //for select page thematique
  const animatedComponents = makeAnimated();
  const [listAllPageThematiqueExiste, setListAllPageThematiqueExiste] =
    useState([]);
  const [selectedlistAllPageThematique, setSelectedlistAllPageThematique] =
    useState([]);

  useEffect(() => {
    getAllPagesThematiqueForSelectInUrlButtonBanniere();
  }, []);

  useEffect(() => {
    setTypeBanniere(banniereItem?.type_banniere === "Page thematique" ? 1 : 0);
  }, [banniereItem?.type_banniere]);

  useEffect(() => {
    setSelectedlistAllPageThematique(
      banniereItem?.urlPageThematiqueSelected != undefined
        ? banniereItem?.urlPageThematiqueSelected
        : ""
    );
  }, [banniereItem?.urlPageThematiqueSelected]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setThematiqueUpdate({ ...thematiqueUpdate, [name]: value });
  };
  const handlePageThematiqueSelectedChange = (selectedOptions) => {
    setSelectedlistAllPageThematique(selectedOptions);
  };

  //*************************Chekbox */
  function onValueChange(event) {
    if (event.target.value === "0") {
      setTypeBanniere(0);
    } else if (event.target.value === "1") {
      setTypeBanniere(1);
    }
  }

  const updateThematiqueBanniere = (e) => {
    e.preventDefault();
    const thematiqueModel = {
      id: banniereItem?.id,
      titre: thematiqueUpdate.titre || banniereItem?.titre,
      description: thematiqueUpdate.description || banniereItem?.description,
      type: typeBanniere,
      titreButton:
        typeBanniere == 0
          ? thematiqueUpdate.titreButton || banniereItem?.titreButton
          : "",
      UrlButton:
        typeBanniere == 0
          ? urlsCommon.ADRESS_Site +
              "thematique/vetements/?page=1&theme=" +
              selectedlistAllPageThematique.label || banniereItem?.urlBouton
          : "",
      // visible: banniereItem.visible,
    };
    ShopThematiqueService.updateThematiquesBanniere(thematiqueModel, imageFile)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.href = "/allBanniereThematiqueHome";
          }, 1000);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  //******for image */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };
  const getAllPagesThematiqueForSelectInUrlButtonBanniere = () => {
    ShopThematiqueService.getAllPagesThematiqueForSelectInUrlButtonBanniere()
      .then((response) => {
        if (response.data.success) {
          setListAllPageThematiqueExiste(response.data.success);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  return (
    <>
      <div
        class="modal fade"
        id="exampleModalEditThematique"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalEditThematique"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalEditThematique">
                Update Banniere
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>Titre :</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="titre"
                    name="titre"
                    value={thematiqueUpdate?.titre || banniereItem?.titre}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <div className="input-group">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="description"
                    value={
                      thematiqueUpdate?.description || banniereItem?.description
                    }
                    name="description"
                    onChange={handleChange}
                    rows={3}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">
                      Image Banniere {""}
                      {typeBanniere == 1 ? "(ratio 0.20)" : null}
                    </label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          onChange={handleFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                          id="fileInputLabel"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100"></div>

                <div className="col-sm-6">
                  {imageFile ? (
                    <>
                      <div className="input-group-append">
                        <img
                          src={imagePreview}
                          alt="Selected"
                          style={{ width: "80%" }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {banniereItem?.urlImage && (
                        <div className="input-group-append">
                          <img
                            src={banniereItem?.urlImage || ""}
                            alt="Selected"
                            style={{ width: "80%" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="mb-4"></div>

              <div className="row ">
                <div className="col-md-6">
                  <div className="form-group clearfix">
                    <div className="icheck-asbestos d-inline">
                      <input
                        id="section1"
                        type="radio"
                        name="section1"
                        value="0"
                        checked={typeBanniere == 0}
                        onChange={onValueChange}
                      />
                      <label htmlFor="section1">
                        Bannière de page d'accueil
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group clearfix">
                    <div className="icheck-asbestos d-inline">
                      <input
                        id="section2"
                        type="radio"
                        name="section2"
                        value="1"
                        checked={typeBanniere == 1}
                        onChange={onValueChange}
                      />
                      <label htmlFor="section2">
                        Bannière de page Thematique
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3"></div>
              {typeBanniere == 0 && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Button Titre</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="title Button Banniere"
                            value={
                              thematiqueUpdate?.titreButton ||
                              banniereItem?.titreButton
                            }
                            onChange={handleChange}
                            name="titreButton"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Sélectionner Page Thematique</label>
                        <Select
                          defaultValue={selectedlistAllPageThematique || ""}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={listAllPageThematiqueExiste}
                          onChange={handlePageThematiqueSelectedChange}
                          value={selectedlistAllPageThematique || ""}
                          placeholder="Sélectionner Les produits..."
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                // onClick={() => {
                //   setIsModalArchiverOrVisibleOpen(false);
                //   window.location.reload();
                // }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-warning"
                onClick={(e) => updateThematiqueBanniere(e)}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BanniereThematiqueUpdateModal;
