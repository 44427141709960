import { faFlag, faLayerGroup, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import DashboardService from "src/services/DashboardService";

export default function Dashboard() {
  const [nbBrands, setNbBrands] = useState(0);
  const [nbSousFamilles, setNbSousFamilles] = useState(0);
  const [nbCategories, setNbCategories] = useState(0);
  //commande
  const [nbCommandes, setNbCommandes] = useState(0);
  const [ChiffresAffairesAttendu, setChiffresAffairesAttendu] = useState(0);
  const [ChiffreAffaireLivree, setChiffreAffaireLivree] = useState(0);
  const [MoyNbArticleParPanier, setMoyNbArticleParPanier] = useState(0);
  const [nombreCommandesSelonEtat, setNombreCommandesSelonEtat] = useState([]);
  const [PanierMoyen, setPanierMoyen] = useState(0);

  useEffect(() => {
    getNombreBrands();
    getNombreSousFamilles();
    getNombreCategories();
    getNombreCommandes();
    getChiffreAffaireLivree();
    getChiffresAffairesAttendu();
    getMoyNbArticleParPanier();
    getNombreCommandesSelonEtat();
    getPanierMoyen();
  }, []);
  const getNombreBrands = () => {
    DashboardService.getNombreBrands()
      .then((response) => {
        if (response.data.success) {
          setNbBrands(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getNombreSousFamilles = () => {
    DashboardService.getNombreSousFamilles()
      .then((response) => {
        if (response.data.success) {
          setNbSousFamilles(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getNombreCategories = () => {
    DashboardService.getNombreCategories()
      .then((response) => {
        if (response.data.success) {
          setNbCategories(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getNombreCommandes = () => {
    DashboardService.getNombreCommandes()
      .then((response) => {
        if (response.data.success) {
          setNbCommandes(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getChiffresAffairesAttendu = () => {
    DashboardService.getChiffresAffairesAttendu()
      .then((response) => {
        if (response.data.success) {
          setChiffresAffairesAttendu(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getChiffreAffaireLivree = () => {
    DashboardService.getChiffreAffaireLivree()
      .then((response) => {
        if (response.data.success) {
          setChiffreAffaireLivree(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getNombreCommandesSelonEtat = () => {
    DashboardService.getNombreCommandesSelonEtat()
      .then((response) => {
        if (response.data.success) {
          setNombreCommandesSelonEtat(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getPanierMoyen = () => {
    DashboardService.getPanierMoyen()
      .then((response) => {
        if (response.data.success) {
          setPanierMoyen(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getMoyNbArticleParPanier = () => {
    DashboardService.getMoyNbArticleParPanier()
      .then((response) => {
        if (response.data.success) {
          setMoyNbArticleParPanier(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  function determineClasseCss(index) {
    if (index == 0) {
      return "small-box bg-warning";
    } else if (index == 1) {
      return "small-box bg-dark";
    } else if (index == 2) {
      return "small-box bg-warning";
    } else if (index == 3) {
      return "small-box bg-warning";
    } else if (index == 4) {
      return "small-box bg-dark";
    } else if (index == 5) {
      return "small-box bg-warning";
    } else if (index == 6) {
      return "small-box bg-dark";
    } else if (index == 7) {
      return "small-box bg-warning";
    } else {
      return "small-box bg-dark";
    }
  }

  return (
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Tableau de bord Kastelo</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <a href="/">Kastelo</a>
                </li>
                <li class="breadcrumb-item active">Tableau de bord Kastelo</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            {/* <div class="col-lg-3 col-6">
              <div class="small-box bg-danger">
                <div class="inner">
                  <h3>{nbBrands}</h3>

                  <p>Marques</p>
                </div>
                <div class="icon">
                  <FontAwesomeIcon icon={faFlag} />
                </div>

                <a href="/allBrands" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>
                    {nbSousFamilles}
                    <sup style={{ fontSize: "20px" }}>%</sup>
                  </h3>

                  <p>Sous Familles</p>
                </div>
                <div class="icon">
                  <FontAwesomeIcon icon={faTag} />
                </div>
                <a href="/allSubFamilyProducts" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>{nbCategories}</h3>

                  <p>Categories</p>
                </div>
                <div class="icon">
                  <FontAwesomeIcon icon={faLayerGroup} />
                </div>
                <a href="/allCategories" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div> */}
            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>{nbCommandes}</h3>

                  <p>Commandes Total</p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a href="/allCommandes" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            {Array.isArray(nombreCommandesSelonEtat) &&
              nombreCommandesSelonEtat.map((nbcommande, index) => (
                <div class="col-lg-3 col-6" key={index}>
                  {/* <div class={`${determineClasseCss(index)}`}> */}
                  <div class="small-box bg-warning">
                    <div class="inner">
                      <h3>{nbcommande?.value}</h3>
                      <p>{nbcommande?.label || ""}</p>
                    </div>
                    <div class="icon">
                      <i class="ion ion-bag"></i>
                    </div>
                    <a href="/allCommandes" class="small-box-footer">
                      Plus d'infos <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              ))}
          </div>
          <div className="row mt-2">
            <div className="col mb-2">
              <h2> Chiffre d'Affaires </h2>
            </div>

            <div className="w-100"></div>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>
                    {ChiffresAffairesAttendu}
                    <sup style={{ fontSize: "20px" }}>TND</sup>
                  </h3>

                  <p>Chiffre d'Affaires Attendu </p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a href="/" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>
                    {ChiffreAffaireLivree}{" "}
                    <sup style={{ fontSize: "20px" }}>TND</sup>
                  </h3>

                  <p>Chiffre d'Affaires Livrée </p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a href="/" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col mb-2">
              <h2> Moyen </h2>
            </div>
            <div className="w-100"></div>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>
                    {PanierMoyen} <sup style={{ fontSize: "20px" }}>TND</sup>
                  </h3>

                  <p>Panier Moyen </p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a href="/" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>{MoyNbArticleParPanier}</h3>

                  <p>Moyen Nombre Article Par Panier</p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a href="/" class="small-box-footer">
                  Plus info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
