import axios from "axios";
import { useNavigate } from "react-router-dom";
import http from "../common/http-common.jsx";
import TokenService from "../services/token.js";

http.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
http.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/authenticate" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await http.post("/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          // console.log(rs.data.errorCode);

          const accessToken = rs.data?.accessToken;
          // console.log(accessToken);
          TokenService.updateLocalAccessToken(accessToken);
          return http(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      //here
      if (
        err.response.status === 403 &&
        originalConfig.url === "/refreshtoken" &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        try {
          console.log("it is here ", err.response.status);

          TokenService.removeUser();
          localStorage.removeItem("status");
          localStorage.setItem("accessIsDenied", "denied");
          window.location.href = "/login";
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);
export default http;
