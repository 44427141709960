import { useEffect, useState } from "react";
import Pagination from "src/common/Pagination";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
  faRemoveFormat,
  faTrash,
  faTrashRestore,
} from "@fortawesome/free-solid-svg-icons";
import TotalLookService from "src/services/TotalLookService";
import { Image, Spinner } from "react-bootstrap";
import UpdateTotalLook from "./UpdateTotalLook";
import VisibleTotalLook from "./VisibleTotalLook";
import ArchiverTotalLook from "./ArchiverTotalLook";

export default function TotalLookPage() {
  // const [loading, setLoading] = useState(true);
  const [look, setLook] = useState("");
  const [TotalLook, setTotalLook] = useState([]);
  const [ShowFormImage, setShowFormImage] = useState(false);
  const [searchValueTotaLook, setSearchValueTotaLook] = useState(null);
  const [searchValueProduits, setSearchValueProduits] = useState(null);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    getAllTotalLook(searchValueTotaLook, searchValueProduits);
    // getNombreTotalLook();
  }, [currentPage - 1, itemsPerPage]);

  const getAllTotalLook = (searchValueTotaLook, searchValueProduits) => {
    TotalLookService.getAllTotalLook(
      currentPage - 1,
      itemsPerPage,
      searchValueTotaLook,
      searchValueProduits
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setTotalLook(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibleLook = (id) => {
    TotalLookService.visible(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getAllTotalLook(searchValueTotaLook, searchValueProduits);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const archiveLook = (id) => {
    TotalLookService.archive(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getAllTotalLook(searchValueTotaLook, searchValueProduits);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  // //for visible sur le site
  // function showFormImage() {
  //   setShowFormImage(!ShowFormImage);
  // }

  // function buttonVisible(id, visible) {
  //   if (visible) {
  //     return (
  //       <button
  //         style={{ width: "150px" }}
  //         class="btn btn-danger btn-sm float-center"
  //         data-toggle="modal"
  //         data-target="#modal-visible"
  //         onClick={() => {
  //           setLook(id);
  //           showFormImage();
  //         }}
  //       >
  //         Rendre Invisible{" "}
  //         <FontAwesomeIcon icon={faEyeSlash} className="ml-2" />
  //       </button>
  //     );
  //   } else {
  //     return (
  //       <button
  //         style={{ width: "150px" }}
  //         className="btn btn-success btn-sm"
  //         data-toggle="modal"
  //         data-target="#modal-visible"
  //         onClick={() => {
  //           setLook(id);
  //           showFormImage();
  //         }}
  //       >
  //         Rendre Visible <FontAwesomeIcon icon={faEye} className="ml-2" />
  //       </button>
  //     );
  //   }
  // }

  // function buttonArchived(id, isArchived) {
  //   if (isArchived) {
  //     return (
  //       <button
  //         style={{ width: "190px" }}
  //         class="btn btn-danger btn-sm float-center"
  //         data-toggle="modal"
  //         data-target="#modal-archived"
  //         onClick={() => {
  //           setLook(id);
  //           showFormImage();
  //         }}
  //       >
  //         Rendre Not Archived{" "}
  //         <FontAwesomeIcon icon={faTrashRestore} className="ml-2" />
  //       </button>
  //     );
  //   } else {
  //     return (
  //       <button
  //         style={{ width: "190px" }}
  //         className="btn btn-success btn-sm"
  //         data-toggle="modal"
  //         data-target="#modal-archived"
  //         onClick={() => {
  //           setLook(id);
  //           showFormImage();
  //         }}
  //       >
  //         Rendre Archived <FontAwesomeIcon icon={faTrash} className="ml-2" />
  //       </button>
  //     );
  //   }
  // }

  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div className="row mb-2">
            <div className="col-sm-12">
              <div class="card  mt-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <b>Page Total Look </b>
                  </h5>
                  <button
                    className="btn btn-dark float-right"
                    onClick={() => (window.location.href = "/addTotalLook")}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <br />

                  <div className="row">
                    <div className="col-6">
                      <input
                        disabled={searchValueProduits ? true : false}
                        className="form-control mt-3"
                        type="search"
                        placeholder="chercher totalLook ..."
                        aria-label="Search"
                        value={searchValueTotaLook || ""}
                        onChange={(event) => {
                          setSearchValueTotaLook(event.target.value || null);
                          getAllTotalLook(
                            event.target.value,
                            searchValueProduits
                          );
                        }}
                      />
                      <i
                        className="icon-class"
                        onClick={(e) => {
                          setSearchValueTotaLook(null);
                          getAllTotalLook(null, searchValueProduits);
                        }}
                      ></i>
                    </div>
                    <div className="col-6">
                      <input
                        disabled={searchValueTotaLook ? true : false}
                        className="form-control mt-3"
                        type="search"
                        placeholder="chercher totalLook selon produit ..."
                        aria-label="Search"
                        value={searchValueProduits || ""}
                        onChange={(event) => {
                          setSearchValueProduits(event.target.value || null);
                          getAllTotalLook(
                            searchValueTotaLook,
                            event.target.value
                          );
                        }}
                      />
                      <i
                        className="icon-class"
                        onClick={(e) => {
                          setSearchValueProduits(null);
                          getAllTotalLook(searchValueTotaLook, null);
                        }}
                      ></i>
                    </div>
                  </div>
                  <br />

                  <button
                    className="btn btn-dark float-right"
                    onClick={(e) => {
                      setSearchValueTotaLook("");
                      setSearchValueProduits("");
                      getAllTotalLook(null, null);
                      setSearchValueTotaLook(null);
                      setSearchValueProduits(null);
                    }}
                  >
                    Clear Filtre
                    <FontAwesomeIcon icon={faRemoveFormat} className="ml-2" />
                  </button>
                </div>
              </div>
              <section class="content">
                <div class="card card-dark">
                  <div class="card-header">
                    <h3 class="card-title">Les Looks disponibles</h3>

                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <table class="table  projects">
                      <thead>
                        <tr>
                          <th>Image </th>
                          <th>Titre </th>
                          <th>Sous Titre</th>
                          {/* <th>Les Produits</th> */}
                          <th>Modifier</th>
                          <th>Visibilité</th>
                          <th>Archiver</th>
                        </tr>
                      </thead>
                      <tbody>
                        {TotalLook.length > 0 ? (
                          <>
                            {TotalLook &&
                              TotalLook.map((item, index) => (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor: item?.visible
                                      ? "#E6E6E9"
                                      : "none",
                                  }}
                                >
                                  <td>
                                    <div>
                                      {/* {loading && <Spinner />}{" "} */}
                                      <Image
                                        src={item.image || ""}
                                        width={80}
                                        height={100}
                                        loading="lazy"
                                        // onLoad={() => {
                                        //   console.log("Image loaded");
                                        //   setLoading(false);
                                        // }}
                                        // style={
                                        //   loading ? { display: "none" } : {}
                                        // }
                                      />
                                    </div>
                                  </td>
                                  <td>{item.titre || ""}</td>
                                  <td>{item.sousTitre || ""}</td>
                                  {/* <td>
                                    <button
                                      style={{ width: "150px" }}
                                      class="btn btn-dark btn-sm float-center"
                                      data-toggle="modal"
                                      data-target="#modal-produits-totalLook"
                                      onClick={() => {
                                        setShowFormImage(true);
                                        setLook(item);
                                      }}
                                    >
                                      Voir Produits
                                      <FontAwesomeIcon
                                        icon={faCartShopping}
                                        className="ml-2"
                                      />
                                    </button>
                                    {ShowFormImage && (
                                      <div
                                        className="modal fade"
                                        id="modal-produits-totalLook"
                                      >
                                        <ProduitsTotalLook look={look} />
                                      </div>
                                    )}
                                  </td> */}
                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      class="btn btn-warning btn-sm float-center"
                                      data-toggle="modal"
                                      data-target="#modal-edit"
                                      onClick={() => {
                                        setShowFormImage(true);
                                        setLook(item);
                                      }}
                                    >
                                      Modifier
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="ml-2"
                                      />
                                    </button>
                                    {ShowFormImage && (
                                      <div
                                        className="modal fade"
                                        id="modal-edit"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="modal-edit-label"
                                        aria-hidden="true"
                                      >
                                        <UpdateTotalLook look={look} />
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className={
                                        item?.visibled
                                          ? "btn btn-success btn-sm"
                                          : "btn btn-secondary btn-sm"
                                      }
                                      onClick={() => visibleLook(item?.id)}
                                    >
                                      {item?.visibled ? (
                                        <>
                                          Rendre InVisible{" "}
                                          <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          Rendre Visible{" "}
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="ml-2"
                                          />
                                        </>
                                      )}
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className={
                                        item?.archived
                                          ? "btn btn-danger btn-sm"
                                          : "btn btn-secondary btn-sm"
                                      }
                                      onClick={() => archiveLook(item?.id)}
                                    >
                                      {item?.archived ? (
                                        <>
                                          Rendre not archived{" "}
                                          <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          Rendre archived{" "}
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="ml-2"
                                          />
                                        </>
                                      )}
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr className="text-center">
                              <td colSpan="4">Pas de Looks pour le moment</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    {TotalLook.length > 0 && (
                      <>
                        <div className="d-flex" style={{ padding: "15px" }}>
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(newPage) => setCurrentPage(newPage)}
                          />
                          <div className="ml-3">
                            <select
                              className="form-control pagination-select float-right"
                              id="itemsPerPageSelect"
                              value={itemsPerPage}
                              onChange={(e) =>
                                setItemsPerPage(parseInt(e.target.value, 10))
                              }
                            >
                              {[4, 7, 10, 15, 20].map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
