import React, { useState, useEffect } from "react";
import ContactService from "src/services/ContactService";
import SideBarEmail from "./SideBarEmail";
import UtilsFormule from "src/utils/UtilsFormule";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContenuEmail() {
  const [contactDetails, setContactDetails] = useState([]);
  useEffect(() => {
    getContactDetailsById(localStorage.getItem("idContact"));
  }, []);

  const getContactDetailsById = (idContact) => {
    ContactService.getContactDetails(idContact)
      .then((response) => {
        if (response.data.success) {
          setContactDetails(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <section class="content">
          <div class="row">
            <div class="col-md-3">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Folders</h3>

                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <SideBarEmail />
              </div>
            </div>

            <div class="col-md-9">
              <div class="card card-primary card-outline">
                <div class="card-header">
                  <h3 class="card-title">Contenu Email</h3>

                  <div class="card-tools">
                    <a href="#" class="btn btn-tool" title="Previous">
                      <i class="fas fa-chevron-left"></i>
                    </a>
                    <a href="#" class="btn btn-tool" title="Next">
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </div>
                </div>

                <div class="card-body p-0">
                  <div class="mailbox-read-info">
                    <h5>{contactDetails?.sujet || ""}</h5>
                    <h6>
                      From: {contactDetails?.email || ""}
                      <span class="mailbox-read-time float-right">
                        {UtilsFormule.formatDateHours(
                          contactDetails?.dateSend
                        ) || ""}
                      </span>
                    </h6>
                  </div>

                  {/* <div class="mailbox-controls with-border text-center">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-default btn-sm"
                        data-container="body"
                        title="Delete"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-default btn-sm"
                        data-container="body"
                        title="Reply"
                      >
                        <i class="fas fa-reply"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-default btn-sm"
                        data-container="body"
                        title="Forward"
                      >
                        <i class="fas fa-share"></i>
                      </button>
                    </div>

                    <button
                      type="button"
                      class="btn btn-default btn-sm"
                      title="Print"
                    >
                      <i class="fas fa-print"></i>
                    </button>
                  </div> */}

                  <div class="mailbox-read-message">
                    {/* <p>Bo,</p> */}

                    <p>{contactDetails?.contenu || ""}</p>

                    <p>
                      {contactDetails?.fullName || ""}
                      <br />
                      <FontAwesomeIcon icon={faPhone} className="mr-2" />
                      {contactDetails?.numero || ""}
                    </p>
                  </div>
                </div>

                {/* <div class="card-footer">
                  <div class="float-right">
                    <button type="button" class="btn btn-default">
                      <i class="fas fa-reply"></i> Reply
                    </button>
                    <button type="button" class="btn btn-default">
                      <i class="fas fa-share"></i> Forward
                    </button>
                  </div>
                  <button type="button" class="btn btn-default">
                    <i class="far fa-trash-alt"></i> Delete
                  </button>
                  <button type="button" class="btn btn-default">
                    <i class="fas fa-print"></i> Print
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ContenuEmail;
