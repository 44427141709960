import { useEffect, useState } from "react";
import DiscountService from "../services/DiscountService";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import getCurrentUserId from "src/common/currentUserId";

const UpdateDeclencheur = () => {
  const navigate = useNavigate();
  const [declencheur, setDeclencheur] = useState("");
  const [declencheurUpdate, setDeclencheurUpdate] = useState("");

  useEffect(() => {
    getDeclencheurById(localStorage.getItem("idDeclencheurForUpdate"));
  }, []);

  function formattedCurrentDate(DateInput) {
    return new Date(DateInput).toISOString().split("T")[0];
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDeclencheurUpdate({ ...declencheurUpdate, [name]: value });
  };

  const getDeclencheurById = async (idDeclencheur) => {
    try {
      const response = await DiscountService.getDeclencheurById(idDeclencheur);
      if (response.data.success) {
        setDeclencheur(response.data.success);
      }
      console.log("data", response.data.success);
    } catch (error) {
      console.log("response", error);
    }
  };

  function TestValidationDateBettween(DateDebut, DateFin) {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    if (
      // DateDebut < formattedCurrentDate ||
      // DateFin < formattedCurrentDate ||
      DateFin < DateDebut
    ) {
      return true;
    }
  }
  const onValidHandler = () => {
    let UpdateDeclencheurModelForTest = {
      libelle: declencheurUpdate.nameCC,
      description: declencheurUpdate.descriptionCC,
      startDate: declencheurUpdate?.startDate,
      endDate: declencheurUpdate?.endDate,
    };

    let UpdateDeclencheurModel = {
      id: localStorage.getItem("idDeclencheurForUpdate"),
      libelle: declencheurUpdate.nameCC || declencheur.nameCC,
      description: declencheurUpdate.descriptionCC || declencheur.descriptionCC,
      idUser: getCurrentUserId(),
      startDate: declencheurUpdate?.startDate
        ? (declencheurUpdate?.startDate || "").concat("T05:05:59")
        : declencheur?.startDate,
      endDate: declencheurUpdate?.endDate
        ? (declencheurUpdate?.endDate || "").concat("T05:05:59")
        : declencheur?.endDate,
    };

    if (
      !UpdateDeclencheurModelForTest.libelle &&
      !UpdateDeclencheurModelForTest.description &&
      !UpdateDeclencheurModelForTest.startDate &&
      !UpdateDeclencheurModelForTest.endDate
    ) {
      toast.error("pas de modification");
      return;
    }

    if (
      TestValidationDateBettween(
        UpdateDeclencheurModel.startDate,
        UpdateDeclencheurModel.endDate
      )
    ) {
      toast.error("il faut selectionner date correcte");
      return;
    }
    if (
      !UpdateDeclencheurModel.libelle ||
      !UpdateDeclencheurModel.description
    ) {
      toast.error("completez les info de declencheur");
      return;
    }
    DiscountService.updateDeclencheur(UpdateDeclencheurModel).then((result) => {
      if (result.data.success) {
        toast.success(result.data.success);
        // setTimeout(() => {
        //   navigate(`/allDeclencheurs`);
        // }, 1000);
      } else if (result.data.error) {
        toast.error("error : " + result.data.error);
      } else {
        toast.error("error");
      }
    });
  };
  const AnnulerUpdate = () => {
    navigate(`/allDeclencheurs`);
  };
  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <section className="content-header">
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active">Modifier Priorité</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content ">
        <div className="container-fluid">
          <div className="timeline">
            <div className="time-label">
              <span style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>Modifier déclencheur</span>
              </span>
            </div>
            <div>
              <i className="fas fa-1 " style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>1</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 12:05
                </span>
                <h3 className="timeline-header">
                  <b>Informations Générales</b>
                </h3>
                <div className="timeline-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="inputName">Libellé</label>
                        <input
                          value={declencheurUpdate.nameCC || declencheur.nameCC}
                          onChange={handleChange}
                          type="text"
                          id="inputName"
                          className="form-control"
                          name="nameCC"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <textarea
                          value={
                            declencheurUpdate.descriptionCC ||
                            declencheur.descriptionCC
                          }
                          placeholder="Saisir..."
                          onChange={handleChange}
                          id="inputDescription"
                          className="form-control"
                          rows={4}
                          defaultValue={""}
                          name="descriptionCC"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div>
              <i className="fas fa-3 " style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>3</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 5 mins ago
                </span>
                <h3 className="timeline-header no-bordert">
                  <b>Choisir le type de la promotion</b>{" "}
                </h3>
                <div className="timeline-body">
                  <div className="row">{ListPromotionsExiste()}</div>
                </div>
              </div>
            </div> */}
            <div>
              <i className="fas fa-6" style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>4</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 27 mins ago
                </span>
                <h3 className="timeline-header">
                  <b> Choisir la période de la promotion</b>
                </h3>
                <div className="timeline-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="inputName">Date début</label>
                        <TextField
                          style={{ width: "100%" }}
                          className="col-sm-12"
                          variant="outlined"
                          value={
                            declencheurUpdate.startDate
                              ? declencheurUpdate.startDate
                              : declencheur?.startDate
                              ? formattedCurrentDate(declencheur.startDate)
                              : ""
                          }
                          onChange={handleChange}
                          id="datetime-local"
                          // type="datetime-local"
                          type="date"
                          defaultValue="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          name="startDate"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="inputName">Date fin</label>

                        <TextField
                          style={{ width: "100%" }}
                          className="col-sm-12"
                          variant="outlined"
                          value={
                            declencheurUpdate.endDate
                              ? declencheurUpdate.endDate
                              : declencheur?.endDate
                              ? formattedCurrentDate(declencheur.endDate)
                              : ""
                          }
                          name="endDate"
                          onChange={handleChange}
                          id="datetime-local"
                          // type="datetime-local"
                          type="date"
                          defaultValue="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                class="btn btn-dark"
                style={{ marginTop: "1%" }}
                onClick={onValidHandler}
              >
                Confirmer
              </button>

              <button
                class="btn btn-secondary float-right"
                style={{ marginTop: "1%" }}
                onClick={AnnulerUpdate}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default UpdateDeclencheur;
