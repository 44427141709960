import React from "react";
import { toast } from "react-toastify";
import VenteFlashService from "../services/VenteFlashService";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function DeletePromotionVenteFlash({
  isModalOpenDelete,
  setIsModalOpenDelete,
  idVenteFlash,
  promotionDelete,
}) {
  const OnDeletePromotion = (event) => {
    event.preventDefault();
    VenteFlashService.deletePromotionFromVenteFlash(
      idVenteFlash,
      promotionDelete?.id
    )
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <Modal
        show={isModalOpenDelete}
        onHide={() => setIsModalOpenDelete(false)}
        // size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            Suppression promotion :
            <br />
            <p>
              <b>{promotionDelete?.name || ""}</b>
            </p>
          </Modal.Title>
          <button
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsModalOpenDelete(false)}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          Veuillez vous Confirmer la suppression de cette promotion : <br />
          <b>{promotionDelete?.name || ""}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsModalOpenDelete(false)}
          >
            Annuler
          </Button>
          <Button variant="warning" onClick={OnDeletePromotion}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeletePromotionVenteFlash;
