import React, { useState, useEffect } from "react";
import VenteFlashService from "../services/VenteFlashService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faNoteSticky,
  faPlus,
  faRecycle,
  faShoppingCart,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UpdateVentesFlash from "./UpdateVentesFlash";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "src/common/Pagination";
import UtilsFormule from "src/utils/UtilsFormule";

function AllVentesFlash() {
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const [venteFlashList, setVenteFlashList] = useState([]);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [index, setIndex] = useState("");

  useEffect(() => {
    getAllVentesFlash();
  }, [currentPage, itemsPerPage]);

  const getAllVentesFlash = () => {
    VenteFlashService.getAllVenteFlash(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setVenteFlashList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const archiverVenteFlashById = (idVenteFlash) => {
    VenteFlashService.archiverVenteFlashById(idVenteFlash)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledVenteFlashById = (idVenteFlash) => {
    VenteFlashService.visibledVenteFlashById(idVenteFlash)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //**************for edit vente flash modal
  const openModalEdit = (index) => {
    setIsModalOpenEdit(true);
    setIndex(index);
  };

  const onNavigate = (e) => {
    e.preventDefault();
    window.location.href = "/addVentesFlash";
  };

  const openSectionPromotionVenteFlash = (id, name) => {
    localStorage.setItem("idVenteFlash", id);
    localStorage.setItem("NameVenteFlash", name);
    const url = "/promotionVenteFlash";
    window.open(url, "_blank");
  };
  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les ventes Flash </b> :
                    </h5>
                    <button
                      className="btn btn-dark float-right"
                      onClick={(e) => onNavigate(e)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">Les ventes Flash disponibles</h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th>Libellé</th>
                            <th>Date debut</th>
                            <th>Date fin</th>
                            {/* <th>Button</th> */}
                            <th>Modifier</th>
                            <th>Voir Promotions</th>
                            <th>Visibilité</th>
                            <th>Archiver</th>
                          </tr>
                        </thead>
                        <tbody>
                          {venteFlashList && venteFlashList.length > 0 ? (
                            venteFlashList.map((venteFlash, index) => (
                              <tr key={index}>
                                <td>{venteFlash?.name || ""}</td>
                                <td>
                                  {UtilsFormule.formatDateHours(
                                    venteFlash.dateDebut
                                  ) || ""}
                                </td>
                                <td>
                                  {UtilsFormule.formatDateHours(
                                    venteFlash.dateFin
                                  ) || ""}
                                </td>
                                {/* <td>
                                  <div>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-sm btn-dark"
                                      onClick={() =>
                                        (window.location.href =
                                          venteFlash.urlBouton)
                                      }
                                    >
                                      Url Button
                                      <FontAwesomeIcon
                                        icon={faEye}
                                        className="ml-2"
                                      />
                                    </button>
                                  </div>
                                </td> */}
                                <td>
                                  <div>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-sm btn-warning"
                                      data-toggle="modal"
                                      data-target="#exampleModalCenter"
                                      onClick={() => openModalEdit(index)}
                                    >
                                      Modifier
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="ml-2"
                                      />
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-sm btn-dark"
                                      // disabled
                                      onClick={() =>
                                        openSectionPromotionVenteFlash(
                                          venteFlash?.id,
                                          venteFlash?.name
                                        )
                                      }
                                    >
                                      Voir Promotions
                                      <FontAwesomeIcon
                                        icon={faShoppingCart}
                                        className="ml-2"
                                      />
                                    </button>
                                  </div>
                                </td>

                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      venteFlash?.visibled
                                        ? "btn btn-success btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    onClick={() =>
                                      visibledVenteFlashById(
                                        venteFlashList[index].id
                                      )
                                    }
                                  >
                                    {venteFlash?.visibled ? (
                                      <>
                                        Rendre InVisible{" "}
                                        <FontAwesomeIcon
                                          icon={faEyeSlash}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Visible{" "}
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      venteFlash?.archived
                                        ? "btn btn-danger btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    onClick={() =>
                                      archiverVenteFlashById(
                                        venteFlashList[index].id
                                      )
                                    }
                                  >
                                    {venteFlash?.archived ? (
                                      <>
                                        Restaurer{" "}
                                        <FontAwesomeIcon
                                          icon={faRecycle}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Archived{" "}
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                <b>Pas de venteFlash Pages</b>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {venteFlashList && venteFlashList.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {isModalOpenEdit && (
          <UpdateVentesFlash
            isModalOpenEdit={isModalOpenEdit}
            setIsModalOpenEdit={setIsModalOpenEdit}
            venteFlashGet={venteFlashList[index]}
          />
        )}
      </div>
    </>
  );
}

export default AllVentesFlash;
