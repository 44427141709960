import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class SelectOptionsService {
  getAllCategoriesAndSousFamilles() {
    return api.get(
      urlsCommon.BASE_SELECT_OPTION_URL +
        "/produits/getAllCategoriesAndSousFamilles"
    );
  }
  getAllBrands() {
    return api.get(
      urlsCommon.BASE_SELECT_OPTION_URL + "/produits/getAllBrands"
    );
  }
}
export default new SelectOptionsService();
