import { Component } from "react";
import CarrousselTrendingHomeService from "../../services/CarrousselTrendingHomeService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class ProductTrending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: this.props.reference,
      //   products: [],
      product: "",
      pictureUrl: "",
    };
    // this.changeRef = this.changeRef.bind(this);
  }
  addToTrending = (e) => {
    // let produitsTrendingModel = {
    //   idTrendingCarroussel: sessionStorage.getItem("idCarroussel"),
    //   productRefs: this.state.ref,
    //   // files: this.state.files[0],
    // };
    CarrousselTrendingHomeService.addProductsTrendingHome(
      sessionStorage.getItem("idCarroussel"),
      this.state.ref
    )
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          toast.success("Ajout du produit avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", error);
      });
  };

  componentDidMount() {
    this.forceUpdate();
    console.log("reference", this.state.ref);
    CarrousselTrendingHomeService.getProductByRef(this.state.ref)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          console.log("response object", response.data["object"]["Product"][0]);
          //   console.log("product", this.state.product);
          this.setState({ product: response.data["object"]["Product"][0] });
          console.log("product", this.state.product);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", error);
      });
    CarrousselTrendingHomeService.getImageProductByRef(this.state.ref)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ pictureUrl: response.data["object"] });
          console.log("pictureUrl", this.state.pictureUrl);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  render() {
    if (this.props.ref === "") {
      return ProductTrending;
    } else {
      return (
        <div className="modal fade" id="modal-Trending">
          <div className="modal-dialog">
            <ToastContainer />
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ajouter le produit au Trending</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    window.location.reload(false);
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                    <img
                      className="files-list-item-preview-image"
                      src={this.state.pictureUrl}
                      // style={{
                      //   width: "70%",
                      // }}
                    />
                  </div>
                  <div className="col-md-12">
                    <label>
                      {this.state.product["ref"]}-{this.state.product["name"]}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => {
                    window.location.reload(false);
                  }}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={this.addToTrending}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
