import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TestInputTotalLook } from "src/common/staticMethodes";
import TotalLookService from "src/services/TotalLookService";

function AddTotalLook() {
  const navigate = useNavigate();
  const [newLook, setNewLook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  //update produits
  const [produit, setProduit] = useState("");
  const [refProduit, setRefProduit] = useState("");
  const [produitsLook, setProduitsLook] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewLook({ ...newLook, [name]: value });
  };
  const onValueChange = (event) => {
    const { name, value, checked, type } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setNewLook((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const getProduitDetailsByRef = (reference) => {
    TotalLookService.getProduitDetailsByRef(reference)
      .then((response) => {
        if (response.data.success) {
          setProduit(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //image
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };
  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };
  const addToList = () => {
    if (refProduit.trim() !== "" && produitsLook.length < 4) {
      setProduitsLook((prev) => [...prev, produit]);
      setRefProduit("");
    } else {
      if (refProduit.trim() == "") {
        toast.error("pas de reference de produit");
      }
      if (produitsLook.length > 3) {
        toast.error("il faut ajouter 4 produits max");
      }
      if (produitsLook.length < 2) {
        toast.error("il faut ajouter 2 produits min");
      }
    }
  };

  const deleteProduct = (index) => {
    const updatedList = [...produitsLook];
    updatedList.splice(index, 1);
    setProduitsLook(updatedList);
  };

  const updateTotalLook = () => {
    const model = {
      name: newLook?.titre,
      titre: newLook?.titre,
      subTitle: newLook?.subTitle,
      description: newLook?.description,
      titreBouton: newLook?.titreBouton,
      visible: newLook?.visible || false,
    };
    TestInputTotalLook(model, imageFile, produitsLook);
    const produitsReferences = produitsLook.map(
      (produit) => produit.codeArticle
    );
    // console.log("model :", model);
    // console.log("produitsReferences :", produitsReferences);

    TotalLookService.createTotalLook(model, produitsReferences, imageFile)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Ajouter Look</h3>
                </div>
                <div className="card-body">
                  <img
                    className="animation__shake"
                    src={imagePreview || "dist/img/no_Images.png"}
                    alt="image logo"
                    width={80}
                    height={120}
                    style={{ float: "right" }}
                  />
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Titre</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="titre"
                            // value={
                            //   newLook && newLook.titre !== null
                            //     ? newLook.titre
                            //     : look && look.titre
                            //     ? look.titre
                            //     : ""
                            // }
                            value={newLook?.titre}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Sous Titre</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            name="subTitle"
                            type="text"
                            className="form-control"
                            value={newLook?.subTitle}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <div className="input-group">
                      <textarea
                        type="text"
                        className="form-control"
                        name="description"
                        placeholder="..."
                        value={newLook.description}
                        onChange={handleChange}
                        rows={3}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Titre button</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="titreBouton"
                            value={newLook?.titreBouton}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          Cliquer pour Ajouter une image
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={handleFileChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                              id="fileInputLabel"
                            >
                              Choose file
                            </label>
                          </div>

                          {imageFile && (
                            <div className="input-group-append">
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={handleDeleteClick}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col">
                      <div className="form-group" style={{ float: "right" }}>
                        {imageFile && (
                          <>
                            <label htmlFor="exampleInputFile">
                              Image de Look
                            </label>

                            <div className="input-group-append">
                              <img
                                src={imagePreview}
                                alt="Selected"
                                style={{ width: "20%" }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Ajouter des produits par code article (entre 2 et 4
                          produits)
                        </label>
                        <div className="input-group">
                          <input
                            value={refProduit}
                            onChange={(e) => {
                              setRefProduit(e.target.value);
                              getProduitDetailsByRef(e.target.value);
                            }}
                            type="text"
                            className="form-control"
                          />
                          <div className="input-group-append">
                            <button
                              onClick={addToList}
                              type="button"
                              className="btn btn-dark"
                            >
                              <i className="fas fa-solid fa-arrow-down"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          Check pour Rendre Visible
                        </label>
                        <div className="input-group">
                          <div className="form-group clearfix icheck-asbestos d-inline">
                            <input
                              id="visible"
                              type="checkbox"
                              name="visible"
                              value={newLook?.visible}
                              onChange={onValueChange}
                            />
                            <label htmlFor="visible">
                              Visible ou Not Visible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    {produitsLook && produitsLook.length > 0 && (
                      <table class="table">
                        <thead class="thead-dark">
                          <tr>
                            <th>#</th>
                            <th>Reference</th>
                            <th>Libelle </th>
                            {/* <th>Famille</th> */}
                            <th>Sous Famille</th>
                            <th>Categorie</th>
                            {/* <th>Prix</th>
                      <th>Promo</th>
                      <th>Prix apres Promotion</th> */}
                            <th>Supprimer</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {produitsLook &&
                              produitsLook.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div>
                                      <Image
                                        src={item?.image || ""}
                                        width={40}
                                        height={60}
                                        loading="lazy"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <b>{item?.codeArticle || ""}</b>
                                  </td>
                                  <td>{item?.nameToFo || ""}</td>
                                  <td>{item?.sousFamille || ""}</td>
                                  <td>{item?.category || ""}</td>
                                  {/* <td>{item.price || ""}</td> */}

                                  <td>
                                    <button
                                      onClick={() => deleteProduct(index)}
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                    >
                                      Supprimer
                                      <i class="fas fa-trash ml-2"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-default"
                    onClick={() => navigate("/allTotalLook")}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={updateTotalLook}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddTotalLook;
