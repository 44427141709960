import { Component } from "react";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagsService from "../../services/TagsService";

export default class AddTagsToProduct extends Component {
  state = {
    selectedOption: "",
    options: [],
    value: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      colors: [],
      options: [],
      background: "#FFFFFF",
      price: "",
      idColor: "",
      stock: 1,
      showFormColor: false,
      files: [],
    };
    // this.changePrice = this.changePrice.bind(this);
    // this.changeStock = this.changeStock.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    TagsService.getAllTagsInSubFamily(this.props.idProduct)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ brands: response.data["object"] });
          console.log("brands", this.state.brands);
          // this.setState({ alert_message: "success" });
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }


  
  render() {
    return (
      <form>
        <ToastContainer />
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <label>Prix : </label>
              {/* <CurrencyInput
                suffix=" DNT"
                class="form-control"
                precision="3"
                value={this.state.price}
                onChangeEvent={this.changePrice}
              /> */}
            </div>
            <div className="col-md-6">
              <label>Stock</label>
              {/* <NumericInput
                className="form-control"
                value={this.state.stock}
                onChange={this.changeStock}
              /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div class="form-group">
                <label>Choisir le couleur</label>
                {/* <Select
                  value={this.state.selectedOption}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={this.state.options}
                  onChange={this.handleChange}
                  placeholder="Saisir..."
                /> */}
              </div>
            </div>

            <div className="col-md-1">
              <label>
                <br />{" "}
              </label>
              {/* <Circle colors={[this.state.background]} /> */}
            </div>
            <div className="col-md-3">
              <label>
                <br />{" "}
              </label>
              <br />
              <button
                type="button"
                class="btn btn-default"
                data-toggle="modal"
                data-target="#modal-color"
              >
                Nouvelle couleur
              </button>
            </div>
          </div>

          <div className="modal fade" id="modal-color">
            {/* <ColorPopup /> */}
          </div>

          <br />

          <br />
          <button
            type="submit"
            className="btn btn-success float-right"
            // onClick={this.addVairent}
          >
            Ajouter
          </button>
        </div>
      </form>
    );
  }
}
