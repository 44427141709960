import React, { useState } from "react";
import { toast } from "react-toastify";
import TopBarService from "src/services/TopBarService";

function UpdateTopBar(props) {
  const {
    isModalConfirmationOpen,
    setIsModalConfirmationOpen,
    idTopBar,
    topBarTexte,
  } = props;

  const [TopBarUpdate, setTopBarUpdate] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setTopBarUpdate({ ...TopBarUpdate, [name]: value });
  };

  const updateTopBarTexte = (event) => {
    event.preventDefault();
    TopBarService.updateTopBar(idTopBar, TopBarUpdate.texte)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTopBarUpdate({});
          setIsModalConfirmationOpen(false);
          window.location.reload(true);
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModal">
                Update topbar
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>Texte :</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control float-right"
                    // placeholder="texte"
                    name="texte"
                    onChange={handleChange}
                    value={TopBarUpdate?.texte || topBarTexte}
                    required
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsModalConfirmationOpen(false);
                  window.location.href = "allTopBar";
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-warning"
                onClick={updateTopBarTexte}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateTopBar;
