import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import TopBarService from "src/services/TopBarService";

function AddTopBar() {
  const [TopBar, setTopBar] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTopBar({ ...TopBar, [name]: value });
  };

  const AddTopBar = (event) => {
    event.preventDefault();

    TopBarService.addTopBar(TopBar.texte)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTopBar({});
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Top Bar</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Texte :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="texte"
                        name="texte"
                        onChange={handleChange}
                        value={TopBar.texte || ""}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-default"
                    onClick={() => (window.location.href = "/allTopBar")}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={AddTopBar}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddTopBar;
