import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class TopBarService {
  //********* Api add */
  addTopBar(texte_top_bar) {
    return api.post(urlsCommon.BASE_TOP_BAR_URL + "/add", null, {
      params: {
        texte_top_bar: texte_top_bar,
      },
    });
  }

  //********* Api update */
  updateTopBar(id, texte_top_bar) {
    return api.put(urlsCommon.BASE_TOP_BAR_URL + "/update/" + id, null, {
      params: {
        texte_top_bar: texte_top_bar,
      },
    });
  }

  //********* Api Get */
  getTopBarDetails(id) {
    return api.get(urlsCommon.BASE_TOP_BAR_URL + "/get/" + id);
  }

  getAllTopBar(page, size) {
    return api.get(urlsCommon.BASE_TOP_BAR_URL + "/get/all", {
      params: {
        page: page,
        size: size,
      },
    });
  }
  getNombreTopBar() {
    return api.get(urlsCommon.BASE_TOP_BAR_URL + "/getNombreTopBar");
  }

  //********* Api archiver and visivility */

  archiverTopBar(id) {
    return api.put(urlsCommon.BASE_TOP_BAR_URL + "/archiver/" + id);
  }
  visibledTopBar(id) {
    return api.put(urlsCommon.BASE_TOP_BAR_URL + "/visibled/" + id);
  }
}
export default new TopBarService();
