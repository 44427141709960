import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ThematiqueService from "src/services/ThematiqueService";
import NouveauteService from "src/services/NouveauteService";

function ModalThematiqueUpdate(props) {
  const { isModalUpdateOpen, setIsModalUpdateOpen, thematique } = props;
  const [thematiqueToUpdate, setThematiqueToUpdate] = useState([]);
  //image
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setThematiqueToUpdate({ ...thematiqueToUpdate, [name]: value });
  };

  //******for image */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const updateThematique = () => {
    const thematiqueModel = {
      id: thematique?.id,
      titre: thematiqueToUpdate.titre || thematique?.titre,
      description: thematiqueToUpdate.description || thematique?.description,
    };

    ThematiqueService.updateThematiquePage(thematiqueModel, imageFile)
      .then((response) => {
        if (response.data.success) {
          setIsModalUpdateOpen(false);
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Update Thematique <b>{thematique?.titre || ""}</b>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>Titre thematique page :</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control float-right"
                    placeholder="titre"
                    name="titre"
                    onChange={handleChange}
                    value={thematiqueToUpdate?.titre || thematique?.titre}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Description thematique page :</label>
                <div className="input-group">
                  <textarea
                    type="text"
                    className="form-control float-right"
                    placeholder="description"
                    name="description"
                    onChange={handleChange}
                    value={
                      thematiqueToUpdate?.description || thematique?.description
                    }
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">
                      Image Banniere (Ratio : 0.20)
                    </label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          onChange={handleFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                          id="fileInputLabel"
                        >
                          Choose file
                        </label>
                      </div>
                      {imageFile && (
                        <>
                          <div className="input-group-append">
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={handleDeleteClick}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-100"></div>

                <div className="col-sm-6">
                  {imageFile ? (
                    <>
                      <div className="input-group-append">
                        <img
                          src={imagePreview}
                          alt="Selected"
                          style={{ width: "80%" }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {thematique?.urlImage ? (
                        <div className="input-group-append">
                          <img
                            src={thematique?.urlImage}
                            alt="Selected"
                            style={{ width: "80%" }}
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsModalUpdateOpen(false);
                  window.location.href = "allThematiquesPages";
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-dark"
                onClick={updateThematique}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalThematiqueUpdate;
