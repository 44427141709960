import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ThematiqueService from "src/services/ThematiqueService";

function ArchivedProductThematique({
  productToDelete,
  idThematique,
  setIsModalSuppressionOpen,
  thematiqueName,
}) {
  const SupprimerProduit = (idThematique, idProductToDelete) => {
    ThematiqueService.removeProductFromThematiquePage(
      idThematique,
      idProductToDelete
    )
      .then((response) => {
        if (response.data.success) {
          setIsModalSuppressionOpen(false);
          toast.success(response.data.success);
          window.location.reload();
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModalthematiqueProducts"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalthematiqueProducts"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Supprimer Produit : <b>{productToDelete.name_product || ""} </b>{" "}
                -{" "}
                <b style={{ color: "red" }}>
                  {productToDelete.ref_product || ""}
                </b>
              </h5>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card-body">
              Veuillez confimer de Supprimer ce produit{" "}
              <b>{productToDelete.name_product || ""}</b> de cette page
              thematique <b>{thematiqueName || ""}</b>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsModalSuppressionOpen(false);
                  window.location.href = "thematiqueProducts";
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={() =>
                  SupprimerProduit(idThematique, productToDelete?.id)
                }
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArchivedProductThematique;
