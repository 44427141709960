import { Component } from "react";
import { Link } from "react-router-dom";
import MenuImageService from "../../services/MenuImageService";
export default class MenuImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carroussels: [],
    };
  }
  componentDidMount() {
    this.forceUpdate();
    MenuImageService.allMenuImage()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ carroussels: response.data["object"] });
          console.log("carroussels", this.state.carroussels);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Image de menu</h5>
              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addMenuImage" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    <i class="fas fa-plus"></i>
                    Ajouter
                  </Link>
                </button>
              </p>
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Les images des familles de produits de menu</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Famille Produit Menu </th>
                    <th>Image</th>
                    <th>Modifier</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.carroussels.map((carroussel) => (
                    <tr>
                      <td>{carroussel.id}</td>
                      <td>{carroussel.familyProduct.name}</td>

                      <td>
                        {" "}
                        <button
                          class="btn btn-warning btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          onClick={() => {
                            this.setState({ idCarroussel: carroussel.id });
                            this.showFormImage();
                          }}
                        >
                          <i class="fas fa-images"></i> Voir l'image
                        </button>
                        {/* {this.state.showFormImage && (
                          <CategoryImage idCategory={this.state.idCategory} />
                        )} */}
                      </td>
                      <td>
                        {" "}
                        <button
                          class="btn btn-warning btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          onClick={() => {
                            this.setState({ idCarroussel: carroussel.id });
                            this.showFormImage();
                          }}
                        >
                          <i class="fas fa-images"></i> Voir l'image
                        </button>
                        {/* {this.state.showFormImage && (
                          <CategoryImage idCategory={this.state.idCategory} />
                        )} */}
                      </td>

                      <td>
                        {" "}
                        <button
                          class="btn btn-warning btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          onClick={() => {
                            this.setState({ idCarroussel: carroussel.id });
                            this.showFormImage();
                          }}
                        >
                          <i class="fas fa-images"></i> Voir l'image
                        </button>
                        {/* {this.state.showFormImage && (
                          <CategoryImage idCategory={this.state.idCategory} />
                        )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
