import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import 1 from "../../../reduxSaga/sagas/requests/currentUserId";
import { Snackbar, TextField } from "@material-ui/core";
import CurrencyInput from "react-currency-input";

import DiscountService from "../services/DiscountService";
import { Alert } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import ProductService from "../services/ProductService";
import BrandService from "../services/BrandService";
import FamilyProductService from "../services/FamilyProductService";
import SubFamilyProductService from "../services/SubFamilyProductService";
import CategoryService from "../services/CategoryService";
import getCurrentUserId from "src/common/currentUserId";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import withLoader from "src/common/progressBar/withLoader";

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};
const AddDiscountProduct = ({ startLoading, stopLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();

  const [availableBrands, setAvailableBrands] = useState([]);
  const [availableFamilies, setAvailableFamilies] = useState([]);
  const [availableSubFamilies, setAvailableSubFamilies] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionFamily, setSelectedOptionFamily] = useState(null);
  const [selectedOptionSubFamily, setSelectedOptionSubFamily] = useState(null);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState(null);
  const [brandId, setBrandId] = useState();
  const [brandSelectPlaceHolder, setBrandSelectPlaceHolder] =
    useState("Marque");
  const [familySelectPlaceHolder, setFamilySelectPlaceHolder] =
    useState("Famille ");
  const [subFamilySelectPlaceHolder, setSubFamilySelectPlaceHolder] =
    useState("Sous Famille ");
  const [categorySelectPlaceHolder, setCategorySelectPlaceHolder] =
    useState("Catégorie ");
  const [familyId, setFamilyId] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [subFamilyId, setSubFamilyId] = useState(0);
  const [productId, setProductId] = useState(0);

  const [allBrands, setAllBrands] = useState([]);
  const [allFamilly, setAllFamilly] = useState([]);
  const [allSubFamilies, setAllSubFamilies] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [type, setType] = useState(1);
  const [typePromo, setTypePromo] = useState(1);

  const [openInfoAlert, setOpenInfoAlert] = useState(false);
  const [infoAlertText, setInfoAlertText] = useState();
  const [productsFile, setProductsFile] = useState("");
  const [productCodeArticle, setProductCodeArticle] = useState("none");
  const [percent, setPercent] = useState(0);
  const [amount, setAmount] = useState(0);

  const [warehouseSelectPlaceHolder, setWarehouseSelectPlaceHolder] =
    useState(null);

  const [listWarehouseId, setListWarehouseId] = useState([]);
  const [selectedOptionWarehouse, setSelectedOptionWarehouse] = useState(null);
  const [allWarehouse, setAllWarehouse] = useState([{}]);
  const [warehouses, setWarehouses] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState();
  const [startDate, setStartDate] = useState("any");
  const [endDate, setEndDate] = useState("any");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [successAlertText, setSuccessAlertText] = useState();
  const [product, setProduct] = useState(null);
  const [isClickedProduct, setIsClickedProduct] = useState(false);

  useEffect(() => {
    fetchAvailableBrands();
    fetchAvailableFamilies();
  }, []);
  useEffect(() => {
    fetchAvailableSubFamilies(familyId);
  }, [familyId]);
  useEffect(() => {
    fetchAvailableCategories(subFamilyId);
  }, [subFamilyId]);
  const fetchAvailableBrands = async () => {
    try {
      const response = await BrandService.getAllBrand();
      if (response.data["code"] === "201") {
        setAvailableBrands(response.data["object"]);
      }
    } catch (error) {
      console.log("response", error);
    }
  };

  const fetchAvailableFamilies = async () => {
    try {
      const response = await FamilyProductService.getAllFamilyProducts();
      if (response.data["code"] === "201") {
        setAvailableFamilies(response.data["object"]);
      }
    } catch (error) {
      console.log("response", error);
    }
  };

  const fetchAvailableSubFamilies = async (idFamily) => {
    try {
      const response =
        await SubFamilyProductService.getAllSubFamiliesByFamilyId(idFamily);
      if (response.data["code"] === "201") {
        setAvailableSubFamilies(response.data["object"]);
      }
    } catch (error) {
      console.log("response", error);
    }
  };
  const fetchAvailableCategories = async (subFamilyId) => {
    try {
      const response =
        await CategoryService.getAllCategoriesByIdSubFamilyProduct(subFamilyId);
      if (response.data["code"] === "201") {
        setAvailableCategories(response.data["object"]);
      }
    } catch (error) {
      console.log("response", error);
    }
  };
  useEffect(() => {
    availableBrands &&
      availableBrands.map((element, i) => {
        if (i === 0) {
          setBrandId(element.id);
        }
        setAllBrands((oldArray) => [
          ...oldArray,
          { value: element.id, label: element.name },
        ]);
      });
  }, [availableBrands]);

  useEffect(() => {
    setAllFamilly([]);
    availableFamilies &&
      availableFamilies.map((element, i) => {
        if (i === 0) {
          setFamilyId(element.id);
          // setTypeFamily(element.type);
        }
        setAllFamilly((oldArray) => [
          ...oldArray,
          {
            value: element.id,
            label: element.name,
            customElements: element.type,
          },
        ]);
      });
  }, [availableFamilies]);

  useEffect(() => {
    if (availableSubFamilies.length === 0) {
      setSubFamilyId(0);
      setCategoryId(0);
    }
    setAllSubFamilies([]);

    availableSubFamilies &&
      availableSubFamilies.map((element, i) => {
        if (i === 0) {
          setSubFamilyId(element.id);
        }
        setAllSubFamilies((oldArray) => [
          ...oldArray,
          {
            value: element.id,
            label: element.name,
            // customElements: element.type,
          },
        ]);
      });
  }, [availableSubFamilies]);
  // useEffect(() => {
  //   subFamilyId && dispatch(getAllCategoriesBySubFamilyId(subFamilyId));
  // }, [subFamilyId]);

  useEffect(() => {
    warehouses &&
      warehouses.map((element) => {
        let exist = false;
        if (allWarehouse.length !== 0) {
          for (let index = 0; index < allWarehouse.length; index++) {
            if (allWarehouse[index].value === element?.id) {
              exist = true;
              break;
            }
          }
        }
        if (element?.id !== undefined && exist === false) {
          setAllWarehouse((oldArray) => [
            ...oldArray,
            { value: element.id, label: element.name },
          ]);
        }
        //here
      });
  }, [warehouses]);

  useEffect(() => {
    if (availableCategories === undefined || availableCategories.length === 0) {
      setCategoryId(0);
    }
    setAllCategories([]);
    availableCategories &&
      availableCategories.map((element, i) => {
        if (i === 0) {
          setCategoryId(element.id);
          // setTypeCategory(element.type);
        }
        setAllCategories((oldArray) => [
          ...oldArray,
          {
            value: element.id,
            label: element.name,
            customElements: element.type,
          },
        ]);
      });
  }, [availableCategories]);

  const onChangeSelectedBrand = (selectedOptionn) => {
    setSelectedOption();
    setBrandId(selectedOptionn.value);
    setBrandSelectPlaceHolder(selectedOptionn.label);
  };
  const onChangeSelectedFamily = (selectedOption) => {
    setSelectedOptionFamily();
    setFamilyId(selectedOption.value);
    setFamilySelectPlaceHolder(selectedOption.label);
  };
  const onChangeSelectedSubFamily = (selectedOption) => {
    setSelectedOptionSubFamily();
    setSubFamilyId(selectedOption.value);
    setSubFamilySelectPlaceHolder(selectedOption.label);
  };
  const onChangeSelectedCategory = (selectedOption) => {
    setSelectedOptionCategory();
    setCategoryId(selectedOption.value);
    setCategorySelectPlaceHolder(selectedOption.label);
  };
  const onValueChange = (event) => {
    if (event.target.value === "1") {
      setType(1);
    } else if (event.target.value === "2") {
      setType(2);
    } else if (event.target.value === "3") {
      setType(3);
    } else if (event.target.value === "4") {
      setType(4);
    } else if (event.target.value === "5") {
      setType(5);
    } else if (event.target.value === "6") {
      setType(6);
    } else if (event.target.value === "7") {
      setType(7);
    }
  };

  const onValueChangePromo = (event) => {
    if (event.target.value === "1") {
      setTypePromo(1);
    } else if (event.target.value === "2") {
      setTypePromo(2);
    }
  };

  const onUploadFileHandler = (e) => {
    // if (e.target.files[0].size > 2097152) {
    setProductsFile(e.target.files[0]);
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = e.target.files[0]
        ? e.target.files[0].name
        : "Entrer le fichier excel des produits";
    }
    document.getElementById("exampleInputFileLabel").innerText =
      e.target.files[0].name;
  };

  const onDeleteFileHandler = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Entrer le fichier excel des produits";
      setProductsFile(null);
    }
  };

  const onChangeCodeArticleHandler = (event) => {
    setProductCodeArticle(event.target.value);
  };
  const typePromotionProduct = () => {
    if (type === 1) {
      return (
        <div className="form-group">
          <label>Choisir la marque</label>
          <div className="input-group">
            <Select
              // style={{ width: "200%" }}
              styles={styles}
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={selectedOption}
              onChange={onChangeSelectedBrand}
              autosize={false}
              options={allBrands}
              placeholder={brandSelectPlaceHolder}
            />
          </div>
        </div>
      );
    } else if (type === 2) {
      return (
        <div className="form-group">
          <label>Choisir la famille</label>
          <Select
            styles={styles}
            closeMenuOnSelect={true}
            components={animatedComponents}
            defaultValue={selectedOptionFamily}
            onChange={onChangeSelectedFamily}
            options={allFamilly}
            placeholder={familySelectPlaceHolder}
          />
        </div>
      );
    } else if (type === 3) {
      return (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Choisir la famille</label>
              <Select
                styles={styles}
                closeMenuOnSelect={true}
                components={animatedComponents}
                defaultValue={selectedOptionFamily}
                onChange={onChangeSelectedFamily}
                options={allFamilly}
                placeholder={familySelectPlaceHolder}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Choisir la sous famille</label>
              <div className="input-group">
                <Select
                  styles={styles}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  defaultValue={selectedOptionSubFamily}
                  onChange={onChangeSelectedSubFamily}
                  autosize={false}
                  options={allSubFamilies}
                  placeholder={subFamilySelectPlaceHolder}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (type === 4) {
      return (
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Choisir la famille</label>
              <Select
                styles={styles}
                closeMenuOnSelect={true}
                components={animatedComponents}
                defaultValue={selectedOptionFamily}
                onChange={onChangeSelectedFamily}
                options={allFamilly}
                placeholder={familySelectPlaceHolder}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Choisir la sous famille</label>
              <div className="input-group">
                <Select
                  styles={styles}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  defaultValue={selectedOptionSubFamily}
                  onChange={onChangeSelectedSubFamily}
                  autosize={false}
                  options={allSubFamilies}
                  placeholder={subFamilySelectPlaceHolder}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label>Choisir la catégorie</label>
              <div className="input-group">
                <Select
                  styles={styles}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  defaultValue={selectedOptionCategory}
                  onChange={onChangeSelectedCategory}
                  autosize={false}
                  options={allCategories}
                  placeholder={categorySelectPlaceHolder}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (type === 5) {
      return (
        <div className="row">
          <div className="col-sm-12">
            <div class="form-group">
              <label for="exampleInputFile">Les produits </label>
              <div class="input-group">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      accept="application/pdf, .xlsx, .xls, "
                      type="file"
                      className="custom-file-input"
                      id="exampleInputFile"
                      onChange={onUploadFileHandler}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="exampleInputFile"
                      id="fileInputLabel"
                    >
                      Choose file
                    </label>
                  </div>
                  {productsFile && (
                    <>
                      <div className="input-group-append">
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={onDeleteFileHandler}
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (type === 6) {
      return (
        <div class="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="inputName">Choisir code article</label>
              <div className="input-group">
                <div className="input-group-prepend" style={{ width: "90%" }}>
                  <input
                    onChange={onChangeCodeArticleHandler}
                    type="text"
                    id="inputName"
                    className="form-control"
                  />{" "}
                </div>
                <div className=" input-group-append">
                  <button
                    onClick={getProdcutByCodeArticle}
                    type="button"
                    class="btn btn-dark"
                  >
                    <i class="fas  fa-solid fa-arrow-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {productInfos()}
        </div>
      );
    }
  };
  const onChangePercentHandler = (event) => {
    setPercent(event.target.value);
  };
  const onChangeAmountHandler = (event) => {
    setAmount(event.target.value);
  };

  const productInfos = () => {
    if (isClickedProduct && product !== null) {
      return (
        <div className="col-sm-6">
          {" "}
          <label>Produit choisit</label> <br />{" "}
          <label>
            {product?.ref || ""}-{product?.name || ""}-{product?.category || ""}
          </label>
        </div>
      );
    }
  };

  const getProdcutByCodeArticle = () => {
    if (productCodeArticle === "none") {
      setOpenErrorAlert(true);
      setErrorAlertText("vous devez choisir un article");
    } else {
      ProductService.getProductByRef(productCodeArticle)
        .then((response) => {
          if (response.data.object) {
            setProduct(response.data.object);
            setProductId(response.data.object.id);
            setIsClickedProduct(true);
            console.log(response.data.object);
          } else {
            setOpenInfoAlert(true);
            setInfoAlertText("Aucun produit de ce code article");
          }
          console.log("product", response);
        })
        .catch((e) => {
          setOpenErrorAlert(true);
          setErrorAlertText(e);
        });
    }
  };
  const blocPromoValue = () => {
    if (typePromo === 1) {
      return (
        <input
          onChange={onChangePercentHandler}
          type="number"
          value={percent}
          min="0"
          id="percent"
          className="form-control"
        />
      );
    } else if (typePromo === 2) {
      return <h1>Montant</h1>;
    }
  };
  const onChangeSelectedWarehouse = (selectedOption) => {
    setWarehouseSelectPlaceHolder();
    console.log(selectedOption);
    setListWarehouseId(selectedOption);
  };
  const onChangeStartDate = (event) => {
    setStartDate(event.target.value.concat(":59"));
  };
  const onChangeEndDate = (event) => {
    setEndDate(event.target.value.concat(":59"));
  };
  const onChangeNameHandler = (event) => {
    setName(event.target.value);
  };
  const onChangeDescriptionHandler = (event) => {
    setDescription(event.target.value);
  };

  const onValidHandler = () => {
    if (name == null) {
      setOpenErrorAlert(true);
      setErrorAlertText("Erreur :le nom est vide ! ");
    } else if (description == null) {
      setOpenErrorAlert(true);
      setErrorAlertText("Erreur :la description est vide ! ");
    }

    let promotionProductAddModel = {
      libelle: name,
      description: description,
      idUser: getCurrentUserId(),
      typePromotionProduct: type,
      idBrand: [brandId],
      idFamily: [familyId],
      idSubFamily: [subFamilyId],
      idCategory: [categoryId],
      idProduct: [productId],
      promotionPercent: percent,
      promotionAmount: amount,
    };
    startLoading();
    DiscountService.addPromotionProduct(promotionProductAddModel, productsFile)
      .then((result) => {
        console.log("result", result.data["success"]);
        if (result.data["success"]) {
          stopLoading();
          setOpenSuccessAlert(true);
          setSuccessAlertText("Enregistrement avec succès");
          setTimeout(() => {
            navigate(`/allPromotions`);
          }, 2000);
        } else {
          setOpenErrorAlert(true);
          setErrorAlertText("Erreur : erreur serveur");
          stopLoading();
        }
      })
      .catch((e) => {
        console.log("error in addPromotionProduct", e);
        setOpenErrorAlert(true);
        setErrorAlertText("Erreur : problème lors de l'ajout de la promotion");
        stopLoading();
      })
      .finally(() => {
        stopLoading();
      });
  };
  const handleEroorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessAlert(false);
  };

  const generateXLSX = () => {
    const currentDate = new Date().toISOString().replace(/[-T:.]/g, "");

    const data = [["code_article", "prix_vente", "prix_apres_vente"]];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Promotion");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);

    const fileName = `promotion_${currentDate}.xlsx`;
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 0);
  };

  const promotionBloc = () => {
    if (type !== 5) {
      return (
        <>
          {" "}
          <b> Choisir le montant/la pourcentage de la promotion</b>
          <br />
          <div className="row">
            <div className="col-sm-5">
              <div className="input-group">
                <label>Pourcentage Promotion</label>{" "}
                <div className="input-group-prepend" style={{ width: "85%" }}>
                  <CurrencyInput
                    id="percent"
                    className="form-control"
                    precision="3"
                    value={percent}
                    onChangeEvent={onChangePercentHandler}
                  />
                </div>
                <div className=" input-group-append">
                  <button type="button" class="btn btn-dark">
                    {" "}
                    <i class="fas fa-solid fa-percent"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="input-group">
                <label>Ou</label>{" "}
              </div>
            </div>
            <div className="col-sm-5">
              <div className="input-group">
                <label>Montant Promotion</label>{" "}
                <div className="input-group-prepend" style={{ width: "85%" }}>
                  <CurrencyInput
                    id="percent"
                    className="form-control"
                    precision="3"
                    value={amount}
                    onChangeEvent={onChangeAmountHandler}
                  />
                </div>
                <div className=" input-group-append">
                  <button type="button" class="btn btn-dark">
                    {" "}
                    <i class="fas fa-dollar-sign"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <section className="content" style={{ marginTop: "1%" }}>
      <div className="container-fluid">
        <div className="timeline">
          <div className="time-label">
            <span style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>Régle sur Produit</span>
            </span>
          </div>
          <div>
            <i className="fas fa-1 " style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>1</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 12:05
              </span>
              <h3 className="timeline-header">
                <b>Informations Générales</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="inputName">Libellé</label>
                      <input
                        onChange={onChangeNameHandler}
                        type="text"
                        id="inputName"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="inputDescription">Description</label>
                      <textarea
                        value={description}
                        placeholder="Saisir..."
                        onChange={onChangeDescriptionHandler}
                        id="inputDescription"
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <i className="fas fa-2 " style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>2</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 12:05
              </span>
              <h3 className="timeline-header">
                <b>Choisir le dépot</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Choisir le/ les dépôt</label>

                      <Select
                        styles={styles}
                        isMulti
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={selectedOptionWarehouse}
                        onChange={onChangeSelectedWarehouse}
                        options={allWarehouse}
                        placeholder={warehouseSelectPlaceHolder}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <i className="fas fa-3 " style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>3</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 5 mins ago
              </span>
              <h3 className="timeline-header no-border">
                <b>Choisir le type de la promotion</b>
                {type == 5 && (
                  <>
                    <br /> <br />
                    <button
                      type="button"
                      class="btn btn-dark"
                      onClick={() => generateXLSX()}
                    >
                      Telecharger Modéle
                      <FontAwesomeIcon icon={faDownload} className="ml-2" />
                    </button>
                  </>
                )}
              </h3>

              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Promotion sur : </label>{" "}
                    <div class="form-group  clearfix">
                      <div class="icheck-asbestos d-inline">
                        <input
                          id="brand"
                          type="radio"
                          name="Marque"
                          value="1"
                          checked={type === 1}
                          onChange={onValueChange}
                        />
                        <label for="brand">Marque </label>{" "}
                      </div>

                      <div class="icheck-asbestos d-inline">
                        <input
                          id="familly"
                          type="radio"
                          name="Famille"
                          value="2"
                          checked={type === 2}
                          onChange={onValueChange}
                        />

                        <label for="familly"> Famille</label>
                      </div>
                      <div class="icheck-asbestos d-inline">
                        <input
                          id="subfamilly"
                          type="radio"
                          name="Sous famille"
                          value="3"
                          checked={type === 3}
                          onChange={onValueChange}
                        />

                        <label for="subfamilly"> Sous Famille</label>
                      </div>
                      <div class="icheck-asbestos d-inline">
                        <input
                          id="category"
                          type="radio"
                          name="Catégorie"
                          value="4"
                          checked={type === 4}
                          onChange={onValueChange}
                        />

                        <label for="category"> Catégorie</label>
                      </div>
                      <div class="icheck-asbestos d-inline">
                        <input
                          id="listproduct"
                          type="radio"
                          name="Liste Produits"
                          value="5"
                          checked={type === 5}
                          onChange={onValueChange}
                        />

                        <label for="listproduct">Liste des Produits</label>
                      </div>
                      <div class="icheck-asbestos d-inline">
                        <input
                          id="product"
                          type="radio"
                          name="Produit"
                          value="6"
                          checked={type === 6}
                          onChange={onValueChange}
                        />

                        <label for="product"> Produit</label>
                      </div>
                      <div class="icheck-asbestos d-inline">
                        <input
                          id="allproduct"
                          type="radio"
                          name="AllProduit"
                          value="7"
                          checked={type === 7}
                          onChange={onValueChange}
                        />

                        <label for="allproduct"> Tous les produits</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <i className="fas fa-4" style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>4</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 27 mins ago
              </span>
              <h3 className="timeline-header">
                <b> Choisir les produits</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-12"> {typePromotionProduct()}</div>
                </div>
                {promotionBloc()}
              </div>
            </div>
          </div>
          {/* <div>
            <i className="fas fa-5" style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>5</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 27 mins ago
              </span>
              <h3 className="timeline-header">
                <b> Choisir le montant/la pourcentage de la promotion</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="input-group">
                      <label>Pourcentage Promotion</label>{" "}
                      <div
                        className="input-group-prepend"
                        style={{ width: "85%" }}
                      >
                        <CurrencyInput
                          id="percent"
                          className="form-control"
                          precision="3"
                          value={amount}
                          onChangeEvent={onChangeAmountHandler}
                        />
                      </div>
                      <div className=" input-group-append">
                        <button type="button" class="btn btn-dark">
                          {" "}
                          <i class="fas fa-solid fa-percent"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="input-group">
                      <label>Ou</label>{" "}
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="input-group">
                      <label>Montant Promotion</label>{" "}
                      <div
                        className="input-group-prepend"
                        style={{ width: "85%" }}
                      >
                        <CurrencyInput
                          id="percent"
                          className="form-control"
                          precision="3"
                          value={percent}
                          onChangeEvent={onChangePercentHandler}
                        />
                      </div>
                      <div className=" input-group-append">
                        <button type="button" class="btn btn-dark">
                          {" "}
                          <i class="fas fa-dollar-sign"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* date debut et fin */}
          {/* <div>
            <i className="fas fa-6" style={{ backgroundColor: "black" }}>
              <span style={{ color: "white" }}>5</span>
            </i>
            <div className="timeline-item">
              <span className="time">
                <i className="fas fa-clock" /> 27 mins ago
              </span>
              <h3 className="timeline-header">
                <b> Choisir la période de la promotion</b>
              </h3>
              <div className="timeline-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="inputName">Date début</label>

                      <TextField
                        style={{ width: "100%" }}
                        className="col-sm-12"
                        variant="outlined"
                        value={startDate}
                        onChange={onChangeStartDate}
                        id="datetime-local"
                        type="datetime-local"
                        defaultValue="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="inputName">Date fin</label>

                      <TextField
                        style={{ width: "100%" }}
                        className="col-sm-12"
                        variant="outlined"
                        value={endDate}
                        onChange={onChangeEndDate}
                        id="datetime-local"
                        type="datetime-local"
                        defaultValue="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <button
              class="btn btn-dark"
              style={{ marginTop: "1%" }}
              onClick={onValidHandler}
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={openErrorAlert}
        autoHideDuration={2000}
        onClose={handleEroorClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert variant="filled" onClose={handleEroorClose} severity="error">
          {errorAlertText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccessAlert}
        autoHideDuration={2000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert variant="filled" onClose={handleSuccessClose} severity="success">
          {successAlertText}
        </Alert>
      </Snackbar>
    </section>
  );
};
export default withLoader(AddDiscountProduct);
