import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import VenteFlashService from "../services/VenteFlashService";
import DiscountService from "src/services/DiscountService";
import { TextField } from "@mui/material";
import TestValidationDateBettween, {
  TestInputVenteFlash,
} from "src/common/staticMethodes";

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

function AddVentesFlash() {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [venteFlash, setVenteFlash] = useState({});

  //for select Promotion by search
  const animatedComponents = makeAnimated();
  const [promotionList, setPromotionsList] = useState([]);
  const [promotionSelected, setPromotionSelected] = useState([]);
  const [searchData, setSearchData] = useState("");

  //date
  const [startDate, setStartDate] = useState("any");
  const [endDate, setEndDate] = useState("any");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVenteFlash({ ...venteFlash, [name]: value });
  };

  const onChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const onChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  // image
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const allpromotionsForAddToDeclencheur = async (searchData) => {
    try {
      const response = await DiscountService.allpromotionsForAddToDeclencheur(
        searchData
      );
      if (response.data.success) {
        setPromotionsList(response.data.success);
      } else {
        setPromotionsList([]);
      }
      console.log("data", response.data.success);
    } catch (error) {
      console.log("response", error);
    }
  };

  const AddVenteFlash = (event) => {
    event.preventDefault();

    const idsPromotions = promotionSelected.map((promotion) => promotion.value);
    const AddModel = {
      dateDebut: new Date(startDate),
      dateFin: new Date(endDate),
      IdsPromotions: idsPromotions,
      libelle: venteFlash.libelle,
      titreBouton: venteFlash.titreBouton,
      urlBouton: venteFlash.urlBouton,
    };

    if (
      TestInputVenteFlash(
        startDate,
        endDate,
        idsPromotions,
        AddModel.libelle,
        AddModel.titreBouton,
        AddModel.urlBouton,
        imageFile
      )
    ) {
      return;
    }

    console.log(AddModel);

    VenteFlashService.addVenteFlash(AddModel, imageFile)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setVenteFlash({});
          setStartDate("any");
          setEndDate("any");
          handleDeleteClick();
          setSearchData("");
          setPromotionSelected([]);
          setPromotionsList([]);
        } else if (response.data.error) {
          console.log(response.data.error);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const onChangeSelectedPromotion = (selectedOption) => {
    setPromotionSelected(selectedOption);
  };

  const handleInputChange = (searchData) => {
    if (searchData) {
      setSearchData(searchData);
      allpromotionsForAddToDeclencheur(searchData);
    } else {
      setPromotionsList([]);
    }
  };
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Vente flash</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Libellé :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="libellé"
                        name="libelle"
                        onChange={handleChange}
                        value={venteFlash.libelle || ""}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="w-100"></div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Titre button :</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control float-right"
                            placeholder="titre button"
                            name="titreBouton"
                            onChange={handleChange}
                            value={venteFlash.titreBouton || ""}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Lien button :</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control float-right"
                            placeholder="https://www.exemple.com"
                            name="urlBouton"
                            onChange={handleChange}
                            value={venteFlash.urlBouton || ""}
                            required
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          Image Background carrée
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={handleFileChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                              id="fileInputLabel"
                            >
                              Choose file
                            </label>
                          </div>
                          {imageFile && (
                            <>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={handleDeleteClick}
                                >
                                  Supprimer
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm-6">
                      {imageFile && (
                        <>
                          <div className="input-group-append">
                            <img
                              src={imagePreview}
                              alt="Selected"
                              style={{ width: "30%" }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Sélectionner promotion</label>

                    <Select
                      styles={styles}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={promotionSelected}
                      onInputChange={handleInputChange}
                      onChange={onChangeSelectedPromotion}
                      options={promotionList}
                      placeholder="Sélectionnez une promotion .."
                      isMulti
                    />
                    {/* 
                    <select
                      className="form-control"
                      name="idPriorities"
                      onChange={(e) => {
                        setPromotionSelected(e.target.value);
                      }}
                      value={promotionSelected || ""}
                      required
                    >
                      <option value="">Sélectionnez une promotion</option>
                      {promotionList.map((promotion, index) => (
                        <option key={index} value={promotion?.id || ""}>
                          {promotion?.name || ""}
                        </option>
                      ))}
                    </select> */}
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="inputName">Date début</label>

                        <TextField
                          style={{ width: "100%" }}
                          className="col-sm-12"
                          variant="outlined"
                          value={startDate}
                          onChange={onChangeStartDate}
                          id="datetime-local"
                          type="datetime-local"
                          // type="date"
                          defaultValue="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="inputName">Date fin</label>

                        <TextField
                          style={{ width: "100%" }}
                          className="col-sm-12"
                          variant="outlined"
                          value={endDate}
                          onChange={onChangeEndDate}
                          id="datetime-local"
                          type="datetime-local"
                          defaultValue="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default">
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={AddVenteFlash}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddVentesFlash;
