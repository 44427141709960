import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class CategoryService {
  addCategory(CategoryModel, picture) {
    const formData = new FormData();
    formData.append("CategoryModel", JSON.stringify(CategoryModel));
    formData.append("picture", picture);

    return api.post(urlsCommon.BASE_CATEGORY_URL + "/addCategory", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getAllCategoriesByIdSubFamilyProduct(idSubFamilyProduct) {
    return api.get(
      urlsCommon.BASE_CATEGORY_URL +
        "/getAllCategoriesByIdSubFamilyProduct/" +
        idSubFamilyProduct
    );
  }

  getAllCategories(page, size) {
    return api.get(urlsCommon.BASE_CATEGORY_URL + "/getAllCategories", {
      params: {
        page: page,
        size: size,
      },
    });
  }

  getAllCategoriesBySousFamille() {
    return api.get(
      urlsCommon.BASE_CATEGORY_URL + "/getAllCategoriesBySousFamille"
    );
  }

  getCategoryById(idCategory) {
    return api.get(
      urlsCommon.BASE_CATEGORY_URL + "/getCategoryById/" + idCategory
    );
  }
  updatePicture(picture, idCategory) {
    return api.put(
      urlsCommon.BASE_CATEGORY_URL + "/updateCategoryPicture",
      picture,
      {
        params: {
          idCategory,
        },
      }
    );
  }
  updateName(idCategory, name) {
    return api.put(
      urlsCommon.BASE_CATEGORY_URL +
        "/updateCategoryName/" +
        idCategory +
        "/" +
        name
    );
  }
  visibleCategory(idCategory, deed) {
    return api.put(
      urlsCommon.BASE_CATEGORY_URL +
        "/visibleCategory/" +
        idCategory +
        "/" +
        deed
    );
  }
  archiveCategory(idCategory, deed) {
    return api.get(
      urlsCommon.BASE_CATEGORY_URL +
        "/archiveCategory/" +
        idCategory +
        "/" +
        deed
    );
  }

  updateCategory(idCategory, modelToUpdate) {
    return api.put(
      urlsCommon.BASE_CATEGORY_URL + "/updateCategory/" + idCategory,
      modelToUpdate
    );
  }

  searchCategorie(page, size, dataToSearch) {
    return api.get(urlsCommon.BASE_CATEGORY_URL + "/search", {
      params: {
        page: page,
        size: size,
        dataToSearch: dataToSearch,
      },
    });
  }
}
export default new CategoryService();
