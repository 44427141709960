import { Component } from "react";
import CurrencyInput from "react-currency-input";
import NumericInput from "react-numeric-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Files from "react-files";
import CarrousselPromotionHomeService from "../../services/CarrousselPromotionHomeService";
export default class AddCarrousselPromotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subtitle: "",
      titleP1: "",
      titleP2: "",
      titleLien: "",
      urlLien: "",
      visible: false,
      files: [],
    };
    this.changeTitle = this.changeTitle.bind(this);
    this.changeTitleP1 = this.changeTitleP1.bind(this);
    this.changeTitleP2 = this.changeTitleP2.bind(this);
    this.changeTitleLien = this.changeTitleLien.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeUrlLien = this.changeUrlLien.bind(this);
  }

  handleChange(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }
  changeTitle(event) {
    this.setState({ title: event.target.value });
    console.log("title", this.state.title);
  }
  // changeSubtitle(event) {
  //   this.setState({ subtitle: event.target.value });
  //   console.log("subtitle", this.state.subtitle);
  // }

  changeTitleP1(event) {
    this.setState({ titleP1: event.target.value });
    console.log("titleP1", this.state.titleP1);
  }
  changeTitleP2(event) {
    this.setState({ titleP2: event.target.value });
    console.log("titleP2", this.state.titleP2);
  }
  changeTitleLien(event) {
    this.setState({ titleLien: event.target.value });
    console.log("titleLien", this.state.titleLien);
  }
  changeUrlLien(event) {
    this.setState({ urlLien: event.target.value });
    console.log("urlLien", this.state.urlLien);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  addCarrousselHome = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);
    // let pric = this.state.price.replace(" DNT", "");

    let carrousselPromotionHomeModel = {
      title: this.state.title,
      texteGras: this.state.titleP1,
      texteNormal: this.state.titleP2,
      titleLien: this.state.titleLien,
      urlLien: this.state.urlLien,
      // private String urlLien;
      // private boolean visible;
      visible: this.state.visible,
      // files: dataFile,
    };

    CarrousselPromotionHomeService.addCarrousselPromotionHome(
      dataFile,
      JSON.stringify(carrousselPromotionHomeModel)
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la carroussel avec succée");
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Le bloc de promotion</h5>
              Deux étapes:
              <br /> 1-Ajouter le libellé
              <br /> 2-L'images de la caroussel (376px * 160px)
            </div>
          </div>
        </section>

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline ">
            <div class="card-header">
              <h3 class="card-title">
                {" "}
                <b>Ajouter la bloc promotion</b>
              </h3>
            </div>

            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-4">
                    <label>Titre</label>

                    <input
                      type="title"
                      className="form-control"
                      placeholder="Ajouter  Titre"
                      value={this.state.title}
                      onChange={this.changeTitle}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Partie 1 (Gras):</label>

                    <input
                      type="Subtitle"
                      className="form-control"
                      placeholder="Ajouter Titre partie 1"
                      value={this.state.titleP1}
                      onChange={this.changeTitleP1}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Partie 2 (Normal):</label>

                    <input
                      type="Subtitle"
                      className="form-control"
                      placeholder="Ajouter Titre partie 2"
                      value={this.state.titleP2}
                      onChange={this.changeTitleP2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Titre lien</label>

                    <input
                      type="titre bouton"
                      className="form-control"
                      placeholder="Ajouter Titre du bouton"
                      value={this.state.titleLien}
                      onChange={this.changeTitleLien}
                    />
                  </div>
                  <div className="col-md-9">
                    <label>Url lien</label>

                    <input
                      type="Url"
                      className="form-control"
                      placeholder="Ajouter le lien"
                      value={this.state.urlLien}
                      onChange={this.changeUrlLien}
                    />
                  </div>
                </div>
                {/* <div className="row">
                        <div class="col-md-6">
                          <label>Date Début </label>
                          <br />

                          <DatePicker
                            className="form-control"
                            selected={this.state.dateDebut}
                            onChange={this.changeDateDebut}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <div class="col-md-6">
                          <label>Date Fin</label>
                          <br />

                          <DatePicker
                            className="form-control"
                            selected={this.state.dateDebut}
                            onChange={this.changeDateFin}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div> */}
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <Files
                      ref="files"
                      className="files-dropzone-list"
                      // style={{ height: "100px" }}
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      multiple
                      maxFiles={1}
                      maxFileSize={10000000}
                      minFileSize={0}
                      clickable
                    >
                      <label>Cliquer pour Ajouter une image</label>
                    </Files>

                    {this.state.files.length > 0 ? (
                      <div className="files-list">
                        <ul>
                          {this.state.files.map((file) => (
                            <li className="files-list-item" key={file.id}>
                              <div className="files-list-item-preview">
                                {file.preview.type === "image" ? (
                                  <div className="row">
                                    <img
                                      className="files-list-item-preview-image"
                                      src={file.preview.url}
                                      style={{
                                        width: "700px",
                                        height: "300px",
                                      }}
                                    />
                                    <button
                                      id={file.id}
                                      class="btn btn-navbar"
                                      type="button"
                                      // data-widget="navbar-search"
                                      onClick={this.filesRemoveOne.bind(
                                        this,
                                        file
                                      )}
                                    >
                                      <i class="fas fa-times"></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="files-list-item-preview-extension">
                                    {file.extension}
                                  </div>
                                )}
                              </div>
                              <div className="files-list-item-content">
                                <div className="files-list-item-content-item files-list-item-content-item-1">
                                  {file.name}
                                </div>
                                <div className="files-list-item-content-item files-list-item-content-item-2">
                                  {file.sizeReadable}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <h1></h1>
                    )}
                  </div>
                </div>

                <div className="col-md-12 float-right">
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={this.state.visible}
                        onChange={this.handleChange}
                      />{" "}
                      Rendre visible dans le site
                    </label>
                  </div>
                </div>
                {/* </div> */}
              </form>
            </div>
            <br />
            <div className="card-footer">
              <button
                type="submit"
                className="btn btn-success float-right"
                onClick={this.addCarrousselHome}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
