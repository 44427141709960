import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UpdateVentesFlash from "./UpdateVentesFlash";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "src/common/Pagination";
import VenteFlashService from "src/services/VenteFlashService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DiscountService from "src/services/DiscountService";
import DeletePromotionVenteFlash from "./DeletePromotionVenteFlash";

function AllPromotionsBypromotionVenteFlash() {
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [promotionVenteFlashList, setpromotionVenteFlashList] = useState([]);

  //modal delete promotion
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [idVenteFlash, setIdVenteFlash] = useState("");
  const [idPromotion, setIdPromotion] = useState("");
  const [promotionDelete, setPromotionDelete] = useState("");

  //for select Promotion by search
  const animatedComponents = makeAnimated();
  const [promotionList, setPromotionsList] = useState([]);
  const [promotionSelected, setPromotionSelected] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [searchDataInVenteFlash, setSearchDataInVenteFlash] = useState("");

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchDataInVenteFlash]);

  const fetchData = () => {
    if (searchDataInVenteFlash === "") {
      getVentesPromotions(
        localStorage.getItem("idVenteFlash"),
        currentPage - 1,
        itemsPerPage
      );
    } else {
      searchPromotionsVenteFlash(
        localStorage.getItem("idVenteFlash"),
        currentPage - 1,
        itemsPerPage,
        searchDataInVenteFlash
      );
    }
  };

  //********search data in vente flash *********/
  const clearSearchValue = () => {
    setSearchDataInVenteFlash("");
    getVentesPromotions(
      localStorage.getItem("idVenteFlash"),
      currentPage - 1,
      itemsPerPage
    );
  };

  //********for add promotion *********/

  const onChangeSelectedPromotion = (selectedOption) => {
    setPromotionSelected(selectedOption);
  };

  const handleInputChange = (searchData) => {
    if (searchData) {
      setSearchData(searchData);
      allpromotionsForAddToDeclencheur(searchData);
    } else {
      setPromotionsList([]);
    }
  };
  const allpromotionsForAddToDeclencheur = async (searchData) => {
    try {
      const response = await DiscountService.allpromotionsForAddToDeclencheur(
        searchData
      );
      if (response.data.success) {
        setPromotionsList(response.data.success);
      } else {
        setPromotionsList([]);
      }
      console.log("data", response.data.success);
    } catch (error) {
      console.log("response", error);
    }
  };
  //********for add promotion *********/
  const searchPromotionsVenteFlash = (
    idVenteFlash,
    page,
    size,
    dataToSearch
  ) => {
    VenteFlashService.searchPromotionsVenteFlash(
      idVenteFlash,
      page,
      size,
      dataToSearch
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setpromotionVenteFlashList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getVentesPromotions = (idVenteFlash, page, size) => {
    VenteFlashService.getVentesPromotions(idVenteFlash, page, size)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setpromotionVenteFlashList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //**************for edit vente flash modal

  const openSectionProduitsOfPromotionVenteFlash = (id, name) => {
    localStorage.setItem("idPromotionVenteFlash", id);
    localStorage.setItem("namePromotionVenteFlash", name);

    const url = "/produitsPromotionsVenteFlash";
    window.open(url, "_blank");
  };
  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const onAddPromotions = () => {
    const idsPromotions = JSON.stringify(
      promotionSelected.map((promotion) => promotion.value)
    );

    VenteFlashService.updatePromotionsVenteFlash(
      localStorage.getItem("idVenteFlash"),
      idsPromotions
    )
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const openModalDelete = (idVenteFlash, promotion) => {
    setIsModalOpenDelete(true);
    setIdVenteFlash(idVenteFlash);
    setPromotionDelete(promotion);
  };

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les promotions de vente flash </b> :
                    </h5>
                    <br />
                    <br />
                    <div className="form-group">
                      <div className="col-md-6 ">
                        <label>Sélectionner promotions à ajouter</label>
                        <div className="d-flex">
                          <Select
                            styles={styles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={promotionSelected}
                            onInputChange={handleInputChange}
                            onChange={onChangeSelectedPromotion}
                            options={promotionList}
                            placeholder="Sélectionnez une promotion .."
                            isMulti
                          />
                          <button
                            className="btn btn-dark float-right ml-2"
                            onClick={(e) => onAddPromotions(e)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-md-6 ">
                        <label>Chercher Promotions </label>
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          value={searchDataInVenteFlash}
                          onChange={(event) =>
                            setSearchDataInVenteFlash(event.target.value)
                          }
                        />
                        <i
                          className="icon-class"
                          onClick={clearSearchValue}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">
                        Les Promotions disponibles de cette vente Flash :{" "}
                        {localStorage.getItem("NameVenteFlash") || ""}{" "}
                      </h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th>Libellé</th>
                            <th>Voir Produits</th>
                            <th>Supprimer Promotion</th>
                          </tr>
                        </thead>
                        <tbody>
                          {promotionVenteFlashList &&
                          promotionVenteFlashList.length > 0 ? (
                            promotionVenteFlashList.map(
                              (promotionVenteFlash, index) => (
                                <tr key={index}>
                                  <td>{promotionVenteFlash?.name || ""}</td>

                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className={
                                        promotionVenteFlash?.visibled
                                          ? "btn btn-success btn-sm"
                                          : "btn btn-secondary btn-sm"
                                      }
                                      onClick={() =>
                                        openSectionProduitsOfPromotionVenteFlash(
                                          promotionVenteFlash?.id,
                                          promotionVenteFlash?.name
                                        )
                                      }
                                    >
                                      <>
                                        Voir Produits{" "}
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="ml-2"
                                        />
                                      </>
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-secondary btn-sm"
                                      onClick={() =>
                                        openModalDelete(
                                          localStorage.getItem("idVenteFlash"),
                                          promotionVenteFlash
                                        )
                                      }
                                    >
                                      Supprimer
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="ml-2"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                <b>Pas de promotions Vente Flash Pages</b>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {promotionVenteFlashList &&
                        promotionVenteFlashList.length > 0 && (
                          <>
                            <div className="d-flex" style={{ padding: "15px" }}>
                              <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(newPage) =>
                                  setCurrentPage(newPage)
                                }
                              />
                              <div className="ml-3">
                                <select
                                  className="form-control pagination-select float-right"
                                  id="itemsPerPageSelect"
                                  value={itemsPerPage}
                                  onChange={(e) =>
                                    setItemsPerPage(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                >
                                  {[4, 7, 10, 15, 20].map((value) => (
                                    <option key={value} value={value}>
                                      {value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {isModalOpenDelete && (
          <DeletePromotionVenteFlash
            isModalOpenDelete={isModalOpenDelete}
            setIsModalOpenDelete={setIsModalOpenDelete}
            idVenteFlash={idVenteFlash}
            promotionDelete={promotionDelete}
          />
        )}
      </div>
    </>
  );
}

export default AllPromotionsBypromotionVenteFlash;
