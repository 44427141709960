import React, { useState } from "react";
import { toast } from "react-toastify";
import ShopThematiqueService from "src/services/ShopThematiqueService";

function ArchiverAndVisibleBanniereThematique(props) {
  const { value, id, setIsModalArchiverOrVisibleOpen, banniereItem } = props;

  const archiverById = (id) => {
    ShopThematiqueService.archive(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledById = (id) => {
    ShopThematiqueService.visible(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const archiverOrVisible = (event, id, value) => {
    event.preventDefault();
    if (value == "archiver") {
      archiverById(id);
      return;
    } else if (value == "visible") {
      visibledById(id);
      return;
    }
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModalArchiverOrVisible"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalArchiverOrVisible">
                {value === "archiver" && banniereItem.archived == false ? (
                  <>Archiver Banniere</>
                ) : value === "archiver" && banniereItem.archived == true ? (
                  <>Not Archiver Banniere</>
                ) : value === "visible" && banniereItem.visible == false ? (
                  <>Rendre Banniere visibled </>
                ) : value === "visible" && banniereItem.visible == true ? (
                  <>Not visibled Banniere</>
                ) : null}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card-body">
              Veuillez confimer {""}
              <b>
                {value === "archiver" && banniereItem.archived == false ? (
                  <>d'Archiver Banniere</>
                ) : value === "archiver" && banniereItem.archived == true ? (
                  <> de rendre Banniere Not Archived </>
                ) : value === "visible" && banniereItem.visible == false ? (
                  <>de rendre Banniere visible </>
                ) : value === "visible" && banniereItem.visible == true ? (
                  <>de rendre Banniere not visible </>
                ) : null}
              </b>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsModalArchiverOrVisibleOpen(false);
                  window.location.reload();
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-warning"
                onClick={(e) => archiverOrVisible(e, id, value)}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArchiverAndVisibleBanniereThematique;
