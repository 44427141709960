import axios from "axios";
import { Component } from "react";
import Files from "react-files";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FamilyProductService from "../../services/FamilyProductService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
export default class FamilyProductImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      family: "",
      files: [],
    };
  }

  componentDidMount() {
    this.forceUpdate();
    FamilyProductService.getFamilyById(this.props.idFamilyProduct)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ family: response.data["object"] });
          console.log("family", this.state.family);
          // this.setState({ alert_message: "success" });
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  filesUpload = () => {
    const formData = new FormData();
    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      formData.append(
        key,
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });

    axios
      .post(`/files`, formData)
      .then((response) =>
        window.alert(`${this.state.files.length} files uploaded succesfully!`)
      )
      .catch((err) => window.alert("Error uploading files :("));
  };

  saveFamilyProduct = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);
    FamilyProductService.updatePicture(dataFile, this.state.family.id)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Mise à jour de l'image avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div class="card card-dark">
        <ToastContainer />
        <div className="card-header">
          <h3 className="card-title">
            Modifier image de famille {this.state.family.name} / Dimensions :
            (624px L * 500px H)
          </h3>

          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
              title="Collapse"
            >
              <i className="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <label>L'image actuelle</label>
              <img
                className="files-list-item-preview-image mt-3"
                src={this.state.family.url}
                style={{ width: "80%" }}
              />
            </div>

            <div className="col">
              <Files
                ref="files"
                className="files-dropzone-list"
                // style={{ height: "100px" }}
                onChange={this.onFilesChange}
                onError={this.onFilesError}
                multiple
                maxFiles={1}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                {this.state.files.length === 0 ? (
                  <label>Cliquer pour modifier l'image</label>
                ) : (
                  <>
                    <div>
                      <label>l'image apres la modification</label>
                      <button
                        id={this.state.files[0].id}
                        class="btn btn-danger btn-sm ml-2"
                        type="button"
                        // data-widget="navbar-search"
                        onClick={this.filesRemoveOne.bind(
                          this,
                          this.state.files[0]
                        )}
                      >
                        Supprimer l'image
                        <FontAwesomeIcon icon={faTrash} className="ml-3" />
                      </button>
                    </div>
                  </>
                )}
              </Files>

              <div className="mb-2"></div>

              {this.state.files.length > 0 && (
                <>
                  <div className="files-list">
                    {this.state.files.map((file) => (
                      <>
                        <div className="files-list-item" key={file.id}>
                          <div className="files-list-item-preview">
                            {file.preview.type === "image" ? (
                              <img
                                className="files-list-item-preview-image"
                                src={file.preview.url}
                                style={{ width: "80%" }}
                              />
                            ) : (
                              <ul>
                                <li>
                                  <div className="files-list-item-preview-extension">
                                    {file.extension}
                                  </div>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer justify-content-between">
          <button
            type="button"
            className="btn btn-default btn-sm"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            Annuler
          </button>
          <button
            style={{
              float: "right",
            }}
            type="submit"
            className="btn btn-dark btn-sm"
            onClick={this.saveFamilyProduct}
            disabled={this.state.files[0] ? false : true}
          >
            Confirmer
          </button>
        </div>
      </div>
    );
  }
}
