import { Component } from "react";
import { Link } from "react-router-dom";
import PromotionBanniereService from "../../services/PromotionBanniereService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PromotionBanniereDelete from "./PromotionBanniereDelete";
import PromotionBanniereVisible from "./PromotionBanniereVisible";

export default class PromotionBanniere extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotions: [],
      idPromotion: "",
      showFormImage: false,
    };
  }
  componentDidMount() {
    this.forceUpdate();
    PromotionBanniereService.allPromotionBanniere()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ promotions: response.data["object"] });
          console.log("resonse");
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  formatDate(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth()).padStart(2, "0");
    let year = cdate.getFullYear();
    return day + "/" + month + "/" + year;
  }

  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Banniere promotion</h5>
              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addPromotionBanniere" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    <i class="fas fa-plus"></i>
                    Ajouter
                  </Link>
                </button>
              </p>
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Banniere de la page promotion</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Titre </th>
                    {/* <th>date début </th>
                    <th>date fin </th> */}
                    <th>visible</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.promotions.map((promotion) => (
                    <tr>
                      <td>{promotion.id}</td>
                      <td>{promotion.titre}</td>

                      <td>
                        <button
                          data-toggle="modal"
                          data-target="#modal-visible"
                          // style={{ width: 40 }}
                          className={`btn btn-sm ${
                            promotion?.visible ? "btn-secondary" : "btn-primary"
                          }`}
                          onClick={() => {
                            this.setState({
                              idPromotion: promotion.id,
                            });
                            this.showFormImage();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={promotion?.visible ? faEyeSlash : faEye}
                          />
                        </button>
                      </td>
                      <td>
                        <button
                          // style={{ width: 40 }}
                          className={`btn btn-sm ${
                            promotion?.isArchived ? "btn-success" : "btn-danger"
                          }`}
                          onClick={() => {
                            this.setState({
                              idPromotion: promotion.id,
                            });
                            this.showFormImage();
                          }}
                          data-toggle="modal"
                          data-target="#modal-default"
                        >
                          <FontAwesomeIcon
                            icon={promotion?.isArchived ? faRecycle : faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-visible">
                      <PromotionBanniereVisible id={this.state.idPromotion} />
                    </div>
                  )}
                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-default">
                      <PromotionBanniereDelete id={this.state.idPromotion} />
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
