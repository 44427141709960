import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class BanniereCategoryHomeService {
  addBigCategory(image, model) {
    const formData = new FormData();
    formData.append("BigBanniereCategoryModel", JSON.stringify(model));
    formData.append("file", image);

    return api.post(
      urlsCommon.BASE_BANNIERE_CATEGORY_HOME_URL + "/addBigCategory",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  addSmallCategory(image, model) {
    const formData = new FormData();
    formData.append("BigBanniereCategoryModel", JSON.stringify(model));
    formData.append("file", image);

    return api.post(
      urlsCommon.BASE_BANNIERE_CATEGORY_HOME_URL + "/addSmallCategory",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  updateCategory(image, model) {
    const formData = new FormData();
    formData.append("BigBanniereCategoryModel", JSON.stringify(model));
    formData.append("file", image);

    return api.put(
      urlsCommon.BASE_BANNIERE_CATEGORY_HOME_URL + "/updateCategory",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getAllCategoryHomeBannnieres(page, size) {
    return api.get(
      urlsCommon.BASE_BANNIERE_CATEGORY_HOME_URL +
        "/getAllCategoryHomeBannnieres/",

      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  //visible and archive
  archiver(id) {
    return api.put(
      urlsCommon.BASE_BANNIERE_CATEGORY_HOME_URL + "/archiver/" + id
    );
  }
  visibled(id) {
    return api.put(
      urlsCommon.BASE_BANNIERE_CATEGORY_HOME_URL + "/visibled/" + id
    );
  }
}
export default new BanniereCategoryHomeService();
