import { Component } from "react";
import FamilyProductService from "../../services/FamilyProductService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class FamilyUpdateLibelle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      family: "",
      libelle: "",
      disabled: true,
    };
    this.changeLibelle = this.changeLibelle.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    FamilyProductService.getFamilyById(this.props.idFamilyProduct)
      .then((response) => {
        //console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ family: response.data["object"] });
          //console.log("family", this.state.family);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    //console.log("libelle", this.state.libelle);
    //console.log("familyname", this.state.family.name);
    if (
      event.target.value === this.state.libelle ||
      event.target.value === this.state.family.name
    ) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  }
  saveFamilyProduct = (e) => {
    e.preventDefault();

    FamilyProductService.updateFamilyProductName(
      this.state.family.id,
      this.state.libelle
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Mise à jour du libellé avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Modifier Libellé</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2">
                    <label>Libellé</label>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="libelle"
                      className="form-control"
                      value={
                        this.state.libelle === ""
                          ? this.state.family.name
                          : this.state.libelle
                      }
                      onChange={this.changeLibelle}
                    />
                  </div>
                </div>
                <br />
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-info"
              disabled={this.state.disabled}
              onClick={this.saveFamilyProduct}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    );
  }
}
