import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class SizeService {
  createSize(model) {
    return api.post(urlsCommon.BASE_SIZE_URL + "/create", model);
  }
  getAllSizes() {
    return api.get(urlsCommon.BASE_SIZE_URL + "/getAllsizes");
  }
}
export default new SizeService();
