import { Component } from "react";
import Files from "react-files";
import { axios } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import makeAnimated from "react-select/animated";
import Select from "react-select";
import CategoryService from "../../services/CategoryService";
import SubFamilyProductService from "../../services/SubFamilyProductService";
import { Navigate } from "react-router";
export default class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      files: [],
      idSubFamilyProduct: "",
      subFamilyProducts: [],
      livrable: false,
      options: [],
      redirect: false,
      visible: false,
    };
    this.changeLibelle = this.changeLibelle.bind(this);
    this.handleVisible = this.handleVisible.bind(this);
  }
  handleVisible(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }
  componentDidMount() {
    this.forceUpdate();
    SubFamilyProductService.getAllSubFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ subFamilyProducts: response.data["object"] });
          console.log("subFamilyProducts", this.state.subFamilyProducts);
          // this.setState({ alert_message: "success" });
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  remplissageOptions() {
    this.state.subFamilyProducts.map((famille) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: famille.id, label: famille.name },
        ],
      })
    );
  }
  handleChangeList = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ idSubFamilyProduct: selectedOption.value });
    console.log("idSubFamilyProduct", this.state.idSubFamilyProduct);
  };
  handleChange(event) {
    if (this.state.livrable) {
      this.setState({ livrable: false });
    } else {
      this.setState({ livrable: true });
    }

    console.log("livrable", this.state.livrable);
  }

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  saveCategory = (e) => {
    e.preventDefault();
    let CategoryModel = {
      name: this.state.libelle,
      livrable: this.state.livrable,
      visible: this.state.visible,
      idSubfamilyProduct: this.state.idSubFamilyProduct,
    };

    if (
      this.state.files[0] == undefined &&
      CategoryModel.name == "" &&
      CategoryModel.idSubfamilyProduct == ""
    ) {
      toast.error("Veuillez remplir tous les champs !");
      return;
    }

    if (CategoryModel.name == "") {
      toast.error("Veuillez remplir tous les champs !");
      return;
    }
    if (CategoryModel.idSubfamilyProduct == "") {
      toast.error("Veuillez Selectionner une Sous famille !");
      return;
    }

    if (this.state.files[0] == undefined) {
      toast.error("Veuillez ajouter une image !");
      return;
    }

    CategoryService.addCategory(CategoryModel, this.state.files[0])
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la catégorie avec succée");

          this.setState({ libelle: "" });
          this.setState({ idSubFamilyProduct: "" });
          this.setState({ selectedOption: "" });
          this.filesRemoveAll();

          setTimeout(() => {
            this.setState({ redirect: true });
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    const animatedComponents = makeAnimated();
    if (this.state.redirect) {
      return <Navigate replace to="/allCategories" />;
    } else {
      return (
        <div class="content-wrapper">
          <ToastContainer />
          <div class="container-fluid">
            <section class="content-header">
              <div class="callout callout-success">
                <h5>Ajouter une catégorie</h5>
                Deux étapes:
                <br /> 1-Ajouter une image (1520px * 148px)
                <br /> 2-Ajouter le libellé et valider
              </div>
            </section>
            <div className="row">
              <div className="col-md-6">
                <section class="content">
                  <div class="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-outline card-success">
                          <div className="card-header">
                            <h3 className="card-title">
                              <b>1-AJOUTER UNE IMAGE</b>
                            </h3>
                          </div>

                          <form>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <div>
                                    <Files
                                      ref="files"
                                      className="files-dropzone-list"
                                      // style={{ height: "100px" }}
                                      onChange={this.onFilesChange}
                                      onError={this.onFilesError}
                                      multiple
                                      maxFiles={1}
                                      maxFileSize={10000000}
                                      minFileSize={0}
                                      clickable
                                    >
                                      <label>
                                        Cliquer pour Ajouter une image
                                      </label>
                                    </Files>

                                    {this.state.files.length > 0 ? (
                                      <div className="files-list">
                                        <ul>
                                          {this.state.files.map((file) => (
                                            <li
                                              className="files-list-item"
                                              key={file.id}
                                            >
                                              <div className="files-list-item-preview">
                                                {file.preview.type ===
                                                "image" ? (
                                                  <div className="row">
                                                    <img
                                                      className="files-list-item-preview-image"
                                                      src={file.preview.url}
                                                      style={{
                                                        width: "70%",
                                                        height: "70%",
                                                      }}
                                                    />
                                                    <button
                                                      id={file.id}
                                                      class="btn btn-navbar"
                                                      type="button"
                                                      // data-widget="navbar-search"
                                                      onClick={this.filesRemoveOne.bind(
                                                        this,
                                                        file
                                                      )}
                                                    >
                                                      <i class="fas fa-times"></i>
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <div className="files-list-item-preview-extension">
                                                    {file.extension}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="files-list-item-content">
                                                <div className="files-list-item-content-item files-list-item-content-item-1">
                                                  {file.name}
                                                </div>
                                                <div className="files-list-item-content-item files-list-item-content-item-2">
                                                  {file.sizeReadable}
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ) : (
                                      <h1></h1>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-6">
                <section class="content">
                  <div class="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-outline card-success">
                          <div className="card-header">
                            <h3 className="card-title">
                              <b>2-AJOUTER LE LIBELLE ET VALIDER</b>
                            </h3>
                          </div>

                          <form>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-2">
                                  <label>Libellé</label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="libelle"
                                    className="form-control"
                                    placeholder="Ajouter libellé"
                                    value={this.state.libelle}
                                    onChange={this.changeLibelle}
                                  />
                                </div>
                              </div>
                              <label>Choisir la sous-famille</label>
                              <Select
                                id={"SelectMarque"}
                                value={this.state.selectedOption}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={this.state.options}
                                onChange={this.handleChangeList}
                                placeholder="Saisir..."
                              />

                              <br />
                              <div onChange={this.onChangeValue}>
                                <div class="checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      defaultChecked={this.state.livrable}
                                      onChange={this.handleChange}
                                    />{" "}
                                    Livrable
                                  </label>
                                </div>
                              </div>

                              <div class="checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    defaultChecked={this.state.visible}
                                    onChange={this.handleVisible}
                                  />{" "}
                                  Visible sur le site web
                                </label>
                              </div>
                            </div>

                            <div className="card-footer">
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={this.saveCategory}
                              >
                                Valider
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
