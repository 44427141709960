import { Link } from "react-router-dom";
import SectionHomeService from "../../services/SectionHomeService";
import SectionHomeArchived from "./SectionHomeArchived";
import SectionHomeVisible from "./SectionHomeVisible";
import { useEffect, useState } from "react";
import Pagination from "src/common/Pagination";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faPlus,
  faTrash,
  faTrashRestore,
  faYen,
} from "@fortawesome/free-solid-svg-icons";

export default function SectionHomePage() {
  const [idsection, setIdsection] = useState("");
  const [SectionHome, setSectionHome] = useState([]);
  const [ShowFormImage, setShowFormImage] = useState(false);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    getAllSectionHome();
    // getNombreSectionHome();
  }, [currentPage, itemsPerPage]);

  const getAllSectionHome = () => {
    SectionHomeService.getAllSectionHome(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setSectionHome(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //for visible sur le site
  function showFormImage() {
    setShowFormImage(!ShowFormImage);
  }

  function buttonVisible(id, visible) {
    if (visible === 1) {
      return (
        <button
          style={{ width: "150px" }}
          class="btn btn-danger btn-sm float-center"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            setIdsection(id);
            showFormImage();
          }}
        >
          Rendre Invisible{" "}
          <FontAwesomeIcon icon={faEyeSlash} className="ml-2" />
        </button>
      );
    } else {
      return (
        <button
          style={{ width: "150px" }}
          className="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            setIdsection(id);
            showFormImage();
          }}
        >
          Rendre Visible <FontAwesomeIcon icon={faEye} className="ml-2" />
        </button>
      );
    }
  }

  function buttonArchived(id, isArchived) {
    if (isArchived === 1) {
      return (
        <button
          style={{ width: "190px" }}
          class="btn btn-danger btn-sm float-center"
          data-toggle="modal"
          data-target="#modal-archived"
          onClick={() => {
            setIdsection(id);
            showFormImage();
          }}
        >
          Rendre Not Archived{" "}
          <FontAwesomeIcon icon={faTrashRestore} className="ml-2" />
        </button>
      );
    } else {
      return (
        <button
          style={{ width: "190px" }}
          className="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-archived"
          onClick={() => {
            setIdsection(id);
            showFormImage();
          }}
        >
          Rendre Archived <FontAwesomeIcon icon={faTrash} className="ml-2" />
        </button>
      );
    }
  }

  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div className="row mb-2">
            <div className="col-sm-12">
              <div class="card  mt-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <b>Les Sections Home </b>
                  </h5>
                  <button
                    className="btn btn-dark float-right"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={() => (window.location.href = "/addSection")}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              <section class="content">
                <div class="card card-dark">
                  <div class="card-header">
                    <h3 class="card-title">Les Sections Home disponibles</h3>

                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <table class="table  projects">
                      <thead>
                        <tr>
                          <th>Titre </th>
                          <th>Place</th>
                          <th>Visibilité</th>
                          <th>Archiver</th>
                        </tr>
                      </thead>
                      <tbody>
                        {SectionHome.length > 0 ? (
                          <>
                            {SectionHome &&
                              SectionHome.map((section, index) => (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor: section?.visible
                                      ? "#E6E6E9"
                                      : "none",
                                  }}
                                >
                                  <td>{section.titre}</td>
                                  <td>{section?.placeInHomePage || ""}</td>
                                  <td>
                                    {buttonVisible(section.id, section.visible)}
                                    {ShowFormImage && (
                                      <div
                                        className="modal fade"
                                        id="modal-visible"
                                      >
                                        <SectionHomeVisible
                                          idSectionHome={idsection}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {buttonArchived(
                                      section.id,
                                      section.isArchived
                                    )}

                                    {ShowFormImage && (
                                      <div
                                        className="modal fade"
                                        id="modal-archived"
                                      >
                                        <SectionHomeArchived
                                          idSectionHome={idsection}
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr className="text-center">
                              <td colSpan="4">
                                Pas de Section Home pour le moment
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    {SectionHome.length > 0 && (
                      <>
                        <div className="d-flex" style={{ padding: "15px" }}>
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(newPage) => setCurrentPage(newPage)}
                          />
                          <div className="ml-3">
                            <select
                              className="form-control pagination-select float-right"
                              id="itemsPerPageSelect"
                              value={itemsPerPage}
                              onChange={(e) =>
                                setItemsPerPage(parseInt(e.target.value, 10))
                              }
                            >
                              {[4, 7, 10, 15, 20].map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
