import { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

export default class Navbar extends Component {
  render() {
    return (
      // <div>

      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" className="brand-link text-center">
          <span className="brand-text font-weight-light">
            Plateforme Kastelo <br /> BY IWARE
          </span>
        </a>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image"></div>
            <div className="info">
              <a href="/" className="d-block">
                Admin Kastelo
              </a>
            </div>
          </div>
          <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
              <input
                className="form-control form-control-sidebar"
                type="search"
                placeholder="Recherche"
                aria-label="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-sidebar">
                  <i className="fas fa-search fa-fw" />
                </button>
              </div>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* <li className="nav-item ">
                <a className="nav-link ">
                  <i className="nav-icon fas fa-star" />
                  <p>
                    Les marques
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allBrands" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les marques </p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link">
                  <i class="fas fa-thin fa-layer-group"></i>{" "}
                  <p>
                    Secteur d'activité
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/AddActivite" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter activité </p>
                    </Link>
                    <Link to="/allActivite" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les activités </p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link">
                  <i class="fas fa-solid fa-users"></i>{" "}
                  <p>
                    Multivendeur
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allVendeurs" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les multiVendeurs </p>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item ">
                <a className="nav-link">
                  <i className="nav-icon fas fa-shopping-bag" />
                  <p>
                    Familles
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addFamilyProduct" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter famille </p>
                    </Link>
                    <Link to="/allFamilyProducts" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les familles </p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link">
                  <i className="nav-icon fas fa-shopping-bag" />
                  <p>
                    Sous-Familles
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addSubFamilyProduct" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter sous-famille </p>
                    </Link>
                    <Link to="/allSubFamilyProducts" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les sous-familles </p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item ">
                <a className="nav-link ">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Les catégories
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addCategory" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter catégorie </p>
                    </Link>
                    <Link to="/allCategories" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les catégorie </p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link">
                  <i class="fas fa-thin fa-layer-group"></i>{" "}
                  <p>
                    Nouveautes
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allNouveauteProduit" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Nouveautes page </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addNouveauteProduit" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter Nouveautes </p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item ">
                <a className="nav-link">
                  <i className="nav-icon fas fa-shopping-bag" />
                  <p>
                    Saisons
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addSaison" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter saison </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allSaisons" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>les Saisons </p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link">
                  <i className="nav-icon fas fa-shopping-bag" />
                  <p>
                    Produits
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addProduct" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter un produit </p>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/addProductsExcel" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter des produits </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/productByRef" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Produit par référence </p>
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link to="/allProducts" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Liste des produits</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/AllSyhncroCsvRead" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Sychnro data </p>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link">
                      <i className="nav-icon fas fa-shopping-bag" />
                      <p>
                        Les Couleurs
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/AllColorsVariant" className="nav-link ">
                          <i className="far fa-circle nav-icon" />
                          <p>Les Couleurs </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/addColor" className="nav-link ">
                          <i className="far fa-circle nav-icon" />
                          <p>Ajouter une couleur </p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/addSize" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter Size </p>
                    </Link>
                  </li>
                </ul>
              </li>

              {/* <li className="nav-item ">
                <a  className="nav-link ">
                  <i className="nav-icon fas fa-solid fa-paper-plane" />
                  <p>
                    Les tags
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addTags" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter tag </p>
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* <li className="nav-item ">
                <a  className="nav-link ">
                  <i class="nav-icon fas fa-solid fa-award" />
                  <p>
                    Bon Plan
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allBonPlans" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les bon plans </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/addBonPlan" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter bon plan </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/addBonPlanProducts" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter bon plan Produit</p>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item ">
                <a className="nav-link ">
                  <i class="nav-icon fas fa-solid fa-bullhorn" />
                  <p>
                    Promotion
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allPromotions" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les promotions </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allDeclencheurs" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les declencheurs </p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/addSoldes" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Ajouter Solde </p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link">
                  <i class="fas fa fa-bolt"></i>{" "}
                  <p>
                    Ventes flash
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allVentesFlash" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Les ventes flash </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/addVentesFlash" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>add ventes flash </p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link ">
                  <i className="nav-icon fas fa-solid fa-dolly" />
                  <p>
                    Livraison
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allLivraisons" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Livraison</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="allCommandes">
                  <i className="nav-icon fas fa-shopping-cart" />
                  <p>
                    Les commandes
                    {/* <i className="right fas fa-angle-left" /> */}
                  </p>
                </a>
                {/* <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allCommandes" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Toutes les commandes </p>
                    </Link>
                  </li>
                </ul> */}
                {/* <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/newCommandes" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Nouvelles  </p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/preparationCommandes" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>En Cours de préparation</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/preparedCommandes" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Préparées et en attente de livraison</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/livraisonCommande" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>En cours de livraison</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/livredCommande" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Livrées</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/reservedCommande" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Réservées</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/annuleCommande" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Annulées</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/returnedCommande" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Retournées</p>
                    </Link>
                  </li>
                </ul> */}
              </li>
              {/* 
              <li className="nav-item ">
                <a  className="nav-link ">
                  <i className="nav-icon fas fa-user" />
                  <p>
                    Clients
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allClients" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Clients</p>
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item ">
                <a className="nav-link ">
                  <i className="nav-icon fas fa-columns" />
                  <p>
                    Bannières
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>

                <ul className="nav nav-treeview">
                  <li className="nav-item ">
                    <div className="nav-link text-center">
                      <p>--- Page Home ---</p>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/allCarrousselHome" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Carrousels One</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allBanniereCategoryHome" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Banniere category</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allBanniereTotalLookHome" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Banniere Total Look</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allBanniereThematiqueHome" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Banniere Thematique</p>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <div className="nav-link text-center">
                      <p>--- Page Nouveaute ---</p>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/allBanniereNouveaute" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Banniere Nouveaute</p>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <div className="nav-link text-center">
                      <p>--- Page Promotion ---</p>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/allBannierePromotion" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Banniere Promotion</p>
                    </Link>
                  </li>

                  <li className="nav-item ">
                    <div className="nav-link text-center">
                      <p>--- Page Vetements ---</p>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/allVetementsBanniere" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Banniere Vetements</p>
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Link to="/allCarrousselCategory" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Catégories Home</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allCarrousselPromotions" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Carroussel Promotions</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allBanniereTrending" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Bannière trending</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/allCarrousselOffre" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Carroussel offre</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/allMenuImage" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Menu Image</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allMenuElement" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Menu Element</p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/allMenuCategory" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Menu Category</p>
                    </Link>
                  </li> */}
                </ul>
              </li>

              <li className="nav-item ">
                <a className="nav-link ">
                  <i className="nav-icon fas fa-chalkboard-teacher" />
                  <p>
                    Front Office
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allTotalLook" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Total Look Page</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allSections" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Home Section</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allTopBar" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>TopBar</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allThematiquesPages" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p>Thematique Page</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item ">
                <a  className="nav-link">
                  <i class="fas fa-sharp fa-light fa-camera-retro"></i>{" "}
                  <p>
                    Les produits de jour
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/allProduitJour" className="nav-link ">
                      <i className="far fa-circle nav-icon" />
                      <p> produit de jour</p>
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item ">
                <a className="nav-link" href="page-contact">
                  <FontAwesomeIcon icon={faComment} /> <p>Contact Page</p>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}
