import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class SoldesServices {
  addSoldes(model, file) {
    const formData = new FormData();
    formData.append("soldesModel", JSON.stringify(model));
    formData.append("file", file);

    return api.post(urlsCommon.BASE_SOLDES_URL + "/addSoldes", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
export default new SoldesServices();
