import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class MenuCategoryService {
  addMenuCategory(typeElement, id) {
    return api.get(
      urlsCommon.BASE_MENU_CATEGORY_URL +
        "/addMenuCategory/" +
        typeElement +
        "/" +
        id
    );
  }

  allMenuCategory(isActive, archived) {
    return api.get(
      urlsCommon.BASE_MENU_CATEGORY_URL +
        "/allMenuCategory/" +
        isActive +
        "/" +
        archived
    );
  }

  numberMenuCategoryActiveAndArchived(isActive, archived) {
    return api.get(
      urlsCommon.BASE_MENU_CATEGORY_URL +
        "/numberMenuCategoryActiveAndArchived/" +
        isActive +
        "/" +
        archived
    );
  }

  allMenuCategoryArchived(archived) {
    return api.get(
      urlsCommon.BASE_MENU_CATEGORY_URL + "/allMenuCategoryArchived/" + archived
    );
  }

  getMenuCategoryById(idMenuCategory) {
    return api.get(
      urlsCommon.BASE_MENU_CATEGORY_URL +
        "/getMenuCategoryById/" +
        idMenuCategory
    );
  }
  archiveMenuCategory(idMenuCategory, isArchived) {
    return api.get(
      urlsCommon.BASE_MENU_CATEGORY_URL +
        "/archiveMenuCategory/" +
        idMenuCategory +
        "/" +
        isArchived
    );
  }
  activeMenuCategory(idMenuCategory, isActive) {
    return api.get(
      urlsCommon.BASE_MENU_CATEGORY_URL +
        "/activeMenuCategory/" +
        idMenuCategory +
        "/" +
        isActive
    );
  }
}
export default new MenuCategoryService();
