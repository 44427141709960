import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ContactService {
  getAllContacts() {
    return api.get(urlsCommon.BASE_CONTACT_URL + "/getAll");
  }
  getContactDetails(id) {
    return api.get(urlsCommon.BASE_CONTACT_URL + "/getDetails/" + id);
  }

  getNombreContacts() {
    return api.get(urlsCommon.BASE_CONTACT_URL + "/getNombreContacts");
  }
}
export default new ContactService();
