import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faRecycle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import NouveauteService from "src/services/NouveauteService";
import Pagination from "src/common/Pagination";
import ModalConfirmationChangeNouveaute from "./ModalConfirmationChangeNouveaute";

function AllNouveauteProduit() {
  const [nouveauteProduitList, setnouveauteProduitList] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [index, setIndex] = useState("");
  const [nouveauteProduit, setnouveauteProduit] = useState({});
  const [nbProducts, setNbProducts] = useState(0);

  const [isModalConfirmationProductOpen, setIsModalConfirmationProductOpen] =
    useState(false);

  useEffect(() => {
    getAllNouveauteProduit();
    getNombreProduitsNouveaute();
  }, [currentPage, itemsPerPage]);

  const getAllNouveauteProduit = () => {
    NouveauteService.getListNouveauteProducts(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setnouveauteProduitList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const openModalConfirmationProduct = (indexProduct, produit) => {
    setIsModalConfirmationProductOpen(true);
    if (indexProduct == "All_products") {
      setIndex("All_products");
    } else {
      setIndex(nouveauteProduitList[indexProduct]?.id);
      setnouveauteProduit(produit);
    }
  };
  const getNombreProduitsNouveaute = () => {
    NouveauteService.getNombreProduitsNouveaute()
      .then((response) => {
        if (response.data.success) {
          setNbProducts(response.data.success);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les Nouveautes Produits </b> : {nbProducts}
                    </h5>
                    <button
                      class="btn btn-dark float-right"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={() =>
                        openModalConfirmationProduct("All_products", "pas_name")
                      }
                      disabled={nouveauteProduitList.length == 0}
                    >
                      {nouveauteProduitList.length == 0 ? (
                        <>Pas de produits en nouveaute</>
                      ) : (
                        <>
                          Rendre tous les produits non nouveaux
                          <FontAwesomeIcon icon={faRecycle} className="ml-2" />
                        </>
                      )}
                    </button>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">
                        Les Nouveautes Produits disponibles
                      </h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th># Reference</th>
                            <th>Libellé</th>
                            <th>Libellé Site</th>
                            <th>Famille</th>
                            <th>Sous famille</th>
                            <th>Category</th>
                            <th className="text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nouveauteProduitList &&
                          nouveauteProduitList.length > 0 &&
                          Array.isArray(nouveauteProduitList) ? (
                            nouveauteProduitList.map(
                              (nouveauteProduit, index) => (
                                <tr key={index}>
                                  <td>
                                    <b>{nouveauteProduit?.codeArticle || ""}</b>
                                  </td>
                                  <td>{nouveauteProduit?.name || ""}</td>
                                  <td>
                                    {nouveauteProduit?.nameToFo || "vide"}
                                  </td>
                                  <td>{nouveauteProduit?.famille || ""}</td>
                                  <td>{nouveauteProduit?.sousFamille || ""}</td>
                                  <td>{nouveauteProduit?.category || ""}</td>

                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                      }}
                                    >
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                        onClick={() =>
                                          openModalConfirmationProduct(
                                            index,
                                            nouveauteProduit
                                          )
                                        }
                                      >
                                        Rendre Not New
                                        <FontAwesomeIcon
                                          icon={faEyeSlash}
                                          className="ml-2"
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <b>Pas de Nouveaux produits</b>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {nouveauteProduitList.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalConfirmationProductOpen && (
        <ModalConfirmationChangeNouveaute
          isModalConfirmationProductOpen={isModalConfirmationProductOpen}
          setIsModalConfirmationProductOpen={setIsModalConfirmationProductOpen}
          idProduct={index}
          nouveauteProduit={nouveauteProduit}
        />
      )}
    </>
  );
}

export default AllNouveauteProduit;
