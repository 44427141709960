import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faNoteSticky,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import ColorService from "src/services/ColorService";
import UpdateColorsVariant from "./UpdateColorsVariant";
import Pagination from "../../../common/Pagination.jsx";

function AllProductByColor(props) {
  const { colorSelected, isProductsColorList, setIsProductsColorList } = props;
  const [productsColorList, setProductsColorList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchValue]);

  const fetchData = () => {
    if (searchValue === "") {
      getAllProductsInColor();
    } else {
      searchProductInProductsOfColor();
    }
  };
  const searchProductInProductsOfColor = () => {
    ColorService.searchProductInProductsOfColor(
      currentPage - 1,
      itemsPerPage,
      colorSelected.id,
      searchValue
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setProductsColorList(response.data.success.content);
          console.log(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getAllProductsInColor = () => {
    ColorService.getAllProductsInColor(
      currentPage - 1,
      itemsPerPage,
      colorSelected.id
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setProductsColorList(response.data.success.content);
          console.log(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const clearSearchValue = () => {
    setSearchValue("");
    getAllProductsInColor();
  };
  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <ToastContainer />
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="card  mt-3">
                  <div className="card-body">
                    <div className="card-title">
                      <h5>
                        Les Produits de Ce couleur : {""}
                        {colorSelected.colorName +
                          " - " +
                          colorSelected.colorHexa || " "}
                      </h5>
                    </div>
                    <button
                      className="btn btn-dark float-right"
                      onClick={() => setIsProductsColorList(false)}
                    >
                      Retour
                    </button>
                  </div>
                </div>

                <>
                  <section className="content">
                    <div className="card">
                      <div className="card-header">
                        <div
                          className="card-title"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <div className="mr-2">Les produits disponibles :</div>
                          <b>{totalElements || 0} Produits</b>
                          <div>
                            <input
                              className="form-control mr-sm-2 ml-3"
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                              value={searchValue}
                              onChange={(event) =>
                                setSearchValue(event.target.value)
                              }
                            />
                            <i
                              className="icon-class"
                              onClick={clearSearchValue}
                            ></i>
                          </div>
                        </div>

                        <div className="card-tools">
                          <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                            title="Collapse"
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                        </div>
                      </div>

                      <div className="card-body p-0">
                        <table className="table  projects">
                          <thead>
                            <tr>
                              <th>Référence</th>
                              <th>Libellé</th>
                              <th>Famille</th>
                              <th>Sous famille</th>
                              <th>Category</th>
                              <th>Prix</th>
                              <th>% promotion</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productsColorList &&
                            productsColorList.length == 0 ? (
                              <>
                                {searchValue != "" ? (
                                  <>
                                    <td>ce produit n'existe pas</td>
                                  </>
                                ) : (
                                  <>
                                    <td>Pas de produits.</td>
                                  </>
                                )}
                              </>
                            ) : (
                              Array.isArray(productsColorList) &&
                              productsColorList.map((product, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{product?.codeArticle || ""} </td>
                                    <td>{product?.name || ""}</td>
                                    <td>{product?.famille?.name || ""}</td>
                                    <td>{product?.sousFamille?.name || ""}</td>
                                    <td>{product?.category?.name || ""}</td>
                                    <td>{product?.price || ""}</td>
                                    <td>
                                      {product?.sold ? product?.percent : ""}
                                    </td>
                                  </tr>
                                </>
                              ))
                            )}
                          </tbody>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right "
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </table>
                      </div>
                    </div>
                  </section>{" "}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllProductByColor;
