import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class CarrousselOffreHomeService {
  addCarrousselOffreHome(picture, carrousselOffreHomeModel) {
    return api.post(
      urlsCommon.BASE_CARROUSSEL_OFFRE_URL + "/addCarrousselOffreHome",
      picture,
      {
        params: {
          carrousselOffreHomeModel,
        },
      }
    );
  }
  allCarrousselOffreHome() {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_OFFRE_URL + "/allCarrousselOffreHome"
    );
  }
}
export default new CarrousselOffreHomeService();
