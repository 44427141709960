import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ShopLookService {
  
  getBanniereTotalLookById(id) {
    return api.get(
      urlsCommon.BASE_BANNIERE_TOTALlOOK_URL + "/getBanniereTotalLookById/" + id
    );
  }

  addShopLookHome(picture, shopLookHomeModel) {
    return api.post(
      urlsCommon.BASE_BANNIERE_TOTALlOOK_URL + "/addShopLookHome",
      picture,
      {
        params: {
          shopLookHomeModel,
        },
      }
    );
  }

  allShopLookHome() {
    return api.get(urlsCommon.BASE_BANNIERE_TOTALlOOK_URL + "/allShopLookHome");
  }

  visible(id, deed) {
    return api.get(
      urlsCommon.BASE_BANNIERE_TOTALlOOK_URL + "/visible/" + id + "/" + deed
    );
  }
  archive(id, deed) {
    return api.get(
      urlsCommon.BASE_BANNIERE_TOTALlOOK_URL + "/archiver/" + id + "/" + deed
    );
  }
}
export default new ShopLookService();
