import { useEffect, useState } from "react";
import discountService from "src/services/DiscountService";

const VoirPromotionCartDetails = ({ idPromotionCart }) => {
  console.log("idPromotionCart :" + idPromotionCart);
  const [promotionDetails, setPromotionDetails] = useState([]);

  useEffect(() => {
    getpromotionCartDetails(idPromotionCart);
  }, [idPromotionCart]);

  const getpromotionCartDetails = async (id) => {
    try {
      const response = await discountService.getpromotionCartDetails(id);
      if (response.data.success) {
        setPromotionDetails(response.data.success);
      }
      console.log("data", response.data.success);
    } catch (error) {
      console.log("response", error);
    }
  };
  return (
    <>
      <section class="content">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">
              promotion sur panier : {promotionDetails?.libelle || ""}
            </h3>

            <div class="card-tools">
              <button
                type="button"
                class="btn btn-tool"
                data-card-widget="collapse"
                title="Collapse"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <b>libelle :</b>
            <p> {promotionDetails?.libelle || ""}</p>
            <b>Description :</b>
            <br />
            <p> {promotionDetails?.description || ""}</p>

            {promotionDetails?.type == 1 &&
              promotionDetails?.typeConfigPromotion == 1 && (
                <>
                  <ul>
                    <li>
                      {" "}
                      <b>type de promotion :</b> promotion sur Nombre de
                      produits
                    </li>
                    <li>
                      {" "}
                      <b>Action de promotion :</b> Action sur la totalité des
                      produits
                    </li>
                  </ul>
                  <table class="table  projects">
                    <thead>
                      <tr>
                        <th>Nombre de produits </th>
                        <th>Montant de remise</th>
                        <th>Pourcentage de remise</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promotionDetails?.listRules &&
                      promotionDetails?.listRules.length > 0 &&
                      Array.isArray(promotionDetails?.listRules) ? (
                        <>
                          {promotionDetails?.listRules.map((element, index) => (
                            <tr>
                              <td>{element.nbrProducts || ""}</td>
                              <td>
                                {element?.amount?.toFixed(3) || ""}
                                {element?.amount ? "TND" : ""}
                              </td>
                              <td>
                                {element?.percent || ""}
                                {element?.percent ? "%" : ""}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr className="text-center">
                            <td colSpan="8">Pas de remise</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </>
              )}

            {promotionDetails?.type == 1 &&
              promotionDetails?.typeConfigPromotion == 2 && (
                <>
                  <ul>
                    <li>
                      {" "}
                      <b>type de promotion :</b> promotion sur Nombre de
                      produits
                    </li>
                    <li>
                      {" "}
                      <b>Action de promotion :</b> Action par produit
                    </li>
                  </ul>

                  <table class="table  projects">
                    <thead>
                      <tr>
                        <th>Ordre de produits </th>
                        <th>Montant de remise</th>
                        <th>Pourcentage de remise</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promotionDetails?.listRules &&
                      promotionDetails?.listRules.length > 0 &&
                      Array.isArray(promotionDetails?.listRules) ? (
                        <>
                          {promotionDetails?.listRules.map((element, index) => (
                            <tr>
                              <td>{element.ordreProduct || ""}</td>
                              <td>
                                {element?.amount?.toFixed(3) || ""}{" "}
                                {element?.amount ? "TND" : ""}
                              </td>
                              <td>
                                {element?.percent || ""}
                                {element?.percent ? "%" : ""}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr className="text-center">
                            <td colSpan="8">Pas de remise</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </>
              )}
            {promotionDetails?.type == 2 &&
              promotionDetails?.typeConfigPromotion == 3 && (
                <>
                  <ul>
                    <li>
                      <b>type de promotion :</b> promotion sur valeur panier
                    </li>
                  </ul>

                  <table class="table  projects">
                    <thead>
                      <tr>
                        <th>Minimum de valeur panier </th>
                        <th>Maximum de valeur panier </th>
                        <th>Montant de remise</th>
                        <th>Pourcentage de remise</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promotionDetails?.listRules &&
                      promotionDetails?.listRules.length > 0 &&
                      Array.isArray(promotionDetails?.listRules) ? (
                        <>
                          {promotionDetails?.listRules.map((element, index) => (
                            <tr>
                              <td>{element.valueMin || ""}</td>
                              <td>{element.valueMax || ""}</td>
                              <td>
                                {element?.amount?.toFixed(3) || ""}{" "}
                                {element?.amount ? "TND" : ""}
                              </td>
                              <td>
                                {element?.percent || ""}
                                {element?.percent ? "%" : ""}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr className="text-center">
                            <td colSpan="8">Pas de remise</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </>
              )}
          </div>
        </div>
      </section>
    </>
  );
};
export default VoirPromotionCartDetails;
