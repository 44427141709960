import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import makeAnimated from "react-select/animated";
import Select from "react-select";

import { Navigate } from "react-router";
import SaisonService from "../services/SaisonService";
export default class AddSaison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annee: "",
      selectedsaisonsPredefini: "",
      allsaisonsPredefini: [],
      options: [],
      redirect: false,
      visible: false,
    };
    this.changeAnnee = this.changeAnnee.bind(this);
    this.handleVisible = this.handleVisible.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    SaisonService.getAllSaisonsPredefini().then((response) => {
      console.log("response", response.data["code"]);
      if (response.data["code"] === "201") {
        this.setState({ allsaisonsPredefini: response.data["object"] });
        console.log("saisonsPredefini", this.state.allsaisonsPredefini);
        this.remplissageOptions();
      }
    });
  }
  remplissageOptions() {
    this.state.allsaisonsPredefini.map((saisonpre) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: saisonpre, label: saisonpre },
        ],
      })
    );
  }
  handleChangeList = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ selectedsaisonsPredefini: selectedOption.value });
    console.log(
      "selectedsaisonsPredefini",
      this.state.selectedsaisonsPredefini
    );
  };

  handleVisible(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }

  handleChange(event) {
    if (this.state.livrable) {
      this.setState({ livrable: false });
    } else {
      this.setState({ livrable: true });
    }

    console.log("livrable", this.state.livrable);
  }

  changeAnnee(event) {
    this.setState({ annee: event.target.value });
    console.log("annee", this.state.annee);
  }

  saveSaison = (e) => {
    e.preventDefault();

    let SaisonModel = {
      annee: this.state.annee,
      visible: this.state.visible,
      saison: this.state.selectedsaisonsPredefini,
    };
    if (SaisonModel.annee == "" && SaisonModel.saison == "") {
      toast.error("Veuillez remplir tous les champs !");
      return;
    }

    if (SaisonModel.annee == "") {
      toast.error("Veuillez remplir tous les champs !");
      return;
    }
    if (SaisonModel.saison == "") {
      toast.error("Veuillez Selectionner une saison !");
      return;
    }

    SaisonService.addSaison(SaisonModel)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la catégorie avec succée");
          this.setState({ annee: "" });
          this.setState({ selectedOption: "" });
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    const animatedComponents = makeAnimated();
    if (this.state.redirect) {
      return <Navigate replace to="/allSaisons" />;
    } else {
      return (
        <div class="content-wrapper">
          <ToastContainer />
          <section class="content-header">
            <div class="container-fluid">
              <div class="callout callout-success">
                <h5>Ajouter Saison</h5>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <section class="content">
                  <div class="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-outline card-success">
                          {/* <div className="card-header">
                            <h3 className="card-title">
                              <b>2-AJOUTER LE LIBELLE ET VALIDER</b>
                            </h3>
                          </div> */}

                          <form>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-2">
                                  <label>Année</label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Ajouter année"
                                    value={this.state.annee}
                                    onChange={this.changeAnnee}
                                  />
                                </div>
                              </div>
                              <label>Choisir la saison</label>
                              <Select
                                // id={"SelectMarque"}
                                value={this.state.selectedOption}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={this.state.options}
                                onChange={this.handleChangeList}
                                placeholder="Saisir..."
                              />

                              <div class="checkbox mt-3">
                                <label>
                                  <input
                                    type="checkbox"
                                    defaultChecked={this.state.visible}
                                    onChange={this.handleVisible}
                                  />{" "}
                                  Visible sur le site web
                                </label>
                              </div>
                            </div>

                            <div className="card-footer text-right">
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={this.saveSaison}
                              >
                                Valider
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}
