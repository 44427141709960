import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class BonPlanService {
  addBonPlan(picture, addBonPlanModel) {
    return api.post(urlsCommon.urlsCommon + "/addBonPlan", picture, {
      params: {
        addBonPlanModel,
      },
    });
  }
  addBonPlanProduct(idBonPlan, idProduct) {
    return api.get(
      urlsCommon.urlsCommon +
        "/addBonPlanProduct/" +
        idBonPlan +
        "/" +
        idProduct
    );
  }

  allBonPlanProducts(idBonPlan) {
    return api.get(urlsCommon.urlsCommon + "/allBonPlanProducts/" + idBonPlan);
  }

  allBonPlans() {
    return api.get(urlsCommon.urlsCommon + "/allBonPlans");
  }
  getBonPlanById(idBonPlan) {
    return api.get(urlsCommon.urlsCommon + "/getBonPlanById/" + idBonPlan);
  }
  archiveProduitBonPlanById(idProductBonPlan, deed) {
    return api.get(
      urlsCommon.urlsCommon +
        "/archiveProduitBonPlanById/" +
        idProductBonPlan +
        "/" +
        deed
    );
  }
  getProduitBonPlanById(idProductBonPlan) {
    return api.get(
      urlsCommon.urlsCommon + "/getProduitBonPlanById/" + idProductBonPlan
    );
  }
}
export default new BonPlanService();
