import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class LivraisonService {
  visible(id) {
    return api.put(urlsCommon.BASE_LIVRAISON_URL + "/visible/" + id);
  }
  archive(id) {
    return api.put(urlsCommon.BASE_LIVRAISON_URL + "/archiver/" + id);
  }

  updateLivraison(model) {
    return api.put(urlsCommon.BASE_LIVRAISON_URL + "/updateLivraison", model);
  }

  getAllLivraisons(page, size) {
    return api.get(urlsCommon.BASE_LIVRAISON_URL + "/getAllLivraison", {
      params: {
        page: page,
        size: size,
      },
    });
  }

  addLivraison(model) {
    return api.post(urlsCommon.BASE_LIVRAISON_URL + "/addLivraison", model);
  }
  addLivreur(picture, nameLivreur) {
    return api.post(urlsCommon.BASE_LIVRAISON_URL + "/addLivreur", picture, {
      params: {
        nameLivreur,
      },
    });
  }
  getAllLivreurs() {
    return api.get(urlsCommon.BASE_LIVRAISON_URL + "/getAllLivreurs");
  }
  addLivraisonCategory(livraison) {
    return api.post(
      urlsCommon.BASE_LIVRAISON_URL + "/addLivraisonCategory",
      livraison
    );
  }
}
export default new LivraisonService();
