import { Component } from "react";
import { Link } from "react-router-dom";
import CategoryService from "../../services/CategoryService";
import CategoryDelete from "../CategoryPopupModel/CategoryDelete";
import CategoryImage from "../CategoryPopupModel/CategoryImage";
import CategoryVisible from "../CategoryPopupModel/CategoryVisible";
import Pagination from "src/common/Pagination";
import CategoryUpdate from "../CategoryPopupModel/CategoryUpdate";

export default class AllCategories extends Component {
  state = {
    idCategory: "",
    categories: [],
    showFormImage: false,
    //pagination
    currentPage: 1,
    itemsPerPage: 7,
    totalPages: 1,
    totalElements: 0,
    sousFamilleCategory: "",
    searchValue: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      idCategory: "",
      categories: [],
      showForm: false,
      //pagination
      currentPage: 1,
      itemsPerPage: 7,
      totalPages: 1,
      totalElements: 0,
      sousFamilleCategory: "",
      searchValue: "",
    };
    // this.open = this.open.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    this.getAllCategories();
  }

  // filterDataOrFetch = () => {
  //   if (this.state.searchValue !== "") {
  //     this.searchCategorie();
  //   } else if (this.state.searchValue !== "") {
  //     this.setState({ searchValue: "" });
  //     this.getAllCategories();
  //   } else {
  //     this.getAllCategories();
  //   }
  // };

  clearSearchValue = (e) => {
    // e.preventDefault();
    console.log("clear");
    console.log(this.state.searchValue);
    this.setState({ searchValue: "" });
  };

  getAllCategories() {
    if (this.state.searchValue !== null) {
      CategoryService.searchCategorie(
        this.state.currentPage - 1,
        this.state.itemsPerPage,
        this.state.searchValue
      )
        .then((response) => {
          if (response.data.success) {
            this.setState({
              totalPages: response.data.success.totalPages,
              totalElements: response.data.success.totalElements,
              categories: response.data.success.content,
            });
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la requête API:", error);
        });
    } else {
      // this.setState({ searchValue: "" });
      CategoryService.getAllCategories(
        this.state.currentPage - 1,
        this.state.itemsPerPage
      )
        .then((response) => {
          if (response.data.success) {
            this.setState({
              totalPages: response.data.success.totalPages,
              totalElements: response.data.success.totalElements,
              categories: response.data.success.content,
            });
          } else {
            console.log("response", "error");
          }
        })
        .catch((error) => {
          console.log("response", "error");
        });
    }
  }

  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  buttonArchive(id, isArchived) {
    if (isArchived === 0) {
      return (
        <button
          className="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idCategory: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-trash"></i> Archiver
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idCategory: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-check"></i>Restaurer
        </button>
      );
    }
  }
  labelVisible(id, visible) {
    if (visible === 1) {
      return <label>Rendre Invisible</label>;
    } else {
      return <label>Rendre Visible</label>;
    }
  }

  buttonVisible(id, visible) {
    if (visible === 1) {
      return (
        <button
          className="btn btn-danger btn-sm float-center"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idCategory: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-recycle"></i>
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idCategory: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-recycle"></i>
        </button>
      );
    }
  }
  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les catégories</h5>
                <p className="float-right">
                  <button className="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/addCategory" }}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <i className="fas fa-plus" /> Ajouter
                    </Link>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="col-md-12">
          <section className="content">
            <div className="col-12">
              <div className="card">
                <div className="card-header"></div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <div>
                        <input
                          className="form-control mr-sm-2 ml-3 mt-3"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          value={this.state.searchValue}
                          onChange={(event) =>
                            this.setState(
                              { searchValue: event.target.value },
                              () => {
                                this.getAllCategories();
                              }
                            )
                          }
                        />
                        <i
                          className="icon-class"
                          onClick={this.clearSearchValue}
                        ></i>
                      </div>
                      <tr>
                        <th>Libellé</th>
                        <th>Famille</th>
                        <th>Sous Famille</th>
                        <th>Voir l'image</th>
                        <th>Modifier</th>
                        <th>Visibilité dans le site</th>
                        <th>Archiver</th>
                        <th>Voir les produits</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.categories.map((category, index) => (
                        <tr key={index}>
                          <td>{category?.name || ""}</td>
                          <td>{category?.famille || ""}</td>
                          <td>{category?.sousfamille?.name || ""}</td>

                          <td>
                            <button
                              className="btn btn-warning btn-sm"
                              data-toggle="modal"
                              data-target="#modal-Image"
                              onClick={() => {
                                this.setState({ idCategory: category.id });
                                this.showFormImage();
                              }}
                            >
                              <i className="fas fa-images"></i> Voir l'image
                            </button>
                            {this.state.showFormImage && (
                              <CategoryImage
                                idCategory={this.state.idCategory}
                              />
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-info btn-sm"
                              data-toggle="modal"
                              data-target="#modal-update"
                              onClick={() => {
                                this.setState({ idCategory: category.id });
                                this.setState({
                                  sousFamilleCategory: category.sousfamille,
                                });

                                this.showFormImage();
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i> Modifier
                            </button>
                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-update">
                                <CategoryUpdate
                                  idCategory={this.state.idCategory}
                                  sousFamilleCategory={
                                    this.state.sousFamilleCategory
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {this.labelVisible(category.id, category.visible)}
                            {this.buttonVisible(category.id, category.visible)}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-visible">
                                <CategoryVisible
                                  idCategory={this.state.idCategory}
                                />
                              </div>
                            )}
                          </td>

                          <td>
                            {this.buttonArchive(
                              category.id,
                              category.isArchived
                            )}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-default">
                                <CategoryDelete
                                  idCategory={this.state.idCategory}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "idCategory",
                                  category.id
                                );
                                this.showFormImage();
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/allProductByCategory",
                                }}
                                style={{ color: "white" }}
                              >
                                <i className="fas fa-folder"></i> Voir les
                                produits
                              </Link>
                            </button>
                          </td>
                          {/* <td>
                            <Link
                              to={{
                                pathname: "/allProductByCategory",
                              }}
                            >
                              <button className="btn btn-primary btn-sm">
                                <i className="fas fa-folder"></i> Voir les produits
                              </button>
                            </Link>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex" style={{ padding: "15px" }}>
                    <Pagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={(newPage) => {
                        this.setState({ currentPage: newPage }, () => {
                          this.getAllCategories();
                        });
                      }}
                    />

                    <div className="ml-3">
                      <select
                        className="form-control pagination-select float-right"
                        id="itemsPerPageSelect"
                        value={this.state.itemsPerPage}
                        onChange={(e) => {
                          this.setState(
                            {
                              itemsPerPage: parseInt(e.target.value, 10),
                            },
                            () => {
                              this.getAllCategories();
                            }
                          );
                        }}
                      >
                        {[4, 7, 10, 15, 20].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
