import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import LivraisonService from "src/services/LivraisonService";

function AddLivraison() {
  const [livraison, setlivraison] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setlivraison({ ...livraison, [name]: value });
  };

  const Addlivraison = (event) => {
    event.preventDefault();
    const LivraisonModel = {
      name: livraison.name,
      price: livraison.price,
      Montant_gratuit: livraison.Montant_gratuit,
    };

    LivraisonService.addLivraison(LivraisonModel)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setlivraison({});
          setTimeout(function () {
            window.location.href = "/allLivraisons";
          }, 1000);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Ajouter Livraison</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Libellé :</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control float-right"
                          placeholder="libellé"
                          name="name"
                          onChange={handleChange}
                          value={livraison.name || ""}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Price : (example : 8 dt)</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control float-right"
                          placeholder="Prix de livraison"
                          name="price"
                          onChange={handleChange}
                          value={livraison.price || ""}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Montant Gratuit : (example : 250 dt)</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control float-right"
                          placeholder="Montant gratuit à partir de .."
                          name="Montant_gratuit"
                          onChange={handleChange}
                          value={livraison.Montant_gratuit || ""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-default">
                  Annuler
                </button>
                <button
                  className="btn btn-secondary float-right"
                  onClick={Addlivraison}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddLivraison;
