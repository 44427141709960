class urlsCommon {
  // static ADRESS_Site = "http://localhost:3000/";
  static ADRESS_Site = "https://kastelo.com.tn/";

  static ADRESS = "ecommerce";
  static ADRESS_microservice_import_data = "importdata-fromExcel";
  static URLS = urlsCommon.ADRESS;
  static URLS_SITE = urlsCommon.ADRESS_Site;

  //cron manuelle
  static BASE_synchro_read_csv_URL =
    urlsCommon.ADRESS_microservice_import_data + "/ref/csv";

  //select options
  static BASE_SELECT_OPTION_URL = urlsCommon.URLS + "/selectOptions";

  //activity
  static BASE_ACTIVITE_URL = urlsCommon.URLS + "/activite";

  //bon plan
  static BASE_BON_PLAN_URL = urlsCommon.URLS + "/bonPlan";

  //brand
  static BASE_BRAND_URL = urlsCommon.URLS + "/brands";

  //************Home Page

  //menu top bar
  static BASE_TOP_BAR_URL = urlsCommon.URLS + "/topBar";

  //carrousel category
  static BASE_CARROUSSEL_CATEGORY_URL =
    urlsCommon.URLS + "/carrousselCategoryHome";

  //section home
  static BASE_SECTION_HOME_URL = urlsCommon.URLS + "/sectionHome";

  //caroussel
  static BASE_CARROUSSEL_URL = urlsCommon.URLS + "/carrousselHome";

  static BASE_CARROUSSEL_OFFRE_URL = urlsCommon.URLS + "/carrousselOffreHome";

  static BASE_CARROUSSEL_PROMOTION_URL =
    urlsCommon.URLS + "/carrousselPromotionHome";

  static BASE_CARROUSSEL_TRENDING_URL =
    urlsCommon.URLS + "/carrousselTrendingHome";

  //bannieres
  static BASE_BANNIERE_CATEGORY_HOME_URL =
    urlsCommon.URLS + "/categoryHomeBannnieres";

  //products
  static BASE_PRODUCT_URL = urlsCommon.URLS + "/product";

  //product images
  static BASE_PRODUCT_PICTURE_URL = urlsCommon.URLS + "/productPicture";

  //product de jour
  static BASE_PRODUCT_JOUR_URL = urlsCommon.URLS + "/produitJour";

  //***************variant***/
  //variant
  static BASE_VARIANT_URL = urlsCommon.URLS + "/variants";

  //color
  static BASE_COLOR_URL = urlsCommon.URLS + "/color";

  ///**end variant */

  //category
  static BASE_CATEGORY_URL = urlsCommon.URLS + "/category";

  //menu-category
  static BASE_MENU_CATEGORY_URL = urlsCommon.URLS + "/menuCategory";

  //commande
  static BASE_COMMANDE_URL = urlsCommon.URLS + "/commande";

  //soldes
  static BASE_SOLDES_URL = urlsCommon.URLS + "/soldes";
  //promotion
  static BASE_PROMOTION_URL = urlsCommon.URLS + "/promotion";

  //famille
  static BASE_FAMILY_URL = urlsCommon.URLS + "/familyProduct";

  //livraison
  static BASE_LIVRAISON_URL = urlsCommon.URLS + "/livraison";

  //element
  static BASE_MENU_ELEMENT_URL = urlsCommon.URLS + "/menuElement";

  //Image Menu
  static BASE_MENU_Image_URL = urlsCommon.URLS + "/menuImage";

  //multivendeur
  static BASE_MULTIVENDEUR_URL = urlsCommon.URLS + "/multivendeur";

  //saison
  static BASE_SAISON_URL = urlsCommon.URLS + "/saison";

  //totalLook
  static BASE_TOTALlOOK_URL = urlsCommon.URLS + "/totalLook";

  //banniere totalLook
  static BASE_BANNIERE_TOTALlOOK_URL = urlsCommon.URLS + "/shopLook";

  //thematique
  static BASE_THEMATIQUE_BANNIERE_URL = urlsCommon.URLS + "/shopThematique";

  //size
  static BASE_SIZE_URL = urlsCommon.URLS + "/size";

  //sous famille
  static BASE_SUB_FAMILY_URL = urlsCommon.URLS + "/subFamilyProduct";

  //tags
  static BASE_TAGS_URL = urlsCommon.URLS + "/tags";

  //thematique
  static BASE_THEMATIQUE_URL = urlsCommon.URLS + "/thematique";

  //***********bannieres*******************/
  //nouveaute
  static BASE_NOUVEAUTE_URL = urlsCommon.URLS + "/nouveaute";

  //promotion
  static BASE_BANNIERE_PROMOTION_URL = urlsCommon.URLS + "/promotion";

  //promotion
  static BASE_BANNIERE_VETEMENTES_URL = urlsCommon.URLS + "/vetements";

  //*********** end bannieres*******************/

  //vente flash
  static BASE_VENTE_FLASH_URL = urlsCommon.URLS + "/venteflash";

  static BASE_SYNCHRO_CSV_READ = urlsCommon.URLS + "/synchroCsv";

  static BASE_CONTACT_URL = urlsCommon.URLS + "/contactUs";

  static BASE_DASHBOARD_URL = urlsCommon.URLS + "/dashboard";
}

export default urlsCommon;
