import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import AddBanniereCategoryHomeBig from "./AddBanniereCategoryHomeBig";
import AddBanniereCategoryHomeSmall from "./AddBanniereCategoryHomeSmall";

export default class AddBanniereCategoryHome extends Component {
  render() {
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter les Categories de page Home</h5>
            </div>
          </div>
          <AddBanniereCategoryHomeBig />
          <AddBanniereCategoryHomeSmall />
        </section>
      </div>
    );
  }
}
