import { Component } from "react";
import CurrencyInput from "react-currency-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Files from "react-files";
import VetementsService from "../../services/VetementsBanniereService";
export default class AddVetementsBanniere extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      dateDebut: "",
      dateFin: "",
      visible: false,
      files: [],
    };
    this.changeTitle = this.changeTitle.bind(this);
    this.changeDateDebut = this.changeDateDebut.bind(this);
    this.changeDateFin = this.changeDateFin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addVetementsBanniere = this.addVetementsBanniere.bind(this);
  }

  handleChange(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }
  changeTitle(event) {
    this.setState({ title: event.target.value });
    console.log("title", this.state.title);
  }

  changeDateDebut(date) {
    this.setState({ dateDebut: date });
  }
  changeDateFin(date) {
    this.setState({ dateFin: date });
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log("onFilesChange", this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  addVetementsBanniere = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);
    let vetementsModel = {
      titre: this.state.title,
      // dateDebut: this.state.dateDebut,
      // dateFin: this.state.dateFin,
      visible: this.state.visible,
      // files: this.state.files[0],
    };

    VetementsService.addVetementsBanniere(
      dataFile,
      JSON.stringify(vetementsModel)
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la carroussel avec succée");
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter Banniere Page Vetements</h5>
              Deux étapes:
              <br /> 1-Ajouter le libellé
              <br /> 2-L'images de la caroussel (1520px * 148px)
            </div>
          </div>
        </section>

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline ">
            <div className="card-header">
              <h3 className="card-title">
                <b>Ajouter Banniere de la page Vetements</b>
              </h3>
            </div>

            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <label>Titre</label>

                    <input
                      type="title"
                      className="form-control"
                      placeholder="Ajouter Sous Titre"
                      value={this.state.title}
                      onChange={this.changeTitle}
                    />
                  </div>
                </div>
                {/* <div className="row">
                  <div class="col-md-6">
                    <label>Date Début </label>
                    <br />

                    <DatePicker
                      className="form-control"
                      selected={this.state.dateDebut}
                      onChange={this.changeDateDebut}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Date Fin</label>
                    <br />

                    <DatePicker
                      className="form-control"
                      selected={this.state.dateFin}
                      onChange={this.changeDateFin}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div> */}
                <br />
                <div className="col-md-12">
                  <div>
                    <Files
                      ref="files"
                      className="files-dropzone-list"
                      // style={{ height: "100px" }}
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      multiple
                      maxFiles={1}
                      maxFileSize={10000000}
                      minFileSize={0}
                      clickable
                    >
                      <label>Cliquer pour Ajouter une image</label>
                    </Files>

                    {this.state.files.length > 0 ? (
                      <div className="files-list">
                        <ul>
                          {this.state.files.map((file) => (
                            <li className="files-list-item" key={file.id}>
                              <div className="files-list-item-preview">
                                {file.preview.type === "image" ? (
                                  <div className="row">
                                    <img
                                      className="files-list-item-preview-image"
                                      src={file.preview.url}
                                      style={{
                                        width: "30%",
                                        height: "30%",
                                      }}
                                    />
                                    <button
                                      id={file.id}
                                      class="btn btn-navbar"
                                      type="button"
                                      // data-widget="navbar-search"
                                      onClick={this.filesRemoveOne.bind(
                                        this,
                                        file
                                      )}
                                    >
                                      <i class="fas fa-times"></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="files-list-item-preview-extension">
                                    {file.extension}
                                  </div>
                                )}
                              </div>
                              <div className="files-list-item-content">
                                <div className="files-list-item-content-item files-list-item-content-item-1">
                                  {file.name}
                                </div>
                                <div className="files-list-item-content-item files-list-item-content-item-2">
                                  {file.sizeReadable}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <h1></h1>
                    )}
                  </div>
                </div>

                <div className="col-md-12 ">
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={this.state.visible}
                        onChange={this.handleChange}
                      />{" "}
                      Rendre visible dans le site
                    </label>
                  </div>
                </div>
              </div>
            </form>
            {/* </div> */}
            <br />
            <div className="card-footer">
              <button
                type="submit"
                className="btn btn-success float-right"
                onClick={this.addVetementsBanniere}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
