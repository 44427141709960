import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class FamilyProductService {
  saveFamilyProduct(picture, livrable, nameFamilyProduct, visible, idBrand) {
    return api.post(
      urlsCommon.BASE_FAMILY_URL + "/addFamilyProduct/" + idBrand,
      picture,
      {
        params: {
          nameFamilyProduct,
          livrable,
          visible,
        },
      }
    );
  }
  getAllFamilyProducts() {
    return api.get(urlsCommon.BASE_FAMILY_URL + "/getAllFamilyProducts");
  }

  getFamilyById(idFamilyProduct) {
    return api.get(
      urlsCommon.BASE_FAMILY_URL + "/getFamilyProductsById/" + idFamilyProduct
    );
  }
  updatePicture(picture, idFamilyProduct) {
    return api.put(
      urlsCommon.BASE_FAMILY_URL + "/updateFamilyProductPicture",
      picture,
      {
        params: {
          idFamilyProduct,
        },
      }
    );
  }

  updateFamilyProductName(idFamilyProduct, name) {
    return api.put(
      urlsCommon.BASE_FAMILY_URL +
        "/updateFamilyProductName/" +
        idFamilyProduct +
        "/" +
        name
    );
  }
  visibleFamily(idFamilyProduct, deed) {
    return api.get(
      urlsCommon.BASE_FAMILY_URL +
        "/visibleFamilyProduct/" +
        idFamilyProduct +
        "/" +
        deed
    );
  }
  archiveFamily(idFamilyProduct, deed) {
    return api.get(
      urlsCommon.BASE_FAMILY_URL +
        "/archiveFamilyProduct/" +
        idFamilyProduct +
        "/" +
        deed
    );
  }
}
export default new FamilyProductService();
