import { Oval } from "react-loader-spinner";

const ProgressBar = () => {
  return (
    <div
      className="preloader flex-column justify-content-center align-items-center"
      style={{ opacity: 0.5 }}
    >
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#000000"
        secondaryColor="#968F8E"
        ariaLabel="tail-spin-loading"
        radius="1"
      />
    </div>
  );
};
export default ProgressBar;
