import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class MenuElementService {
  addMenuElement(nameElement, typeElement) {
    //{ params: {
    //   nameElement,
    //   typeElement,
    // },}
    return api.get(
      urlsCommon.BASE_MENU_URL +
        "/addMenuElement/" +
        nameElement +
        "/" +
        typeElement
    );
    // return api.get(BASE_BRAND_URL+"/getBrandsById/"+1);
  }
  // addMenuElement(nameElement, typeElement) {
  //   console.log("in menu service", nameElement + typeElement);
  //   return api.post(urlsCommon.BASE_MENU_URL + "/addMenuElement", {
  //     params: { nameElement, typeElement },
  //   });
  // }
  addMenuElementFamily(picture, menuElementModel) {
    return api.post(
      urlsCommon.BASE_MENU_URL + "/addMenuElementFamily",
      picture,
      {
        params: {
          menuElementModel,
        },
      }
    );
  }
  allMenuImage(isActive, archived) {
    return api.get(
      urlsCommon.BASE_MENU_URL + "/allMenuElement/" + isActive + "/" + archived
    );
  }

  numberMenuElementActiveAndArchived(isActive, archived) {
    return api.get(
      urlsCommon.BASE_MENU_URL +
        "/numberMenuElementActiveAndArchived/" +
        isActive +
        "/" +
        archived
    );
  }

  allMenuImageArchived(archived) {
    return api.get(
      urlsCommon.BASE_MENU_URL + "/allMenuImageArchived/" + archived
    );
  }

  getMenuElementById(idMenuElement) {
    return api.get(
      urlsCommon.BASE_MENU_URL + "/getMenuElementById/" + idMenuElement
    );
  }
  archiveMenuElement(idMenuElement, isArchived) {
    return api.get(
      urlsCommon.BASE_MENU_URL +
        "/archiveMenuElement/" +
        idMenuElement +
        "/" +
        isArchived
    );
  }
  activeMenuElement(idMenuElement, isActive) {
    return api.get(
      urlsCommon.BASE_MENU_URL +
        "/activeMenuElement/" +
        idMenuElement +
        "/" +
        isActive
    );
  }
}
export default new MenuElementService();
