import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class PromotionBanniereService {
  addPromotionBanniere(picture, promotionBanniereModel) {
    return api.post(
      urlsCommon.BASE_BANNIERE_PROMOTION_URL + "/addPromotion",
      picture,
      {
        params: {
          promotionBanniereModel,
        },
      }
    );
  }
  allPromotionBanniere() {
    return api.get(urlsCommon.BASE_BANNIERE_PROMOTION_URL + "/allPromotion");
  }

  getPromotionBanniereById(id) {
    return api.get(
      urlsCommon.BASE_BANNIERE_PROMOTION_URL + "/getPromotionBanniereById/" + id
    );
  }
  visible(id, deed) {
    return api.get(
      urlsCommon.BASE_BANNIERE_PROMOTION_URL + "/visible/" + id + "/" + deed
    );
  }
  archive(id, deed) {
    return api.get(
      urlsCommon.BASE_BANNIERE_PROMOTION_URL + "/archiver/" + id + "/" + deed
    );
  }
}
export default new PromotionBanniereService();
