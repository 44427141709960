import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import CurrencyInput from "react-currency-input";
import DiscountService from "../services/DiscountService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import getCurrentUserId from "src/common/currentUserId";
import { Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};
const UpdateDiscountPanier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState();
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [successAlertText, setSuccessAlertText] = useState();
  const [type, setType] = useState(1);
  const [typeProduit, setTypeProduit] = useState(0);
  const [extrats, setExtrats] = useState([]);
  const [valueMin, setValueMin] = useState(0);
  const [ordreProduct, setOrdreProduct] = useState(0);
  const [valueMax, setValueMax] = useState(0);
  const [amount, setAmount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [nbrProducts, setNbrProducts] = useState(0);
  const [promotionDetails, setPromotionDetails] = useState([]);

  useEffect(() => {
    getpromotionCartDetails(localStorage.getItem("idPromotionToUpdate"));
  }, []);
  const getpromotionCartDetails = (id) => {
    DiscountService.getpromotionCartDetails(id)
      .then((response) => {
        if (response.data.success) {
          setPromotionDetails(response.data.success);
          setType(response.data.success.type);
          setTypeProduit(response.data.success.typeConfigPromotion);
          setExtrats(response.data.success.listRules);
          setOrdreProduct(
            response.data.success.listRules[
              response.data.success.listRules.length - 1
            ].ordreProduct + 1
          );
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const handleEroorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessAlert(false);
  };

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
  };
  const onChangeDescriptionHandler = (event) => {
    setDescription(event.target.value);
  };

  const onValueChange = (event) => {
    if (event.target.value === "1") {
      setType(1);
    } else if (event.target.value === "2") {
      setType(2);
    }
  };
  const onValueChangeTypeProduit = (event) => {
    if (event.target.value === "1") {
      setTypeProduit(1);
    } else if (event.target.value === "2") {
      setTypeProduit(2);
    }
  };
  const changeValeurMin = (event) => {
    setValueMin(event.target.value);
  };
  const changeValeurMax = (event) => {
    setValueMax(event.target.value);
  };

  function createUI() {
    return (
      <div class="row">
        <div class="col-md-2">
          <label>Min </label>
          <div className="input-group">
            <CurrencyInput
              id="montantDevise"
              className="form-control"
              precision="3"
              value={valueMin}
              onChangeEvent={changeValeurMin}
            />
          </div>
        </div>
        <div class="col-md-2">
          <label>Max </label>
          <div className="input-group">
            <CurrencyInput
              id="montantDevise"
              className="form-control"
              precision="3"
              value={valueMax}
              onChangeEvent={changeValeurMax}
            />
          </div>
        </div>

        <div className="col-sm-3">
          <div className="input-group">
            <label>Pourcentage Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="percent"
                className="form-control"
                precision="3"
                value={percent}
                onChangeEvent={onChangePercentHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-solid fa-percent"></i>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-1">
          <div className="input-group">
            <label>Ou</label>{" "}
          </div>
        </div>
        <div className="col-sm-3">
          <div className="input-group">
            <label>Montant Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="amount"
                className="form-control"
                precision="3"
                value={amount}
                onChangeEvent={onChangeAmountHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-dollar-sign"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const onChangePercentHandler = (event) => {
    setPercent(event.target.value);
  };
  const onChangeAmountHandler = (event) => {
    setAmount(event.target.value);
  };
  function createUIProducts() {
    return (
      <div class="row">
        <div className="col-sm-3">
          <label>Choisir le nombre de produits</label>
          <input
            onChange={onChangeNbrProductsHandler}
            value={nbrProducts}
            type="number"
            min="0"
            id="nbrProducts"
            className="form-control"
          />
        </div>

        <div className="col-sm-4">
          <div className="input-group">
            <label>Pourcentage Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="percent"
                className="form-control"
                precision="3"
                value={percent}
                onChangeEvent={onChangePercentHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-solid fa-percent"></i>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-1">
          <div className="input-group">
            <label>Ou</label>{" "}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="input-group">
            <label>Montant Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "80%" }}>
              <CurrencyInput
                id="amount"
                className="form-control"
                precision="3"
                value={amount}
                onChangeEvent={onChangeAmountHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-dollar-sign"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function createUIParProducts() {
    return (
      <div class="row">
        <div className="col-sm-3">
          <label>L'ordre des produits</label>
          <br />
          <label>{ordreProduct}</label>
        </div>

        <div className="col-sm-4">
          <div className="input-group">
            <label>Pourcentage Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "85%" }}>
              <CurrencyInput
                id="percent"
                className="form-control"
                precision="3"
                value={percent}
                onChangeEvent={onChangePercentHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-solid fa-percent"></i>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-1">
          <div className="input-group">
            <label>Ou</label>{" "}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="input-group">
            <label>Montant Promotion</label>{" "}
            <div className="input-group-prepend" style={{ width: "85%" }}>
              <CurrencyInput
                id="amount"
                className="form-control"
                precision="3"
                value={amount}
                onChangeEvent={onChangeAmountHandler}
              />
            </div>
            <div className=" input-group-append">
              <button type="button" class="btn btn-dark">
                {" "}
                <i class="fas fa-dollar-sign"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function addClick() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        valueMin: valueMin,
        valueMax: valueMax,
        amount: amount,
        percent: percent,
      },
    ]);
    setAmount(0);
    setPercent(0);
    setValueMin(0);
    setValueMax(0);
  }
  function addClickProduct() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        nbrProducts: nbrProducts,
        amount: amount,
        percent: percent,
      },
    ]);
    setPercent(0);
    setAmount(0);
    setNbrProducts(0);
  }
  function addClickParProduct() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        ordreProduct: ordreProduct,
        amount: amount,
        percent: percent,
      },
    ]);
    setOrdreProduct(ordreProduct + 1);
    setPercent(0);
    setAmount(0);
  }
  const removeExtrat = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
    }
  };
  const removeExtratProduct = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
    }
  };
  const removeExtratParProduct = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
      setOrdreProduct(ordreProduct - 1);
    }
  };
  function addExtratToView() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["valueMin"] === "" &&
      extrats[0]["valueMax"] === "" &&
      extrats[0]["amount"] === "" &&
      extrats[0]["percent"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>Valeur Min</td>
              <td>Valeur Max</td>
              <td>pourcentage Remise</td>
              <td>Montant Remise</td>
              <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.valueMin}</td>
                  <td>{extrat.valueMax}</td>
                  <td>{extrat.percent}</td>
                  <td>{extrat.amount}</td>

                  <td>
                    <button
                      type="button"
                      class="btn btn-danger float-right"
                      onClick={() => removeExtrat(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  function addExtratToViewProduct() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["amount"] === "" &&
      extrats[0]["percent"] === "" &&
      extrats[0]["amount"] === "" &&
      extrats[0]["nbrProducts"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>Nombre d'articles</td>
              <td>Promotion Pourcentage</td>
              <td>Promotion Montant</td>
              <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.nbrProducts}</td>
                  <td>{extrat.percent}</td>
                  <td>{extrat.amount}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={() => removeExtratProduct(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  function addExtratToViewParProduct() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["ordreProduct"] === "" &&
      extrats[0]["amount"] === "" &&
      extrats[0]["percent"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>L'article le moin cher</td>
              <td>Promotion Pourcentage</td>
              <td>Promotion Montant</td> <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.ordreProduct}</td>
                  <td>{extrat.percent}</td>
                  <td>{extrat.amount}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={() => removeExtratParProduct(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  const onChangeNbrProductsHandler = (event) => {
    setNbrProducts(event.target.value);
  };
  const actionProductsTable = () => {
    if (typeProduit === 1) {
      return <>{productsTable()}</>;
    } else if (typeProduit === 2) {
      return <>{parProductsTable()}</>;
    }
  };
  const parProductsTable = () => {
    return (
      <>
        {createUIParProducts()}
        <br />
        <label></label>
        <button
          style={{ height: "40 px", width: "40 px" }}
          type="button"
          className="btn btn-dark float-right"
          onClick={addClickParProduct}
        >
          <i class="fa fa-plus"></i>
        </button>
        {addExtratToViewParProduct()}
      </>
    );
  };

  const productsTable = () => {
    return (
      <>
        {createUIProducts()}
        <br />
        <label></label>
        <button
          style={{ height: "40 px", width: "40 px" }}
          type="button"
          className="btn btn-dark float-right"
          onClick={addClickProduct}
        >
          <i class="fa fa-plus"></i>
        </button>
        {addExtratToViewProduct()}
      </>
    );
  };
  const typePromotionCart = () => {
    if (type === 1) {
      return (
        <div>
          <br />
          <div class="form-group  clearfix">
            <div class="icheck-asbestos d-inline">
              <input
                id="totaliteproduit"
                type="radio"
                name="totaliteproduit"
                value="1"
                checked={typeProduit === 1}
                onChange={onValueChangeTypeProduit}
              />
              <label for="totaliteproduit">
                Action sur la totalité des produits{" "}
              </label>
            </div>

            <div class="icheck-asbestos d-inline">
              <input
                id="produit"
                type="radio"
                name="produit"
                value="2"
                checked={typeProduit === 2}
                onChange={onValueChangeTypeProduit}
              />

              <label for="produit">Action par produit</label>
            </div>
          </div>
          {actionProductsTable()}
        </div>
      );
    } else if (type === 2) {
      return (
        <div className="form-group">
          <label>La valeur du panier</label>
          {createUI()}
          <br />
          <button
            type="button"
            class="btn btn-dark float-right"
            onClick={addClick}
          >
            <i class="fa fa-plus"></i>
          </button>
          {addExtratToView()}
        </div>
      );
    }
  };
  const onValidHandler = () => {
    // if (name == null) {
    //   setOpenErrorAlert(true);
    //   setErrorAlertText("Erreur :le nom est vide ! ");
    // } else if (description == null) {
    //   setOpenErrorAlert(true);
    //   setErrorAlertText("Erreur :la description est vide ! ");
    // }

    let promotionCartAddModel = {
      idPromotion: localStorage.getItem("idPromotionToUpdate"),
      libelle: name,
      description: description,
      idUser: getCurrentUserId(),
      type: type,
      listRules: extrats,
      typeConfigPromotion: typeProduit,
    };
    console.log("promotionCartAddModel :",promotionCartAddModel)
        DiscountService.updatePromotionCart(promotionCartAddModel).then(
          (result) => {
            if (result.data.success) {
              setOpenSuccessAlert(true);
              setSuccessAlertText("Enregistrement avec succées");
              setTimeout(() => {
                navigate(`/allPromotions`);
              }, 2000);
            } else {
              setOpenErrorAlert(true);
              setErrorAlertText("Erreur :erreur serveur ");
            }
          }
        );
  };
  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active">Modifier promotion</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content ">
        <div className="container-fluid">
          <div className="row d-flex justify-content-cente">
            <div className="col">
              <Button
                className="my-custom-class-btn-style  mr-2 "
                variant="secondary"
                // disabled={panierClicked}
                // onClick={handleClickPanier}
              >
                <i class="fas fa-shopping-cart"></i> Régle sur Panier
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="content" style={{ marginTop: "1%" }}>
        <div className="container-fluid">
          <div className="timeline">
            <div className="time-label">
              <span style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>Règle Sur Panier</span>
              </span>
            </div>
            <div>
              <i className="fas fa-1 " style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>1</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 12:05
                </span>
                <h3 className="timeline-header">
                  <b>Informations Générales</b>
                </h3>
                <div className="timeline-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="inputName">Libellé</label>
                        <input
                          value={name || promotionDetails?.libelle}
                          onChange={onChangeNameHandler}
                          type="text"
                          id="inputName"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <textarea
                          value={description || promotionDetails?.description}
                          placeholder="Saisir..."
                          onChange={onChangeDescriptionHandler}
                          id="inputDescription"
                          className="form-control"
                          rows={4}
                          defaultValue={""}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <i className="fas fa-3 " style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>3</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 5 mins ago
                </span>
                <h3 className="timeline-header no-border">
                  <b>Choisir le type de la promotion</b>{" "}
                </h3>
                <div className="timeline-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <label>Promotion sur : </label>{" "}
                      <div class="form-group  clearfix">
                        <div class="icheck-asbestos d-inline">
                          <input
                            id="brand"
                            type="radio"
                            name="Marque"
                            value="1"
                            checked={type === 1}
                            onChange={onValueChange}
                          />
                          <label for="brand">Nombre de produits </label>{" "}
                        </div>

                        <div class="icheck-asbestos d-inline">
                          <input
                            id="familly"
                            type="radio"
                            name="Famille"
                            value="2"
                            checked={type === 2}
                            onChange={onValueChange}
                          />

                          <label for="familly">Valeur Panier</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <i className="fas fa-4" style={{ backgroundColor: "black" }}>
                <span style={{ color: "white" }}>4</span>
              </i>
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> 27 mins ago
                </span>
                <h3 className="timeline-header">
                  <b> Configurer Promotion</b>
                </h3>
                <div className="timeline-body">
                  <div className="row">
                    <div className="col-sm-12"> {typePromotionCart()}</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                class="btn btn-dark"
                style={{ marginTop: "1%" }}
                onClick={onValidHandler}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
        <Snackbar
          open={openErrorAlert}
          autoHideDuration={2000}
          onClose={handleEroorClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert variant="filled" onClose={handleEroorClose} severity="error">
            {errorAlertText}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openSuccessAlert}
          autoHideDuration={2000}
          onClose={handleSuccessClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            variant="filled"
            onClose={handleSuccessClose}
            severity="success"
          >
            {successAlertText}
          </Alert>
        </Snackbar>
      </section>
    </div>
  );
};
export default UpdateDiscountPanier;
