import { Component } from "react";
import Files from "react-files";

import { ToastContainer, toast } from "react-toastify";

import ProductService from "../services/ProductService";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import CategoryService from "../services/CategoryService";
import BrandService from "../services/BrandService";
export default class AddProductsExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      listCategory: [],
      idCategory: [],

      brands: [],
      categories: [],
      options: [],
      optionCategories: [],
      selectedOption: null,
      selectedOptionCategory: null,

      idCategorie: [],
      idBrandss: [],
    };
  }
  componentDidMount() {
    this.forceUpdate();
    BrandService.getAllBrand()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ brands: response.data["object"] });
          console.log("brands", this.state.brands);
          // this.setState({ alert_message: "success" });
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });

    CategoryService.getAllCategories()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ categories: response.data["object"] });
          console.log("categories", this.state.categories);
          this.remplissageOptionsCategory();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  remplissageOptions() {
    this.state.brands.map((brand) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: brand.id, label: brand.name },
        ],
      })
    );
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ idBrand: selectedOption.value });
    console.log("idBrand", this.state.idBrand);
  };

  //Select Category
  remplissageOptionsCategory() {
    this.state.categories.map((cat) =>
      this.setState({
        optionCategories: [
          ...this.state.optionCategories,
          { value: cat.id, label: cat.name },
        ],
      })
    );
  }
  handleChangeCategory = (selectedOptionCategory) => {
    this.setState({ listCategory: this.state.selectedOptionCategory });
    this.setState({ selectedOptionCategory }, () =>
      console.log(`Option selected:`, this.state.selectedOptionCategory)
    );

    console.log(`Option selected:`, this.state.selectedOptionCategory);
    console.log(`Option selected2:`, this.state.selectedOptionCategory);
  };
  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  filesUpload = () => {
    const formData = new FormData();
    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      formData.append(
        "pictures",
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });
    let idProduct = this.state.idProd;
    ProductService.addBigPictures(formData, idProduct)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout des grandes images avec succée");
        } else {
          toast.error(response.data["object"]);
        }
      })
      .catch((err) => {
        toast.error("une erreur a survenue");
      });
  };

  saveProducts = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);

    toast.success("Ajout des produits avec succée");
    // BrandService.addBrand(dataFile, this.state.libelle)
    //   .then((response) => {
    //     if (response.data["code"] === "201") {
    //       toast.success("Ajout de la marque avec succée");
    //     } else if (response.data["code"] === "400") {
    //       toast.error(response.data["object"]);
    //     }

    //     console.log("response", response);
    //   })
    //   .catch((error) => {
    //     toast.error("une erreur a survenue");
    //   });
  };
  render() {
    const animatedComponents = makeAnimated();
    const animatedComponentsCategory = makeAnimated();
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter Les produits </h5>
            </div>
          </div>
        </section>

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline ">
            <div class="card-header">
              <h3 class="card-title">
                {" "}
                <b>Ajouter le fichier qui contient les produits à Ajouter</b>
              </h3>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div class="form-group">
                      <label>Choisir la marque</label>
                      <Select
                        id={"SelectMarque"}
                        value={this.state.selectedOption}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={this.state.options}
                        onChange={this.handleChange}
                        placeholder="Choisir..."
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div class="form-group">
                      <label>Choisir la catégorie</label>
                      <Select
                        id={"SelectCategory"}
                        isMulti
                        value={this.state.selectedOptionCategory}
                        closeMenuOnSelect={false}
                        components={animatedComponentsCategory}
                        options={this.state.optionCategories}
                        onChange={this.handleChangeCategory}
                        placeholder="Choisir..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <Files
                    ref="files"
                    className="files-dropzone-list"
                    // style={{ height: "100px" }}
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    multiple
                    maxFiles={1}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <label>Cliquer pour Ajouter le fichier</label>
                  </Files>

                  {this.state.files.length > 0 ? (
                    <div className="files-list">
                      <ul>
                        {this.state.files.map((file) => (
                          <li className="files-list-item" key={file.id}>
                            <div className="files-list-item-preview">
                              {file.preview.type === "image" ? (
                                <div className="row">
                                  <img
                                    className="files-list-item-preview-image"
                                    src={file.preview.url}
                                    style={{
                                      width: "700px",
                                      height: "300px",
                                    }}
                                  />
                                  <button
                                    id={file.id}
                                    class="btn btn-navbar"
                                    type="button"
                                    // data-widget="navbar-search"
                                    onClick={this.filesRemoveOne.bind(
                                      this,
                                      file
                                    )}
                                  >
                                    <i class="fas fa-times"></i>
                                  </button>
                                </div>
                              ) : (
                                <div className="files-list-item-preview-extension">
                                  {file.extension}
                                </div>
                              )}
                            </div>
                            <div className="files-list-item-content">
                              <div className="files-list-item-content-item files-list-item-content-item-1">
                                {file.name}
                              </div>
                              <div className="files-list-item-content-item files-list-item-content-item-2">
                                {file.sizeReadable}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <h1></h1>
                  )}
                </div>

                {/* </div> */}
                <br />
              </form>
            </div>
            <div className="card-footer">
              <button
                type="submit"
                className="btn btn-success float-right"
                onClick={this.saveProducts}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
