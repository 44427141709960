import { Component } from "react";
import Files from "react-files";

import makeAnimated from "react-select/animated";
import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProductService from "../../services/ProductService";
import FamilyProductService from "../../services/FamilyProductService";
import MenuImageService from "../../services/MenuImageService";

export default class AddMenuImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      families: [],
      family: "",
      idFamily: "",
      selectedOption: null,
      options: [],
      files: [],
      visible: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeVisible = this.handleChangeVisible.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();

    FamilyProductService.getAllFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ families: response.data["object"] });
          console.log("families", this.state.families);
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  remplissageOptions() {
    this.state.families.map((cat) =>
      this.setState({
        options: [...this.state.options, { value: cat.id, label: cat.name }],
      })
    );
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ idFamily: selectedOption.value });
    console.log("idFamily", this.state.idFamily);
  };

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  filesUpload = () => {
    const formData = new FormData();
    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      formData.append(
        "pictures",
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });
    let idProduct = this.state.idProd;
    ProductService.addBigPictures(formData, idProduct)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout des grandes images avec succée");
        } else {
          toast.error(response.data["object"]);
        }
      })
      .catch((err) => {
        toast.error("une erreur a survenue");
      });
  };

  addMenuImage = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);

    let menuImageModel = {
      idFamilyProduct: this.state.idFamily,
      visible: this.state.visible,
    };

    MenuImageService.addMenuImage(dataFile, JSON.stringify(menuImageModel))
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la carroussel avec succée");
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };
  handleChangeVisible(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }

  render() {
    const animatedComponents = makeAnimated();
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter une image au menu</h5>
              Deux étapes:
              <br /> 1-Choisir la famille de produits
              <br /> 2-Ajouter l'image (682px * 853px)
            </div>
          </div>
        </section> 

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0"></div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <div class="form-group">
                      <label>Choisir la famille</label>
                      <Select
                        id={"SelectCategory"}
                        value={this.state.selectedOption}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={this.state.options}
                        onChange={this.handleChange}
                        placeholder="Choisir..."
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div class="checkbox">
                      <br />
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          defaultChecked={this.state.visible}
                          onChange={this.handleChangeVisible}
                        />{" "}
                        Rendre visible dans le site
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <Files
                    ref="files"
                    className="files-dropzone-list"
                    // style={{ height: "100px" }}
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    multiple
                    maxFiles={1}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <label>Cliquer pour Ajouter une image</label>
                  </Files>

                  {this.state.files.length > 0 ? (
                    <div className="files-list">
                      <ul>
                        {this.state.files.map((file) => (
                          <li className="files-list-item" key={file.id}>
                            <div className="files-list-item-preview">
                              {file.preview.type === "image" ? (
                                <div className="row">
                                  <img
                                    className="files-list-item-preview-image"
                                    src={file.preview.url}
                                    style={{
                                      width: "700px",
                                      height: "300px",
                                    }}
                                  />
                                  <button
                                    id={file.id}
                                    class="btn btn-navbar"
                                    type="button"
                                    // data-widget="navbar-search"
                                    onClick={this.filesRemoveOne.bind(
                                      this,
                                      file
                                    )}
                                  >
                                    <i class="fas fa-times"></i>
                                  </button>
                                </div>
                              ) : (
                                <div className="files-list-item-preview-extension">
                                  {file.extension}
                                </div>
                              )}
                            </div>
                            <div className="files-list-item-content">
                              <div className="files-list-item-content-item files-list-item-content-item-1">
                                {file.name}
                              </div>
                              <div className="files-list-item-content-item files-list-item-content-item-2">
                                {file.sizeReadable}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <h1></h1>
                  )}
                </div>

                {/* </div> */}
                <br />
              </form>
            </div>
            <div className="card-footer">
              <button
                type="submit"
                className="btn btn-success float-right"
                onClick={this.addMenuImage}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
