import { Component } from "react";
import CurrencyInput from "react-currency-input";
import NumericInput from "react-numeric-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Files from "react-files";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CategoryService from "../../services/CategoryService";
import CarrousselCategoryHomeService from "../../services/CarrousselCategoryHomeService";

export default class AddCarrousselCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      files: [],
      optionCategories: [],
      selectedOptionCategory: null,
      idCategorie: "",
      visible: false,
    };
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }
  componentDidMount() {
    this.forceUpdate();

    CategoryService.getAllCategories()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ categories: response.data["object"] });
          console.log("categories", this.state.categories);
          this.remplissageOptionsCategory();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  remplissageOptionsCategory() {
    this.state.categories.map((cat) =>
      this.setState({
        optionCategories: [
          ...this.state.optionCategories,
          { value: cat.id, label: cat.name },
        ],
      })
    );
  }
  handleChangeCategory = (selectedOptionCategory) => {
    this.setState({ selectedOptionCategory }, () =>
      console.log(`Option selected:`, this.state.selectedOptionCategory)
    );
    this.setState({ idCategorie: selectedOptionCategory.value });
    console.log("idCategorie", this.state.idCategorie);
  };
  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  addCarrousselHome = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);

    let carrousselCategoryHomeModel = {
      idCategory: this.state.idCategorie,
      visible: this.state.visible,
    };

    CarrousselCategoryHomeService.addCarrousselCategoryHome(
      dataFile,
      JSON.stringify(carrousselCategoryHomeModel)
    )
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout de la carroussel avec succée");
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };
  render() {
    const animatedComponentsCategory = makeAnimated();
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter La Carroussel Catégorie</h5>
              <br /> 1-Choisir la catégorie
              <br /> 2-Choisir la visibilté dans le FrontOffice
              <br /> 3-L'images de la caroussel (1009px * 636px)
            </div>
          </div>
        </section>

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline ">
            <div class="card-header">
              <h3 class="card-title"> Catégorie de la page Home</h3>
            </div>

            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-8">
                    <div class="form-group">
                      <label>Choisir la catégorie</label>
                      <Select
                        id={"SelectCategory"}
                        value={this.state.selectedOptionCategory}
                        closeMenuOnSelect={true}
                        components={animatedComponentsCategory}
                        options={this.state.optionCategories}
                        onChange={this.handleChangeCategory}
                        placeholder="Saisir..."
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="checkbox">
                      <br />
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          defaultChecked={this.state.visible}
                          onChange={this.handleChange}
                        />{" "}
                        Rendre visible dans le site
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <Files
                    ref="files"
                    className="files-dropzone-list"
                    // style={{ height: "100px" }}
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    multiple
                    maxFiles={1}
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <label>Cliquer pour Ajouter une image</label>
                  </Files>

                  {this.state.files.length > 0 ? (
                    <div className="files-list">
                      <ul>
                        {this.state.files.map((file) => (
                          <li className="files-list-item" key={file.id}>
                            <div className="files-list-item-preview">
                              {file.preview.type === "image" ? (
                                <div className="row">
                                  <img
                                    className="files-list-item-preview-image"
                                    src={file.preview.url}
                                    style={{
                                      width: "700px",
                                      height: "300px",
                                    }}
                                  />
                                  <button
                                    id={file.id}
                                    class="btn btn-navbar"
                                    type="button"
                                    // data-widget="navbar-search"
                                    onClick={this.filesRemoveOne.bind(
                                      this,
                                      file
                                    )}
                                  >
                                    <i class="fas fa-times"></i>
                                  </button>
                                </div>
                              ) : (
                                <div className="files-list-item-preview-extension">
                                  {file.extension}
                                </div>
                              )}
                            </div>
                            <div className="files-list-item-content">
                              <div className="files-list-item-content-item files-list-item-content-item-1">
                                {file.name}
                              </div>
                              <div className="files-list-item-content-item files-list-item-content-item-2">
                                {file.sizeReadable}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <h1></h1>
                  )}
                </div>

                {/* </div> */}
                <br />
                <button
                  type="submit"
                  className="btn btn-success float-right"
                  onClick={this.addCarrousselHome}
                >
                  Ajouter
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
