import React, { useState, useEffect } from "react";
import ContactService from "src/services/ContactService";

function SideBarEmail() {
  const [nbContact, setNbContact] = useState(0);
  useEffect(() => {
    getNombreContacts();
  }, []);
  const getNombreContacts = () => {
    ContactService.getNombreContacts()
      .then((response) => {
        if (response.data.success) {
          setNbContact(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  return (
    <>
      <div class="card-body p-0">
        <ul class="nav nav-pills flex-column">
          <li class="nav-item active">
            <a href="/page-contact" class="nav-link">
              <i class="fas fa-inbox"></i> Boîte de réception
              <span class="badge bg-dark float-right">{nbContact}</span>
            </a>
          </li>
          {/* <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="far fa-envelope"></i> Sent
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="far fa-file-alt"></i> Drafts
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="fas fa-filter"></i> Junk
                        <span class="badge bg-warning float-right">65</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="far fa-trash-alt"></i> Trash
                      </a>
                    </li> */}
        </ul>
      </div>
    </>
  );
}

export default SideBarEmail;
