import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class SyhncroCsvReadService {
  getAllSynchroReadCsv(page, size) {
    return api.get(urlsCommon.BASE_SYNCHRO_CSV_READ + "/getAllSynchroReadCsv", {
      params: {
        page: page,
        size: size,
      },
    });
  }

  readCSVFile(userId) {
    return api.get(
      urlsCommon.BASE_synchro_read_csv_URL + "/readFileCsv/" + userId
    );
  }
}
export default new SyhncroCsvReadService();
