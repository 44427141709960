import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class CarrousselPromotionHomeService {
  addCarrousselPromotionHome(picture, carrousselPromotionHomeModel) {
    return api.post(
      urlsCommon.BASE_CARROUSSEL_PROMOTION_URL + "/addCarrousselPromotionHome",
      picture,
      {
        params: {
          carrousselPromotionHomeModel,
        },
      }
    );
  }
  allCarrousselPromotionHome() {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_PROMOTION_URL + "/allCarrousselPromotionHome"
    );
  }
}
export default new CarrousselPromotionHomeService();
