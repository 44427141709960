import { Component } from "react";
import CategoryService from "../../services/CategoryService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubFamilyProductService from "src/services/SubFamilyProductService";

export default class CategoryUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      libelleFo: "",
      SousFamillesOptions: [],
      sousFamilleSelected: "",
    };
    this.changeInputLibelleFo = this.changeInputLibelleFo.bind(this);
    this.changeSousFamilleSelected = this.changeSousFamilleSelected.bind(this);
  }

  changeSousFamilleSelected(event) {
    this.setState({ sousFamilleSelected: event.target.value }, () => {
      console.log("sousFamilleSelected", this.state.sousFamilleSelected);
      console.log("dd", this.props.sousFamilleCategory);
    });
  }

  componentDidMount() {
    this.forceUpdate();
    this.getALLSouFamilles();
    CategoryService.getCategoryById(this.props.idCategory)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ category: response.data["object"] });
          // console.log("category", this.state.category);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  changeInputLibelleFo(event) {
    this.setState({
      libelleFo: event.target.value,
    });
  }
  updateCategory = (e) => {
    e.preventDefault();
    const modelToUpdate = {
      idSousFamille:
        this.state.sousFamilleSelected || this.props.sousFamilleCategory.id,
      nameToFo: this.state.libelleFo,
    };
    console.log("modelToUpdate", modelToUpdate);
    CategoryService.updateCategory(this.state.category.id, modelToUpdate)
      .then((response) => {
        if (response.data.success) {
          toast.success("Mise à jour  avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  getALLSouFamilles = () => {
    SubFamilyProductService.getAllSubFamilyProducts()
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ SousFamillesOptions: response.data.object });
        } else {
          console.log(response.data.object);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Modifier la catégorie {this.state.category.name}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <label>Libellé</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Saisir..."
                      value={this.state.category.name || ""}
                      // onChange={this.changeInputLibelleFo}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <label>Libellé Site</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Saisir..."
                      value={
                        this.state.libelleFo ||
                        this.state.category.nameComplementaire
                      }
                      onChange={this.changeInputLibelleFo}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <label>sous famille</label>
                    <select
                      className="form-control"
                      value={this.state.sousFamilleSelected}
                      onChange={this.changeSousFamilleSelected}
                    >
                      <option value={this.props.sousFamilleCategory.id}>
                        {this.props.sousFamilleCategory.name}
                      </option>{" "}
                      {/* Default option */}
                      {this.state.SousFamillesOptions.map(
                        (sousFamille, index) => (
                          <option key={sousFamille.id} value={sousFamille.id}>
                            {sousFamille.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={this.updateCategory}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    );
  }
}
