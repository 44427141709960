import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class TagsService {
  addTags(tags) {
    return api.post(urlsCommon.BASE_TAGS_URL + "/addTags", tags);
  }
  addTagToProduct(tagModel) {
    return api.post(urlsCommon.BASE_TAGS_URL + "/addTagToProduct", tagModel);
  }
  getTagById(idTags) {
    return api.get(urlsCommon.BASE_TAGS_URL + "/getTagById/" + idTags);
  }
  getAllTagsInSubFamily(idProduct) {
    return api.get(
      urlsCommon.BASE_TAGS_URL + "/getAllTagsInSubFamily/" + idProduct
    );
  }
  getTagsProduct(idProduct) {
    return api.get(urlsCommon.BASE_TAGS_URL + "/getTagsProduct/" + idProduct);
  }
}
export default new TagsService();
