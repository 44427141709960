import { Component } from "react";
import { Link } from "react-router-dom";
import VetementsService from "../../services/VetementsBanniereService";
import VetementsBanniereDelete from "./VetementsBanniereDelete";
import VetementsBanniereVisible from "./VetementsBanniereVisible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default class VetementsBanniere extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vetements: [],
      idVetementsBanniere: "",
      showFormImage: false,
    };
  }
  componentDidMount() {
    this.forceUpdate();
    VetementsService.allVetementsBanniere()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ vetements: response.data["object"] });
          console.log("resonse");
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  formatDate(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth()).padStart(2, "0");
    let year = cdate.getFullYear();
    return day + "/" + month + "/" + year;
  }
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Banniere vetements</h5>
              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addVetementsBanniere" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    <i class="fas fa-plus"></i>
                    Ajouter
                  </Link>
                </button>
              </p>
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Banniere de la page vetements</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Titre </th>
                    <th>visible</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.vetements.map((vetements) => (
                    <tr>
                      <td>{vetements.id}</td>
                      <td>{vetements.titre}</td>
                      {/* <td>{this.formatDate(vetements.dateDebut)}</td>
                      <td>{this.formatDate(vetements.dateFin)}</td> */}
                      <td>
                        <button
                          data-toggle="modal"
                          data-target="#modal-visible"
                          // style={{ width: 40 }}
                          className={`btn btn-sm ${
                            vetements?.visible ? "btn-secondary" : "btn-primary"
                          }`}
                          onClick={() => {
                            this.setState({
                              idVetementsBanniere: vetements.id,
                            });
                            this.showFormImage();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={vetements?.visible ? faEyeSlash : faEye}
                          />
                        </button>
                      </td>
                      <td>
                        <button
                          // style={{ width: 40 }}
                          className={`btn btn-sm ${
                            vetements?.isArchived ? "btn-success" : "btn-danger"
                          }`}
                          onClick={() => {
                            this.setState({
                              idVetementsBanniere: vetements.id,
                            });
                            this.showFormImage();
                          }}
                          data-toggle="modal"
                          data-target="#modal-default"
                        >
                          <FontAwesomeIcon
                            icon={vetements?.isArchived ? faRecycle : faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-visible">
                      <VetementsBanniereVisible
                        id={this.state.idVetementsBanniere}
                      />
                    </div>
                  )}
                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-default">
                      <VetementsBanniereDelete
                        id={this.state.idVetementsBanniere}
                      />
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
