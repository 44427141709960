import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class CarrousselCategoryHomeService {
  addCarrousselCategoryHome(picture, carrousselCategoryHomeModel) {
    return api.post(
      urlsCommon.BASE_CARROUSSEL_CATEGORY_URL + "/addCarrousselCategoryHome",
      picture,
      {
        params: {
          carrousselCategoryHomeModel,
        },
      }
    );
  }
  allCarrousselCategoryHome() {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_CATEGORY_URL + "/allCarrousselCategoryHome"
    );
  }
}
export default new CarrousselCategoryHomeService();
