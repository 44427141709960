import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SectionHomeService from "../../services/SectionHomeService";
import { useEffect, useState } from "react";
import ProductService from "src/services/ProductService";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function AddSectionHome() {
  const [SectionHome, setSectionHome] = useState({});
  const [placeInHomePage, setPlaceInHomePage] = useState(0);
  //for selecet product
  const [listAllProducts, setListAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsDefault, setSelectedProductsDefault] = useState([]);
  const animatedComponents = makeAnimated();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSectionHome({ ...SectionHome, [name]: value });
  };
  useEffect(() => {
    getAllProductsByList();
  }, []);

  //*************************Chekbox */
  function onValueChange(event) {
    if (event.target.value === "0") {
      setPlaceInHomePage(0);
    } else if (event.target.value === "1") {
      setPlaceInHomePage(1);
    } else if (event.target.value === "2") {
      setPlaceInHomePage(2);
    }
  }

  //**************select Products */
  const getAllProductsByList = () => {
    ProductService.getAllProductsByList()
      .then((response) => {
        if (response.data.success) {
          setListAllProducts(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
    console.log("Selected Products:", selectedOptions);
    console.log(selectedProducts);
  };
  //**************/.select Products */

  const AddSectionHome = (e) => {
    e.preventDefault();
    const listIdsProductsModel = selectedProducts.map(
      (product) => product.value
    );

    const SectionHomeModel = {
      name: SectionHome.name,
      titre: SectionHome.title,
      sousTitle: SectionHome.sousTitle,
      description: SectionHome.description,
      titreBouton: SectionHome.titleButton,
      urlBouton: SectionHome.urlButton,
      visible: SectionHome.visible,
      listIdsProducts: listIdsProductsModel,
      placeInHomePage: placeInHomePage,
    };

    SectionHomeService.addSectionHome(SectionHomeModel)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setPlaceInHomePage(0);
          setSectionHome({});
          setSelectedProducts([]);
          setTimeout(function () {
            window.location.href = "/allSections";
          }, 2000);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <ToastContainer />
          <div className="col-md-12">
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Ajouter Section Home</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Nom section Home dans Base données :</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-clock"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nom section Home"
                      name="name"
                      value={SectionHome.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Titre</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Titre section Home"
                          value={SectionHome.title}
                          name="title"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Sous Titre</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sous Titre section Home"
                          value={SectionHome.sousTitle}
                          onChange={handleChange}
                          name="sousTitle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Button Titre</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Button Titre section Home"
                          value={SectionHome.titleButton}
                          onChange={handleChange}
                          name="titleButton"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Lien Button</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-clock"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Url Button section Home"
                          value={SectionHome.urlButton}
                          onChange={handleChange}
                          name="urlButton"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Sélectionner Les produits</label>

                  <Select
                    defaultValue={selectedProductsDefault || ""}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={listAllProducts}
                    onChange={handleProductChange}
                    value={selectedProducts}
                    placeholder="Sélectionner Les produits..."
                  />
                </div>
                <div className="mb-4"></div>
                <div className="row ">
                  <div className="col-md-4">
                    <div className="form-group clearfix">
                      <div className="icheck-asbestos d-inline">
                        <input
                          id="section1"
                          type="radio"
                          name="section1"
                          value="0"
                          checked={placeInHomePage === 0}
                          onChange={onValueChange}
                        />
                        <label htmlFor="section1">
                          After Categories In Page Home
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group clearfix">
                      <div className="icheck-asbestos d-inline">
                        <input
                          id="section2"
                          type="radio"
                          name="section2"
                          value="1"
                          checked={placeInHomePage === 1}
                          onChange={onValueChange}
                        />
                        <label htmlFor="section2">
                          After TotalLook Section In Page Home
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group clearfix">
                      <div className="icheck-asbestos d-inline">
                        <input
                          id="section3"
                          type="radio"
                          name="section3"
                          value="2"
                          checked={placeInHomePage === 2}
                          onChange={onValueChange}
                        />
                        <label htmlFor="section3">
                          After Thematique Section In Page Home
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 ">
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={SectionHome.visible}
                        onChange={handleChange}
                      />{" "}
                      Rendre visible dans le site
                    </label>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-default"
                  onClick={() => (window.location.href = "/allSections")}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-secondary float-right"
                  onClick={AddSectionHome}
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
