import React, { useState, useEffect } from "react";
import ContactService from "src/services/ContactService";
import { EmailParser, EmailTime, TruncatedContent } from "./ElementEmail";
import SideBarEmail from "./SideBarEmail";

function ContactPage() {
  const [contactList, setContactList] = useState([]);
  const [indexContact, setIndexContact] = useState("");

  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const [totalPages, setTotalPages] = useState(1);
  // const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    getAllContacts();
  }, []);

  const getAllContacts = () => {
    ContactService.getAllContacts()
      .then((response) => {
        if (response.data.success) {
          setContactList(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getContactDetails = (id) => {
    localStorage.setItem("idContact", id);
    const url = "/page-contact-email";
    window.open(url, "_blank");
  };
  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <section class="content">
          <div class="card  mt-3">
            <div class="card-body">
              <h5 class="card-title">
                <b>Page Contact </b>
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              {/* <a href="compose.html" class="btn btn-primary btn-block mb-3">
                Compose
              </a> */}

              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Emails</h3>

                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <SideBarEmail />
              </div>
            </div>
            <div class="col-md-9">
              <div class="card card-dark card-outline">
                <div class="card-header">
                  <h3 class="card-title">Emails</h3>

                  {/* <div class="card-tools">
                    <div class="input-group input-group-sm">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Mail"
                      />
                      <div class="input-group-append">
                        <div class="btn btn-primary">
                          <i class="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="card-body p-0">
                  {/* <div class="mailbox-controls">
                    <button
                      type="button"
                      class="btn btn-default btn-sm checkbox-toggle"
                    >
                      <i class="far fa-square"></i>
                    </button>
                    <div class="btn-group">
                      <button type="button" class="btn btn-default btn-sm">
                        <i class="far fa-trash-alt"></i>
                      </button>
                      <button type="button" class="btn btn-default btn-sm">
                        <i class="fas fa-reply"></i>
                      </button>
                      <button type="button" class="btn btn-default btn-sm">
                        <i class="fas fa-share"></i>
                      </button>
                    </div>
                    <button type="button" class="btn btn-default btn-sm">
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <div class="float-right">
                      1-50/200
                      <div class="btn-group">
                        <button type="button" class="btn btn-default btn-sm">
                          <i class="fas fa-chevron-left"></i>
                        </button>
                        <button type="button" class="btn btn-default btn-sm">
                          <i class="fas fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div> */}

                  <div class="table-responsive mailbox-messages">
                    {contactList &&
                      contactList.map((contact, index) => (
                        <>
                          <table class="table table-hover table-striped">
                            <tbody>
                              <tr
                                onClick={() => {
                                  getContactDetails(contact?.id);
                                }}
                              >
                                {/* <td>
                                  <div class="icheck-primary">
                                    <input
                                      type="checkbox"
                                      value=""
                                      id="check1"
                                    />
                                    <label for="check1"></label>
                                  </div>
                                </td>
                                <td class="mailbox-star">
                                  <a href="#">
                                    <i class="fas fa-star text-warning"></i>
                                  </a>
                                </td> */}
                                <td class="mailbox-name">
                                  <EmailParser email={contact?.email || ""} />
                                </td>
                                <td class="mailbox-subject">
                                  <b> {contact?.sujet || ""}</b> -{" "}
                                  <TruncatedContent
                                    content={contact?.contenu || ""}
                                  />
                                </td>
                                <td class="mailbox-attachment"></td>
                                <td class="mailbox-date text-right">
                                  <EmailTime emailDate={contact?.dateSend} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      ))}
                  </div>
                </div>

                {/* <div class="card-footer p-0">
                  <div class="mailbox-controls">
                    <button
                      type="button"
                      class="btn btn-default btn-sm checkbox-toggle"
                    >
                      <i class="far fa-square"></i>
                    </button>
                    <div class="btn-group">
                      <button type="button" class="btn btn-default btn-sm">
                        <i class="far fa-trash-alt"></i>
                      </button>
                      <button type="button" class="btn btn-default btn-sm">
                        <i class="fas fa-reply"></i>
                      </button>
                      <button type="button" class="btn btn-default btn-sm">
                        <i class="fas fa-share"></i>
                      </button>
                    </div>

                    <button type="button" class="btn btn-default btn-sm">
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <div class="float-right">
                      1-50/200
                      <div class="btn-group">
                        <button type="button" class="btn btn-default btn-sm">
                          <i class="fas fa-chevron-left"></i>
                        </button>
                        <button type="button" class="btn btn-default btn-sm">
                          <i class="fas fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ContactPage;
