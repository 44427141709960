import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import 1 from "../../../reduxSaga/sagas/requests/currentUserId";
import { Snackbar, TextField } from "@material-ui/core";
import CurrencyInput from "react-currency-input";

import DiscountService from "../services/DiscountService";
import { Alert } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import ProductService from "../services/ProductService";
import BrandService from "../services/BrandService";
import FamilyProductService from "../services/FamilyProductService";
import SubFamilyProductService from "../services/SubFamilyProductService";
import CategoryService from "../services/CategoryService";
import { Button } from "react-bootstrap";
import SoldesServices from "src/services/SoldesServices";
import Loading from "src/common/loading";
import getCurrentUserId from "src/common/currentUserId";
import { ToastContainer, toast } from "react-toastify";

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};
const AddSoldes = () => {
  const navigate = useNavigate();
  const [productsFile, setProductsFile] = useState("");
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState();
  const [startDate, setStartDate] = useState("any");
  const [endDate, setEndDate] = useState("any");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [successAlertText, setSuccessAlertText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  const onUploadFileHandler = (e) => {
    setProductsFile(e.target.files[0]);
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    }
  };
  const onDeleteFileHandler = () => {
    setProductsFile("");
    setFileName("");
  };
  const typePromotionProduct = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div class="form-group">
            <label for="exampleInputFile">Les produits </label>
            <div className="input-group">
              <div className="custom-file">
                <input
                  accept="application/pdf, .xlsx, .xls"
                  type="file"
                  className="custom-file-input"
                  id="exampleInputFile"
                  onChange={onUploadFileHandler}
                />
                <label className="custom-file-label" htmlFor="exampleInputFile">
                  {fileName ? fileName : "Entrer le fichier excel des produits"}
                </label>
              </div>
              {fileName && (
                <button
                  className="btn btn-danger"
                  onClick={onDeleteFileHandler}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const onChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };
  const onChangeNameHandler = (event) => {
    setName(event.target.value);
  };
  const onChangeDescriptionHandler = (event) => {
    setDescription(event.target.value);
  };

  function TestValidationDateBettween(DateDebut, DateFin) {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    if (
      DateDebut < formattedCurrentDate ||
      DateFin < formattedCurrentDate ||
      DateFin < DateDebut ||
      DateDebut == "any" ||
      DateFin == "any"
    ) {
      return true;
    }
  }
  const onValidHandler = () => {
    if (!name && !productsFile) {
      toast.error("Erreur : remplir les champs !");
      return;
    }
    if (!name) {
      toast.error("Erreur : la libelle est vide !");
      return;
    }
    if (!productsFile) {
      toast.error("Erreur : pas de fichier !");
      setIsLoading(false);
      return;
    }
    if (TestValidationDateBettween(startDate, endDate)) {
      toast.error("il faut selectionner date correcte");
      return;
    }

    let promotionProductAddModel = {
      libelle: name,
      description: description,
      idUser: getCurrentUserId(),
      libelleDeclencheur: name,
      descriptionDeclencheur: description,
      startDate: new Date(startDate.concat("T05:05:59")),
      endDate: new Date(endDate.concat("T07:05:59")),
    };
    if (
      promotionProductAddModel.libelle &&
      promotionProductAddModel.description &&
      promotionProductAddModel.idUser &&
      promotionProductAddModel.libelleDeclencheur &&
      promotionProductAddModel.startDate &&
      promotionProductAddModel.endDate
    ) {
      setIsLoading(true);
    }

    SoldesServices.addSoldes(promotionProductAddModel, productsFile).then(
      (result) => {
        console.log("result", result.data.success);
        if (result.data.success) {
          setIsLoading(false);
          setOpenSuccessAlert(true);
          setSuccessAlertText("Enregistrement avec succées");
          navigate(`/allDeclencheurs`);

          // setTimeout(() => {
          //   navigate(`/allDeclencheurs`);
          // }, 2000);
        } else {
          setIsLoading(false);
          setOpenErrorAlert(true);
          setErrorAlertText("Erreur :erreur serveur ");
        }
      }
    );
  };

  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <ToastContainer />
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active">Ajouter promotion</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          {isLoading == true ? (
            <>
              <section class="content">
                <div class="card card-dark">
                  <div
                    class="card-body p-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Loading />
                  </div>
                </div>
              </section>
            </>
          ) : (
            <>
              <div className="timeline">
                <div>
                  <i className="fas fa-1 " style={{ backgroundColor: "black" }}>
                    <span style={{ color: "white" }}>1</span>
                  </i>
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock" /> 12:05
                    </span>
                    <h3 className="timeline-header">
                      <b>Informations Générales</b>
                    </h3>
                    <div className="timeline-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="inputName">Libellé</label>
                            <input
                              onChange={onChangeNameHandler}
                              type="text"
                              id="inputName"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="inputDescription">
                              Description
                            </label>
                            <textarea
                              value={description}
                              placeholder="Saisir..."
                              onChange={onChangeDescriptionHandler}
                              id="inputDescription"
                              className="form-control"
                              rows={4}
                              defaultValue={""}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <i className="fas fa-3 " style={{ backgroundColor: "black" }}>
                    <span style={{ color: "white" }}>2</span>
                  </i>
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock" /> 5 mins ago
                    </span>
                    <h3 className="timeline-header no-border">
                      <b>upload file excel</b>{" "}
                    </h3>
                    <div className="timeline-body">
                      <div className="row">
                        <div className="col-sm-12">
                          {typePromotionProduct()}
                        </div>
                      </div>
                    </div>

                    <div></div>
                  </div>
                </div>
                <div>
                  <i className="fas fa-6" style={{ backgroundColor: "black" }}>
                    <span style={{ color: "white" }}>3</span>
                  </i>
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock" /> 27 mins ago
                    </span>
                    <h3 className="timeline-header">
                      <b> Choisir la période de la promotion</b>
                    </h3>
                    <div className="timeline-body">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Date début</label>

                            <TextField
                              style={{ width: "100%" }}
                              className="col-sm-12"
                              variant="outlined"
                              value={startDate}
                              onChange={onChangeStartDate}
                              id="datetime-local"
                              type="date"
                              // type="datetime-local"
                              defaultValue="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Date fin</label>

                            <TextField
                              style={{ width: "100%" }}
                              className="col-sm-12"
                              variant="outlined"
                              value={endDate}
                              onChange={onChangeEndDate}
                              id="datetime-local"
                              // type="datetime-local"
                              type="date" // Change type to "date"
                              defaultValue="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    class="btn btn-dark"
                    style={{ marginTop: "1%" }}
                    onClick={onValidHandler}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};
export default AddSoldes;
