import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import Select from "react-select";
import CommandeService from "src/services/CommandeService";
import Utils from "src/utils/Utils";
import Circle from "src/common/circle";
import getCurrentUserId from "src/common/currentUserId";
import UtilsFormule from "src/utils/UtilsFormule";

function DetailsCommande() {
  const [commandeDetails, setCommandeDetails] = useState({});
  const [livraisonAdressDetails, setLivraisonAdressDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [commandeStates, setCommandeStates] = useState([]);

  const [etatCommandeList, setEtatCommandeList] = useState([]);
  const [etatCommandeSelected, setEtatCommandeSelected] = useState();

  const [sourceCommandeList, setSourceCommandeList] = useState([]);
  const [sourceCommandeSelected, setSourceCommandeSelected] = useState("");

  const selectedSourceDefault = sourceCommandeList.find(
    (option) => option.value === commandeDetails?.sourceCommandeWithValue?.value
  );
  const selectedEtatCommandeDefault = etatCommandeList.find(
    (option) => option.value === commandeDetails?.etatCommandeWithValue?.value
  );

  useEffect(() => {
    getCommandeDetailsById(localStorage.getItem("idCommande"));
    getAllEtatCommande();
    getAllSourceCommande();
  }, []);

  ///***********Source commande */
  const handleSelectSourceCommande = (selectedOption) => {
    setSourceCommandeSelected(selectedOption);
  };
  const getAllSourceCommande = () => {
    CommandeService.getAllSourceCommande()
      .then((response) => {
        if (response.data.success) {
          setSourceCommandeList(response.data.success);
        }
      })
      .catch((error) => {
        //e.error("Erreur lors de la requête API:", error);
      });
  };

  const updateCommandeSource = () => {
    CommandeService.updateCommandeSource(
      commandeDetails?.id,
      sourceCommandeSelected?.value
    )
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getCommandeDetailsById(commandeDetails?.id);
          // window.location.replace("/allCommandes");
        }
      })
      .catch((error) => {
        //e.log("response", "error");
      });
  };
  ///***********end Source commande */

  ///***********Etat commande */
  const handleSelectEtatCommande = (selectedOption) => {
    setEtatCommandeSelected(selectedOption);
  };
  const getAllEtatCommande = () => {
    CommandeService.getAllEtatCommande(1)
      .then((response) => {
        if (response.data.success) {
          setEtatCommandeList(response.data.success);
        }
      })
      .catch((error) => {
        //e.error("Erreur lors de la requête API:", error);
      });
  };
  ///***********end Etat commande */

  const getCommandeDetailsById = (idCommande) => {
    CommandeService.getCommandeDetailsById(idCommande)
      .then((response) => {
        if (response.data.success) {
          setCommandeDetails(response.data.success.commande);
          setLivraisonAdressDetails(
            response.data.success.commande.livraisonDetails
          );
          setProducts(response.data.success.products);
          setUserDetails(response.data.success.userDetails);
          setCommandeStates(response.data.success.commandeStates);
        }
      })
      .catch((error) => {
        //e.error("Erreur lors de la requête API:", error);
      });
  };

  const multiply = (price, quantity) => {
    return price * quantity;
  };

  const addition = (priceSousTotal, priceLivraison) => {
    return priceSousTotal + priceLivraison;
  };
  const updateCommandeState = () => {
    CommandeService.updateCommandeState(
      getCurrentUserId(),
      commandeDetails?.id,
      etatCommandeSelected?.value
    )
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getCommandeDetailsById(commandeDetails?.id);
          // window.location.replace("/allCommandes");
        }
      })
      .catch((error) => {
        //e.log("response", "error");
      });
  };
  const areEqualOrSup = (value1, value2) => {
    if (Math.abs(value1 - value2) == 0 || value1 > value2) {
      return true;
    } else {
      return false;
    }
  };
  console.log(
    "commandeDetails.remiseCommande :",
    commandeDetails.remiseCommande
  );
  return (
    <>
      <div class="content-wrapper">
        <div class="container-fluid">
          <ToastContainer />
          <section className="content">
            <div classname="card">
              <div className="invoice p-3 mb-3">
                <div className="row">
                  <div className="col-3">
                    <h4>
                      <i class="far fa-calendar"></i>{" "}
                      {UtilsFormule.formatDateHours(
                        commandeDetails?.dateCommande || ""
                      )}
                    </h4>
                  </div>
                  <div className="col-3">
                    <h4>
                      <span
                        className={
                          commandeDetails?.etatCommandeWithValue?.value == 1
                            ? "badge badge-dark"
                            : commandeDetails?.etatCommandeWithValue?.value == 2
                            ? "badge badge-primary"
                            : commandeDetails?.etatCommandeWithValue?.value == 3
                            ? "badge badge-info"
                            : commandeDetails?.etatCommandeWithValue?.value == 4
                            ? "badge badge-warning"
                            : commandeDetails?.etatCommandeWithValue?.value == 5
                            ? "badge badge-success"
                            : commandeDetails?.etatCommandeWithValue?.value == 6
                            ? "badge badge-danger"
                            : commandeDetails?.etatCommandeWithValue?.value == 7
                            ? "badge badge-secondary"
                            : "badge badge-dark"
                        }
                      >
                        {commandeDetails?.etatCommandeWithValue?.label || ""}
                      </span>
                    </h4>
                  </div>
                  <div className="col">
                    <Select
                      id="SelectMarque"
                      value={sourceCommandeSelected || selectedSourceDefault}
                      options={sourceCommandeList}
                      onChange={handleSelectSourceCommande}
                      placeholder="change Source"
                    />
                  </div>
                  <button
                    className="btn btn-default float-right"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      updateCommandeSource();
                    }}
                  >
                    <i className="fas fa-check" />
                  </button>
                  <div className="col">
                    <Select
                      id="SelectMarque"
                      value={
                        etatCommandeSelected || selectedEtatCommandeDefault
                      }
                      options={etatCommandeList}
                      onChange={handleSelectEtatCommande}
                      placeholder="change l'etat"
                    />
                  </div>
                  <button
                    className="btn btn-default float-right"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      updateCommandeState();
                    }}
                  >
                    <i className="fas fa-check" />
                  </button>

                  <button
                    onClick={() => {
                      window.print();
                    }}
                    rel="noopener"
                    target="_blank"
                    className="btn btn-default float-right"
                  >
                    <i className="fas fa-print" />
                  </button>
                </div>
                <br />
                <br />
                <div className="row invoice-info">
                  <div className="col-sm-4 invoice-col">
                    <address>
                      <strong>Informations Client</strong>
                      <ul>
                        <li>
                          <b>Nom & Prenom :</b> {userDetails?.name || ""}
                        </li>
                        <li>
                          <b>Adresse :</b> {userDetails?.codePostal || ""}{" "}
                          {userDetails?.adress}, {userDetails?.ville},{" "}
                          {userDetails?.pays}
                        </li>
                        <li>
                          <b>Tel :</b> {userDetails?.tlf}
                        </li>
                        <li>
                          <b>Email :</b> {userDetails?.email}
                        </li>
                      </ul>
                    </address>
                  </div>

                  {/* /.col */}
                  <div className="col-sm-4 invoice-col">
                    <strong>Adresse de Livraison </strong>
                    <ul>
                      <li>
                        <b>Code Postal :</b>{" "}
                        {livraisonAdressDetails?.codePostal || ""}
                      </li>
                      <li>
                        <b>Adresse :</b> {livraisonAdressDetails?.adress || ""}
                      </li>
                      <li>
                        <b>Ville :</b> {livraisonAdressDetails?.ville || ""}
                      </li>
                      <li>
                        <b>Pays :</b> {livraisonAdressDetails?.pays || ""}
                      </li>
                    </ul>

                    <strong>Méthode de livraison </strong>
                    <ul>
                      <li>
                        <b>Type :</b> {livraisonAdressDetails?.type || ""}
                      </li>
                      <li>
                        {commandeDetails?.priceTotal >
                          commandeDetails?.montantGratuit}
                        <b>Prix :</b>{" "}
                        {commandeDetails?.montantGratuit != null &&
                        commandeDetails?.priceTotal >=
                          commandeDetails?.montantGratuit
                          ? "Gratuite"
                          : livraisonAdressDetails?.prixLiraison?.toFixed(3) +
                              " TND" || ""}
                      </li>
                    </ul>
                  </div>

                  {/* /.col */}
                  <div className="col-sm-4 invoice-col">
                    <b>Ref Commande :</b> {commandeDetails?.referenceCommande}
                    <br />
                    <b>Date : </b>
                    {UtilsFormule.formatDateHours(
                      commandeDetails?.dateCommande || ""
                    )}
                    <br />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Ref Produit</th>
                          <th>Libellé</th>
                          <th>code à barre</th>
                          <th>Couleur</th>
                          <th></th>
                          <th>Taille</th>
                          <th>Quantité</th>
                          <th>Prix Unitaire</th>
                          <th>Prix Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products &&
                          products.map((product) => (
                            <tr>
                              <td>
                                <img
                                  src={product?.image || ""}
                                  alt="img product"
                                  width={50}
                                  height={70}
                                />
                              </td>
                              <td>{product?.refProduct || ""}</td>
                              <td>
                                {product?.nameFo
                                  ? product?.nameFo
                                  : product?.name}
                              </td>
                              <td>{product?.codeABarre || ""}</td>
                              <td>
                                <Circle colors={product?.color || ""} />
                              </td>
                              <td>{product?.colorName || ""}</td>
                              <td>{product?.size || ""}</td>
                              <td>{product?.qty || ""}</td>
                              <td>{product?.price?.toFixed(3) || ""} TND</td>
                              <td>
                                {multiply(product.price, product.qty)?.toFixed(
                                  3
                                )}{" "}
                                TND
                              </td>{" "}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  {/* <div className="col-3">
                    <p className="lead">Les Etats de commande</p>
                    <p
                      className="text-muted well well-sm shadow-none"
                      style={{ marginTop: 10 }}
                    >
                      Payement à la livraison
                    </p>
                  </div> */}
                  <div className="col-6">
                    <p className="lead">Etats Commande :</p>

                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          {commandeStates &&
                            commandeStates.map((commandeState) => (
                              <tr>
                                <th
                                  style={{ width: "50%" }}
                                  className="text-muted well well-sm shadow-none"
                                >
                                  {commandeState?.etatCommande || ""}
                                </th>
                                <td className="text-muted well well-sm shadow-none">
                                  {UtilsFormule.formatDateHours(
                                    commandeState?.dateCommande || ""
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-6">
                    <p className="lead">
                      Commande :{" "}
                      {UtilsFormule.formatDateHours(
                        commandeDetails?.dateCommande || ""
                      )}
                    </p>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th style={{ width: "50%" }}>Sous totale :</th>
                            <td>
                              {commandeDetails?.priceTotal?.toFixed(3) || ""}{" "}
                              TND
                            </td>
                          </tr>

                          {commandeDetails?.montantGratuit != null &&
                          commandeDetails?.priceTotal >=
                            commandeDetails?.montantGratuit ? (
                            <>
                              <tr>
                                <th>Prix de livraison :</th>
                                <td>Gratuite</td>
                              </tr>
                              <tr>
                                <th>Total :</th>
                                <td>
                                  {commandeDetails?.priceTotal?.toFixed(3)} TND
                                </td>
                              </tr>
                              {commandeDetails.remiseCommande && (
                                <tr>
                                  <th>Prix apres remise :</th>
                                  <td>
                                    {commandeDetails?.remiseCommande?.toFixed(
                                      3
                                    )}{" "}
                                    TND
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : (
                            <>
                              <tr>
                                <th>Prix de livraison :</th>
                                <td>
                                  {" "}
                                  {livraisonAdressDetails?.prixLiraison?.toFixed(
                                    3
                                  )}{" "}
                                  TND
                                </td>
                              </tr>
                              <tr>
                                <th>Total :</th>
                                <td>
                                  {addition(
                                    commandeDetails?.priceTotal,
                                    livraisonAdressDetails?.prixLiraison
                                  )?.toFixed(3)}{" "}
                                  TND
                                </td>
                              </tr>
                              {commandeDetails.remiseCommande && (
                                <tr>
                                  <th>Prix apres remise :</th>
                                  <td>
                                    {addition(
                                      commandeDetails?.remiseCommande,
                                      livraisonAdressDetails?.prixLiraison
                                    )?.toFixed(3)}{" "}
                                    TND
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <label>Remarque Client</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      style={{
                        width: "100%",
                        height: 100,
                        fontSize: 14,
                        lineHeight: 1,
                        border: "1px solid #dddddd",
                        padding: 10,
                      }}
                      value={commandeDetails?.remarque || ""}
                      // onChange={this.changeRemarque}
                    />
                  </div>
                  {/* <div class="col-md-1">
                    <label></label>
                    <br />
                    <br />
                    <button
                      className="btn btn-success float-right"
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        this.changeRemarqueCommande();
                      }}
                    >
                      <i className="fas fa-check" />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default DetailsCommande;
