import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class NouveauteService {
  //*********bannieres **********/
  addNouveauteBanniere(picture, nouveauteProductModel) {
    return api.post(urlsCommon.BASE_NOUVEAUTE_URL + "/addNouveaute", picture, {
      params: {
        nouveauteProductModel,
      },
    });
  }
  allNouveauteBanniere() {
    return api.get(urlsCommon.BASE_NOUVEAUTE_URL + "/allNouveaute");
  }

  getNouveauteBanniereById(id) {
    return api.get(
      urlsCommon.BASE_NOUVEAUTE_URL + "/getNouveauteBanniereById/" + id
    );
  }
  visible(id, deed) {
    return api.get(
      urlsCommon.BASE_NOUVEAUTE_URL + "/visible/" + id + "/" + deed
    );
  }
  archive(id, deed) {
    return api.get(
      urlsCommon.BASE_NOUVEAUTE_URL + "/archiver/" + id + "/" + deed
    );
  }
  //*********/. bannieres **********/

  //*********page **********/

  getNombreProduitsNouveaute() {
    return api.get(
      urlsCommon.BASE_NOUVEAUTE_URL + "/page/nombreProduitsNouveaute"
    );
  }

  getAllProductsForAddNew() {
    return api.get(
      urlsCommon.BASE_NOUVEAUTE_URL + "/page/getAllProductsForAddNew"
    );
  }

  getListNouveauteProducts(page, size) {
    return api.get(
      urlsCommon.BASE_NOUVEAUTE_URL + "/page/getListNouveauteProducts",
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  rendreProductNotNew(idProduct) {
    return api.put(
      urlsCommon.BASE_NOUVEAUTE_URL + "/page/rendreProductNotNew/" + idProduct
    );
  }

  rendreNotNewAllProducts() {
    return api.put(
      urlsCommon.BASE_NOUVEAUTE_URL + "/page/rendreNotNewAllProducts"
    );
  }

  addNouveauteProducts(NouveauteProducts) {
    return api.post(
      urlsCommon.BASE_NOUVEAUTE_URL + "/page/addNouveauteProducts",
      NouveauteProducts
    );
  }
  //*********/. page **********/
}
export default new NouveauteService();
