import { Component } from "react";
import BrandDelete from "../Products/BrandPopupModel/BrandDelete";
import BrandUpdateLibelle from "../Products/BrandPopupModel/BrandUpdateLibelle";
// import BrandDelete from "./../Products/PopupModel/BrandDelete";
import BrandImage from "../Products/BrandPopupModel/BrandImage";
import { Link } from "react-router-dom";
import BrandService from "../services/BrandService";
import BrandVisible from "../Products/BrandPopupModel/BrandVisible";

export default class AllBrands extends Component {
  state = {
    idBrand: "",
    brands: [],
    numberBrands: "",

    showFormImage: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      numberBrands: "",
      showForm: false,
    };
    // this.open = this.open.bind(this);
  }
  buttonArchive(id, isArchived) {
    if (isArchived === 0) {
      return (
        <button
          class="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idBrand: id });
            this.showFormImage();
          }}
        >
          <i class="fas fa-trash"></i> Archiver
        </button>
      );
    } else {
      return (
        <button
          class="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idBrand: id });
            this.showFormImage();
          }}
        >
          <i class="fas fa-check"></i>Restaurer
        </button>
      );
    }
  }

  componentDidMount() {
    this.forceUpdate();
    BrandService.getAllBrand()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ brands: response.data["object"] });
          console.log("brands", this.state.brands);
          // this.setState({ alert_message: "success" });
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
    BrandService.getNumberAllBrand()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ numberBrands: response.data["object"] });
          console.log("numberBrands", this.state.numberBrands);
          // this.setState({ alert_message: "success" });
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  // openImage(id) {
  //   <BrandImage idBrand={id} />;
  // }

  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };

  labelVisible(id, visible) {
    if (visible === 1) {
      return <label>Visible dans le site</label>;
    } else {
      return <label>Invisible dans le site</label>;
    }
  }

  buttonVisible(id, visible) {
    if (visible === 1) {
      return (
        <button
          class="btn btn-danger btn-sm float-center"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idBrand: id });
            this.showFormImage();
          }}
        >
          {/* <i class="fas fa-trash"></i>  */}
          Rendre invisible
        </button>
      );
    } else {
      return (
        <button
          class="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idBrand: id });
            this.showFormImage();
          }}
        >
          {/* <i class="fas fa-check"></i> */}
          Rendre visible
        </button>
      );
    }
  }

  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <div className="float-left">
                  <h5>Les marques</h5>
                  <p> {this.state.numberBrands} marques</p>
                </div>
                <p className="float-right">
                  <button class="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/addBrand" }}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <i className="fas fa-plus" /> Ajouter
                    </Link>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="col-md-12">
          <section class="content">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  {/* <h3 className="card-title">Toutes les marques</h3> */}
                  <div className="card-tools">
                    {/* <div
                      className="input-group input-group-sm"
                      style={{ width: 150 }}
                    >
                      <input
                        type="text"
                        name="table_search"
                        className="form-control float-right"
                        placeholder="Search"
                      />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-default">
                          <i className="fas fa-search" />
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Libellé</th>
                        <th>Voir l'image</th>
                        <th>Modifier</th>
                        <th>Visibilité dans le site</th>
                        <th>Archiver</th>
                        <th>Voir les produits</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.brands.map((brand) => (
                        <tr>
                          <td>{brand.name}</td>
                          <td>
                            {" "}
                            <button
                              class="btn btn-warning btn-sm"
                              data-toggle="modal"
                              data-target="#modal-Image"
                              onClick={() => {
                                this.setState({ idBrand: brand.id });
                                this.showFormImage();
                              }}
                            >
                              <i class="fas fa-images"></i> Voir l'image
                            </button>
                            {this.state.showFormImage && (
                              <BrandImage idBrand={this.state.idBrand} />
                            )}
                          </td>
                          <td>
                            <button
                              class="btn btn-info btn-sm"
                              data-toggle="modal"
                              data-target="#modal-update"
                              onClick={() => {
                                this.setState({ idBrand: brand.id });
                                this.showFormImage();
                              }}
                            >
                              <i class="fas fa-pencil-alt"></i> Modifier
                            </button>
                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-update">
                                <BrandUpdateLibelle
                                  idBrand={this.state.idBrand}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {this.labelVisible(brand.id, brand.visible)}
                            <br />
                            {this.buttonVisible(brand.id, brand.visible)}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-visible">
                                <BrandVisible idBrand={this.state.idBrand} />
                              </div>
                            )}
                          </td>
                          <td>
                            {this.buttonArchive(brand.id, brand.isArchived)}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-default">
                                <BrandDelete idBrand={this.state.idBrand} />
                              </div>
                            )}
                          </td>
                          <td>
                            <button
                              class="btn btn-primary btn-sm"
                              onClick={() => {
                                sessionStorage.setItem("idBrand", brand.id);
                                this.showFormImage();
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/allProductsByBrand",
                                }}
                                style={{ color: "white" }}
                              >
                                <i class="fas fa-folder"></i> Voir les produits
                              </Link>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
