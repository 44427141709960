import { useEffect, useState } from "react";
import Pagination from "src/common/Pagination";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
  faRecycle,
  faTrash,
  faTrashRestore,
  faYen,
} from "@fortawesome/free-solid-svg-icons";
import ShopThematiqueService from "src/services/ShopThematiqueService";
import ArchiverAndVisibleBanniereThematique from "./ArchiverAndVisibleBanniereThematique";
import BanniereThematiqueUpdateModal from "./BanniereThematiqueUpdateModal";

export default function BanniereThematiqueHomePage() {
  const [idthematiqueBanniere, setIdthematiqueBanniere] = useState("");
  const [ThematiqueBanniere, setThematiqueBanniere] = useState([]);
  const [ShowFormImage, setShowFormImage] = useState(false);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  //**************for archiver or visibled
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [isModalArchiverOrVisibleOpen, setIsModalArchiverOrVisibleOpen] =
    useState(false);
  const [value, setValue] = useState(null);
  const [banniereItem, setBanniereItem] = useState("");
  const [index, setIndex] = useState("");

  const openModalArchiverOrVisible = (index, thematique, value) => {
    setIsModalArchiverOrVisibleOpen(true);
    setIndex(ThematiqueBanniere[index]?.id);
    setValue(value);
    setBanniereItem(thematique);
  };
  //**************for update Modal
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);

  const openModalUpdate = (thematique) => {
    setIsModalUpdateOpen(true);
    setBanniereItem(thematique);
  };

  useEffect(() => {
    getAllThematiqueBanniere();
    // getNombreThematiqueBanniere();
  }, [currentPage, itemsPerPage]);

  const getAllThematiqueBanniere = () => {
    ShopThematiqueService.allShopThematiqueHome(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setThematiqueBanniere(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <div className="content-wrapper" style={{ marginTop: "0" }}>
      <div className="content">
        <div className="container-fluid">
          <ToastContainer />
          <div className="row mb-2">
            <div className="col-sm-12">
              <div class="card  mt-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <b>Les Bannieres Thematiques </b>
                  </h5>
                  <button
                    className="btn btn-dark float-right"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={() =>
                      (window.location.href = "/addBanniereThematiqueHome")
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              <thematiqueBanniere class="content">
                <div class="card card-dark">
                  <div class="card-header">
                    <h3 class="card-title">
                      Les Bannieres Thematiques disponibles
                    </h3>

                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <table class="table  projects">
                      <thead>
                        <tr>
                          <th>Titre </th>
                          <th>Type Banniere</th>
                          <th>Url Site</th>
                          <th>Modifier</th>
                          <th>Visibilité</th>
                          <th>Archiver</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ThematiqueBanniere.length > 0 ? (
                          <>
                            {ThematiqueBanniere &&
                              ThematiqueBanniere.map(
                                (thematiqueBanniere, index) => (
                                  <tr
                                    key={index}
                                    // style={{
                                    //   backgroundColor:
                                    //     thematiqueBanniere?.visible &&
                                    //     !thematiqueBanniere?.archived
                                    //       ? "#E6E6E9"
                                    //       : "none",
                                    // }}
                                  >
                                    <td>{thematiqueBanniere.titre}</td>
                                    <td>
                                      {thematiqueBanniere?.type_banniere || ""}
                                    </td>
                                    <td>
                                      <>
                                        <button
                                          style={{ width: "190px" }}
                                          className="btn btn-dark btn-sm"
                                          onClick={() => {
                                            if (
                                              thematiqueBanniere?.type_banniere ===
                                              "Page d'accueil"
                                            ) {
                                              window.open(
                                                thematiqueBanniere?.url_page,
                                                "_blank"
                                              );
                                            }
                                          }}
                                          disabled={
                                            thematiqueBanniere?.type_banniere !==
                                            "Page d'accueil"
                                          }
                                        >
                                          {thematiqueBanniere?.type_banniere ==
                                          "Page d'accueil" ? (
                                            <>
                                              Voir Lien{" "}
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                className="ml-2"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              Pas de Lien{" "}
                                              <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                className="ml-2"
                                              />
                                            </>
                                          )}
                                        </button>
                                      </>
                                    </td>
                                    <td>
                                      <button
                                        style={{ width: "190px" }}
                                        className="btn btn-warning btn-sm"
                                        data-toggle="modal"
                                        data-target="#exampleModalEditThematique"
                                        onClick={() =>
                                          openModalUpdate(thematiqueBanniere)
                                        }
                                      >
                                        Modifier
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="ml-2"
                                        />
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        style={{ width: "190px" }}
                                        className={
                                          thematiqueBanniere?.visible
                                            ? "btn btn-success btn-sm"
                                            : "btn btn-secondary btn-sm"
                                        }
                                        data-toggle="modal"
                                        data-target="#exampleModalArchiverOrVisible"
                                        onClick={() =>
                                          openModalArchiverOrVisible(
                                            index,
                                            thematiqueBanniere,
                                            "visible"
                                          )
                                        }
                                      >
                                        {thematiqueBanniere?.visible ? (
                                          <>
                                            Rendre InVisible{" "}
                                            <FontAwesomeIcon
                                              icon={faEyeSlash}
                                              className="ml-2"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            Rendre Visible{" "}
                                            <FontAwesomeIcon
                                              icon={faEye}
                                              className="ml-2"
                                            />
                                          </>
                                        )}
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        style={{ width: "190px" }}
                                        className={
                                          thematiqueBanniere?.archived
                                            ? "btn btn-danger btn-sm"
                                            : "btn btn-secondary btn-sm"
                                        }
                                        data-toggle="modal"
                                        data-target="#exampleModalArchiverOrVisible"
                                        onClick={() =>
                                          openModalArchiverOrVisible(
                                            index,
                                            thematiqueBanniere,
                                            "archiver"
                                          )
                                        }
                                      >
                                        {thematiqueBanniere?.archived ? (
                                          <>
                                            Rendre Not Archived{" "}
                                            <FontAwesomeIcon
                                              icon={faRecycle}
                                              className="ml-2"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            Rendre Archived{" "}
                                            <FontAwesomeIcon
                                              icon={faTrash}
                                              className="ml-2"
                                            />
                                          </>
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                )
                              )}
                          </>
                        ) : (
                          <>
                            <tr className="text-center">
                              <td colSpan="5">
                                Pas de thematiques Bannieres pour le moment
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    {ThematiqueBanniere.length > 0 && (
                      <>
                        <div className="d-flex" style={{ padding: "15px" }}>
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(newPage) => setCurrentPage(newPage)}
                          />
                          <div className="ml-3">
                            <select
                              className="form-control pagination-select float-right"
                              id="itemsPerPageSelect"
                              value={itemsPerPage}
                              onChange={(e) =>
                                setItemsPerPage(parseInt(e.target.value, 10))
                              }
                            >
                              {[4, 7, 10, 15, 20].map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </thematiqueBanniere>
            </div>
          </div>
        </div>
      </div>
      {isModalArchiverOrVisibleOpen && (
        <ArchiverAndVisibleBanniereThematique
          setIsModalArchiverOrVisibleOpen={setIsModalArchiverOrVisibleOpen}
          id={index}
          value={value}
          banniereItem={banniereItem}
        />
      )}
      {isModalUpdateOpen && (
        <BanniereThematiqueUpdateModal
          setIsModalUpdateOpen={setIsModalUpdateOpen}
          banniereItem={banniereItem}
        />
      )}
    </div>
  );
}
