import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class BrandService {
  addBrand(picture, nameBrands, visible) {
    return api.post(urlsCommon.BASE_BRAND_URL + "/addBrands", picture, {
      params: {
        nameBrands,
        visible,
      },
    });
    // return api.get(urlsCommon.BASE_BRAND_URL+"/getBrandsById/"+1);
  }
  getAllBrand() {
    return api.get(urlsCommon.BASE_BRAND_URL + "/getAllBrandS/");
  }
  getNumberAllBrand() {
    return api.get(urlsCommon.BASE_BRAND_URL + "/getNumberAllBrand");
  }

  getBrandById(idBrand) {
    return api.get(urlsCommon.BASE_BRAND_URL + "/getBrandsById/" + idBrand);
  }

  archiveBrand(idBrands, deed) {
    return api.get(
      urlsCommon.BASE_BRAND_URL + "/archiveBrands/" + idBrands + "/" + deed
    );
  }
  updatePicture(picture, idBrands) {
    return api.put(urlsCommon.BASE_BRAND_URL + "/updatePicture", picture, {
      params: {
        idBrands,
      },
    });
  }
  updateName(idBrands, name) {
    return api.put(
      urlsCommon.BASE_BRAND_URL + "/updateName/" + idBrands + "/" + name
    );
  }

  visibleBrand(idBrand, deed) {
    return api.put(
      urlsCommon.BASE_BRAND_URL + "/visibleBrand/" + idBrand + "/" + deed
    );
  }
}
export default new BrandService();
