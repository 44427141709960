import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "src/common/Pagination";
import ThematiqueService from "src/services/ThematiqueService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import NouveauteService from "src/services/NouveauteService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ArchivedProductThematique from "./ArchivedProductThematique";

function ThematiqueProducts() {
  const [thematiqueProductsList, setthematiqueProductsList] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [index, setIndex] = useState("");
  const [thematiqueProducts, setthematiqueProducts] = useState({});
  const [nbProducts, setNbProducts] = useState(0);
  //select produits
  const animatedComponents = makeAnimated();
  const [listAllProducts, setListAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsDefault, setSelectedProductsDefault] = useState([]);
  //modal
  const [isModalSuppressionOpen, setIsModalSuppressionOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState("");
  const [thematiqueSelected, setThematiqueSelected] = useState("");

  useEffect(() => {
    getAllProductsForAddWithSearch();
    getProductsForThematiquesPage(
      localStorage.getItem("idProductsThematique"),
      currentPage - 1,
      itemsPerPage
    );
    // getNombreProduitsNouveaute();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    getAllProductsForAddWithSearch();
  }, []);

  const getAllProductsForAddWithSearch = () => {
    NouveauteService.getAllProductsForAddNew()
      .then((response) => {
        if (response.data.success) {
          setListAllProducts(response.data.success);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getProductsForThematiquesPage = (
    idThematique,
    currentPage,
    itemsPerPage
  ) => {
    ThematiqueService.getProductsForThematiquesPage(
      idThematique,
      currentPage,
      itemsPerPage
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setthematiqueProductsList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
  };

  const updateProductsForThematiquePage = (idThematique, idsProducts) => {
    ThematiqueService.updateProductsForThematiquePage(idThematique, idsProducts)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setSelectedProducts([]);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const openModalSuppression = (thematique, produit) => {
    setIsModalSuppressionOpen(true);
    setProductToDelete(produit);
    setThematiqueSelected(thematique);
  };
  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les Produits de thematique </b> :
                    </h5>
                    <br />
                    <div className="mt-3"></div>

                    <div className="form-group">
                      <label>Ajouter des produits</label>
                      <div className="row">
                        <div className="col">
                          <Select
                            defaultValue={selectedProductsDefault}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={listAllProducts}
                            onChange={handleProductChange}
                            value={selectedProducts}
                          />
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-dark"
                            onClick={(e) =>
                              updateProductsForThematiquePage(
                                localStorage.getItem("idProductsThematique"),
                                selectedProducts.map((product) => product.value)
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">
                        Les Produits de thematique disponibles
                      </h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th># ref</th>
                            <th>produit</th>
                            <th>Suppression</th>
                          </tr>
                        </thead>
                        <tbody>
                          {thematiqueProductsList &&
                          thematiqueProductsList.length > 0 &&
                          Array.isArray(thematiqueProductsList) ? (
                            thematiqueProductsList.map(
                              (thematiqueProducts, index) => (
                                <tr key={index}>
                                  <td>
                                    <b>
                                      {thematiqueProducts?.ref_product || ""}
                                    </b>
                                  </td>
                                  <td>
                                    {thematiqueProducts?.name_product || ""}
                                  </td>

                                  <td>
                                    <div>
                                      <button
                                        style={{ width: "190px" }}
                                        className="btn btn-sm btn-danger"
                                        data-toggle="modal"
                                        data-target="#exampleModalthematiqueProducts"
                                        onClick={() => {
                                          openModalSuppression(
                                            localStorage.getItem(
                                              "thematiqueName"
                                            ),
                                            thematiqueProducts
                                          );
                                        }}
                                      >
                                        Supprimer
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="ml-2"
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <b>Pas de Produits pour cette thematique</b>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {thematiqueProductsList.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {isModalSuppressionOpen && (
          <ArchivedProductThematique
            thematiqueName={thematiqueSelected}
            idThematique={localStorage.getItem("idProductsThematique")}
            setIsModalSuppressionOpen={setIsModalSuppressionOpen}
            productToDelete={productToDelete}
          />
        )}
      </div>
    </>
  );
}

export default ThematiqueProducts;
