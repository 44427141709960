
//import categories from "./categories"
import staff from "./staff"
//import societe from "./societe"
import { combineReducers } from "redux";

const reducers = combineReducers({
  
   
    staff,
    //societe ,
});

export default reducers;