import axios from "axios";
import { Component } from "react";
import Files from "react-files";
import ProductService from "../../services/ProductService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class AddSmallImagesProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idProd: this.props.idProduct,
      filesPetites: [],
    };
  }
  onFilesSmallChange = (filesPetites) => {
    this.setState(
      {
        filesPetites,
      },
      () => {
        console.log(this.state.filesPetites);
      }
    );
  };

  onFilesSmallError = (error, fileSmall) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesSmallRemoveOne = (fileSmall) => {
    this.refs.filesPetites.removeFile(fileSmall);
  };

  filesSmallRemoveAll = () => {
    this.refs.filesPetites.removeFiles();
  };

  filesSmallUpload = () => {
    const formData = new FormData();
    Object.keys(this.state.filesPetites).forEach((key) => {
      const file = this.state.filesPetites[key];
      formData.append(
        "smPictures",
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });
    let idProduct = this.state.idProd;
    ProductService.addSmallPictures(formData, idProduct)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout des petites images avec succée");
        } else {
          toast.error(response.data["object"]);
        }
      })
      .catch((err) => {
        toast.error("une erreur a survenue");
      });
  };
  render() {
    if (this.state.idProduct === "") {
      return <label>Vous devez ajouter les informations du produit </label>;
    } else {
      return (
        <form>
          <div className="card-body">
            <ToastContainer />
            <div>
              <Files
                ref="filesPetites"
                className="files-dropzone-list"
                // style={{ height: "100px" }}
                onChange={this.onFilesSmallChange}
                onError={this.onFilesSmallError}
                multiple
                maxFiles={5}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <label>Cliquer pour Ajouter des images 300*300px</label>
              </Files>

              {this.state.filesPetites.length > 0 ? (
                <div className="files-list">
                  <ul>
                    {this.state.filesPetites.map((file) => (
                      <li className="files-list-item" key={file.id}>
                        <div className="files-list-item-preview">
                          {file.preview.type === "image" ? (
                            <div className="row">
                              <img
                                className="files-list-item-preview-image"
                                src={file.preview.url}
                              />
                              <button
                                id={file.id}
                                class="btn btn-navbar"
                                type="button"
                                // data-widget="navbar-search"
                                onClick={this.filesSmallRemoveOne.bind(
                                  this,
                                  file
                                )}
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </div>
                          ) : (
                            <div className="files-list-item-preview-extension">
                              {file.extension}
                            </div>
                          )}
                        </div>
                        <div className="files-list-item-content">
                          <div className="files-list-item-content-item files-list-item-content-item-1">
                            {file.name}
                          </div>
                          <div className="files-list-item-content-item files-list-item-content-item-2">
                            {file.sizeReadable}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <h1></h1>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <button
                type="reset"
                class="btn btn-success col cancel "
                onClick={this.filesSmallUpload}
              >
                <i class="fas fa-upload"></i>
                <span> Upload </span>
              </button>
            </div>
            <div className="col-md-6">
              <button
                type="reset"
                class="btn btn-danger col cancel float-right"
                onClick={this.filesSmallRemoveAll}
              >
                <i class="fas fa-times-circle"></i>
                <span> Annuler</span>
              </button>
            </div>
          </div>
        </form>
      );
    }
  }
}
