import { Component } from "react";
import ProductService from "../services/ProductService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router";

export default class ProductByRef extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: "",
      products: "",
      showProducts: false,
      navigateUrl: false,
    };
    this.changeRef = this.changeRef.bind(this);
  }

  changeRef(event) {
    this.setState({ ref: event.target.value });
    console.log("ref", this.state.ref);
  }

  ProductAndValidation() {
    if (this.state.showProducts) {
      console.log("product", this.state.products);
      if (this.state.products === "") {
        return <h3>aucun produit</h3>;
      } else {
        return (
          <section class="content">
            <div className="card">
              {/* <h3 className="card-title">Toutes les marques</h3> */}
              <div className="card-tools">
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Libellé</th>
                        <th>Référence</th>
                        {/* <th>Minimum stock</th> */}
                        <th>Voir les détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.products.name}</td>
                        <td>{this.state.products.ref} </td>
                        {/* <td>{this.state.products.short_desc}</td> */}
                        <td>
                          <button
                            class="btn btn-primary btn-sm"
                            onClick={() => {
                              sessionStorage.setItem(
                                "idProductList",
                                this.state.products.id
                              );

                              this.setState({ navigateUrl: true });
                            }}
                          >
                            {/* <Link
                                to={{
                                  pathname: "/detailsProduct",
                                }}
                                style={{ color: "white" }}
                                onClick={sessionStorage.setItem(
                                  "idProductList",
                                  product.id
                                )}
                              > */}
                            <i class="fas fa-folder"></i> Voir
                            {/* </Link> */}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        );
      }
    }
  }
  findProduct = (e) => {
    e.preventDefault();

    ProductService.getProductByRef(this.state.ref)
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ products: response.data["object"] });
          this.setState({ showProducts: true });
          // toast.success("Ajout du produit avec succée");
          // console.log("idProduct", this.state.idProduct);
        } else {
          toast.error(response.data["code"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("Une erreur a survenue");
      });
  };

  render() {
    if (this.state.navigateUrl) {
      return <Navigate to="/detailsProduct" />;
    } else {
      return (
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid">
              <div class="callout callout-success">
                <h5>Produit par référence</h5>
              </div>
            </div>
          </section>

          <section class="content">
            <div className="card card-success card-outline ">
              <div class="card-header">
                <h3 class="card-title"> Choisir un produit Référence</h3>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-2">
                      <label>Ref Produit</label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="Ref"
                        className="form-control"
                        placeholder="Choisir un référence"
                        value={this.state.ref}
                        onChange={this.changeRef}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-footer">
                <button
                  type="submit"
                  className="btn btn-success float-right"
                  onClick={this.findProduct}
                >
                  Voir
                </button>
              </div>
            </div>
          </section>

          {this.ProductAndValidation()}
        </div>
      );
    }
  }
}
