import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import NouveauteService from "src/services/NouveauteService";
import ThematiqueService from "src/services/ThematiqueService";

function AddThematiquePage() {
  const [listAllProducts, setListAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsDefault, setSelectedProductsDefault] = useState([]);
  const animatedComponents = makeAnimated();
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [thematique, setThematique] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getAllProductsForAddWithSearch();
  }, []);

  function onSearchChange(e) {
    setSearchTerm(e.target.value);
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setThematique({ ...thematique, [name]: value });
  };
  const addThematiquePage = () => {
    //test sur les inputs
    if (
      thematique.titre === undefined ||
      thematique.description === undefined ||
      imageFile == null
    ) {
      toast.error("Merci de renseigner les informations à ajouter.");
      return;
    }
    const idsProducts = selectedProducts.map((product) => product.value);

    if (idsProducts === undefined || idsProducts.length === 0) {
      toast.error("Merci de renseigner les informations à ajouter.");
      return;
    }

    const thematiqueModel = {
      titre: thematique.titre,
      description: thematique.description,
      idsProducts: idsProducts,
    };

    ThematiqueService.addThematiquePage(thematiqueModel, imageFile)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setSelectedProducts([]);
          setThematique({});
          handleDeleteClick();
          setTimeout(function () {
            window.location.href = "/allThematiquesPages";
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const getAllProductsForAddWithSearch = () => {
    NouveauteService.getAllProductsForAddNew()
      .then((response) => {
        if (response.data.success) {
          setListAllProducts(response.data.success);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
  };

  //******for image */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    // Update the label text with the file name
    const label = document.getElementById("fileInputLabel");
    if (label) {
      label.innerText = file ? file.name : "Choose file";
    }

    // Update image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleDeleteClick = () => {
    const fileInput = document.getElementById("exampleInputFile");
    const label = document.getElementById("fileInputLabel");

    if (fileInput && label) {
      fileInput.value = null;
      label.innerText = "Choose file";
      setImageFile(null);
      setImagePreview(null);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Configurer thematique page</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Titre thematique page :</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control float-right"
                        placeholder="titre"
                        name="titre"
                        onChange={handleChange}
                        value={thematique.titre || ""}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description thematique page :</label>
                    <div className="input-group">
                      <textarea
                        type="text"
                        className="form-control float-right"
                        placeholder="description"
                        name="description"
                        onChange={handleChange}
                        value={thematique.description || ""}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          Image Banniere de ratio 0.20
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={handleFileChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                              id="fileInputLabel"
                            >
                              Choose file
                            </label>
                          </div>
                          {imageFile && (
                            <>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={handleDeleteClick}
                                >
                                  Delete
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm-6">
                      {imageFile && (
                        <>
                          <div className="input-group-append">
                            <img
                              src={imagePreview}
                              alt="Selected"
                              style={{ width: "30%" }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Sélectionner Les produits</label>

                    <Select
                      defaultValue={selectedProductsDefault || ""}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={listAllProducts}
                      onChange={handleProductChange}
                      value={selectedProducts}
                      placeholder="Sélectionner Les produits..."
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default">
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={addThematiquePage}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddThematiquePage;
