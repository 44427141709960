import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class MultivendeurService {
  uploadFileImage(data) {
    return api.post(urlsCommon.BASE_MULTIVENDEUR_URL + "/uploadImage", data);
  }
  addMultivendeur(multivendeurModel) {
    return api.post(
      urlsCommon.BASE_MULTIVENDEUR_URL + "/addMultivendeur",
      multivendeurModel
    );
  }
  getAllMultivendeur() {
    return api.get(urlsCommon.BASE_MULTIVENDEUR_URL + "/multivendeurs");
  }
  deleteMultivendeur(id, isArchive) {
    return api.get(
      urlsCommon.BASE_MULTIVENDEUR_URL +
        "/deleteMultivendeur/" +
        id +
        "/" +
        isArchive
    );
  }
}
export default new MultivendeurService();
