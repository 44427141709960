// import update from "immutability-helper";
const initialState = {
  staff: [],
  suppliers: [],
  transporters: [],
  products: [],
  warehouses: [],
  availableBrands: [],
  archivedBrands: [],
  availableRealWarehouses: [],
  archivedRealWarehouses: [],
  realWarehouseNotification: null,
  availableFamilies: [],
  archivedFamilies: [],
  availableSubFamilies: [],
  archivedSubFamilies: [],
  availableOrderPurchase: [],
  archivedOrderPurchase: [],
  orderPurchaseNotification: null,
  availableCategories: [],
  availableProduct: null,
  archivedCategories: [],
  availableProducts: [],
  archivedProducts: [],
  productNotification: null,
  categoryNotification: null,
  brandNotification: null,
  FamilyNotification: null,
  subFamilyNotification: null,
  productsByWarehouse: null,
  warehousesManager: [],
  productPurchaseToVWarehouse: [],
  productPurchaseToVWarehouseError: null,
  warehouseTransaction: null,
  warehouseTransactionError: null,
  realWarehouse: [],
  realWarehouseError: null,
  ProductQuantitytByWarehouse: null,
  error: null,
  login: null,
};

function reverseString(str) {
  // empty string
  let firstIndex = str.indexOf("-");
  let lastIndex = str.lastIndexOf("-");
  let year = "";
  let month = "";
  let day = "";
  for (let index = lastIndex + 1; index < str.length; index++) {
    year = year + str[index];
  }
  if (firstIndex === 1) {
    month = "0" + str[0];
  } else {
    month = str[0] + str[1];
  }

  for (let index = firstIndex + 1; index < lastIndex; index++) {
    if (firstIndex + 1 === lastIndex - 1) {
      day = "0" + str[index];
    } else {
      day = day + str[index];
    }
  }

  let newString = year + "-" + month + "-" + day;

  return newString;
}

const staff = (state = initialState, action) => {
  switch (action.type) {
    case "GET_Staff_REQUESTED":
      return { ...state };
    case "GET_Staff_SUCCESS":
      return { ...state, staff: action.staff };
    case "GET_Staff_FAILED":
      return { ...state, error: action.error };

    case "LOGIN_REQUESTED":
      return { ...state };
    case "LOGIN_SUCCESS":
      return { ...state, login: action.login };
    case "LOGIN_FAILED":
      return { ...state, error: action.error };

    case "GET_SUPPLIERS_REQUESTED":
      return { ...state };
    case "GET_SUPPLIERS_SUCCESS":
      return { ...state, suppliers: action.suppliers };
    case "GET_SUPPLIERS_FAILED":
      return { ...state, error: action.error };

    case "GET_Products_REQUESTED":
      return { ...state };
    case "GET_Products_SUCCESS":
      return { ...state, products: action.products };
    case "GET_Products_FAILED":
      return { ...state, error: action.error };

    case "GET_WAREHOUSES_REQUESTED":
      return { ...state };
    case "GET_WAREHOUSES_SUCCESS":
      state.warehouses = [];
      return { ...state, warehouses: action.warehouses };
    case "GET_WAREHOUSES_FAILED":
      return { ...state, error: action.error };

    case "GET_ALLWAREHOUSES_MANAGER_REQUESTED":
      return { ...state };
    case "GET_ALLWAREHOUSES_MANAGER_SUCCESS":
      return { ...state, warehousesManager: action.warehousesManager };
    case "GET_ALLWAREHOUSES_MANAGER_FAILED":
      return { ...state, error: action.error };

    case "POST_REAL_WAREHOUSE_REQUESTED":
      return { ...state };
    case "POST_REAL_WAREHOUSE_SUCCESS":
      return { ...state, realWarehouse: action.realWarehouse };
    case "POST_REAL_WAREHOUSE_FAILED":
      return { ...state, realWarehouseError: action.error };

    case "POST_PRODUCT_PURCHASE_TOVWAREHOUSE_REQUESTED":
      return { ...state };
    case "POST_PRODUCT_PURCHASE_TOVWAREHOUSE_SUCCESS":
      return {
        ...state,
        productPurchaseToVWarehouse: action.productPurchaseToVWarehouse,
      };
    case "POST_PRODUCT_PURCHASE_TOVWAREHOUSE_FAILED":
      return { ...state, productPurchaseToVWarehouse: action.error };

    case "GET_PRODUCT_QUANTITY_BY_WAREHOUSE_REQUESTED":
      return { ...state };
    case "GET_PRODUCT_QUANTITY_BY_WAREHOUSE_SUCCESS":
      return {
        ...state,
        ProductQuantitytByWarehouse: action.ProductQuantitytByWarehouse,
      };
    case "GET_PRODUCT_QUANTITY_BY_WAREHOUSE_FAILED":
      return { ...state, ProductQuantitytByWarehouse: action.error };

    case "GET_PRODUCTS_BY_WAREHOUSE_REQUESTED":
      return { ...state };
    case "GET_PRODUCTS_BY_WAREHOUSE_SUCCESS":
      return { ...state, productsByWarehouse: action.productsByWarehouse };
    case "GET_PRODUCTS_BY_WAREHOUSE_FAILED":
      return { ...state, productsByWarehouse: action.error };

    case "POST_ASKING_WAREHOUSE_TRANSACTION_REQUESTED":
      return { ...state };
    case "POST_ASKING_WAREHOUSE_TRANSACTION_SUCCESS":
      return { ...state, warehouseTransaction: action.warehouseTransaction };
    case "POST_ASKING_WAREHOUSE_TRANSACTION_FAILED":
      return { ...state, warehouseTransactionError: action.error };

    case "GET_ALLTRANSPORTERS_REQUESTED":
      return { ...state };
    case "GET_ALLTRANSPORTERS_SUCCESS":
      return { ...state, transporters: action.transporters };
    case "GET_ALLTRANSPORTERS_FAILED":
      return { ...state, transporters: action.error };

    case "GET_ALLBRANDS_REQUESTED":
      return { ...state };
    case "GET_ALLBRANDS_SUCCESS":
      if (action.isDeleted === 0) {
        return { ...state, availableBrands: action.brands };
      } else {
        return { ...state, archivedBrands: action.brands };
      }

    case "GET_ALLBRANDS_FAILED":
      if (action.isDeleted === 0) {
        return { ...state, availableBrands: action.error };
      } else {
        return { ...state, archivedBrands: action.error };
      }

    case "POST_BRAND_REQUESTED":
      return { ...state };
    case "POST_BRAND_SUCCESS":
      return {
        // ...state,
        // availableBrands: action.brand,
        // ...state,
        // availableBrands: [...state.availableBrands, action.brand],
        // ...state,
        // availableBrands: state.availableBrands.concat(action.brand),

        // ...state,
        // availableBrands: [...state.availableBrands.brand, 5],
        ...state,
        availableBrands: [
          {
            ...action.brand.success,
            creationDate: reverseString(
              new Date().toLocaleDateString("en-US").replaceAll("/", "-")
            ),
          },
          ...state.availableBrands,
        ],
        brandNotification: "success",
      };

    case "POST_BRAND_FAILED":
      return { ...state, brandNotification: "failed" };

    case "SET_BRAND_NOTIFICATION_REQUESTED":
      return { ...state };
    // case "GET_PRODUCTS_BY_WAREHOUSE_SUCCESS":
    //   return { ...state, brandNotification: action.notificationSetter };
    // case "GET_PRODUCTS_BY_WAREHOUSE_FAILED":
    //   return { ...state, brandNotification: action.error };

    case "SET_BRANDS_ISDELETED_REQUESTED":
      return { ...state };
    case "SET_BRANDS_ISDELETED_SUCCESS":
      if (action.isDeleted === 1) {
        let brandIndex = 0;

        state.availableBrands.forEach((element, index) => {
          if (element.id === action.idBrand) {
            brandIndex = index;
          }
        });

        return {
          ...state,
          availableBrands: state.availableBrands.filter(
            (item, index) => index !== brandIndex
          ),
          archivedBrands: [
            action.deletedAndRestoredBrand.success,
            ...state.archivedBrands,
          ],
        };
      } else {
        let brandIndex = 0;

        state.archivedBrands.forEach((element, index) => {
          if (element.id === action.idBrand) {
            brandIndex = index;
          }
        });
        return {
          ...state,
          archivedBrands: state.archivedBrands.filter(
            (item, index) => index !== brandIndex
          ),
          availableBrands: [
            action.deletedAndRestoredBrand.success,
            ...state.availableBrands,
          ],
        };
      }
    case "SET_BRANDS_ISDELETED_FAILED":
      return { ...state, brandNotification: action.error };

    case "UPDATE_BRAND_REQUESTED":
      return { ...state };
    case "UPDATE_BRAND_SUCCESS":
      let brandIndex = 0;

      state.availableBrands.forEach((element, index) => {
        if (element.id === action.idBrand) {
          brandIndex = index;
          console.log("brandindex", brandIndex);
        }
      });

      return {
        /*  ...state,

        availableBrands: state.availableBrands.filter(
          (item, index) => index !== brandIndex
        ),  availableBrands: [
          action.updatedBrand.success,
          ...state.availableBrands,
        ],*/
        // update(state, {
        //   contents: {
        //     [action.id]: {
        //       text: {$set: action.payload}
        //     }
        //   }
        // // });
        // update(productTableData, {
        //   [indexInDataTable]: {
        //     qte: { $set: amount },
        //   },
        // })

        // ...state,

        // availableBrands: [
        //   ...state.availableBrands,

        //     state.availableBrands[7].name ="helllo",

        //      name: action.updatedBrand.success.name,
        //     description: action.updatedBrand.success.description,

        //  ],
        ...state,
        availableBrands: state.availableBrands.map((content, i) =>
          i === brandIndex
            ? {
                ...content,
                name: action.updatedBrand.success.name,
                description: action.updatedBrand.success.description,
              }
            : content
        ),
      };

    case "UPDATE_BRAND_FAILED":
      return { ...state, availableBrands: action.error };

    case "POST_FAMILY_REQUESTED":
      return { ...state };
    case "POST_FAMILY_SUCCESS":
      return {
        ...state,
        availableFamilies: [
          {
            ...action.family.success,
            creationDate: reverseString(
              new Date().toLocaleDateString("en-US").replaceAll("/", "-")
            ),
          },
          ...state.availableFamilies,
        ],
        familyNotification: "success",
      };

    case "POST_FAMILY_FAILED":
      return { ...state, familyNotification: "failed" };

    case "GET_ALL_FAMILIES_REQUESTED":
      return { ...state };
    case "GET_ALL_FAMILIES_SUCCESS":
      if (action.isDeleted === 0) {
        return { ...state, availableFamilies: action.families };
      } else {
        return { ...state, archivedFamilies: action.families };
      }

    case "GET_ALL_FAMILIES_FAILED":
      if (action.isDeleted === 0) {
        return { ...state, availableFamilies: action.error };
      } else {
        return { ...state, archivedFamilies: action.error };
      }

    case "SET_FAMILY_ISDELETED_REQUESTED":
      return { ...state };
    case "SET_FAMILY_ISDELETED_SUCCESS":
      if (action.isDeleted === 1) {
        let familyIndex = 0;

        state.availableFamilies.forEach((element, index) => {
          if (element.id === action.idFamily) {
            familyIndex = index;
          }
        });

        return {
          ...state,
          availableFamilies: state.availableFamilies.filter(
            (item, index) => index !== familyIndex
          ),
          archivedFamilies: [
            action.deletedAndRestoredFamily.success,
            ...state.archivedFamilies,
          ],
        };
      } else {
        let familyIndex = 0;

        state.archivedFamilies.forEach((element, index) => {
          if (element.id === action.idFamily) {
            familyIndex = index;
          }
        });
        return {
          ...state,
          archivedFamilies: state.archivedFamilies.filter(
            (item, index) => index !== familyIndex
          ),
          availableFamilies: [
            action.deletedAndRestoredFamily.success,
            ...state.availableFamilies,
          ],
        };
      }
    case "SET_FAMILY_ISDELETED_FAILED":
      return { ...state, familyNotification: action.error };

    case "UPDATE_FAMILY_REQUESTED":
      return { ...state };
    case "UPDATE_FAMILY_SUCCESS":
      let familyIndex = 0;

      state.availableFamilies.forEach((element, index) => {
        if (element.id === action.idFamily) {
          familyIndex = index;
        }
      });

      return {
        ...state,
        availableFamilies: state.availableFamilies.map((content, i) =>
          i === familyIndex
            ? {
                ...content,
                name: action.updatedFamily.success.name,
                description: action.updatedFamily.success.description,
              }
            : content
        ),
      };

    case "UPDATE_FAMILY_FAILED":
      return { ...state, availableFamilies: action.error };

    //subFamily

    case "GET_ALL_SUB_FAMILIES_REQUESTED":
      return { ...state };
    case "GET_ALL_SUB_FAMILIES_SUCCESS":
      if (action.isDeleted === 0) {
        return { ...state, availableSubFamilies: action.subFamilies };
      } else {
        return { ...state, archivedSubFamilies: action.subFamilies };
      }

    case "GET_ALL_SUB_FAMILIES_FAILED":
      if (action.isDeleted === 0) {
        return { ...state, availableSubFamilies: action.error };
      } else {
        return { ...state, archivedSubFamilies: action.error };
      }

    case "GET_ALL_SUB_FAMILIES_BY_FAMILY_ID_REQUESTED":
      return { ...state };
    case "GET_ALL_SUB_FAMILIES_BY_FAMILY_ID_SUCCESS":
      return { ...state, availableSubFamilies: action.subFamilies };

    case "GET_ALL_SUB_FAMILIES_BY_FAMILY_ID_FAILED":
      return { ...state, availableSubFamilies: action.error };

    case "GET_ALL_CATEGORIES_BY_SUBFAMILY_ID_REQUESTED":
      return { ...state };
    case "GET_ALL_CATEGORIES_BY_SUBFAMILY_ID_SUCCESS":
      return { ...state, availableCategories: action.categories };

    case "GET_ALL_CATEGORIES_BY_SUBFAMILY_ID_FAILED":
      return { ...state, availableCategories: action.error };
    case "GET_PRODUCT_BY_ID_REQUESTED":
      return { ...state };
    case "GET_PRODUCT_BY_ID_SUCCESS":
      return { ...state, availableProduct: action.product };

    case "GET_PRODUCT_BY_ID_FAILED":
      return { ...state, availableProduct: action.error };

    case "SET_SUB_FAMILY_ISDELETED_REQUESTED":
      return { ...state };
    case "SET_SUB_FAMILY_ISDELETED_SUCCESS":
      if (action.isDeleted === 1) {
        let subFamilyIndex = 0;

        state.availableSubFamilies.forEach((element, index) => {
          if (element.id === action.idFamily) {
            subFamilyIndex = index;
          }
        });

        return {
          ...state,
          availableSubFamilies: state.availableSubFamilies.filter(
            (item, index) => index !== subFamilyIndex
          ),
          archivedSubFamilies: [
            action.deletedAndRestoredSubFamily.success,
            ...state.archivedSubFamilies,
          ],
        };
      } else {
        let subFamilyIndex = 0;

        state.archivedSubFamilies.forEach((element, index) => {
          if (element.id === action.idFamily) {
            subFamilyIndex = index;
          }
        });
        return {
          ...state,
          archivedSubFamilies: state.archivedSubFamilies.filter(
            (item, index) => index !== subFamilyIndex
          ),
          availableSubFamilies: [
            action.deletedAndRestoredSubFamily.success,
            ...state.availableSubFamilies,
          ],
        };
      }
    case "SET_SUB_FAMILY_ISDELETED_FAILED":
      return { ...state, SubFamilyNotification: action.error };

    case "POST_SUB_FAMILY_REQUESTED":
      return { ...state };
    case "POST_SUB_FAMILY_SUCCESS":
      return {
        ...state,
        availableSubFamilies: [
          action.subFamily.success,
          ...state.availableSubFamilies,
        ],
        subFamilyNotification: "success",
      };

    case "POST_SUB_FAMILY_FAILED":
      return { ...state, subFamilyNotification: "failed" };

    case "UPDATE_SUB_FAMILY_REQUESTED":
      return { ...state };
    case "UPDATE_SUB_FAMILY_SUCCESS":
      let subFamilyIndex = 0;

      state.availableSubFamilies.forEach((element, index) => {
        if (element.id === action.idSubFamily) {
          subFamilyIndex = index;
        }
      });

      return {
        ...state,
        availableSubFamilies: state.availableSubFamilies.map((content, i) =>
          i === subFamilyIndex
            ? {
                ...content,
                name: action.updatedSubFamily.success.name,
                description: action.updatedSubFamily.success.description,
                productFamilyId:
                  action.updatedSubFamily.success.productFamilyId,
                productFamilyName:
                  action.updatedSubFamily.success.productFamilyName,
              }
            : content
        ),
      };

    case "UPDATE_SUB_FAMILY_FAILED":
      return { ...state, availableFamilies: action.error };

    //Categories

    case "UPDATE_CATEGORY_REQUESTED":
      return { ...state };
    case "UPDATE_CATEGORY_SUCCESS":
      let categoryIndex = 0;

      state.availableCategories.forEach((element, index) => {
        if (element.id === action.idCategory) {
          categoryIndex = index;
        }
      });

      return {
        ...state,
        availableCategories: state.availableCategories.map((content, i) =>
          i === categoryIndex
            ? {
                ...content,
                name: action.updatedCategory.success.name,
                description: action.updatedCategory.success.description,
                productSubFamilyName:
                  action.updatedCategory.success.productSubFamilyName,
                productFamilyName:
                  action.updatedCategory.success.productFamilyName,
                // creationDate: action.updatedCategory.success.creationDate,
              }
            : content
        ),
      };

    case "UPDATE_CATEGORY_FAILED":
      return { ...state, availableCategories: action.error };

    case "GET_ALL_CATEGORIES_REQUESTED":
      return { ...state };
    case "GET_ALL_CATEGORIES_SUCCESS":
      if (action.isDeleted === 0) {
        return { ...state, availableCategories: action.categories };
      } else {
        return { ...state, archivedCategories: action.categories };
      }

    case "GET_ALL_CATEGORIES_FAILED":
      if (action.isDeleted === 0) {
        return { ...state, availableCategories: action.error };
      } else {
        return { ...state, archivedCategories: action.error };
      }

    case "SET_CATEGORY_ISDELETED_REQUESTED":
      return { ...state };
    case "SET_CATEGORY_ISDELETED_SUCCESS":
      if (action.isDeleted === 1) {
        let categoryIndex = 0;

        state.availableCategories.forEach((element, index) => {
          if (element.id === action.idCategory) {
            categoryIndex = index;
          }
        });

        return {
          ...state,
          availableCategories: state.availableCategories.filter(
            (item, index) => index !== categoryIndex
          ),
          archivedCategories: [
            action.deletedAndRestoredCategory.success,
            ...state.archivedCategories,
          ],
        };
      } else {
        let categoryIndex = 0;

        state.archivedCategories.forEach((element, index) => {
          if (element.id === action.idCategory) {
            categoryIndex = index;
          }
        });
        return {
          ...state,
          archivedCategories: state.archivedCategories.filter(
            (item, index) => index !== categoryIndex
          ),
          availableCategories: [
            action.deletedAndRestoredCategory.success,
            ...state.availableCategories,
          ],
        };
      }
    case "SET_CATEGORY_ISDELETED_FAILED":
      return { ...state, categoryNotification: action.error };

    case "POST_CATEGORY_REQUESTED":
      return { ...state };
    case "POST_CATEGORY_SUCCESS":
      return {
        ...state,
        availableCategories: [
          action.category.success,
          ...state.availableCategories,
        ],
        categoryNotification: "success",
      };

    case "POST_CATEGORY_FAILED":
      return { ...state, categoryNotification: "failed" };

    case "POST_PRODUCT_REQUESTED":
      return { ...state };
    case "POST_PRODUCT_SUCCESS":
      return {
        ...state,
        availableProducts: [action.product.success, ...state.availableProducts],
        productNotification: "success",
      };

    case "POST_PRODUCT_FAILED":
      return { ...state, productNotification: "failed" };

    case "GET_ALL_PRODUCTS_BY_FILTER_REQUESTED":
      return { ...state };
    case "GET_ALL_PRODUCTS_BY_FILTER_SUCCESS":
      if (action.isDeleted === 0) {
        return { ...state, availableProducts: action.products };
      } else {
        return { ...state, archivedProducts: action.products };
      }

    case "GET_ALL_PRODUCTS_BY_FILTER_FAILED":
      if (action.isDeleted === 0) {
        return { ...state, availableProducts: action.error };
      } else {
        return { ...state, archivedProducts: action.error };
      }

    case "SET_PRODUCT_ISDELETED_REQUESTED":
      return { ...state };
    case "SET_PRODUCT_ISDELETED_SUCCESS":
      if (action.isDeleted === 1) {
        let productIndex = 0;

        state.availableProducts.forEach((element, index) => {
          if (element.id === action.idProduct) {
            productIndex = index;
          }
        });

        return {
          ...state,
          availableProducts: state.availableProducts.filter(
            (item, index) => index !== productIndex
          ),
          archivedProducts: [
            action.deletedAndRestoredProduct.success,
            ...state.archivedProducts,
          ],
        };
      } else {
        let productIndex = 0;

        state.archivedProducts.forEach((element, index) => {
          if (element.id === action.idProduct) {
            productIndex = index;
          }
        });
        return {
          ...state,
          archivedProducts: state.archivedProducts.filter(
            (item, index) => index !== productIndex
          ),
          availableProducts: [
            action.deletedAndRestoredProduct.success,
            ...state.availableProducts,
          ],
        };
      }
    case "SET_PRODUCT_ISDELETED_FAILED":
      return { ...state, productNotification: action.error };

    case "GET_ALL_REAL_WAREHOUSE_REQUESTED":
      return { ...state };
    case "GET_ALL_REAL_WAREHOUSE_SUCCESS":
      if (action.isDeleted === 0) {
        return { ...state, availableRealWarehouses: action.warehouses };
      } else {
        return { ...state, archivedRealWarehouses: action.warehouses };
      }

    case "GET_ALL_REAL_WAREHOUSE_FAILED":
      if (action.isDeleted === 0) {
        return { ...state, availableRealWarehouses: action.error };
      } else {
        return { ...state, archivedRealWarehouses: action.error };
      }

    case "SET_REAL_WAREHOUSE_ISDELETED_REQUESTED":
      return { ...state };
    case "SET_REAL_WAREHOUSE_ISDELETED_SUCCESS":
      if (action.isDeleted === 1) {
        let warehouseIndex = 0;

        state.availableRealWarehouses.forEach((element, index) => {
          if (element.id === action.idWarehouse) {
            warehouseIndex = index;
          }
        });

        return {
          ...state,
          availableRealWarehouses: state.availableRealWarehouses.filter(
            (item, index) => index !== warehouseIndex
          ),
          archivedRealWarehouses: [
            action.deletedAndRestoredWarehouse.success,
            ...state.archivedRealWarehouses,
          ],
        };
      } else {
        let warehouseIndex = 0;

        state.archivedRealWarehouses.forEach((element, index) => {
          if (element.id === action.idWarehouse) {
            warehouseIndex = index;
          }
        });
        return {
          ...state,
          archivedRealWarehouses: state.archivedRealWarehouses.filter(
            (item, index) => index !== warehouseIndex
          ),
          availableRealWarehouses: [
            action.deletedAndRestoredWarehouse.success,
            ...state.availableRealWarehouses,
          ],
        };
      }
    case "SET_REAL_WAREHOUSE_ISDELETED_FAILED":
      return { ...state, realWarehouseNotification: action.error };

    case "UPDATE_REAL_WAREHOUSE_REQUESTED":
      return { ...state };
    case "UPDATE_REAL_WAREHOUSE_SUCCESS":
      let warehouseIndex = 0;

      state.availableRealWarehouses.forEach((element, index) => {
        if (element.id === action.idWarehouse) {
          warehouseIndex = index;
        }
      });

      return {
        ...state,
        availableRealWarehouses: state.availableRealWarehouses.map(
          (content, i) =>
            i === warehouseIndex
              ? {
                  ...content,
                  name: action.updatedWarehouse.name,
                  reference: action.updatedWarehouse.reference,
                  description: action.updatedWarehouse.description,
                  warhouseManagerName:
                    action.updatedWarehouse.warhouseManagerName,
                  WarehouseManegerId:
                    action.updatedWarehouse.WarehouseManegerId,
                }
              : content
        ),
      };

    case "UPDATE_REAL_WAREHOUSE_FAILED":
      return { ...state, availableRealWarehouses: action.error };

    case "GET_ALL_PRODUCTS_BY_BRAND_ORBY_CATEGORY_ORBY_SUBFAMILY_ORBY_FAMILY_REQUESTED":
      return { ...state };
    case "GET_ALL_PRODUCTS_BY_BRAND_ORBY_CATEGORY_ORBY_SUBFAMILY_ORBY_FAMILY_SUCCESS":
      return { ...state, availableProducts: action.products };
    case "GET_ALL_PRODUCTS_BY_BRAND_ORBY_CATEGORY_ORBY_SUBFAMILY_ORBY_FAMILY_FAILED":
      return { ...state, error: action.error };

    case "GET_ALL_PRODUCTS_BY_WAREHOUSE_REQUESTED":
      return { ...state };
    case "GET_ALL_PRODUCTS_BY_WAREHOUSE_SUCCESS":
      return { ...state, availableProducts: action.products };

    case "GET_ALL_PRODUCTS_BY_WAREHOUSE_FAILED":
      return { ...state, availableProducts: action.error };

    case "POST_ORDER_PURCHASE_TO_WAREHOUSE_REQUESTED":
      return { ...state };
    case "POST_ORDER_PURCHASE_TO_WAREHOUSE_SUCCESS":
      return {
        ...state,
        availableOrderPurchase: [
          action.orderPurchase.success,
          ...state.availableOrderPurchase,
        ],
        orderPurchaseNotification: "success",
      };

    case "POST_ORDER_PURCHASE_TO_WAREHOUSE_FAILED":
      return { ...state, orderPurchaseNotification: "failed" };

    default:
      return state;
  }
};

export default staff;
