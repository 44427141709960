import React, { useEffect, useState } from "react";

const MAX_LENGTH = 60;

const TruncatedContent = ({ content }) => {
  if (!content) return null;

  const truncatedText =
    content.length > MAX_LENGTH
      ? content.substring(0, MAX_LENGTH) + "..."
      : content;

  return <>{truncatedText}</>;
};

const EmailParser = ({ email }) => {
  const username = email.split("@")[0]; // Divise l'adresse e-mail en deux parties et sélectionne la première partie (le nom d'utilisateur)

  return <>{username}</>; // Affiche le nom d'utilisateur
};
const EmailTime = ({ emailDate }) => {
  const [displayTime, setDisplayTime] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const emailDateTime = new Date(emailDate);
    const timeDifference = (currentDate - emailDateTime) / (1000 * 60); // Difference in minutes

    const dayOfMonth = emailDateTime.getDate();
    const monthIndex = emailDateTime.getMonth();
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];

    const formattedDate = `${dayOfMonth} ${months[monthIndex]}`;

    if (timeDifference < 60) {
      setDisplayTime(`${Math.floor(timeDifference)} min`);
    } else if (timeDifference < 24 * 60) {
      setDisplayTime(`${Math.floor(timeDifference / 60)} heures`);
    } else {
      setDisplayTime(formattedDate);
    }
  }, [emailDate]);

  return <span>{displayTime}</span>;
};
export { TruncatedContent, EmailParser, EmailTime };
