import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import TagsService from "../../services/TagsService";
import TagsPopup from "../ProductPopupModel/TagsPopup";

export default class AddTagsProduct extends Component {
  //   state = {
  //     idProduct: "",
  //     tags: [],
  //     selectedOption: "",
  //     showTags: false,
  //     options: [],
  //     idTag: "",
  //   };
  constructor(props) {
    super(props);
    this.state = {
      idProduct: this.props.idProduct,
      showTagsProd: false,
      tags: [],
      selectedOption: "",
      options: [],
      tagsProduct: [],
      tagChanged: false,
      idTag: "",
    };
  }

  componentDidMount() {
    this.forceUpdate();
    TagsService.getAllTagsInSubFamily(this.state.idProduct)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ tags: response.data["object"] });
          console.log("tags", this.state.tags);
          // this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });


  }
  remplissageOptions() {
    this.state.tags.map((tag) =>
      this.setState({
        options: [...this.state.options, { value: tag.id, label: tag.name }],
      })
    );
  }

  // listTagsBackEnd() {

  //   TagsService.getAllTagsProducts(this.state.idProduct)
  //     .then((response) => {
  //       console.log("response", response.data["code"]);
  //       if (response.data["code"] === "201") {
  //         this.setState({ tags: response.data["object"] });
  //         console.log("tags", this.state.tags);
  //         // this.remplissageOptions();
  //       } else {
  //         console.log("response", "error");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("response", "error");
  //     });

  // }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );

    this.setState({ idTag: selectedOption.value });

    console.log("idTag", this.state.idTag);
  };

  showTagsProduct() {
    if (this.state.tagChanged || this.state.showTagsProd) {
      TagsService.getTagsProduct(this.state.idProduct)
        .then((response) => {
          console.log("response", response.data["code"]);
          if (response.data["code"] === "201") {
            this.setState({ tagsProduct: response.data["object"] });
            this.setState({ showTagsProd: false });
            this.setState({ tagChanged: false });

            console.log("tagsProduct", this.state.tagsProduct);
          } else {
            console.log("response", "error");
          }
        })
        .catch((error) => {
          console.log("response", "error");
        });
    }
    if (this.state.tagsProduct.length !== 0) {
      return (
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Tag</th>
              <th>Type</th>
              <th>Valeur</th>
              <th>Unité</th>
            </tr>
          </thead>
          <tbody>
            {this.state.tagsProduct.map((tag) => (
              <tr>
                <td>{tag.tag.name}</td>
                <td>{tag.tag.type}</td>
                <td>{tag.value}</td>

                <td>{this.unite(tag.tag)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  }
  unite(tag) {
    if (tag.unite === null) {
      return <label>{""}</label>;
    } else {
      return tag.unite;
    }
  }

  render() {
    return (
      <form>
        <ToastContainer />
        <div className="card-body">
          <div className="row">
            {/* {this.showTagsProduct()} */}
            <div className="col-md-12 float-right">
              <label>Les tags disponibles </label>
              <table className="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Tag</th>
                    <th>type</th>
                    <th>Unité</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tags.map((tag) => (
                    <tr>
                      <td>{tag.name}</td>
                      <td>{tag.type}</td>
                      <td>{this.unite(tag)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-3 float-right">
              <button
                type="button"
                class="btn btn-success"
                data-toggle="modal"
                data-target="#modal-tags"
              >
                <i className="fas fa-plus" /> Ajouter un tag
              </button>
            </div>

            <div className="modal fade" id="modal-tags">
              <TagsPopup
                idTag={this.state.idTag}
                idProduct={this.state.idProduct}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}
