import { Component, useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import Commandes from "../Commandes/Commandes";
import DetailsCommande from "../Commandes/DetailsCommande";
import AllBrands from "../ListProducts/AllBrands";
import AllProductsByBrand from "../ListProducts/AllProductsByBrand";
import AddLivraison from "../Livreurs/AddLivraison";
import Livreurs from "../Livreurs/Livreurs";
import AddBrand from "../Products/AddBrand";
import AddProduct from "../Products/AddProduct";
import Dashboard from "./Dashboard";
import AllClients from "./../ClientComponents/AllClients";
import AllProductByCategory from "../ListProducts/AllProductByCategory";
import CarrousselHome from "../FrontOffice/CarrousselHome/CarrousselHome";
import AddCarrousselHome from "../FrontOffice/CarrousselHome/AddCarrousselHome";
import CarrousselCategory from "../FrontOffice/CarrousselCategory/CarrousselCategory";
import AddCarrousselCategory from "../FrontOffice/CarrousselCategory/AddCarrousselCategory";
import CarrousselPromotions from "../FrontOffice/CarrousselPromotions/CarrousselPromotions";
import AddCarrousselPromotions from "../FrontOffice/CarrousselPromotions/AddCarrousselPromotions";
import BanniereTrending from "../FrontOffice/BanniereTrending/BanniereTrending";
import AddBanniereTrending from "../FrontOffice/BanniereTrending/AddBanniereTrending";
import CarrousselOffre from "../FrontOffice/CarrousselOffre/CarrousselOffre";
import AddCarrousselOffre from "../FrontOffice/CarrousselOffre/AddCarrousselOffre";
import ProduitsTrending from "../FrontOffice/BanniereTrending/ProduitsTrending";
import MenuImage from "../FrontOffice/MenuImage/MenuImage";
import AddMenuImage from "../FrontOffice/MenuImage/AddMenuImage";
import AddProductsExcel from "../Products/AddProductsExcel";
import ProductByRef from "../ListProducts/ProductByRef";
import AllProducts from "../ListProducts/AllProducts";
import FilterCategorie from "../ListProducts/FilterCategorie";
import AddColor from "../Products/AddColor";
import AddFamilyProduct from "../Products/FamilyProduct/AddFamilyProduct";
import AllFamilyProducts from "../Products/FamilyProduct/AllFamilyProducts";
import AllSubFamilyProducts from "../Products/SubFamilyProduct/AllSubFamilyProducts";
import AddSubFamilyProduct from "../Products/SubFamilyProduct/AddSubFamilyProduct";
import AllCategories from "../Products/Category/AllCategories";
import AddCategory from "../Products/Category/AddCategory";
import AddLivreur from "../Livreurs/AddLivreur";
import AddTags from "../Products/Tags/AddTags";
import Footer from "./Footer";
import AllProductByFamily from "../Products/FamilyProduct/AllProductByFamily";
import DetailsProduct from "../Products/DetailsProduct";
import AddBonPlan from "../BonPlan/AddBonPlan";
import AddBonPlanProducts from "../BonPlan/AddBonPlanProducts";
import BonPlans from "../BonPlan/BonPlans";

import AddMenuElement from "../FrontOffice/MenuElements/AddMenuElement";
import AllMenuElement from "../FrontOffice/MenuElements/AllMenuElement";
import AddMenuCategory from "../FrontOffice/MenuCategory/AddMenuCategory";
import AllMenuCategory from "../FrontOffice/MenuCategory/AllMenuCategory";
import AllProductBySubFamily from "../Products/SubFamilyProduct/AllProductBySubFamily";
import NewCommandes from "../Commandes/NewCommandes";
import PreparationCommande from "../Commandes/PreparationCommande";
import PreparedCommande from "../Commandes/PreparedCommande";
import LivraisonCommande from "../Commandes/LivraisonCommande";
import LivredCommande from "../Commandes/LivredCommande";
import ReservedCommande from "../Commandes/ReservedCommande";
import AnnuleCommande from "../Commandes/AnnuleCommande";
import ReturnedCommande from "../Commandes/ReturnedCommande";
import AllVendeurs from "../MultiVendeur/AllVendeurs";
import AddVendeurs from "../MultiVendeur/AddVendeurs";
import AddActivite from "../secteur-activite/AddActivite";
import AllActivite from "../secteur-activite/AllActivite";

import AllProduitJour from "../ProduitJour/AllProduitJour";
import AddProduitJour from "../ProduitJour/AddProduitJour";
import AddSize from "../Products/AddSize";
import NouveauteBanniere from "../FrontOffice/Nouveaute/NouveauteBanniere";
import AddNouveauteBanniere from "../FrontOffice/Nouveaute/AddNouveauteBanniere";
import BanniereCategoryHomePage from "../FrontOffice/BanniereCategoryHomePage/BanniereCategoryHomePage";
import AddBanniereCategoryHome from "../FrontOffice/BanniereCategoryHomePage/AddBanniereCategoryHome";
import AddBanniereThematiqueHome from "../FrontOffice/BanniereThematiqueHomePage/AddBanniereThematiqueHome";
import BanniereThematiqueHomePage from "../FrontOffice/BanniereThematiqueHomePage/BanniereThematiqueHomePage";
import AddBanniereLookHome from "../FrontOffice/BanniereLookHomePage/AddBanniereLookHome";
import BanniereLookHomePage from "../FrontOffice/BanniereLookHomePage/BanniereLookHomePage";
import AddTotalLook from "../FrontOffice/TotalLook/AddTotalLook";
import TotalLookPage from "../FrontOffice/TotalLook/TotalLookPage";
import Discounts from "../Discount/Discounts";
import AddDiscount from "../Discount/AddDiscount";
import AddDeclencheur from "../Discount/AddDeclencheur";
import Login from "../Login";
import Header from "./Header";
import Navbar from "./Navbar";
import AuthentificationService from "../services/AuthentificationService";
import SocieteAndUserService from "../services/SocieteAndUserService";
import SectionHomePage from "../FrontOffice/SectionHome/SectionHomePage";
import AddSectionHome from "../FrontOffice/SectionHome/AddSectionHome";
import PromotionBanniere from "../FrontOffice/PromotionBanniere/PromotionBanniere";
import AddPromotionBanniere from "../FrontOffice/PromotionBanniere/AddPromotionBanniere";
import VetementsBanniere from "../FrontOffice/VetementsBanniere/VetementsBanniere";
import AddVetementsBanniere from "../FrontOffice/VetementsBanniere/AddVetementsBanniere";
import AllSaisons from "../Saisons/AllSaisons";
import AddSaison from "../Saisons/AddSaison";
import AllVentesFlash from "../Vente-flash/AllVentesFlash";
import AddVentesFlash from "../Vente-flash/AddVentesFlash";
import AllColorsVariant from "../Products/Variants/ColorsVariant/AllColorsVariant";
import AddSoldes from "src/Discount/AddSoldes";
import AllSyhncroCsvRead from "src/SyhncroCsvRead/AllSyhncroCsvRead";
import AllNouveauteProduit from "src/Nouveaute-produit/AllNouveauteProduit";
import AddNouveauteProduit from "src/Nouveaute-produit/AddNouveauteProduit";
import AllTopBar from "src/Top-bar-menu/AllTopBar";
import AddTopBar from "src/Top-bar-menu/AddTopBar";
import ContactPage from "src/Contact-page/ContactPage";
import ContenuEmail from "src/Contact-page/ContenuEmail";
import AllThematiquesPages from "src/FrontOffice/Thematique/AllThematiquesPages";
import AddThematiquePage from "src/FrontOffice/Thematique/AddThematiquePage";
import ThematiqueProducts from "src/FrontOffice/Thematique/ThematiquesProducts";
import AllLivraisons from "src/Livreurs/AllLivraisons";
import AllPromotionActifs from "src/Discount/AllPromotionActifs";
import VoirPrioritesByDeclencheur from "src/Discount/VoirPrioritesByDeclencheur";
import UpdateDeclencheur from "src/Discount/UpdateDeclencheur";
import AllPromotionsByVenteFlash from "src/Vente-flash/AllPromotionsByVenteFlash";
import AllProduitsByPromotionVenteFlash from "src/Vente-flash/AllProduitsByPromotionVenteFlash";
import UpdateDiscountPanier from "src/Discount/UpdateDiscountPanier";

function Home() {
  const [loading, setLoading] = useState(true); // add a new state variable for loading

  const [currentUser, setCurrentUser] = useState(undefined);
  const [accessIsDenied, setAccessIsDenied] = useState("notConnected");
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorAlertText, setErrorAlertText] = useState();
  const [showElement, setShowElement] = useState([]);

  useEffect(() => {
    const user = AuthentificationService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }
    if (localStorage.getItem("accessIsDenied") == null) {
      localStorage.setItem("accessIsDenied", "notConnected");
      setAccessIsDenied("notConnected");
    } else setAccessIsDenied(localStorage.getItem("accessIsDenied"));

    let userr = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("status") == null) {
      setShowElement(["notConnected"]);
    } else {
      setShowElement(JSON.parse(localStorage.getItem("status")));
    }

    SocieteAndUserService.isAvailableUserAndSociete()
      .then((response) => {
        if (Object.keys(response.data)[0] === "notAvailable") {
          setOpenErrorAlert(true);
          setErrorAlertText(
            "Info : vous n'avez pas le droit d'accéder à votre espace "
          );

          // setTimeout(logoutIfUserOrSoceteArchived(), 3000);
          setTimeout(function () {
            AuthentificationService.logout().then((response) => {
              if (Object.keys(response.data) === "success") {
                localStorage.setItem("accessIsDenied", "notConnected");
                localStorage.removeItem("status");

                window.location.href = "/";
              }

              if (Object.keys(response.data) === "failed") {
              }
            });
          }, 2000);
        }
      })
      .catch(() => {
        setOpenErrorAlert(true);
        setErrorAlertText("Erreur : il ya un probléme de connection");
      });
    setLoading(false); // set loading to false after data has been fetched and processed
  }, []);

  const handleEroorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };
  if (loading) {
    // render a loading indicator while loading is true
    return <div id="loader"></div>;
  }
  if (accessIsDenied === "connected") {
    if (currentUser.token.nameRole.includes("SUPER_ADMIN")) {
      return (
        <>
          <Header />
          <Navbar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/addFamilyProduct" element={<AddFamilyProduct />} />
            <Route path="/addBrand" element={<AddBrand />} />
            <Route path="/addCategory" element={<AddCategory />} />
            <Route path="/addProduct" element={<AddProduct />} />
            <Route path="/allBrands" element={<AllBrands />} />
            <Route path="/allFamilyProducts" element={<AllFamilyProducts />} />
            <Route path="/allVendeurs" element={<AllVendeurs />} />
            <Route path="/addVendeurs" element={<AddVendeurs />} />
            <Route path="/addActivite" element={<AddActivite />} />
            <Route path="/allActivite" element={<AllActivite />} />
            <Route path="/AddActivite" element={<AddActivite />} />

            <Route path="/addProduitJour" element={<AddProduitJour />} />
            <Route path="/allProduitJour" element={<AllProduitJour />} />
            <Route
              path="/allSubFamilyProducts"
              element={<AllSubFamilyProducts />}
            />

            <Route
              path="/allProductBySubFamily"
              element={<AllProductBySubFamily />}
            />
            <Route
              path="/addSubFamilyProduct"
              element={<AddSubFamilyProduct />}
            />
            <Route
              path="/allProductsByBrand"
              element={<AllProductsByBrand />}
            />
            <Route path="/detailsProduct" element={<DetailsProduct />} />
            <Route path="/allCategories" element={<AllCategories />} />
            <Route path="/allCommandes" element={<Commandes />} />
            <Route path="/newCommandes" element={<NewCommandes />} />
            <Route
              path="/preparationCommandes"
              element={<PreparationCommande />}
            />
            <Route path="/preparedCommandes" element={<PreparedCommande />} />
            <Route path="/livraisonCommande" element={<LivraisonCommande />} />
            <Route path="/livredCommande" element={<LivredCommande />} />
            <Route path="/reservedCommande" element={<ReservedCommande />} />
            <Route path="/annuleCommande" element={<AnnuleCommande />} />
            <Route path="/returnedCommande" element={<ReturnedCommande />} />

            <Route path="/detailsCommande" element={<DetailsCommande />} />
            <Route path="/allClients" element={<AllClients />} />
            <Route
              path="/allProductByCategory"
              element={<AllProductByCategory />}
            />
            <Route
              path="/allProductByFamily"
              element={<AllProductByFamily />}
            />
            <Route path="/allCarrousselHome" element={<CarrousselHome />} />
            <Route path="/addCarrousselHome" element={<AddCarrousselHome />} />
            <Route
              path="/allCarrousselCategory"
              element={<CarrousselCategory />}
            />

            <Route path="/addMenuElement" element={<AddMenuElement />} />
            <Route path="/allMenuElement" element={<AllMenuElement />} />

            <Route path="/addMenuCategory" element={<AddMenuCategory />} />
            <Route path="/allMenuCategory" element={<AllMenuCategory />} />

            <Route
              path="/addCarrousselCategory"
              element={<AddCarrousselCategory />}
            />
            <Route
              path="/allCarrousselPromotions"
              element={<CarrousselPromotions />}
            />
            <Route
              path="/addCarrousselPromotions"
              element={<AddCarrousselPromotions />}
            />
            <Route path="/allBanniereTrending" element={<BanniereTrending />} />
            <Route
              path="/addBanniereTrending"
              element={<AddBanniereTrending />}
            />
            <Route path="/allCarrousselOffre" element={<CarrousselOffre />} />
            <Route
              path="/addCarrousselOffre"
              element={<AddCarrousselOffre />}
            />
            <Route path="/allProduitsTrending" element={<ProduitsTrending />} />
            <Route path="/allMenuImage" element={<MenuImage />} />
            <Route path="/addMenuImage" element={<AddMenuImage />} />
            <Route path="/addProductsExcel" element={<AddProductsExcel />} />
            <Route path="/productByRef" element={<ProductByRef />} />
            <Route path="/allProducts" element={<AllProducts />} />
            <Route path="/filterCategorie" element={<FilterCategorie />} />
            <Route path="/addColor" element={<AddColor />} />
            <Route path="/AllColorsVariant" element={<AllColorsVariant />} />
            <Route path="/AllSyhncroCsvRead" element={<AllSyhncroCsvRead />} />

            <Route path="/addSize" element={<AddSize />} />

            {/*Category Home Page */}
            <Route
              path="/allBanniereCategoryHome"
              element={<BanniereCategoryHomePage />}
            />
            <Route
              path="/addBanniereCategoryHome"
              element={<AddBanniereCategoryHome />}
            />

            {/*totalLook Home Page */}
            <Route
              path="/addBanniereTotalLookHome"
              element={<AddBanniereLookHome />}
            />
            <Route
              path="/allBanniereTotalLookHome"
              element={<BanniereLookHomePage />}
            />
            {/*totalLook  Page */}
            <Route path="/addTotalLook" element={<AddTotalLook />} />
            <Route path="/allTotalLook" element={<TotalLookPage />} />
            {/*thematique Home Page */}
            <Route
              path="/addBanniereThematiqueHome"
              element={<AddBanniereThematiqueHome />}
            />
            <Route
              path="/allBanniereThematiqueHome"
              element={<BanniereThematiqueHomePage />}
            />

            {/* Nouveaute */}
            <Route
              path="/allBanniereNouveaute"
              element={<NouveauteBanniere />}
            />
            <Route
              path="/addNouveauteBanniere"
              element={<AddNouveauteBanniere />}
            />
            {/* Vetements banniere */}
            <Route
              path="/allVetementsBanniere"
              element={<VetementsBanniere />}
            />
            <Route
              path="/AddVetementsBanniere"
              element={<AddVetementsBanniere />}
            />

            {/* livraison */}
            <Route path="/allLivraisons" element={<AllLivraisons />} />
            <Route path="/addLivraison" element={<AddLivraison />} />

            {/* livreur */}
            <Route path="/allLivreurs" element={<Livreurs />} />
            <Route path="/addLivreur" element={<AddLivreur />} />

            {/* tags */}
            <Route path="/addTags" element={<AddTags />} />

            {/* BonPlan */}
            <Route path="/addBonPlan" element={<AddBonPlan />} />
            <Route
              path="/addBonPlanProducts"
              element={<AddBonPlanProducts />}
            />
            <Route path="/allBonPlans" element={<BonPlans />} />

            {/* declencheur */}
            <Route path="/addDeclencheur" element={<AddDeclencheur />} />
            <Route path="/UpdateDeclencheur" element={<UpdateDeclencheur />} />
            <Route path="/allDeclencheurs" element={<Discounts />} />

            {/* Promotion */}
            <Route path="/updatePromotion" element={<UpdateDiscountPanier />} />
            <Route path="/addPromotion" element={<AddDiscount />} />
            <Route path="/allPromotions" element={<AllPromotionActifs />} />

            <Route
              path="/voirPrioritesByDeclencheur"
              element={<VoirPrioritesByDeclencheur />}
            />

            {/* soldes intefaces */}
            <Route path="/addSoldes" element={<AddSoldes />} />

            {/* Promotion banniere */}
            <Route
              path="/allBannierePromotion"
              element={<PromotionBanniere />}
            />
            <Route
              path="/AddPromotionBanniere"
              element={<AddPromotionBanniere />}
            />

            {/*Section Home  Page */}
            <Route path="/addSection" element={<AddSectionHome />} />
            <Route path="/allSections" element={<SectionHomePage />} />

            {/* Saisons */}
            <Route path="/allSaisons" element={<AllSaisons />} />
            <Route path="/addSaison" element={<AddSaison />} />

            {/* ventes flash */}
            <Route path="/allVentesFlash" element={<AllVentesFlash />} />
            <Route path="/addVentesFlash" element={<AddVentesFlash />} />
            <Route
              path="/promotionVenteFlash"
              element={<AllPromotionsByVenteFlash />}
            />
            <Route
              path="/produitsPromotionsVenteFlash"
              element={<AllProduitsByPromotionVenteFlash />}
            />
            {/* Nouveaute */}
            <Route
              path="/allNouveauteProduit"
              element={<AllNouveauteProduit />}
            />
            <Route
              path="/addNouveauteProduit"
              element={<AddNouveauteProduit />}
            />
            {/* Thematique page */}
            <Route
              path="/allThematiquesPages"
              element={<AllThematiquesPages />}
            />
            <Route path="/addThematiquePage" element={<AddThematiquePage />} />
            <Route
              path="/thematiqueProducts"
              element={<ThematiqueProducts />}
            />

            {/* Top Bar menu */}
            <Route path="/allTopBar" element={<AllTopBar />} />
            <Route path="/addTopBar" element={<AddTopBar />} />

            {/* Contact page*/}
            <Route path="/page-contact" element={<ContactPage />} />
            <Route path="/page-contact-email" element={<ContenuEmail />} />
          </Routes>
          <Footer />
        </>
      );
    }
  } else return <Login></Login>;
}

export default Home;
