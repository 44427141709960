import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class DashboardService {
  getNombreBrands() {
    return api.get(urlsCommon.BASE_DASHBOARD_URL + "/nombre" + "/nombreBrands");
  }
  getNombreSousFamilles() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL + "/nombre" + "/nombreSousFamilles"
    );
  }
  getNombreCategories() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL + "/nombre" + "/nombreCategories"
    );
  }
  //******Commande state */
  getNombreCommandes() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL + "/nombre" + "/nombreCommandes"
    );
  }

  getChiffresAffairesAttendu() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL + "/commande" + "/chiffresAffairesAttendu"
    );
  }
  getChiffreAffaireLivree() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL + "/commande" + "/getChiffreAffaireLivree"
    );
  }
  getNombreCommandesSelonEtat() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL +
        "/commande" +
        "/getNombreCommandesSelonEtat"
    );
  }
  getMoyNbArticleParPanier() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL + "/commande" + "/getMoyNbArticleParPanier"
    );
  }
  getPanierMoyen() {
    return api.get(
      urlsCommon.BASE_DASHBOARD_URL + "/commande" + "/getPanierMoyen"
    );
  }
}
export default new DashboardService();
