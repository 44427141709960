import { Component } from "react";
import SaisonService from "../../services/SaisonService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeAnimated from "react-select/animated";
import Select from "react-select";

export default class SaisonsPopupUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saison: "",
      annee: "",
      selectedsaisonsPredefini: "",
      allsaisonsPredefini: [],
      options: [],
    };
    this.changeAnnee = this.changeAnnee.bind(this);
  }

  remplissageOptions() {
    this.state.allsaisonsPredefini.map((saisonpre) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: saisonpre, label: saisonpre },
        ],
      })
    );
  }
  handleChangeList = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ selectedsaisonsPredefini: selectedOption.value });
    console.log(
      "selectedsaisonsPredefini",
      this.state.selectedsaisonsPredefini
    );
  };
  componentDidMount() {
    this.forceUpdate();
    SaisonService.getSaisonById(this.props.idSaison)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ saison: response.data["object"] });
          const selectedOptionModel = {
            label: this.state.saison.saison,
            value: this.state.saison.saison,
          };
          this.setState({ selectedOption: selectedOptionModel });
          console.log("saison", this.state.saison);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
    SaisonService.getAllSaisonsPredefini().then((response) => {
      console.log("response", response.data["code"]);
      if (response.data["code"] === "201") {
        this.setState({ allsaisonsPredefini: response.data["object"] });
        console.log("saisonsPredefini", this.state.allsaisonsPredefini);
        this.remplissageOptions();
      }
    });
  }

  changeAnnee(event) {
    this.setState({ annee: event.target.value });
    console.log("annee", this.state.annee);
  }
  saveSaison = (e) => {
    e.preventDefault();

    const selectedOptionModel = {
      label: this.state.saison.saison,
      value: this.state.saison.saison,
    };
    const saisonUpdateModel = {
      annee: this.state.annee || this.state.saison.annee,
      saison: this.state.selectedsaisonsPredefini || selectedOptionModel.value,
    };

    SaisonService.update(this.state.saison.id, saisonUpdateModel)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Mise à jour du libellé avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    const animatedComponents = makeAnimated();
    return (
      <>
        <ToastContainer />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Modifier cette saison : {this.state.saison.name}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Année</label>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Saisir..."
                        value={this.state.annee || this.state.saison.annee}
                        onChange={this.changeAnnee}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>Choisir la saison</label>
                    </div>
                    <div className="col-md-12">
                      <Select
                        value={this.state.selectedOption}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={this.state.options}
                        onChange={this.handleChangeList}
                        placeholder="Saisir..."
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-warning"
                onClick={this.saveSaison}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
