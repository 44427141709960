import { Component } from "react";
import MenuElementService from "../../services/MenuElementService";
import { Link } from "react-router-dom";
import MenuElementDelete from "./MenuElementDelete";
import MenuElementDesactiver from "./MenuElementDesactiver";

export default class AllMenuElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carroussels: [],
      idMenuElement: "",
      showFormImage: false,
      menuElementsActive: [],
      menuElementsNonActive: [],
      menuElementsArchived: [],
      numberMenuElementActive: 0,
    };
  }
  componentDidMount() {
    this.forceUpdate();
    // MenuElementService
    MenuElementService.allMenuImage(1, 0)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ menuElementsActive: response.data["object"] });
          console.log("menuElementsActive", this.state.menuElementsActive);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });

    MenuElementService.allMenuImage(0, 0)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ menuElementsNonActive: response.data["object"] });
          console.log(
            "menuElementsNonActive",
            this.state.menuElementsNonActive
          );
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });

    MenuElementService.numberMenuElementActiveAndArchived(1, 0)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ numberMenuElementActive: response.data["object"] });
          console.log(
            "numberMenuElementActive",
            this.state.numberMenuElementActive
          );
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });

    MenuElementService.allMenuImageArchived(1)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ menuElementsArchived: response.data["object"] });
          console.log("menuElementsArchived", this.state.menuElementsArchived);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  formatDate(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth() + 1).padStart(2, "0");
    let year = cdate.getFullYear();
    return day + "/" + month + "/" + year;
  }
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  buttonArchive(id, isArchived) {
    if (isArchived === 0) {
      return (
        <button
          class="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idMenuElement: id });
            this.showFormImage();
          }}
        >
          <i class="fas fa-trash"></i> Archiver
        </button>
      );
    } else {
      return <label>Element archivé</label>;
    }
  }

  buttonDesactive(id, isActive) {
    if (isActive === 1) {
      return (
        <button
          class="btn btn-warning btn-sm"
          data-toggle="modal"
          data-target="#modal-update"
          onClick={() => {
            this.setState({ idMenuElement: id });
            this.showFormImage();
          }}
        >
          <i class="fas fa-pencil-alt"></i> Désactiver
        </button>
      );
    } else {
      return <label>Element désactiver</label>;
    }
  }

  buttonAddMenuElement() {
    if (this.state.numberMenuElementActive === 6) {
      return (
        <p>
          Le nombre maximum d'élement est 6, vous devez désactiver ou supprimer
          un élement pour pouvoir ajouter un nouvel élement
        </p>
      );
    } else {
      return (
        <p className="float-right">
          <button class="btn btn-success btn-sm">
            <Link
              to={{ pathname: "/addMenuElement" }}
              style={{ color: "white", "text-decoration": "none" }}
            >
              <i class="fas fa-plus"></i>
              Ajouter
            </Link>
          </button>
        </p>
      );
    }
  }

  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Image de menu</h5>
              {this.buttonAddMenuElement()}
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Les élements de menu actives</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Titre </th>
                    <th>Type </th>
                    <th>Date de creation</th>
                    <th>Modifier</th>
                    <th>Désactiver du menu</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.menuElementsActive.map((carroussel) => (
                    <tr>
                      <td>{carroussel.id}</td>
                      <td>{carroussel.nameElement}</td>
                      <td>{carroussel.typeElement}</td>
                      <td>{this.formatDate(carroussel.creationDate)}</td>

                      <td>
                        {" "}
                        <button
                          class="btn btn-warning btn-sm"
                          data-toggle="modal"
                          data-target="#modal-Image"
                          onClick={() => {
                            this.setState({ idCarroussel: carroussel.id });
                            this.showFormImage();
                          }}
                        >
                          <i class="fas fa-images"></i> Voir l'image
                        </button>
                      </td>
                      <td>
                        {this.buttonDesactive(
                          carroussel.id,
                          carroussel.isActive
                        )}
                        {this.state.showFormImage && (
                          <div className="modal fade" id="modal-update">
                            <MenuElementDesactiver
                              idMenuElement={this.state.idMenuElement}
                            />
                          </div>
                        )}
                      </td>

                      <td>
                        {this.buttonArchive(
                          carroussel.id,
                          carroussel.isArchived
                        )}
                        {this.state.showFormImage && (
                          <div className="modal fade" id="modal-default">
                            <MenuElementDelete
                              idMenuElement={this.state.idMenuElement}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Les élements de menu non actives</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Titre element de menu </th>
                    <th>Type element menu </th>
                    <th>Date de creation</th>
                    <th>Active</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.menuElementsNonActive.map((carroussel) => (
                    <tr>
                      <td>{carroussel.id}</td>
                      <td>{carroussel.nameElement}</td>
                      <td>{carroussel.typeElement}</td>
                      <td>{this.formatDate(carroussel.creationDate)}</td>
                      <td>
                        {this.buttonDesactive(
                          carroussel.id,
                          carroussel.isActive
                        )}
                        {this.state.showFormImage && (
                          <div className="modal fade" id="modal-update">
                            <MenuElementDesactiver
                              idMenuElement={this.state.idMenuElement}
                            />
                          </div>
                        )}
                      </td>

                      <td>
                        {this.buttonArchive(
                          carroussel.id,
                          carroussel.isArchived
                        )}
                        {this.state.showFormImage && (
                          <div className="modal fade" id="modal-default">
                            <MenuElementDelete
                              idMenuElement={this.state.idMenuElement}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Les élements de menu archivés</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Titre element de menu </th>
                    <th>Type element menu </th>
                    <th>Date de creation</th>
                    <th>Active</th>
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.menuElementsArchived.map((carroussel) => (
                    <tr>
                      <td>{carroussel.id}</td>
                      <td>{carroussel.nameElement}</td>
                      <td>{carroussel.typeElement}</td>
                      <td>{this.formatDate(carroussel.creationDate)}</td>
                      <td>
                        {this.buttonDesactive(
                          carroussel.id,
                          carroussel.isActive
                        )}
                        {this.state.showFormImage && (
                          <div className="modal fade" id="modal-update">
                            <MenuElementDesactiver
                              idMenuElement={this.state.idMenuElement}
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        {this.buttonArchive(
                          carroussel.id,
                          carroussel.isArchived
                        )}
                        {this.state.showFormImage && (
                          <div className="modal fade" id="modal-default">
                            <MenuElementDelete
                              idMenuElement={this.state.idMenuElement}
                            />
                          </div>
                        )}
                      </td>{" "}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
