import React, { useState } from "react";
import { toast } from "react-toastify";
import TopBarService from "src/services/TopBarService";

function ArchiverAndVisibleTopBar(props) {
  const {
    value,
    idTopBar,
    isModalArchiverOrVisibleOpen,
    setIsModalArchiverOrVisibleOpen,
    topbarItem,
  } = props;

  const archiverTopBarById = (idTopBar) => {
    TopBarService.archiverTopBar(idTopBar)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledTopBarById = (idTopBar) => {
    TopBarService.visibledTopBar(idTopBar)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const archiverorVisibleTopBar = (event, idTopBar, value) => {
    event.preventDefault();
    if (value == "archiver") {
      archiverTopBarById(idTopBar);
      return;
    } else if (value == "visible") {
      visibledTopBarById(idTopBar);
      return;
    }
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModalArchiverOrVisible"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalArchiverOrVisible">
                {value === "archiver" && topbarItem.archived == false ? (
                  <>Archiver topbar</>
                ) : value === "archiver" && topbarItem.archived == true ? (
                  <>Not Archiver topbar</>
                ) : value === "visible" && topbarItem.visible == false ? (
                  <>Rendre topbar visibled </>
                ) : value === "visible" && topbarItem.visible == true ? (
                  <>Not visibled topbar</>
                ) : null}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card-body">
              Veuillez confimer{""}
              <b>
                {value === "archiver" && topbarItem.archived == false ? (
                  <>d'Archiver topbar</>
                ) : value === "archiver" && topbarItem.archived == true ? (
                  <> de rendre topbar Not Archived </>
                ) : value === "visible" && topbarItem.visible == false ? (
                  <>de rendre topbar visible </>
                ) : value === "visible" && topbarItem.visible == true ? (
                  <>de rendre topbar not visible </>
                ) : null}
              </b>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsModalArchiverOrVisibleOpen(false);
                  window.location.href = "allTopBar";
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-warning"
                onClick={(e) => archiverorVisibleTopBar(e, idTopBar, value)}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArchiverAndVisibleTopBar;
