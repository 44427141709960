



import { Component } from "react";
import { Link } from "react-router-dom";
import ProductService from "../../services/ProductService";
import SubFamilyProductService from "../../services/SubFamilyProductService";
import BrandDelete from "../BrandPopupModel/BrandDelete";
import ProductPopupBigPictures from "../ProductPopupModel/ProductPopupBigPictures";
import ProductPopupSmallPictures from "../ProductPopupModel/ProductPopupSmallPictures";
import ProductPopupStock from "../ProductPopupModel/ProductPopupStock";
import ProductPopupUpdateAll from "../ProductPopupModel/ProductPopupUpdateAll";

export default class AllProductBySubFamily extends Component {
    constructor(props) {
        super(props);
        this.state = {
            family: "",
            products: [],
        };
    }
    componentDidMount() {
        this.forceUpdate();
        SubFamilyProductService.getSubFamilyById(sessionStorage.getItem("idSubFamilyProduct"))
            .then((response) => {
                console.log("response", response.data["code"]);
                if (response.data["code"] === "201") {
                    // console.log("In Societe else");
                    this.setState({ family: response.data["object"] });
                    console.log("family", this.state.family);
                    // this.setState({ alert_message: "success" });
                } else {
                    console.log("response", "error");
                }
            })
            .catch((error) => {
                console.log("response", "error");
            });
        ProductService.getProductsBySubFamily(
            sessionStorage.getItem("idSubFamilyProduct")
        )
            .then((response) => {
                console.log("response", response.data["code"]);
                if (response.data["code"] === "201") {
                    // console.log("In Societe else");
                    this.setState({ products: response.data["object"] });
                    console.log("products", this.state.products);
                    // this.setState({ alert_message: "success" });
                    //   this.remplissageOptions();
                } else {
                    console.log("response", "error");
                }
            })
            .catch((error) => {
                console.log("response", "error");
            });
    }
    render() {
        if (this.state.products.length === 0) {
            return (
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="callout callout-danger">
                                <h5>Pas de produits dans la sous-famille <b>{this.state.family.name}</b></h5>

                                <p className="float-right">
                                    <button class="btn btn-success btn-sm">
                                        <Link
                                            to={{ pathname: "/addProduct" }}
                                            style={{ color: "white", "text-decoration": "none" }}
                                        >
                                            <i class="fas fa-plus"></i> Ajouter un produit
                                        </Link>
                                    </button>
                                </p>
                                <br />
                            </div>
                        </div>
                    </section>
                </div>
            );
        } else {
            return (
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid"></div>
                    </section>

                    <div className="col-md-12">
                        <section class="content">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Les produits de la sous-famille <b>{this.state.family.name}</b></h3>
                                </div>

                                <div className="card-body">
                                    <table
                                        id="example2"
                                        className="table table-bordered table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Ref-libellé</th>
                                                <th>Description</th>
                                                <th>Stock</th>
                                                <th>Les grandes images</th>
                                                <th>Les petites images</th>
                                                <th>Modifier</th>
                                                <th>Archiver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map((product) => (
                                                <tr>
                                                    <td>
                                                        {product.ref}- <b>{product.name}</b>
                                                    </td>
                                                    <td>{product["short_desc"]}</td>
                                                    <td>
                                                        {product.stock}
                                                        {"  "}
                                                        <button
                                                            class="btn btn-default btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#modal-stock"
                                                            onClick={() => {
                                                                this.setState({ idProduct: product.id });
                                                                this.showFormImage();
                                                            }}
                                                        >
                                                            <i class="fas fa-pencil-alt"></i>
                                                        </button>
                                                        {this.state.showFormImage && (
                                                            <div className="modal fade" id="modal-stock">
                                                                <ProductPopupStock
                                                                    idProduct={this.state.idProduct}
                                                                />
                                                                {/* /.modal-dialog */}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <button
                                                            class="btn btn-warning btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#modal-Image"
                                                            onClick={() => {
                                                                this.setState({ idProduct: product.id });
                                                                this.showFormImage();
                                                            }}
                                                        >
                                                            <i class="fas fa-images"></i> Voir les images
                                                        </button>
                                                        {this.state.showFormImage && (
                                                            <div className="modal fade" id="modal-Image">
                                                                <ProductPopupBigPictures
                                                                    idProduct={this.state.idProduct}
                                                                />
                                                                {/* /.modal-dialog */}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <button
                                                            class="btn btn-warning btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#modal-ImageSmall"
                                                        >
                                                            <i class="fas fa-images"></i> Voir les images
                                                        </button>
                                                        {
                                                            <div className="modal fade" id="modal-ImageSmall">
                                                                <ProductPopupSmallPictures imageUrl="D:/IWARE PROJECTS/ElectroBZ/React/demo1/public/images/brands/1.png" />
                                                                {/* /.modal-dialog */}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <button
                                                            class="btn btn-info btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#modal-update"
                                                            onClick={() => {
                                                                this.setState({ idProduct: product.id });
                                                                this.showFormImage();
                                                            }}
                                                        >
                                                            <i class="fas fa-pencil-alt"></i> Modifier
                                                        </button>
                                                        {this.state.showFormImage && (
                                                            <div className="modal fade" id="modal-update">
                                                                <ProductPopupUpdateAll
                                                                    idProduct={this.state.idProduct}
                                                                />
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <button
                                                            class="btn btn-danger btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#modal-default"
                                                        >
                                                            <i class="fas fa-trash"></i> Archiver
                                                        </button>
                                                        {
                                                            <div className="modal fade" id="modal-default">
                                                                <BrandDelete />
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            );
        }
    }
}



