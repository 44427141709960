import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import SubFamilyProductService from "../../services/SubFamilyProductService";
import TagsService from "../../services/TagsService";
export default class AddTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      type: "",
      selectedOptionSubFamily: null,
      optionsSubfamily: [],
      idSubFamilyProduct: "",
      subFamilyProducts: [],
      selectedOption: null,
      unite: "",
    };
    this.changeLibelle = this.changeLibelle.bind(this);
    this.changeUnite = this.changeUnite.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    SubFamilyProductService.getAllSubFamilyProducts()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ subFamilyProducts: response.data["object"] });
          console.log("subFamilyProducts", this.state.subFamilyProducts);
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  handleChangeList = (selectedOptionSubFamily) => {
    this.setState({ selectedOptionSubFamily }, () =>
      console.log(`Option selected:`, this.state.selectedOptionSubFamily)
    );
    this.setState({ idSubFamilyProduct: selectedOptionSubFamily.value });
    console.log("idSubFamilyProduct", this.state.idSubFamilyProduct);
  };
  remplissageOptions() {
    this.state.subFamilyProducts.map((famille) =>
      this.setState({
        optionsSubfamily: [
          ...this.state.optionsSubfamily,
          { value: famille.id, label: famille.name },
        ],
      })
    );
  }

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }

  changeUnite(event) {
    this.setState({ unite: event.target.value });
    console.log("unite", this.state.unite);
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ type: selectedOption.label });
    console.log("type", this.state.type);
  };

  saveTags = (e) => {
    e.preventDefault();
    let tag = {
      type: this.state.type,
      name: this.state.libelle,
      unite: this.state.unite,
      idSubFamilyProduct: this.state.idSubFamilyProduct,
    };

    TagsService.addTags(tag)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout du tag avec succée");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };
  uniteTag() {
    console.log("type", this.state.type);
    if (this.state.type === "Unité") {
      return (
        <div className="col-md-4">
          <label>Unité</label>

          <input
            type="unite"
            className="form-control"
            placeholder="Saisir..."
            value={this.state.unite}
            onChange={this.changeUnite}
          />
        </div>
      );
    }
  }

  render() {
    const animatedComponents = makeAnimated();
    const animatedComponentsSubFamily = makeAnimated();

    const options = [
      { value: 1, label: "Unité" },
      { value: 2, label: "Libellé" },
    ];
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter le tag</h5>
              <br /> Ajouter le type et le libellé
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-md-12">
            <section class="content">
              <div class="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-outline card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          <b> Ajouter le type et le libellé</b>
                        </h3>
                      </div>

                      <form>
                        <div className="card-body">
                          <div className="row">
                            <br />
                            <div className="col-md-4">
                              <label>Choisir la sous-famille</label>
                              <Select
                                id={"SelectMarque"}
                                value={this.state.selectedOptionSubFamily}
                                closeMenuOnSelect={true}
                                components={animatedComponentsSubFamily}
                                options={this.state.optionsSubfamily}
                                onChange={this.handleChangeList}
                                placeholder="Saisir..."
                              />
                            </div>

                            <div className="col-md-4">
                              <label>Choisir le type de tag : </label>
                              <Select
                                id={"SelectMarque"}
                                value={this.state.selectedOption}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={options}
                                onChange={this.handleChange}
                                placeholder="Saisir..."
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Libellé</label>

                              <input
                                type="libelle"
                                className="form-control"
                                placeholder="Saisir..."
                                value={this.state.libelle}
                                onChange={this.changeLibelle}
                              />
                            </div>
                          </div>
                          {this.uniteTag()}
                        </div>

                        <br />

                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn btn-success float-right"
                            onClick={this.saveTags}
                          >
                            Valider
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
