import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import CommandeService from "src/services/CommandeService";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Pagination from "src/common/Pagination";
import UtilsFormule from "src/utils/UtilsFormule";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faRemove } from "@fortawesome/free-solid-svg-icons";
// import { saveAs } from "file-saver";

function Commandes() {
  const [commandeList, setcommandeList] = useState({});
  const [commandeListExport, setCommandeListExport] = useState([]);
  const [etatCommandeList, setEtatCommandeList] = useState([]);
  const [etatCommandeSelected, setEtatCommandeSelected] = useState("");
  const [debutDate, setDebutDate] = useState(null);
  const [finDate, setFinDate] = useState(null);
  const [fileName, setFileName] = useState("filtered_data.csv");
  const [exportBtnCliqued, setExportBtnCliqued] = useState(false);

  const [listVideMsg, setListVideMsg] = useState(
    "pas de commande pour le moment"
  );

  /*for pagination*/
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    if (etatCommandeSelected && !debutDate && !finDate) {
      filterCommandesByEtat(etatCommandeSelected);
    } else if (debutDate && finDate && !etatCommandeSelected) {
      filterCommandesByDate(
        UtilsFormule.formatDateFilter(debutDate),
        UtilsFormule.formatDateFilter(finDate)
      );
    } else if (etatCommandeSelected && debutDate && finDate) {
      filterCommandesByEtatAndDate(
        etatCommandeSelected,
        UtilsFormule.formatDateFilter(debutDate),
        UtilsFormule.formatDateFilter(finDate)
      );
    } else {
      getAllCommandeByDateCreation();
    }
    exportCommandes(
      etatCommandeSelected ? etatCommandeSelected?.value : null,
      debutDate ? UtilsFormule.formatDateFilter(debutDate) : null,
      finDate ? UtilsFormule.formatDateFilter(finDate) : null
    );
  }, [etatCommandeSelected, finDate, debutDate, currentPage, itemsPerPage]);

  useEffect(() => {
    getAllEtatCommande();
  }, []);

  const exportCommandes = (etat, debutDate, finDate) => {
    CommandeService.exportCommandes(etat, debutDate, finDate)
      .then((response) => {
        if (response.data.success) {
          setCommandeListExport(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const filterCommandesByEtatAndDate = (
    etatCommandeSelected,
    debutDate,
    finDate
  ) => {
    CommandeService.filterCommandesByEtatAndDate(
      etatCommandeSelected.value,
      debutDate,
      finDate,
      currentPage - 1,
      itemsPerPage
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setcommandeList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const filterCommandesByDate = (debutDate, finDate) => {
    CommandeService.filterCommandesByDate(
      debutDate,
      finDate,
      currentPage - 1,
      itemsPerPage
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setcommandeList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const filterCommandesByEtat = (etatCommandeSelected) => {
    CommandeService.filterCommandesByEtat(
      etatCommandeSelected.value,
      currentPage - 1,
      itemsPerPage
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setcommandeList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getAllCommandeByDateCreation = () => {
    CommandeService.getAllCommandeByDateCreation(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setcommandeList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getAllEtatCommande = () => {
    CommandeService.getAllEtatCommande(0)
      .then((response) => {
        if (response.data.success) {
          setEtatCommandeList(response.data.success);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const handleSelectEtatCommande = (selectedOption) => {
    setEtatCommandeSelected(selectedOption);
  };

  const getDetailCommande = (idCommande) => {
    localStorage.setItem("idCommande", idCommande);
    const url = "/detailsCommande";
    window.open(url, "_blank");
  };

  const clearFilter = () => {
    setDebutDate("");
    setFinDate("");
    setEtatCommandeSelected("");
  };

  // const handleExportCSV = () => {
  //   console.log("etatCommandeSelected :", etatCommandeSelected);
  //   console.log("debutDate :", debutDate);
  //   console.log("finDate :", finDate);

  //   const headers = [
  //     { label: "#Ref", key: "ref" },
  //     { label: "Client", key: "client" },
  //     { label: "Nombre Articles", key: "nbArticle" },
  //     { label: "Montant", key: "priceTotal" },
  //     { label: "Etat", key: "etat" },
  //     { label: "Source", key: "source" },
  //     { label: "Date Commande", key: "dateCommande" },
  //   ];

  //   const csvData = [
  //     ...commandeListExport.map((item) => ({
  //       ref: item.referenceCommande,
  //       client: item.userName,
  //       nbArticle: item.nbArticle,
  //       priceTotal: item.priceTotal,
  //       etat: item.etatCommande,
  //       source: item.sourceCommandeWithValue.label,
  //       dateCommande: UtilsFormule.formatDateHours(item?.dateCommande),
  //     })),
  //   ];
  //   return (
  //     <CSVLink data={csvData} headers={headers} filename={fileName}>
  //       <button className="btn btn-sm btn-dark float-left">Exporter CSV</button>
  //     </CSVLink>
  //   );
  // };

  const handleExportExcel = () => {
    const headers = [
      { key: "Reference" },
      { key: "Client" },
      { key: "Email" },
      { key: "Articles" },
      { key: "Montant" },
      { key: "Etat" },
      { key: "Source" },
      { key: "Date" },
      { key: "Livraison" },
    ];

    if (commandeListExport.length === 0) {
      toast.error("No commandes to export.");
      return;
    }

    const data = commandeListExport.map((item) => ({
      Reference: item.referenceCommande,
      Client: item.userName,
      Email: item.email,
      Articles: item.nbArticle,
      Montant: item?.priceTotal?.toFixed(3) + " TND",
      Etat: item.etatCommande,
      Source: item.sourceCommandeWithValue.label,
      Date: UtilsFormule.formatDateHours(item?.dateCommande),
      Livraison:
        item?.livraisonDetails?.codePostal +
        ", " +
        item?.livraisonDetails?.adress +
        ", " +
        item?.livraisonDetails?.ville +
        ", " +
        item?.livraisonDetails?.pays,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data, {
      header: headers.map((h) => h.key),
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Commande Data");

    const filename_commande = `Commandes_${UtilsFormule.formatDateHours(
      new Date()
    )}.xlsx`;
    XLSX.writeFile(workbook, filename_commande);
  };

  return (
    <>
      <div class="content-wrapper" style={{ marginTop: "0" }}>
        <section class="content-header">
          <div class="container-fluid">
            <ToastContainer />
            <div class="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les Commandes</h5>
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <label>Choisir l'état de la commande</label>
                  <Select
                    id="SelectMarque"
                    value={etatCommandeSelected}
                    options={etatCommandeList}
                    onChange={handleSelectEtatCommande}
                    placeholder="Choisir l'état de la commande"
                  />
                </div>
                <div class="col-4">
                  <label>Choisir la date début</label>

                  <DatePicker
                    className="form-control"
                    selected={debutDate}
                    onChange={(date) => setDebutDate(date)}
                    // timeFormat="HH:mm"
                    // timeIntervals={20}
                    // timeCaption="time"
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <div class="col-4">
                  <label>Choisir la date fin</label>

                  <DatePicker
                    className="form-control"
                    selected={finDate}
                    onChange={(date) => setFinDate(date)}
                    // timeFormat="HH:mm"
                    // timeIntervals={20}
                    // timeCaption="time"
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>
              <div className="row mt-3">
                {/* <div className="col">{handleExportCSV()}</div> */}
                <div className="col">
                  <button
                    className="btn btn-dark float-left"
                    onClick={handleExportExcel}
                  >
                    Exporter Excel <FontAwesomeIcon icon={faDownload} />
                  </button>
                </div>

                <div className="col">
                  <button
                    className="btn btn-dark float-right"
                    onClick={() => {
                      clearFilter();
                    }}
                  >
                    Clear Filter <FontAwesomeIcon icon={faRemove} />
                  </button>
                </div>
              </div>
            </div>
            <section class="content">
              <div class="card card-dark">
                <div class="card-header">
                  <h3 class="card-title">Les Commandes disponibles</h3>

                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body p-0">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>#Ref</th>
                        <th>Client</th>
                        <th>Nombre Articles</th>
                        <th>Montant</th>
                        <th>Etat </th>
                        <th>Source </th>
                        <th>Date Commande</th>
                        <th>Livraison Détails</th>
                        {/* <th>Type De Livraison</th> */}
                        <th>Détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      {commandeList && commandeList.length > 0 ? (
                        commandeList &&
                        Array.isArray(commandeList) &&
                        commandeList.map((commande, index) => (
                          <tr key={index}>
                            <td>{commande?.referenceCommande || ""}</td>
                            <td>{commande?.userName || ""}</td>
                            <td>{commande?.nbArticle || ""}</td>
                            <td>
                              {commande?.priceTotal?.toFixed(3) || ""} TND
                            </td>
                            <td>
                              <span
                                className={
                                  commande?.etatCommandeWithValue?.value == 1
                                    ? "badge badge-dark"
                                    : commande?.etatCommandeWithValue?.value ==
                                      2
                                    ? "badge badge-primary"
                                    : commande?.etatCommandeWithValue?.value ==
                                      3
                                    ? "badge badge-info"
                                    : commande?.etatCommandeWithValue?.value ==
                                      4
                                    ? "badge badge-warning"
                                    : commande?.etatCommandeWithValue?.value ==
                                      5
                                    ? "badge badge-success"
                                    : commande?.etatCommandeWithValue?.value ==
                                      6
                                    ? "badge badge-danger"
                                    : commande?.etatCommandeWithValue?.value ==
                                      7
                                    ? "badge badge-secondary"
                                    : "badge badge-dark"
                                }
                              >
                                {commande?.etatCommandeWithValue?.label || ""}
                              </span>
                            </td>
                            <td>
                              <span
                                className={
                                  commande?.sourceCommandeWithValue?.value == 1
                                    ? "badge badge-dark"
                                    : commande?.sourceCommandeWithValue
                                        ?.value == 2
                                    ? "badge badge-primary"
                                    : commande?.sourceCommandeWithValue
                                        ?.value == 3
                                    ? "badge badge-secondary"
                                    : "badge badge-dark"
                                }
                              >
                                {commande?.sourceCommandeWithValue?.label || ""}
                              </span>
                            </td>
                            <td>
                              {UtilsFormule.formatDateHours(
                                commande?.dateCommande || ""
                              )}
                            </td>
                            <td>
                              {commande?.livraisonDetails?.codePostal || ""}
                              {", "}
                              {commande?.livraisonDetails?.adress || ""}
                              {", "}
                              {commande?.livraisonDetails?.ville || ""}
                              {"- "}
                              {commande?.livraisonDetails?.pays || ""}
                            </td>
                            {/* <td>
                                  {commande?.livraisonDetails?.type || ""}
                                </td> */}
                            <td>
                              <div>
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={() => {
                                    getDetailCommande(commande?.id);
                                  }}
                                >
                                  Voir détails
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr className="text-center">
                            <td colSpan="7">{listVideMsg}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                  {commandeList && commandeList.length > 0 && (
                    <>
                      <div className="d-flex" style={{ padding: "15px" }}>
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={(newPage) => setCurrentPage(newPage)}
                        />
                        <div className="ml-3">
                          <select
                            className="form-control pagination-select float-right "
                            id="itemsPerPageSelect"
                            value={itemsPerPage}
                            onChange={(e) =>
                              setItemsPerPage(parseInt(e.target.value, 10))
                            }
                          >
                            {[4, 7, 10, 15, 20].map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
}

export default Commandes;
