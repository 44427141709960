import { Component } from "react";
import { Link } from "react-router-dom";
import MultivendeurService from "../services/MultivendeurService";
export default class AllVendeurs extends Component {
  state = {
    idVendeur: "",
    vendeurs: [],
    showFormImage: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      idVendeur: "",
      vendeurs: [],
      showFormImage: false,
    };
  }
  componentDidMount() {
    this.forceUpdate();
    MultivendeurService.getAllMultivendeur()
      .then((response) => {
        console.log("response", response.data.success);
        if (response.data.success) {
          this.setState({ vendeurs: response.data.success });
          console.log("vendeurs", this.state.vendeurs);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  archiver(id, archiver) {
    MultivendeurService.deleteMultivendeur(id, archiver).then((response) => {
      if (response.data.success) {
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    });
  }
  buttonArchive(id, isArchived) {
    if (isArchived === 0) {
      return (
        <button
          class="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idVendeur: id });
            // this.showFormImage();
            this.archiver(id, 1);
          }}
        >
          <i class="fas fa-trash"></i> Archiver
        </button>
      );
    } else {
      return (
        <button
          class="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idVendeur: id });
            // this.showFormImage();
            this.archiver(id, 0);
          }}
        >
          <i class="fas fa-check"></i>Restaurer
        </button>
      );
    }
  }
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les vendeurs</h5>
                <p className="float-right">
                  <button class="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/addVendeurs" }}
                      style={{ color: "white", "text-decoration": "none" }}
                    >
                      <i className="fas fa-plus" /> Ajouter
                    </Link>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="col-md-12">
          <section class="content">
            <div className="col-12">
              <div className="card">
                <div className="card-header"></div>

                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Libellé</th>
                        <th>Modifier</th>
                        <th>Archiver</th>
                        <th>Voir plus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.vendeurs.map((vendeur) => (
                        <tr>
                          <td>{vendeur.name}</td>
                          <td>
                            <button
                              class="btn btn-info btn-sm"
                              data-toggle="modal"
                              data-target="#modal-update"
                              disabled
                              //   onClick={() => {
                              //     this.setState({ idvendeurProduct: vendeur.id });
                              //     this.showFormImage();
                              //   }}
                            >
                              <i class="fas fa-pencil-alt"></i> Modifier
                            </button>
                            {/* {this.state.showFormImage && (
                                  <div className="modal fade" id="modal-update">
                                    <vendeurUpdateLibelle
                                      idvendeurProduct={this.state.idvendeurProduct}
                                    />
                                  </div>
                                )} */}
                          </td>

                          <td>
                            {this.buttonArchive(vendeur.id, vendeur.isArchived)}

                            {/* {this.state.showFormImage && (
                                  <div className="modal fade" id="modal-default">
                                    <FamilyProductDelete
                                      idFamilyProduct={this.state.idFamilyProduct}
                                    />
                                  </div>
                                )} */}
                          </td>
                          <td>
                            <button
                              class="btn btn-primary btn-sm"
                              disabled
                              //   onClick={() => {
                              //     sessionStorage.setItem("idvendeurProduct", vendeur.id);
                              //     this.showFormImage();
                              //   }}
                            >
                              {/* <Link
                                to={{
                                  pathname: "/allProductByvendeur",
                                }}
                                style={{ color: "white" }}
                              > */}
                                <i class="fas fa-folder"></i> Voir les produits
                              {/* </Link> */}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
