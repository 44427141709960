import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import NouveauteService from "src/services/NouveauteService";

function AddNouveauteProduit() {
  const [listAllProducts, setListAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsDefault, setSelectedProductsDefault] = useState([]);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    getAllProductsForAddNew();
  }, []);

  const addNouveauteProducts = () => {
    const NouveauteProductsModel = JSON.stringify(
      selectedProducts.map((product) => product.value)
    );
    console.log("NouveauteProductsModel", NouveauteProductsModel);
    NouveauteService.addNouveauteProducts(NouveauteProductsModel)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setSelectedProducts([]);
          setTimeout(function () {
            window.location.href = "/allNouveauteProduit";
          }, 2000);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getAllProductsForAddNew = () => {
    NouveauteService.getAllProductsForAddNew()
      .then((response) => {
        if (response.data.success) {
          setListAllProducts(response.data.success);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
    console.log("Selected Products:", selectedOptions);
    console.log(selectedProducts);
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">
                    Ajouter les nouveautes produits
                  </h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Sélectionner Les produits</label>

                    <Select
                      defaultValue={selectedProductsDefault || ""}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={listAllProducts}
                      onChange={handleProductChange}
                      value={selectedProducts}
                      placeholder="Sélectionner Les produits..."
                    />

                    {/* {promotionList.map((promotion, index) => (
                        <option key={index} value={promotion?.id || ""}>
                          {promotion?.name || ""}
                        </option>
                      ))} */}
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default">
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={addNouveauteProducts}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddNouveauteProduit;
