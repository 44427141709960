import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class CarrousselTrendingHomeService {
  addCarrousselTrendingHome(picture, carrousselTrendingHomeModel) {
    return api.post(
      urlsCommon.BASE_CARROUSSEL_TRENDING_URL + "/addCarrousselTrendingHome",
      picture,
      {
        params: {
          carrousselTrendingHomeModel,
        },
      }
    );
  }

  addProductsTrendingHome(idTrending, refProduct) {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_TRENDING_URL +
        "/addProductsTrendingHome/" +
        idTrending +
        "/" +
        refProduct
    );
  }
  allCarrousselTrendingHome() {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_TRENDING_URL + "/allCarrousselTrendingHome"
    );
  }
  getProductByRef(ref) {
    return api.get(urlsCommon.BASE_PRODUCT_URL + "/getProductByRef/" + ref);
  }
  getProduitTrendingById(idProductTrending) {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_TRENDING_URL +
        "/getProduitTrendingById/" +
        idProductTrending
    );
  }
  archiveProduitTrendingById(idProductTrending, isArchived) {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_TRENDING_URL +
        "/archiveProduitTrendingById/" +
        idProductTrending +
        "/" +
        isArchived
    );
  }
  getImageProductByRef(ref) {
    return api.get(
      urlsCommon.BASE_PRODUCT_URL + "/getImageProductByRef/" + ref
    );
  }
  allProductsTrendingHome(idCarroussel) {
    return api.get(
      urlsCommon.BASE_CARROUSSEL_TRENDING_URL +
        "/allProductsTrendingHome/" +
        idCarroussel
    );
  }
}
export default new CarrousselTrendingHomeService();
