import { Component } from "react";
import { Link } from "react-router-dom";

export default class Livreurs extends Component {
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Les modes de livraison</h5>

              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addLivreur" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    <i class="fas fa-plus"></i> Ajouter Livreur
                  </Link>
                </button>{" "}
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addLivraison" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    <i class="fas fa-plus"></i> Ajouter livraison
                  </Link>
                </button>
              </p>
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header border-0">
              <h3 className="card-title">Livraisons</h3>
              {/* <div className="card-tools">
                <a href="#" className="btn btn-tool btn-sm">
                  <i className="fas fa-download" />
                </a>
                <a href="#" className="btn btn-tool btn-sm">
                  <i className="fas fa-bars" />
                </a>
              </div> */}
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>Libellé</th>

                    <th>Nombres de commandes</th>
                    <th>Montant total</th>
                    <th>Plus </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src="dist/img/default-150x150.png"
                        alt="Product 1"
                        className="img-circle img-size-32 mr-2"
                      />
                      Livreur 1
                    </td>

                    <td>
                      <small className="text-success mr-1">
                        <i className="fas fa-arrow-up" />
                        12%
                      </small>
                      12 commandes
                    </td>
                    <td>1000 DNT</td>
                    <td>
                      <a href="#" className="text-muted">
                        <i className="fas fa-search" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="dist/img/default-150x150.png"
                        alt="Product 1"
                        className="img-circle img-size-32 mr-2"
                      />
                      livreur 2
                    </td>

                    <td>
                      <small className="text-danger mr-1">
                        <i className="fas fa-arrow-down" />
                        0.5%
                      </small>
                      10 commandes
                    </td>
                    <td>1100 DNT</td>
                    <td>
                      <a href="#" className="text-muted">
                        <i className="fas fa-search" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
