import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Files from "react-files";
import CurrencyInput from "react-currency-input";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import CategoryService from "../services/CategoryService";

export default class FilterCategorie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      selectedOption: null,
      typeLivraison: "",
      price: "",
      files: [],
      optionsCategorie: [],
      categories: [],
      idCategorie: "",
    };
    this.changeLibelle = this.changeLibelle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changePrice = this.changePrice.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();

    CategoryService.getAllCategories()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ categories: response.data["object"] });
          console.log("categories", this.state.categories);
          this.remplissageOptionsCategory();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ idCategorie: selectedOption.value });
    console.log("idCategorie", this.state.idCategorie);
  };

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  changePrice(event) {
    this.setState({ price: event.target.value });
  }

  // filesUpload = () => {
  //   const formData = new FormData();
  //   Object.keys(this.state.files).forEach((key) => {
  //     const file = this.state.files[key];
  //     formData.append(
  //       key,
  //       new Blob([file], { type: file.type }),
  //       file.name || "file"
  //     );
  //   });

  //   axios
  //     .post(`/files`, formData)
  //     .then((response) =>
  //       window.alert(`${this.state.files.length} files uploaded succesfully!`)
  //     )
  //     .catch((err) => window.alert("Error uploading files :("));
  // };

  saveLivraison = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);

    toast.success("Ajout du mode de livraison avec succée");
    // BrandService.addBrand(dataFile, this.state.libelle)
    //   .then((response) => {
    //     if (response.data["code"] === "201") {
    //       toast.success("Ajout de la marque avec succée");
    //     } else if (response.data["code"] === "400") {
    //       toast.error(response.data["object"]);
    //     }

    //     console.log("response", response);
    //   })
    //   .catch((error) => {
    //     toast.error("une erreur a survenue");
    //   });
  };

  livraisonStandard() {
    console.log("typeLivraison", this.state.typeLivraison);
    if (this.state.typeLivraison === "Standard") {
      return (
        <div className="row">
          {" "}
          <div className="col-md-6">
            <label>Libellé</label>

            <input
              type="libelle"
              className="form-control"
              placeholder="Saisir..."
              value={this.state.libelle}
              onChange={this.changeLibelle}
            />
          </div>
          <div className="col-md-6">
            <label>Prix Initial : </label>
            <CurrencyInput
              suffix=" DNT"
              class="form-control"
              precision="3"
              value={this.state.price}
              onChangeEvent={this.changePrice}
            />
          </div>
          <br />
          <div className="col-md-12">
            <Files
              ref="files"
              className="files-dropzone-list"
              onChange={this.onFilesChange}
              onError={this.onFilesError}
              multiple
              maxFiles={1}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <label>Cliquer pour Ajouter une image</label>
            </Files>

            {this.state.files.length > 0 ? (
              <div className="files-list">
                <ul>
                  {this.state.files.map((file) => (
                    <li className="files-list-item" key={file.id}>
                      <div className="files-list-item-preview">
                        {file.preview.type === "image" ? (
                          <div className="row">
                            <img
                              className="files-list-item-preview-image"
                              src={file.preview.url}
                              // style={{
                              //   width: "10%",
                              //   height: "10%",
                              // }}
                            />
                            <button
                              id={file.id}
                              class="btn btn-navbar"
                              type="button"
                              // data-widget="navbar-search"
                              onClick={this.filesRemoveOne.bind(this, file)}
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        ) : (
                          <div className="files-list-item-preview-extension">
                            {file.extension}
                          </div>
                        )}
                      </div>
                      <div className="files-list-item-content">
                        <div className="files-list-item-content-item files-list-item-content-item-1">
                          {file.name}
                        </div>
                        <div className="files-list-item-content-item files-list-item-content-item-2">
                          {file.sizeReadable}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      );
    }
  }

  livraisonParPoid() {
    console.log("typeLivraison", this.state.typeLivraison);
    if (this.state.typeLivraison === "Par poids") {
      return (
        <div className="row">
          {" "}
          <div className="col-md-6">
            <label>Libellé</label>

            <input
              type="libelle"
              className="form-control"
              placeholder="Saisir..."
              value={this.state.libelle}
              onChange={this.changeLibelle}
            />
          </div>
          <div className="col-md-6">
            <label>Prix Initial du kg : </label>
            <CurrencyInput
              suffix=" DNT"
              class="form-control"
              precision="3"
              value={this.state.price}
              onChangeEvent={this.changePrice}
            />
          </div>
          <br />
          <div className="col-md-12">
            <Files
              ref="files"
              className="files-dropzone-list"
              onChange={this.onFilesChange}
              onError={this.onFilesError}
              multiple
              maxFiles={1}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <label>Cliquer pour Ajouter une image</label>
            </Files>

            {this.state.files.length > 0 ? (
              <div className="files-list">
                <ul>
                  {this.state.files.map((file) => (
                    <li className="files-list-item" key={file.id}>
                      <div className="files-list-item-preview">
                        {file.preview.type === "image" ? (
                          <div className="row">
                            <img
                              className="files-list-item-preview-image"
                              src={file.preview.url}
                              // style={{
                              //   width: "10%",
                              //   height: "10%",
                              // }}
                            />
                            <button
                              id={file.id}
                              class="btn btn-navbar"
                              type="button"
                              // data-widget="navbar-search"
                              onClick={this.filesRemoveOne.bind(this, file)}
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        ) : (
                          <div className="files-list-item-preview-extension">
                            {file.extension}
                          </div>
                        )}
                      </div>
                      <div className="files-list-item-content">
                        <div className="files-list-item-content-item files-list-item-content-item-1">
                          {file.name}
                        </div>
                        <div className="files-list-item-content-item files-list-item-content-item-2">
                          {file.sizeReadable}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      );
    }
  }
  livraisonParDistance() {
    console.log("typeLivraison", this.state.typeLivraison);
    if (this.state.typeLivraison === "Par distance") {
      return (
        <div className="row">
          {" "}
          <div className="col-md-6">
            <label>Libellé</label>

            <input
              type="libelle"
              className="form-control"
              placeholder="Saisir..."
              value={this.state.libelle}
              onChange={this.changeLibelle}
            />
          </div>
          <div className="col-md-6">
            <label>Prix Initial du km : </label>
            <CurrencyInput
              suffix=" DNT"
              class="form-control"
              precision="3"
              value={this.state.price}
              onChangeEvent={this.changePrice}
            />
          </div>
          <br />
          <div className="col-md-12">
            <Files
              ref="files"
              className="files-dropzone-list"
              onChange={this.onFilesChange}
              onError={this.onFilesError}
              multiple
              maxFiles={1}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <label>Cliquer pour Ajouter une image</label>
            </Files>

            {this.state.files.length > 0 ? (
              <div className="files-list">
                <ul>
                  {this.state.files.map((file) => (
                    <li className="files-list-item" key={file.id}>
                      <div className="files-list-item-preview">
                        {file.preview.type === "image" ? (
                          <div className="row">
                            <img
                              className="files-list-item-preview-image"
                              src={file.preview.url}
                              // style={{
                              //   width: "10%",
                              //   height: "10%",
                              // }}
                            />
                            <button
                              id={file.id}
                              class="btn btn-navbar"
                              type="button"
                              // data-widget="navbar-search"
                              onClick={this.filesRemoveOne.bind(this, file)}
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        ) : (
                          <div className="files-list-item-preview-extension">
                            {file.extension}
                          </div>
                        )}
                      </div>
                      <div className="files-list-item-content">
                        <div className="files-list-item-content-item files-list-item-content-item-1">
                          {file.name}
                        </div>
                        <div className="files-list-item-content-item files-list-item-content-item-2">
                          {file.sizeReadable}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      );
    }
  }
  remplissageOptionsCategory() {
    this.state.categories.map((cat) =>
      this.setState({
        optionsCategories: [
          ...this.state.optionsCategories,
          { value: cat.id, label: cat.name },
        ],
      })
    );
  }

  //   handleChange = (selectedOption) => {
  //     this.setState({ selectedOption }, () =>
  //       console.log(`Option selected:`, this.state.selectedOption)
  //     );
  //     this.setState({ typeLivraison: selectedOption.label });
  //   };

  render() {
    const animatedComponents = makeAnimated();

    const options = [
      { value: 1, label: "Standard" },
      { value: 2, label: "Par poids" },
      { value: 3, label: "Par distance" },
    ];
    const animatedComponentsCategory = makeAnimated();
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter un filter à une catégorie</h5>
              {/* <br /> Ajouter le libellé, le prix et l'image */}
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-md-12">
            <section class="content">
              <div class="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-outline card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          <b>Choisir la catégorie et ajouter les filtres</b>
                        </h3>
                      </div>

                      <form>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div class="form-group">
                                <label>Choisir la catégorie</label>
                                <Select
                                  id={"SelectCategorie"}
                                  value={this.state.selectedOption}
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                  options={this.state.optionsCategorie}
                                  onChange={this.handleChange}
                                  placeholder="Choisir..."
                                />
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <label>Choisir le type de livreur</label>

                              <Select
                                value={this.state.selectedOption}
                                closeMenuOnSelect={true}
                                components={animatedComponentsCategory}
                                options={options}
                                onChange={this.handleChange}
                                placeholder="Choisir..."
                              />
                            </div> */}
                          </div>
                          {this.livraisonStandard()}
                          {this.livraisonParPoid()}
                          {this.livraisonParDistance()}
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn btn-success float-right"
                            onClick={this.saveLivraison}
                          >
                            Valider
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
