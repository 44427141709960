import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ColorService {
  createColor(model) {
    return api.post(urlsCommon.BASE_COLOR_URL + "/createColor", model);
  }
  getAllColors(page, size) {
    return api.get(urlsCommon.BASE_COLOR_URL + "/getAllColors", {
      params: {
        page: page,
        size: size,
      },
    });
  }
  updateColorById(id, model) {
    return api.put(urlsCommon.BASE_COLOR_URL + "/update/" + id, model);
  }
  archiverColorById(id) {
    return api.put(urlsCommon.BASE_COLOR_URL + "/archiver/" + id);
  }

  searchColor(page, size, dataToSearch) {
    return api.get(urlsCommon.BASE_COLOR_URL + "/search", {
      params: {
        page: page,
        size: size,
        dataToSearch: dataToSearch,
      },
    });
  }
  //products in color
  getAllProductsInColor(page, size, idColor) {
    return api.get(
      urlsCommon.BASE_COLOR_URL + "/getAllProductsInColor/" + idColor,
      null,
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  searchProductInProductsOfColor(page, size, idColor, dataToSearch) {
    return api.get(
      urlsCommon.BASE_COLOR_URL + "/searchProductInProductsOfColor/" + idColor,
      {
        params: {
          page: page,
          size: size,
          dataToSearch: dataToSearch,
        },
      }
    );
  }

  getColorById(idColor) {
    return api.get(urlsCommon.BASE_COLOR_URL + "/getColorById/" + idColor);
  }
}
export default new ColorService();
