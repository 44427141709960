const IP_SERVER = "51.83.33.118";
const PORT_SERVER = "21";
const USER = "iware";
const PASS = "1w4reftp";
class Utils {
  // uploadFileToFtp(isActive, isDeleted) {
  //   var ftpClient = require("ftp-client"),
  //     config = {
  //       host: IP_SERVER,
  //       port: PORT_SERVER,
  //       user: USER,
  //       password: PASS,
  //     },
  //     options = {
  //       logging: "basic",
  //     },
  //     client = new ftpClient(config, options);
  //   client.connect(function () {
  //     client.upload(
  //       ["test/**"],
  //       "/public_html/test",
  //       {
  //         baseDir: "test",
  //         overwrite: "older",
  //       },
  //       function (result) {
  //         console.log(result);
  //       }
  //     );

  //     client.download(
  //       "/public_html/test2",
  //       "test2/",
  //       {
  //         overwrite: "all",
  //       },
  //       function (result) {
  //         console.log(result);
  //       }
  //     );
  //   });
  // }

  formatDate(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth() + 1).padStart(2, "0");
    let year = cdate.getFullYear();
    return day + "/" + month + "/" + year;
  }
}
export default new Utils();
