import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ShopThematiqueService {
  getThematiqueHome(id) {
    return api.get(
      urlsCommon.BASE_THEMATIQUE_BANNIERE_URL + "/getThematiqueHome/" + id
    );
  }
  getAllPagesThematiqueForSelectInUrlButtonBanniere() {
    return api.get(
      urlsCommon.BASE_THEMATIQUE_BANNIERE_URL +
        "/getAllPagesThematiqueForSelectInUrlButtonBanniere"
    );
  }

  updateThematiquesBanniere(model, file) {
    const formData = new FormData();
    formData.append("modelToUpdateThematique", JSON.stringify(model));
    formData.append("file", file);

    return api.put(
      urlsCommon.BASE_THEMATIQUE_BANNIERE_URL + "/updateThematiquesBanniere",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  addThematiqueBanniere(model, file) {
    const formData = new FormData();
    formData.append("shopThematiqueHomeModel", JSON.stringify(model));
    formData.append("file", file);

    return api.post(
      urlsCommon.BASE_THEMATIQUE_BANNIERE_URL + "/addShopThematiqueHome",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  allShopThematiqueHome(page, size) {
    return api.get(
      urlsCommon.BASE_THEMATIQUE_BANNIERE_URL + "/allShopThematiqueHome",
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  visible(id) {
    return api.put(urlsCommon.BASE_THEMATIQUE_BANNIERE_URL + "/visibled/" + id);
  }
  archive(id) {
    return api.put(urlsCommon.BASE_THEMATIQUE_BANNIERE_URL + "/archiver/" + id);
  }
}
export default new ShopThematiqueService();
