export const getAllStaff = () => {
  return {
    type: "GET_Staff_REQUESTED",
  };
};

export const loginstaff = (loginobject) => {
  return {
    type: "LOGIN_REQUESTED",
    loginobject,
  };
};

export const getAllSuppliers = () => {
  return {
    type: "GET_SUPPLIERS_REQUESTED",
  };
};

export const getAllProducts = () => {
  return {
    type: "GET_Products_REQUESTED",
  };
};

export const getAllWarehouses = () => {
  return {
    type: "GET_WAREHOUSES_REQUESTED",
  };
};

export const getAllWarehousesManager = () => {
  return {
    type: "GET_ALLWAREHOUSES_MANAGER_REQUESTED",
  };
};

export const addNewRealWarehouse = (warehouseModel) => {
  return {
    type: "POST_REAL_WAREHOUSE_REQUESTED",
    warehouseModel,
  };
};

export const addProductPurchaseToVWareHouse = (ProductPurchaseModel) => {
  return {
    type: "POST_PRODUCT_PURCHASE_TOVWAREHOUSE_REQUESTED",
    ProductPurchaseModel,
  };
};

export const getProductQuantitytByWarehouse = (ProductQteAndWarehouse) => {
  return {
    type: "GET_PRODUCT_QUANTITY_BY_WAREHOUSE_REQUESTED",
    ProductQteAndWarehouse,
  };
};

export const getProductsByWarehouse = (idWarehouse) => {
  return {
    type: "GET_PRODUCTS_BY_WAREHOUSE_REQUESTED",
    idWarehouse,
  };
};

export const postRequestTransfertBetweenWarehouses = (
  warehouseTransactionModel
) => {
  return {
    type: "POST_ASKING_WAREHOUSE_TRANSACTION_REQUESTED",
    warehouseTransactionModel,
  };
};

export const getAllTransporters = () => {
  return {
    type: "GET_ALLTRANSPORTERS_REQUESTED",
  };
};

export const getAllBrands = (isDeleted) => {
  return {
    type: "GET_ALLBRANDS_REQUESTED",
    isDeleted,
  };
};
export const getProductById = () => {
  return {
    type: "GET_PRODUCT_BY_ID_REQUESTED",
  };
};

export const addNewBrand = (productModel) => {
  return {
    type: "POST_BRAND_REQUESTED",
    productModel,
  };
};

export const setBrandNotificationState = () => {
  return {
    type: "SET_BRAND_NOTIFICATION_REQUESTED",
  };
};

export const deleteAndRestoreBrand = (deletedAndRestoreModel) => {
  return {
    type: "SET_BRANDS_ISDELETED_REQUESTED",
    deletedAndRestoreModel,
  };
};

export const updateBrandData = (updateBrandModel) => {
  return {
    type: "UPDATE_BRAND_REQUESTED",
    updateBrandModel,
  };
};

export const updateCategoryData = (updateCategoryModel) => {
  return {
    type: "UPDATE_CATEGORY_REQUESTED",
    updateCategoryModel,
  };
};

export const addNewFamily = (familyModel) => {
  return {
    type: "POST_FAMILY_REQUESTED",
    familyModel,
  };
};

export const getAllFamilies = (isDeleted) => {
  return {
    type: "GET_ALL_FAMILIES_REQUESTED",
    isDeleted,
  };
};

export const deleteAndRestoreFamily = (deletedAndRestoreModel) => {
  return {
    type: "SET_FAMILY_ISDELETED_REQUESTED",
    deletedAndRestoreModel,
  };
};

export const updateFamilyData = (updateFamilyModel) => {
  return {
    type: "UPDATE_FAMILY_REQUESTED",
    updateFamilyModel,
  };
};

export const getAllSubFamilies = (isDeleted) => {
  return {
    type: "GET_ALL_SUB_FAMILIES_REQUESTED",
    isDeleted,
  };
};

export const getAllSubFamiliesByFamilyId = (idFamily) => {
  return {
    type: "GET_ALL_SUB_FAMILIES_BY_FAMILY_ID_REQUESTED",
    idFamily,
  };
};

export const deleteAndRestoreSubFamily = (deletedAndRestoreModel) => {
  return {
    type: "SET_SUB_FAMILY_ISDELETED_REQUESTED",
    deletedAndRestoreModel,
  };
};

export const addNewSubFamily = (subFamilyModel) => {
  return {
    type: "POST_SUB_FAMILY_REQUESTED",
    subFamilyModel,
  };
};

export const updateSubFamilyData = (updateSubFamilyModel) => {
  return {
    type: "UPDATE_SUB_FAMILY_REQUESTED",
    updateSubFamilyModel,
  };
};

export const getAllCategories = (isDeleted) => {
  return {
    type: "GET_ALL_CATEGORIES_REQUESTED",
    isDeleted,
  };
};

export const deleteAndRestoreCategory = (deletedAndRestoreModel) => {
  return {
    type: "SET_CATEGORY_ISDELETED_REQUESTED",
    deletedAndRestoreModel,
  };
};

export const addNewCategory = (categoryModel) => {
  return {
    type: "POST_CATEGORY_REQUESTED",
    categoryModel,
  };
};
export const addNewProduct = (productModel) => {
  return {
    type: "POST_PRODUCT_REQUESTED",
    productModel,
  };
};

export const getAllCategoriesBySubFamilyId = (idSubFamily) => {
  return {
    type: "GET_ALL_CATEGORIES_BY_SUBFAMILY_ID_REQUESTED",
    idSubFamily,
  };
};

export const getAllProductsByFilters = (filterModel) => {
  return {
    type: "GET_ALL_PRODUCTS_BY_FILTER_REQUESTED",
    filterModel,
  };
};

export const deleteAndRestoreProduct = (deletedAndRestoreModel) => {
  return {
    type: "SET_PRODUCT_ISDELETED_REQUESTED",
    deletedAndRestoreModel,
  };
};

export const getAllRealWarehouses = (isDeleted) => {
  return {
    type: "GET_ALL_REAL_WAREHOUSE_REQUESTED",
    isDeleted,
  };
};

export const deleteAndRestoreRealWarehouse = (deletedAndRestoreModel) => {
  return {
    type: "SET_REAL_WAREHOUSE_ISDELETED_REQUESTED",
    deletedAndRestoreModel,
  };
};

export const updateRealWarehouseData = (updateWarehouseModel) => {
  return {
    type: "UPDATE_REAL_WAREHOUSE_REQUESTED",
    updateWarehouseModel,
  };
};

export const getAllProductsByFamilyOrSubFamilyOrBrandOrCategory = (
  idElement,
  cible
) => {
  return {
    type: "GET_ALL_PRODUCTS_BY_BRAND_ORBY_CATEGORY_ORBY_SUBFAMILY_ORBY_FAMILY_REQUESTED",
    idElement,
    cible,
  };
};

export const getAllProductsByWarehouse = (idWarehouse) => {
  return {
    type: "GET_ALL_PRODUCTS_BY_WAREHOUSE_REQUESTED",
    idWarehouse,
  };
};
export const addOrderPurchaseToWarehouse = (picture, purchaseOrderModel) => {
  return {
    type: "POST_ORDER_PURCHASE_TO_WAREHOUSE_REQUESTED",
    picture,
    purchaseOrderModel,
  };
};
