import { Component } from "react";
import { Link } from "react-router-dom";

export default class AllClients extends Component {
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <h4> Les clients </h4>
            {/* <div class="callout callout-success">
              <h5>Les commandes</h5>5 nouvelles commandes
              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addLivraison" }}
                    style={{ color: "white", "text-decoration": "none" }}
                  >
                    Nouvelles commandes
                  </Link>
                </button>
              </p>
              <br />
            </div> */}
          </div>
        </section>
        <section class="content">
          <div className="row">
            <div className="col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0"></div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-7">
                      <h2 className="lead">
                        <b>Bilel Yangui</b>
                      </h2>
                      <p className="text-muted text-sm">
                        <b>Commandes: </b> 2{" "}
                      </p>
                      <p className="text-muted text-sm">
                        <b>Montants: </b> 10 000 DNT{" "}
                      </p>
                      <ul className="ml-4 mb-0 fa-ul text-muted">
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-building" />
                          </span>{" "}
                          Adresse: Sousse, Tunisie
                        </li>
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-phone" />
                          </span>{" "}
                          Tel #: + 216 98 750 851
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 text-center">
                      {/* <img
                        src="dist/img/logoBZ.png"
                        alt="user-avatar"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <a href="#" className="btn btn-sm bg-teal">
                      <i className="fas fa-shopping-cart" />
                    </a>
                    <a href="#" className="btn btn-sm btn-primary">
                      <i className="fas fa-user" /> Profil
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0"></div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-7">
                      <h2 className="lead">
                        <b>Bilel Yangui</b>
                      </h2>
                      <p className="text-muted text-sm">
                        <b>Commandes: </b> 2{" "}
                      </p>
                      <p className="text-muted text-sm">
                        <b>Montants: </b> 10 000 DNT{" "}
                      </p>
                      <ul className="ml-4 mb-0 fa-ul text-muted">
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-building" />
                          </span>{" "}
                          Adresse: Sousse, Tunisie
                        </li>
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-phone" />
                          </span>{" "}
                          Tel #: + 216 98 750 851
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 text-center">
                      {/* <img
                        src="dist/img/logoBZ.png"
                        alt="user-avatar"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <a href="#" className="btn btn-sm bg-teal">
                      <i className="fas fa-shopping-cart" />
                    </a>
                    <a href="#" className="btn btn-sm btn-primary">
                      <i className="fas fa-user" /> Profil
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0"></div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-7">
                      <h2 className="lead">
                        <b>Bilel Yangui</b>
                      </h2>
                      <p className="text-muted text-sm">
                        <b>Commandes: </b> 2{" "}
                      </p>
                      <p className="text-muted text-sm">
                        <b>Montants: </b> 10 000 DNT{" "}
                      </p>
                      <ul className="ml-4 mb-0 fa-ul text-muted">
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-building" />
                          </span>{" "}
                          Adresse: Sousse, Tunisie
                        </li>
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-phone" />
                          </span>{" "}
                          Tel #: + 216 98 750 851
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 text-center">
                      {/* <img
                        src="dist/img/logoBZ.png"
                        alt="user-avatar"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <a href="#" className="btn btn-sm bg-teal">
                      <i className="fas fa-shopping-cart" />
                    </a>
                    <a href="#" className="btn btn-sm btn-primary">
                      <i className="fas fa-user" /> Profil
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
              <div className="card bg-light d-flex flex-fill">
                <div className="card-header text-muted border-bottom-0"></div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-7">
                      <h2 className="lead">
                        <b>Bilel Yangui</b>
                      </h2>
                      <p className="text-muted text-sm">
                        <b>Commandes: </b> 2{" "}
                      </p>
                      <p className="text-muted text-sm">
                        <b>Montants: </b> 10 000 DNT{" "}
                      </p>
                      <ul className="ml-4 mb-0 fa-ul text-muted">
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-building" />
                          </span>{" "}
                          Adresse: Sousse, Tunisie
                        </li>
                        <li className="small">
                          <span className="fa-li">
                            <i className="fas fa-lg fa-phone" />
                          </span>{" "}
                          Tel #: + 216 98 750 851
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 text-center">
                      {/* <img
                        src="dist/img/logoBZ.png"
                        alt="user-avatar"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-right">
                    <a href="#" className="btn btn-sm bg-teal">
                      <i className="fas fa-shopping-cart" />
                    </a>
                    <a href="#" className="btn btn-sm btn-primary">
                      <i className="fas fa-user" /> Profil
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
