import { Component } from "react";
import { Link } from "react-router-dom";
import ProduitJourService from "../services/ProduitJourService";
export default class AllProduitJour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carroussels: [],
    };
  }
            componentDidMount() {
              this.forceUpdate();
              ProduitJourService.getProduitJour()
                .then((response) => {
                  console.log("response", response.data);
                  if (response.data["code"]==="success" ) {
                    this.setState({ carroussels: response.data["object"] });
                  } else {
                    console.log("response", "error");
                  }
                })
                .catch((error) => {
                  console.log("response", "error");
                });
            }
            formatDate(date) {
              var cdate = new Date(date);
              var day = String(cdate.getDate()).padStart(2, "0");
              let month = String(cdate.getMonth()).padStart(2, "0");
              let year = cdate.getFullYear();
              return day + "/" + month + "/" + year;
            }
            archiver(id,archiver){
              ProduitJourService.deleteProduitJour(id,archiver).then((response)=>{
                if(response.data.success){
                  setTimeout(function(){
                    window.location.reload();
                 }, 3000);
                }
              })
            }
            buttonArchive(id, isArchived) {
              if (isArchived === 0) {
                return (
                  <button
                    class="btn btn-danger btn-sm"
                    data-toggle="modal"
                    data-target="#modal-default"
                    onClick={() => {
                      this.setState({ idVendeur: id });
                      // this.showFormImage();
                      this.archiver(id,1);
                    }}
                  >
                    <i class="fas fa-trash"></i> Archiver
                  </button>
                );
              } else {
                return (
                  <button
                    class="btn btn-success btn-sm"
                    data-toggle="modal"
                    data-target="#modal-default"
                    onClick={() => {
                      this.setState({ idVendeur: id });
                      // this.showFormImage();
                      this.archiver(id,0);
                    }}
                  >
                    <i class="fas fa-check"></i>Restaurer
                  </button>
                );  
              }
            }
            render() {
              return (
                <div class="content-wrapper">
                  <section class="content-header">
                    <div class="container-fluid">
                      <div class="callout callout-success">
                        <h5>Produit de jour</h5>
                        <p className="float-right">
                          <button class="btn btn-success btn-sm">
                            <Link
                              to={{ pathname: "/addProduitJour" }}
                              style={{ color: "white", "text-decoration": "none" }}
                            >
                              <i class="fas fa-plus"></i>
                              Ajouter
                            </Link>
                          </button>
                        </p>
                        <br />
                      </div>
                    </div>
                  </section>
          
                  <section class="content">
                    <div className="card card-outline card-success">
                      <div className="card-header">
                        <b>Produit de jour de la page Home</b>
                      </div>
                      <div className="card-body table-responsive p-0">
                        <table className="table table-striped table-valign-middle">
                          <thead>
                            <tr>
                              <th>#Id</th>
                              <th>Titre </th>
                              <th>date début </th>
                              <th>date fin </th>
                              <th>Archiver</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.carroussels.map((carroussel) => (
                              <tr>
                                <td>{carroussel.id}</td>
                                <td>{carroussel.titre}</td>
                                <td>{this.formatDate(carroussel.dateDebut)}</td>
                                <td>{this.formatDate(carroussel.dateFin)}</td>
                                <td>
                                {this.buttonArchive(carroussel.id, carroussel.isArchived)}
    
                                {/* {this.state.showFormImage && (
                                  <div className="modal fade" id="modal-default">
                                    <FamilyProductDelete
                                      idFamilyProduct={this.state.idFamilyProduct}
                                    />
                                  </div>
                                )} */}
                              </td>
                            
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
              );
            }
}