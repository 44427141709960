import { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Files from "react-files";
import { TextField } from "@material-ui/core";
import SubFamilyProductService from "../services/SubFamilyProductService";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import ActiviteService from "../services/ActiviteService";
import MultivendeurService from "../services/MultivendeurService";
export default class AddVendeurs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: "",
      prenom: "",
      numTel: "",
      selectedValue: "",
      Matricul: "",
      email: "",
      adresse: "",
      error: "",
      startDate: "",
      typeService: "",
      files: [],
      livrable: false,
      redirect: false,
      visible: false,
      idActivite: "",
      activites: [],
      options: [],
    };
    this.changenom = this.changenom.bind(this);
    this.changePrenom = this.changePrenom.bind(this);
    this.changeAdresse = this.changeAdresse.bind(this);
    this.changenumTel = this.changenumTel.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeMatricul = this.changeMatricul.bind(this);
    // this.saveFamilyProduct = this.saveFamilyProduct.bind(this);

    this.saveMultivendeur = this.saveMultivendeur.bind(this);
  }

  handleChange(event) {
    if (this.state.livrable) {
      this.setState({ livrable: false });
    } else {
      this.setState({ livrable: true });
    }

    console.log("livrable", this.state.livrable);
  }
  componentDidMount() {
    this.forceUpdate();
    ActiviteService.getAllActivite()
      .then((response) => {
        console.log("response", response.data["success"]);
        if (response.data["success"]) {
          // console.log("In Societe else");
          this.setState({ activites: response.data["success"] });
          // console.log("subFamilyProducts", this.state.subFamilyProducts);
          // this.setState({ alert_message: "success" });
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  handleChangeList = (selectedOption) => {
    this.setState({ idActivite: selectedOption.value });
    // console.log("idActivite", this.state.idActivite);
  };

  remplissageOptions() {
    console.log("activites", this.state.activites);
    this.state.activites.map((activite) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: activite.id, label: activite.name },
        ],
      })
    );
  }
  handleVisible(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }

  changenom(event) {
    this.setState({ nom: event.target.value });
    console.log("nom", this.state.nom);
  }
  changePrenom(event) {
    this.setState({ prenom: event.target.value });
    console.log("prenom", this.state.prenom);
  }
  changenumTel(event) {
    this.setState({ numTel: event.target.value });
    console.log("numTel", this.state.numTel);
  }
  changeStartDate(event) {
    this.setState({ startDate: event.target.value.concat(":59") });
  }
  changeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  changeAdresse(e) {
    this.setState({
      adresse: e.target.value,
    });
  }
  changeMatricul(e) {
    this.setState({
      Matricul: e.target.value,
    });
  }

  emailValidation() {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!this.state.email || regex.test(this.state.email) === false) {
      this.setState({
        error: "Email is not valid",
      });
      return false;
    }
    return true;
  }
  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };
  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };
  saveFamilyProduct = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);
    console.log("livrable", this.state.livrable);
    // FamilyProductService.saveFamilyProduct(
    //   dataFile,
    //   this.state.livrable,
    //   this.state.nom,
    //   this.state.visible
    // )
    //   .then((response) => {
    //     if (response.data["code"] === "201") {
    //       toast.success("Ajout de la famille avec succée");
    //       // allFamilyProducts;
    //       setTimeout(() => {
    //         this.setState({ redirect: true });
    //       }, 3000);
    //     } else if (response.data["code"] === "400") {
    //       toast.error(response.data["object"]);
    //     }

    //     console.log("response", response);
    //   })
    //   .catch((error) => {
    //     toast.error("une erreur a survenue");
    //   });
  };
  saveMultivendeur = (e) => {
    e.preventDefault();
    if (this.state.files[0] == undefined) {
      toast.error("add image obligatoire !! ");
      return;
    }
    const dataFile = new FormData();
    dataFile.append("file", this.state.files[0]);
    MultivendeurService.uploadFileImage(dataFile).then((response) => {
      console.log("response", response);
      if (response.data.error) {
        toast.error(response.data.error);
      }
      if (response.data.Success) {
        let multivendeurModel = {
          name: this.state.nom,
          lastname: this.state.prenom,
          adresse: this.state.adresse,
          adresseEmail: this.state.email,
          numberTel: this.state.numTel,
          matricule_Fiscal: this.state.Matricul,
          id_activite: this.state.idActivite,
          file: response.data.Success,
        };
        if (
          !multivendeurModel.name ||
          !multivendeurModel.lastname ||
          !multivendeurModel.adresse ||
          !multivendeurModel.adresseEmail ||
          !multivendeurModel.numberTel ||
          !multivendeurModel.matricule_Fiscal ||
          !multivendeurModel.id_activite ||
          !multivendeurModel.file
        ) {
          toast.error("you need to fill in the fields!");
          return;
        }
        console.log(multivendeurModel);
        MultivendeurService.addMultivendeur(multivendeurModel).then(
          (response) => {
            console.log(response);
            if (response.data.success) {
              toast.success("Vendeur added successfully!");
              setTimeout(() => {
                this.setState({ redirect: true });
              }, 3000);
            } else {
              toast.error(response.data.error);
            }
          }
        );
      }
    });
  };

  render() {
    const animatedComponents = makeAnimated();

    if (this.state.redirect) {
      return <Navigate replace to="/AllVendeurs" />;
    } else {
      return (
        <div class="content-wrapper">
          <ToastContainer />
          <div class="container-fluid">
            <section class="content-header">
              <div class="callout callout-success">
                <h5>Ajouter un vendeur </h5>
                Deux étapes:
                <br /> 1-Ajouter une image
                <br /> 2-Ajouter le libellé et valider
              </div>
            </section>
            <div className="row">
              <div className="col-md-6">
                <section class="content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-outline card-success">
                        <div className="card-header">
                          <h3 className="card-title">
                            <b>1-AJOUTER UNE IMAGE</b>
                          </h3>
                        </div>

                        <form>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div>
                                  <Files
                                    ref="files"
                                    className="files-dropzone-list"
                                    // style={{ height: "100px" }}
                                    onChange={this.onFilesChange}
                                    onError={this.onFilesError}
                                    multiple
                                    maxFiles={1}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                  >
                                    <label>
                                      Cliquer pour Ajouter une image
                                    </label>
                                  </Files>

                                  {this.state.files.length > 0 ? (
                                    <div className="files-list">
                                      <ul>
                                        {this.state.files.map((file) => (
                                          <li
                                            className="files-list-item"
                                            key={file.id}
                                          >
                                            <div className="files-list-item-preview">
                                              {file.preview.type === "image" ? (
                                                <div className="row">
                                                  <img
                                                    className="files-list-item-preview-image"
                                                    src={file.preview.url}
                                                    style={{
                                                      width: "70%",
                                                      height: "70%",
                                                    }}
                                                  />
                                                  <button
                                                    id={file.id}
                                                    class="btn btn-navbar"
                                                    type="button"
                                                    // data-widget="navbar-search"
                                                    onClick={this.filesRemoveOne.bind(
                                                      this,
                                                      file
                                                    )}
                                                  >
                                                    <i class="fas fa-times"></i>
                                                  </button>
                                                </div>
                                              ) : (
                                                <div className="files-list-item-preview-extension">
                                                  {file.extension}
                                                </div>
                                              )}
                                            </div>
                                            <div className="files-list-item-content">
                                              <div className="files-list-item-content-item files-list-item-content-item-1">
                                                {file.name}
                                              </div>
                                              <div className="files-list-item-content-item files-list-item-content-item-2">
                                                {file.sizeReadable}
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  ) : (
                                    <h1></h1>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-6">
                <section class="content">
                  <div class="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-outline card-success">
                          <div className="card-header">
                            <h3 className="card-title">
                              <b>2-AJOUTER UN VENDEUR</b>
                            </h3>
                          </div>

                          <form>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-2">
                                  <label>Nom</label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="libelle"
                                    className="form-control"
                                    placeholder="Ajouter nom"
                                    value={this.state.nom}
                                    onChange={this.changenom}
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-2">
                                  <label>Prénom</label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="libelle"
                                    className="form-control"
                                    placeholder="Ajouter prenom"
                                    value={this.state.prenom}
                                    onChange={this.changePrenom}
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-2">
                                  <label>Num Tel </label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Ajouter Numéro de télephone"
                                    value={this.state.numTel}
                                    onChange={this.changenumTel}
                                  />
                                </div>
                              </div>
                              {/* <br/>
                                <div className="row">
                                  <div className="col-md-2">
                                    <label> date </label>
                                  </div>
                                  <div className="col-md-10">
                                  <TextField
                                                style={{ width: "100%" }}
                                                className="col-sm-12"
                                                variant="outlined"
                                                value={this.state.startDate}
                                                onChange={this.changeStartDate}
                                                id="datetime-local"
                                                type="datetime-local"
                                                defaultValue="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size="small"
                                            />
                                  </div>
                                </div> */}
                              <br />
                              <div className="row">
                                <div className="col-md-2">
                                  <label>Email </label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.changeEmail}
                                    className="form-control"
                                    placeholder="username@gmail.com"
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-2">
                                  <label>Activité</label>
                                </div>
                                <div className="col-md-10">
                                  <Select
                                    id={"SelectMarque"}
                                    value={this.state.selectedOption}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={this.state.options}
                                    onChange={this.handleChangeList}
                                    placeholder="Saisir..."
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-2">
                                  <label> Adresse </label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="libelle"
                                    className="form-control"
                                    placeholder="Ajouter adresse"
                                    value={this.state.adresse}
                                    onChange={this.changeAdresse}
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-2">
                                  <label> Matricule fiscal </label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="libelle"
                                    className="form-control"
                                    placeholder="Ajouter Matricul"
                                    value={this.state.matricul}
                                    onChange={this.changeMatricul}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="card-footer">
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={this.saveMultivendeur}
                              >
                                Valider
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
