import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VetementsBanniereService from "src/services/VetementsBanniereService";

export default class VetementsBanniereDelete extends Component {
  state = {
    totalLook: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      totalLook: "",
    };
    this.removetotalLook = this.removetotalLook.bind(this);
    // this.closeWindow = this.closeWindow.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    VetementsBanniereService.getVetementsBanniereById(this.props.id)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ totalLook: response.data["object"] });
          console.log("totalLook", this.state.totalLook);
          // this.setState({ alert_message: "success" });
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  removetotalLook() {
    if (this.state.totalLook.isArchived === 0) {
      VetementsBanniereService.archive(this.state.totalLook.id, 1)
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Suppression avec succée");
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }

          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    } else if (this.state.totalLook.isArchived === 1) {
      VetementsBanniereService.archive(this.state.totalLook.id, 0)
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Restauration avec succée");
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }

          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    }

    console.log("removeBrand");
  }

  render() {
    if (this.state.totalLook.isArchived === 1) {
      return (
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Restaurer banniere de totalLook {this.state.totalLook.name}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Voulez-vous vraiment restaurer {this.state.totalLook.name}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.removetotalLook}
              >
                Restaurer
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Supprimer la catégorie {this.state.totalLook.name}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Voulez-vous vraiment supprimer {this.state.totalLook.name}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={this.removetotalLook}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}
