import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ActiviteService {
  addActivite(activiteModel) {
    return api.post(
      urlsCommon.BASE_ACTIVITE_URL + "/addActivite",
      activiteModel
    );
  }
  getAllActivite() {
    return api.get(urlsCommon.BASE_ACTIVITE_URL + "/activites");
  }
  deleteActivite(id, isArchive) {
    return api.get(
      urlsCommon.BASE_ACTIVITE_URL + "/deleteActivite/" + id + "/" + isArchive
    );
  }
}
export default new ActiviteService();
