import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class VetementsBanniereService {
  addVetementsBanniere(picture, vetementsBanniereModel) {
    return api.post(urlsCommon.BASE_BANNIERE_VETEMENTES_URL + "/add", picture, {
      params: {
        vetementsBanniereModel,
      },
    });
  }
  allVetementsBanniere() {
    return api.get(urlsCommon.BASE_BANNIERE_VETEMENTES_URL + "/all");
  }

  getVetementsBanniereById(id) {
    return api.get(
      urlsCommon.BASE_BANNIERE_VETEMENTES_URL +
        "/getVetementsBanniereById/" +
        id
    );
  }
  visible(id, deed) {
    return api.get(
      urlsCommon.BASE_BANNIERE_VETEMENTES_URL + "/visible/" + id + "/" + deed
    );
  }
  archive(id, deed) {
    return api.get(
      urlsCommon.BASE_BANNIERE_VETEMENTES_URL + "/archiver/" + id + "/" + deed
    );
  }
}
export default new VetementsBanniereService();
