import { Component } from "react";
import Circle from "@uiw/react-color-circle";
import Files from "react-files";
import CurrencyInput from "react-currency-input";
import ColorService from "../../services/ColorService";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import ColorPopup from "../ProductPopupModel/ColorPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VariantsService from "../../services/VariantsService";
import SizeService from "../../services/SizeService";

import NumericInput from "react-numeric-input";

export default class AddColorProduct extends Component {
  state = {
    colors: [],
    selectedOption: "",
    selectedOptionSize: [],
    options: [],
    background: "#FF5733",
    price: "",
    idColor: "",
    idSize: [],
    stock: [],
    showFormColor: false,
    files: [],
    nbSizes: 1,
    optionsSize: [],
    idVariant: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      colors: [],
      options: [],
      background: "#FFFFFF",
      price: "",
      idColor: "",
      idSize: [],
      stock: [],
      showFormColor: false,
      files: [],
      selectedOptionSize: [],
      nbSizes: 1,
      optionsSize: [],
      idVariant: "",
    };
    this.changePrice = this.changePrice.bind(this);
    this.changeStock = this.changeStock.bind(this);
    this.changeNbSizes = this.changeNbSizes.bind(this);
  }
  changeNbSizes(value) {
    if (value < 1 || value == 1) {
      this.setState({ nbSizes: 1 });
    } else {
      this.setState({ nbSizes: value });
    }
  }
  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  changeSizeSelected = (index, OptionsSelected) => {
    const updatedOptionsSelected = [...this.state.selectedOptionSize];
    updatedOptionsSelected[index] = OptionsSelected;

    const updatedIdSize = [...this.state.idSize];
    updatedIdSize[index] = OptionsSelected.value;

    this.setState({
      selectedOptionSize: updatedOptionsSelected,
      idSize: updatedIdSize,
    });

    console.log("idSize :", updatedIdSize);
  };

  componentDidMount() {
    this.forceUpdate();
    ColorService.getAllColors()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ colors: response.data["object"] });
          console.log("colors", this.state.colors);
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });

    SizeService.getAllSizes()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ sizes: response.data["object"] });
          //console.log("colors", this.state.colors);
          this.remplissageOptionsSize();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  remplissageOptionsSize() {
    this.state.sizes.map((size) =>
      this.setState({
        optionsSize: [
          ...this.state.optionsSize,
          { value: size.id, label: size.size + "-" + size.size_name },
        ],
      })
    );
  }

  changeStock(index, value) {
    const updatedStock = [...this.state.stock];
    updatedStock[index] = value;

    this.setState({ stock: updatedStock });
    console.log("stock : " + updatedStock);
  }

  showFormColor = () => {
    this.setState({ showFormColor: !this.state.showFormColor });
  };

  remplissageOptions() {
    this.state.colors.map((color) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: color.id, label: color.color + "-" + color.color_name },
        ],
      })
    );
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ idColor: selectedOption.value });
    let colorcode = selectedOption.label.split("-");
    this.setState({ background: colorcode[0] });
    console.log("idColor", this.state.idColor);
  };

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }

  changePrice(event) {
    this.setState({ price: event.target.value });
  }

  addVairent = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      formData.append(
        "pictures",
        new Blob([file], { type: file.type }),
        file.name || "file"
      );
    });
    let pric = this.state.price.replace(" DNT", "");

    const variantsModel = {
      idProduct: this.props.idProduct,
      idColor: this.state.idColor,
      price: pric.replace(",", ""),
    };

    VariantsService.addVariants(formData, JSON.stringify(variantsModel))
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({
            idVariant: response.data["object"],
          });

          const variantsSize = [];
          for (let indexSize = 0; indexSize < this.state.nbSizes; indexSize++) {
            const variantsSizeModel = {
              idSize: this.state.idSize[indexSize],
              idVariant: response.data["object"],
              stock: this.state.stock[indexSize],
            };

            variantsSize.push(variantsSizeModel);
          }
          variantsSize.forEach((model, index) => {
            console.log(model);
            VariantsService.addSizeToVariants(JSON.stringify(model))
              .then((response) => {
                if (
                  response.data["code"] === "201" ||
                  response.data["code"] === "200"
                ) {
                  // this.setState({ idVariant: response.data["object"] });
                  toast.success("Ajout du variants Size Model avec succée");
                  // console.log("idProduct", this.state.idProduct);
                } else {
                  toast.error(response.data["code"]);
                }
                //console.log("response", response);
              })
              .catch((error) => {
                toast.error("Une erreur a survenue");
              });
          });
          toast.success("Ajout du varients avec succée");
          // window.location.href="/addProduct"
        } else {
          toast.error(response.data["code"]);
        }
        console.log("variantsModelresponse", response);
        console.log("variantsModeldata", response.data);
      })
      .catch((error) => {
        toast.error("Une erreur a survenue");
      });
  };
  render() {
    const animatedComponents = makeAnimated();

    return (
      <form>
        <ToastContainer />
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <label>Prix : </label>
              <CurrencyInput
                suffix=" DNT"
                class="form-control"
                precision="3"
                value={this.state.price}
                onChangeEvent={this.changePrice}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div class="form-group">
                <label>Choisir le couleur</label>
                <Select
                  value={this.state.selectedOption}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={this.state.options}
                  onChange={this.handleChange}
                  placeholder="Saisir..."
                />
              </div>
            </div>

            <div className="col-md-1">
              <label>
                <br />{" "}
              </label>
              <Circle colors={[this.state.background]} />
            </div>
            <div className="col-md-3">
              <label>
                <br />{" "}
              </label>
              <br />
              <button
                type="button"
                class="btn btn-default"
                data-toggle="modal"
                data-target="#modal-color"
              >
                Nouvelle couleur
              </button>
            </div>
          </div>

          <div className="modal fade" id="modal-color">
            <ColorPopup />
          </div>

          <br />
          <div className="row">
            <Files
              ref="files"
              className="files-dropzone-list"
              // style={{ height: "100px" }}
              onChange={this.onFilesChange}
              onError={this.onFilesError}
              multiple
              maxFiles={5}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <label>Cliquer pour Ajouter des images 1200*1800px</label>
            </Files>

            {this.state.files.length > 0 ? (
              <div className="files-list">
                <ul>
                  {this.state.files.map((file) => (
                    <li className="files-list-item" key={file.id}>
                      <div className="files-list-item-preview">
                        {file.preview.type === "image" ? (
                          <div className="row">
                            <img
                              className="files-list-item-preview-image"
                              src={file.preview.url}
                              style={{
                                width: "20%",
                                height: "20%",
                              }}
                            />
                            <button
                              id={file.id}
                              class="btn btn-navbar"
                              type="button"
                              // data-widget="navbar-search"
                              onClick={this.filesRemoveOne.bind(this, file)}
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        ) : (
                          <div className="files-list-item-preview-extension">
                            {file.extension}
                          </div>
                        )}
                      </div>
                      <div className="files-list-item-content">
                        <div className="files-list-item-content-item files-list-item-content-item-1">
                          {file.name}
                        </div>
                        <div className="files-list-item-content-item files-list-item-content-item-2">
                          {file.sizeReadable}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <h1></h1>
            )}
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <label>Number of size</label>
              <NumericInput
                className="form-control"
                value={this.state.nbSizes}
                onChange={this.changeNbSizes}
              />
            </div>
          </div>

          {this.state.nbSizes > 0 && (
            <>
              {Array.from({ length: this.state.nbSizes }, (_, indexSize) => (
                <div key={indexSize} className="mt-2">
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div class="form-group">
                        <label>Select Size</label>
                        <Select
                          value={this.state.selectedOptionSize[indexSize]}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={this.state.optionsSize}
                          onChange={(OptionsSelected) =>
                            this.changeSizeSelected(indexSize, OptionsSelected)
                          }
                          placeholder="Saisir..."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Stock</label>

                      <NumericInput
                        className="form-control"
                        value={
                          this.state.stock[indexSize] == null
                            ? 1
                            : this.state.stock[indexSize]
                        }
                        onChange={(value) => this.changeStock(indexSize, value)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          <br />
          <button
            type="submit"
            className="btn btn-success float-right"
            onClick={this.addVairent}
          >
            Ajouter
          </button>
        </div>
      </form>
    );
  }
}
