import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class CommandeService {
  getAllCommandeByState(state) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL + "/getAllCommandeByState/" + state
    );
  }
  getAllCommandeByStateAndDates(commandeStateDatesRequest) {
    return api.post(
      urlsCommon.BASE_COMMANDE_URL + "/getAllCommandeByStateByDates/",
      commandeStateDatesRequest
    );
  }
  getCommandeDetailsById(id) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL + "/getCommandeDetailsById/" + id
    );
  }
  getNumberCommandeByState(state) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL + "/getNumberCommandeByState/" + state
    );
  }

  setCommandeRemarque(idCommande, remarque) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL +
        "/setCommandeRemarque/" +
        idCommande +
        "/" +
        remarque
    );
  }
  /*******commande methodes */

  ///*********Etat commande */
  getAllEtatCommande(page) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL + "/getAllEtatCommande/" + page
    );
  }

  updateCommandeState(idUser, idCommande, state) {
    return api.put(
      urlsCommon.BASE_COMMANDE_URL +
        "/updateCommandeState/" +
        idUser +
        "/" +
        idCommande +
        "/" +
        state
    );
  }
  ///*********/.Etat commande */

  ///*********Source commande */
  getAllSourceCommande() {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL + "/source/getAllSourceCommande"
    );
  }

  updateCommandeSource(idCommande, source) {
    return api.put(
      urlsCommon.BASE_COMMANDE_URL +
        "/source/updateCommandeSource/" +
        idCommande +
        "/" +
        source
    );
  }
  ///*********/.Source commande */

  getAllCommandeByDateCreation(page, size) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL + "/getAllCommandeByDateCreation",
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  /*******filter commande methodes */
  filterCommandesByEtat(stateInteger, page, size) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL +
        "/filter/filterCommandesByEtat/" +
        stateInteger,
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  filterCommandesByDate(debutDate, findDate, page, size) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL + "/filter/filterCommandesByDate",
      {
        params: {
          page: page,
          size: size,
          debutDate: debutDate,
          findDate: findDate,
        },
      }
    );
  }

  filterCommandesByEtatAndDate(stateInteger, debutDate, findDate, page, size) {
    return api.get(
      urlsCommon.BASE_COMMANDE_URL +
        "/filter/filterCommandesByEtatAndDate/" +
        stateInteger,
      {
        params: {
          page: page,
          size: size,
          debutDate: debutDate,
          findDate: findDate,
        },
      }
    );
  }
  /***export */
  exportCommandes(state, debutDate, findDate) {
    return api.get(urlsCommon.BASE_COMMANDE_URL + "/csv/exportCommandes", {
      params: {
        state: state,
        debutDate: debutDate,
        findDate: findDate,
      },
    });
  }
}
export default new CommandeService();
