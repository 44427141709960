import urlsCommon from "src/common/urls-common.jsx";
import api from "../common/api.jsx";

class ThematiqueService {
  addThematiquePage(model, file) {
    const formData = new FormData();
    formData.append("thematiquePageAddModel", JSON.stringify(model));
    formData.append("file", file);

    return api.post(urlsCommon.BASE_THEMATIQUE_URL + "/add", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getAllThematiquesPage(page, size) {
    return api.get(urlsCommon.BASE_THEMATIQUE_URL + "/getAll", {
      params: {
        page: page,
        size: size,
      },
    });
  }

  getProductsForThematiquesPage(idThematique, page, size) {
    return api.get(
      urlsCommon.BASE_THEMATIQUE_URL +
        "/getProductsForThematiquesPage/" +
        idThematique,
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
  }

  updateProductsForThematiquePage(id, idsProducts) {
    return api.put(
      urlsCommon.BASE_THEMATIQUE_URL + "/updateProductsForThematiquePage/" + id,
      idsProducts
    );
  }
  updateThematiquePage(model, file) {
    const formData = new FormData();
    formData.append("thematiquePageUpdateModel", JSON.stringify(model));
    formData.append("file", file);

    return api.put(urlsCommon.BASE_THEMATIQUE_URL + "/update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  getProductsForThematiqueForUpdate(idThematique) {
    return api.get(
      urlsCommon.BASE_THEMATIQUE_URL +
        "/getProductsForThematiqueForUpdate/" +
        idThematique
    );
  }

  removeProductFromThematiquePage(idThematique, idProduit) {
    return api.put(
      urlsCommon.BASE_THEMATIQUE_URL +
        "/removeProductFromThematiquePage/" +
        idThematique +
        "/" +
        idProduit
    );
  }

  visible(id) {
    return api.put(urlsCommon.BASE_THEMATIQUE_URL + "/visible/" + id);
  }
  archive(id) {
    return api.put(urlsCommon.BASE_THEMATIQUE_URL + "/archiver/" + id);
  }
}
export default new ThematiqueService();
