import { Component } from "react";
import { Link } from "react-router-dom";
import SaisonsPopupUpdate from "./PopupModel/SaisonsPopupUpdate";
import SaisonsPopupDelete from "./PopupModel/SaisonsPopupDelete";
import SaisonService from "../services/SaisonService";
import SaisonsPopupVisible from "./PopupModel/SaisonsPopupVisible";

export default class AllSaisons extends Component {
  state = {
    idSaison: "",
    saisons: [],
    showFormImage: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      idSaison: "",
      saisons: [],
      showForm: false,
    };
    // this.open = this.open.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    SaisonService.getAllSaisons()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ saisons: response.data["object"] });
          console.log("saisons", this.state.saisons);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  buttonArchive(id, isArchived) {
    if (isArchived === 0) {
      return (
        <button
          className="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idSaison: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-trash"></i> Archiver
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-default"
          onClick={() => {
            this.setState({ idSaison: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-check"></i>Restaurer
        </button>
      );
    }
  }
  labelVisible(id, visible) {
    if (visible === 1) {
      return <label>Rendre Invisible</label>;
    } else {
      return <label>Rendre Visible</label>;
    }
  }

  buttonVisible(id, visible) {
    if (visible === 1) {
      return (
        <button
          className="btn btn-danger btn-sm float-center"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idSaison: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-recycle"></i>
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-success btn-sm"
          data-toggle="modal"
          data-target="#modal-visible"
          onClick={() => {
            this.setState({ idSaison: id });
            this.showFormImage();
          }}
        >
          <i className="fas fa-recycle"></i>
        </button>
      );
    }
  }
  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="callout callout-default">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Les Saisons</h5>
                <p classNameName="float-right">
                  <button className="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/addSaison" }}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <i className="fas fa-plus" /> Ajouter
                    </Link>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="col-md-12">
          <section className="content">
            <div className="col-12">
              <div className="card">
                <div className="card-header"></div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Libellé</th>
                        <th>Modifier</th>
                        <th>Visibilité dans le site</th>
                        <th>Archiver</th>
                        <th>Voir les produits</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.saisons.map((saison) => (
                        <tr>
                          <td>{saison.name}</td>

                          <td>
                            <button
                              className="btn btn-info btn-sm"
                              data-toggle="modal"
                              data-target="#modal-update"
                              onClick={() => {
                                this.setState({ idSaison: saison.id });
                                this.showFormImage();
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i> Modifier
                            </button>
                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-update">
                                <SaisonsPopupUpdate
                                  idSaison={this.state.idSaison}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {this.labelVisible(saison.id, saison.visible)}
                            {this.buttonVisible(saison.id, saison.visible)}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-visible">
                                <SaisonsPopupVisible
                                  idSaison={this.state.idSaison}
                                />
                              </div>
                            )}
                          </td>

                          <td>
                            {this.buttonArchive(saison.id, saison.isArchived)}

                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-default">
                                <SaisonsPopupDelete
                                  idSaison={this.state.idSaison}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                sessionStorage.setItem("idSaison", saison.id);
                                this.showFormImage();
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/allProductBySaison",
                                }}
                                style={{ color: "white" }}
                              >
                                <i className="fas fa-folder"></i> Voir les
                                produits
                              </Link>
                            </button>
                          </td>
                          {/* <td>
                            <Link
                              to={{
                                pathname: "/allProductBySaison",
                              }}
                            >
                              <button className="btn btn-primary btn-sm">
                                <i className="fas fa-folder"></i> Voir les produits
                              </button>
                            </Link>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
