import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarAlt,
  faCarTunnel,
  faCaretDown,
  faCartArrowDown,
  faCartFlatbed,
  faCartPlus,
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
  faRecycle,
  faShoppingCart,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import NouveauteService from "src/services/NouveauteService";
import ThematiqueService from "src/services/ThematiqueService";
import Pagination from "src/common/Pagination";
import ModalThematiqueUpdate from "./ModalThematiqueUpdate";
import ArchivedProductThematique from "./ArchivedProductThematique";
import urlsCommon from "src/common/urls-common";

function AllThematiquesPages() {
  const [thematiqueList, setthematiqueList] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const [index, setIndex] = useState("");
  const [thematique, setThematique] = useState("");
  const [nbProducts, setNbProducts] = useState(0);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);

  useEffect(() => {
    getAllThematiquesPages();
    // getNombreProduitsNouveaute();
  }, [currentPage, itemsPerPage]);

  const getAllThematiquesPages = () => {
    ThematiqueService.getAllThematiquesPage(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setthematiqueList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const getNombreProduitsNouveaute = () => {
    NouveauteService.getNombreProduitsNouveaute()
      .then((response) => {
        if (response.data.success) {
          setNbProducts(response.data.success);
        } else {
          toast.error("error");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const onNavigate = (e) => {
    e.preventDefault();
    window.location.href = "/addThematiquePage";
  };

  const openSectionProductsThematique = (thematiqueName, idThematique) => {
    localStorage.setItem("thematiqueName", thematiqueName);
    localStorage.setItem("idProductsThematique", idThematique);
    const url = "/thematiqueProducts";
    window.open(url, "_blank");
  };

  const openModalUpdate = (thematique) => {
    setIsModalUpdateOpen(true);
    setThematique(thematique);
  };

  const archiverById = (id) => {
    ThematiqueService.archive(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledById = (id) => {
    ThematiqueService.visible(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les Pages Thematiques </b> :
                    </h5>
                    <button
                      className="btn btn-dark float-right"
                      onClick={(e) => onNavigate(e)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">
                        Les Pages Thematiques disponibles
                      </h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th># Titre</th>
                            <th>Url Site</th>
                            <th>Modifier</th>
                            <th>Voir Produits</th>
                            <th>Visibilité</th>
                            <th>Archiver</th>
                          </tr>
                        </thead>
                        <tbody>
                          {thematiqueList &&
                          thematiqueList.length > 0 &&
                          Array.isArray(thematiqueList) ? (
                            thematiqueList.map((thematique, index) => (
                              <tr key={index}>
                                <td>
                                  <b>{thematique?.titre || ""}</b>
                                </td>
                                {/* <td>{thematique?.description || ""}</td> */}
                                <td>
                                  <div>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-sm btn-dark"
                                      onClick={() => {
                                        if (
                                          thematique.visible &&
                                          !thematique.archived
                                        ) {
                                          window.open(
                                            urlsCommon.ADRESS_Site +
                                              "thematique/vetements/?page=1&theme=" +
                                              (thematique
                                                ? thematique.slug
                                                : ""),
                                            "_blank"
                                          );
                                        }
                                      }}
                                      disabled={
                                        !thematique.visible ||
                                        thematique.archived
                                          ? true
                                          : false
                                      }
                                    >
                                      Voir Site
                                      <FontAwesomeIcon
                                        icon={faEye}
                                        className="ml-2"
                                      />
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-sm btn-warning"
                                      data-toggle="modal"
                                      data-target="#exampleModalCenter"
                                      onClick={() => {
                                        openModalUpdate(thematique);
                                      }}

                                      // disabled
                                    >
                                      Modifier
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="ml-2"
                                      />
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      style={{ width: "150px" }}
                                      className="btn btn-sm btn-dark"
                                      data-toggle="modal"
                                      data-target="#exampleModalCenter"
                                      onClick={() => {
                                        openSectionProductsThematique(
                                          thematique.titre,
                                          thematique?.id
                                        );
                                      }}
                                    >
                                      Voir Produits
                                      <FontAwesomeIcon
                                        icon={faShoppingCart}
                                        className="ml-2"
                                      />
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      thematique?.visible
                                        ? "btn btn-success btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() => visibledById(thematique?.id)}
                                  >
                                    {thematique?.visible ? (
                                      <>
                                        Rendre InVisible{" "}
                                        <FontAwesomeIcon
                                          icon={faEyeSlash}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Visible{" "}
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      thematique?.archived
                                        ? "btn btn-danger btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() => archiverById(thematique.id)}
                                  >
                                    {thematique?.archived ? (
                                      <>
                                        Restaurer{" "}
                                        <FontAwesomeIcon
                                          icon={faRecycle}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Archived{" "}
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <b>Pas de thematique Pages</b>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {thematiqueList.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalUpdateOpen && (
        <ModalThematiqueUpdate
          isModalUpdateOpen={isModalUpdateOpen}
          setIsModalUpdateOpen={setIsModalUpdateOpen}
          thematique={thematique}
        />
      )}
    </>
  );
}

export default AllThematiquesPages;
