import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaisonService from "../../services/SaisonService";

export default class SaisonsPopupVisible extends Component {
  state = {
    saison: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      saison: "",
    };
    this.visible = this.visible.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    SaisonService.getSaisonById(this.props.idSaison)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          // console.log("In Societe else");
          this.setState({ saison: response.data["object"] });
          console.log("saison", this.state.saison);
          // this.setState({ alert_message: "success" });
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  visible() {
    if (this.state.saison.visible === 0) {
      SaisonService.visible(this.state.saison.id, 1)
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Saison visible");
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }

          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    } else if (this.state.saison.visible === 1) {
      SaisonService.visible(this.state.saison.id, 0)
        .then((response) => {
          if (response.data["code"] === "201") {
            toast.success("Saison invisible");
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else if (response.data["code"] === "400") {
            toast.error(response.data["object"]);
          }

          console.log("response", response);
        })
        .catch((error) => {
          toast.error("une erreur a survenue");
        });
    }

    console.log("removeBrand");
  }

  render() {
    if (this.state.saison.visible === 1) {
      return (
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                La Saison {this.state.saison.name} est visible
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Rendre la Saison {this.state.saison.name} invisible
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={this.visible}
              >
                Rendre invisible
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-dialog">
          <ToastContainer />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                La Saison {this.state.saison.name} est invisible
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Rendre la Saison {this.state.saison.name} visible
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.visible}
              >
                Rendre visible
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}
