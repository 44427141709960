import { Component } from "react";
import { Link } from "react-router-dom";
import ShopLookService from "../../services/ShopLookService";
import BanniereLookHomeVisible from "./BanniereLookHomeVisible";
import BanniereLookHomeDelete from "./BanniereLookHomeDelete";
import {
  faEye,
  faEyeSlash,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class BanniereLookHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thematiques: [],
      idtotalLookProduct: "",
      showFormImage: false,
    };
  }
  componentDidMount() {
    this.forceUpdate();
    ShopLookService.allShopLookHome()
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ thematiques: response.data["object"] });
          console.log("resonse");
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  formatDate(date) {
    var cdate = new Date(date);
    var day = String(cdate.getDate()).padStart(2, "0");
    let month = String(cdate.getMonth()).padStart(2, "0");
    let year = cdate.getFullYear();
    return day + "/" + month + "/" + year;
  }

  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  render() {
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Banniere totalLook Home page</h5>
              <p className="float-right">
                <button class="btn btn-success btn-sm">
                  <Link
                    to={{ pathname: "/addBanniereTotalLookHome" }}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <i class="fas fa-plus"></i>
                    Ajouter
                  </Link>
                </button>
              </p>
              <br />
            </div>
          </div>
        </section>

        <section class="content">
          <div className="card card-outline card-success">
            <div className="card-header">
              <b>Liste des bannieres totalLook de la page Home</b>
            </div>
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Titre</th>
                    {/* <th>Modifier</th> */}
                    <th>Archiver</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.thematiques.map((thematique) => (
                    <tr>
                      <td>{thematique.id}</td>
                      <td>{thematique.titre}</td>
                      {/* <td>{this.formatDate(thematique.dateDebut)}</td>
                      <td>{this.formatDate(thematique.dateFin)}</td> */}
                      <td>
                        <td className="text-center">
                          <button
                            data-toggle="modal"
                            data-target="#modal-visible"
                            style={{ width: 40 }}
                            className={`btn btn-sm ${
                              thematique?.visible
                                ? "btn-secondary"
                                : "btn-primary"
                            }`}
                            onClick={() => {
                              this.setState({
                                idtotalLookProduct: thematique.id,
                              });
                              this.showFormImage();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={thematique?.visible ? faEyeSlash : faEye}
                            />
                          </button>
                        </td>

                        <td className="text-center">
                          <button
                            style={{ width: 40 }}
                            className={`btn btn-sm ${
                              thematique?.isArchived
                                ? "btn-success"
                                : "btn-danger"
                            }`}
                            onClick={() => {
                              this.setState({
                                idtotalLookProduct: thematique.id,
                              });
                              this.showFormImage();
                            }}
                            data-toggle="modal"
                            data-target="#modal-default"
                          >
                            <FontAwesomeIcon
                              icon={
                                thematique?.isArchived ? faRecycle : faTrash
                              }
                            />
                          </button>
                        </td>
                      </td>
                    </tr>
                  ))}

                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-visible">
                      <BanniereLookHomeVisible
                        idtotalLookProduct={this.state.idtotalLookProduct}
                      />
                    </div>
                  )}
                  {this.state.showFormImage && (
                    <div className="modal fade" id="modal-default">
                      <BanniereLookHomeDelete
                        idtotalLookProduct={this.state.idtotalLookProduct}
                      />
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
