import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
// import ProductService from "../../services/ProductService";
import TagsService from "../../services/TagsService";
import makeAnimated from "react-select/animated";
import Select from "react-select";
export default class TagsPopup extends Component {
  state = {
    idProduct: "",
    tags: [],
    selectedOption: "",
    options: [],
    idTag: "",
    valeur: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      idProduct: this.props.idProduct,
      idTag: "",
      tags: [],
      selectedOption: "",
      options: [],
      valeur: "",
    };
    this.changeValeur = this.changeValeur.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
    TagsService.getAllTagsInSubFamily(this.state.idProduct)
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ tags: response.data["object"] });
          console.log("tags", this.state.tags);
          this.remplissageOptions();
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }
  remplissageOptions() {
    this.state.tags.map((tag) => {
      if (tag.unite !== null) {
        this.setState({
          options: [
            ...this.state.options,
            { value: tag.id, label: tag.unite + "-" + tag.name },
          ],
        });
      } else {
        this.setState({
          options: [...this.state.options, { value: tag.id, label: tag.name }],
        });
      }
    });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );

    this.setState({ idTag: selectedOption.value });

    console.log("idTag", this.state.idTag);
  };
  changeValeur(event) {
    this.setState({ valeur: event.target.value });
    console.log("valeur", this.state.valeur);
  }

  addTagToProduct = (e) => {
    e.preventDefault();

    let tagModel = {
      idProduct: this.props.idProduct,
      idTag: this.state.idTag,
      valeur: this.state.valeur,
    };
    console.log("tagModel", tagModel);
    TagsService.addTagToProduct(tagModel)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout du tag to product avec succée");
          this.setState({ selectedOption: "" });
          this.setState({ valeur: "" });
        } else {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("Une erreur a survenue");
      });
  };

  render() {
    const animatedComponents = makeAnimated();

    return (
      <div className="modal-dialog">
        <ToastContainer />
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Ajouter une couleur</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <label>Choisir tag:</label>

                    <Select
                      value={this.state.selectedOption}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={this.state.options}
                      onChange={this.handleChange}
                      placeholder="Saisir..."
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Saisir la valeur:</label>
                    <input
                      type="libelle"
                      className="form-control"
                      placeholder="Ajouter libellé"
                      value={this.state.valeur}
                      onChange={this.changeValeur}
                    />
                  </div>
                </div>

                <br />
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-success float-right"
              onClick={this.addTagToProduct}
            >
              Ajouter
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
    );
  }
}
