import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "src/common/Pagination";
import LivraisonService from "src/services/LivraisonService";
import ModalLivraisonUpdate from "./ModalLivraisonUpdate";

function AllLivraisons() {
  const [livraisonList, setlivraisonList] = useState({});
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [index, setIndex] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [livraisontexte, setlivraisontexte] = useState({});
  const [nblivraison, setNblivraison] = useState(0);

  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [isModalArchiverOrVisibleOpen, setIsModalArchiverOrVisibleOpen] =
    useState(false);
  const [value, setValue] = useState(null);
  const [livraisonItem, setlivraisonItem] = useState("");

  useEffect(() => {
    getAllLivraisons();
  }, [currentPage, itemsPerPage]);

  const getAllLivraisons = () => {
    LivraisonService.getAllLivraisons(currentPage - 1, itemsPerPage)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setlivraisonList(response.data.success.content);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  //**************for edit top bar modal
  const openModalUpdate = (livraison) => {
    setIsModalOpenEdit(true);
    setlivraisonItem(livraison);
  };
  const archiverById = (id) => {
    LivraisonService.archive(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  const visibledById = (id) => {
    LivraisonService.visible(id)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>Les Types de Livraison </b>
                    </h5>
                    <button
                      className="btn btn-dark float-right"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={() => (window.location.href = "/addLivraison")}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <h3 class="card-title">
                        Les Types de Livraison disponibles
                      </h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th># Libellé</th>
                            <th>Prix de Livraison</th>
                            <th>Montant Gratuit</th>
                            <th>Modifier</th>
                            <th>visible</th>
                            <th>archiver</th>
                          </tr>
                        </thead>
                        <tbody>
                          {livraisonList &&
                          livraisonList.length > 0 &&
                          Array.isArray(livraisonList) ? (
                            livraisonList.map((livraison, index) => (
                              <tr key={index}>
                                <td>{livraison?.name || ""}</td>
                                <td>{livraison?.price || ""}</td>
                                <td>
                                  {livraison?.montant_gratuit != null
                                    ? livraison?.montant_gratuit
                                    : "pas de montant gratuit"}
                                </td>

                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className="btn btn-sm btn-warning"
                                    data-toggle="modal"
                                    data-target="#exampleModalLivraisonUpdate"
                                    onClick={() => {
                                      openModalUpdate(livraison);
                                    }}
                                  >
                                    Modifier
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="ml-2"
                                    />
                                  </button>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      livraison?.visible
                                        ? "btn btn-success btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() => visibledById(livraison?.id)}
                                  >
                                    {livraison?.visible ? (
                                      <>
                                        Rendre InVisible{" "}
                                        <FontAwesomeIcon
                                          icon={faEyeSlash}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Visible{" "}
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                                <td>
                                  <button
                                    style={{ width: "150px" }}
                                    className={
                                      livraison?.archived
                                        ? "btn btn-danger btn-sm"
                                        : "btn btn-secondary btn-sm"
                                    }
                                    data-toggle="modal"
                                    data-target="#exampleModalArchiverOrVisible"
                                    onClick={() => archiverById(livraison?.id)}
                                  >
                                    {livraison?.archived ? (
                                      <>
                                        Restaurer{" "}
                                        <FontAwesomeIcon
                                          icon={faRecycle}
                                          className="ml-2"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        Rendre Archived{" "}
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="ml-2"
                                        />
                                      </>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="5">
                                Pas de livraison pour le moment
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {livraisonList.length > 0 && (
                        <>
                          <div className="d-flex" style={{ padding: "15px" }}>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(newPage) =>
                                setCurrentPage(newPage)
                              }
                            />
                            <div className="ml-3">
                              <select
                                className="form-control pagination-select float-right"
                                id="itemsPerPageSelect"
                                value={itemsPerPage}
                                onChange={(e) =>
                                  setItemsPerPage(parseInt(e.target.value, 10))
                                }
                              >
                                {[4, 7, 10, 15, 20].map((value) => (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {isModalOpenEdit && (
          <ModalLivraisonUpdate
            setIsModalOpenEdit={setIsModalOpenEdit}
            livraison={livraisonItem}
          />
        )}
      </div>
    </>
  );
}

export default AllLivraisons;
