import { Component } from "react";
import { Link } from "react-router-dom";
import BrandDelete from "../Products/BrandPopupModel/BrandDelete";
import BrandImage from "../Products/BrandPopupModel/BrandImage";
import BrandUpdateLibelle from "../Products/BrandPopupModel/BrandUpdateLibelle";
import ProductPopupBigPictures from "../Products/ProductPopupModel/ProductPopupBigPictures";
import ProductPopupSmallPictures from "../Products/ProductPopupModel/ProductPopupSmallPictures";
import ProductPopupStock from "../Products/ProductPopupModel/ProductPopupStock";
import ProductPopupUpdateAll from "../Products/ProductPopupModel/ProductPopupUpdateAll";
import BrandService from "../services/BrandService";
import ProductService from "../services/ProductService";

export default class AllProductsByBrand extends Component {
  state = {
    brand: "",
    products: [],
    showFormImage: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      brand: "",
      products: [],
    };
    // this.open = this.open.bind(this);
  }
  componentDidMount() {
    this.forceUpdate();
    BrandService.getBrandById(sessionStorage.getItem("idBrand"))
      .then((response) => {
        if (response.data["code"] === "201") {
          this.setState({ brand: response.data["object"] });
        } else if (response.data["code"] === "400") {
          // toast.error(response.data["object"]);
        }
        console.log("brand", this.state.brand);

        console.log("response", response);
      })
      .catch((error) => {
        // toast.error("une erreur a survenue");
      });
    ProductService.getProductByIdBrands(sessionStorage.getItem("idBrand"))
      .then((response) => {
        console.log("response", response.data["code"]);
        if (response.data["code"] === "201") {
          this.setState({ products: response.data["object"] });
          console.log("products", this.state.products);
        } else {
          console.log("response", "error");
        }
      })
      .catch((error) => {
        console.log("response", "error");
      });
  }

  showFormImage = () => {
    this.setState({ showFormImage: !this.state.showFormImage });
  };
  render() {
    if (this.state.products.length === 0) {
      return (
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid">
              <div class="callout callout-danger">
                <h5>Pas de produits dans la marque <b>{this.state.brand.name}</b></h5>

                <p className="float-right">
                  <button class="btn btn-success btn-sm">
                    <Link
                      to={{ pathname: "/addProduct" }}
                      style={{ color: "white", "text-decoration": "none" }}
                    >
                      <i class="fas fa-plus"></i> Ajouter un produit
                    </Link>
                  </button>
                </p>
                <br />
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid"></div>
          </section>

          <div className="col-md-12">
            <section class="content">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Les produits de la marque <b>{this.state.brand.name}</b></h3>
                </div>

                <div className="card-body">
                  <table
                    id="example2"
                    className="table table-bordered table-hover"
                  >
                    <thead>
                      <tr>
                        <th>Ref-libellé</th>
                        <th>Description</th>
                        <th>Stock</th>
                        <th>Les grandes images</th>
                        <th>Les petites images</th>
                        <th>Modifier</th>
                        <th>Archiver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.map((product) => (
                        <tr>
                          <td>
                            {product.ref}- <b>{product.name}</b>
                          </td>
                          <td>{product["short_desc"]}</td>
                          <td>
                            {product.stock}
                            {"  "}
                            <button
                              class="btn btn-default btn-sm"
                              data-toggle="modal"
                              data-target="#modal-stock"
                              onClick={() => {
                                this.setState({ idProduct: product.id });
                                this.showFormImage();
                              }}
                            >
                              <i class="fas fa-pencil-alt"></i>
                            </button>
                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-stock">
                                <ProductPopupStock
                                  idProduct={this.state.idProduct}
                                />
                                {/* /.modal-dialog */}
                              </div>
                            )}
                          </td>
                          <td>
                            {" "}
                            <button
                              class="btn btn-warning btn-sm"
                              data-toggle="modal"
                              data-target="#modal-Image"
                              onClick={() => {
                                this.setState({ idProduct: product.id });
                                this.showFormImage();
                              }}
                            >
                              <i class="fas fa-images"></i> Voir les images
                            </button>
                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-Image">
                                <ProductPopupBigPictures
                                  idProduct={this.state.idProduct}
                                />
                                {/* /.modal-dialog */}
                              </div>
                            )}
                          </td>
                          <td>
                            {" "}
                            <button
                              class="btn btn-warning btn-sm"
                              data-toggle="modal"
                              data-target="#modal-ImageSmall"
                            >
                              <i class="fas fa-images"></i> Voir les images
                            </button>
                            {
                              <div className="modal fade" id="modal-ImageSmall">
                                <ProductPopupSmallPictures imageUrl="D:/IWARE PROJECTS/ElectroBZ/React/demo1/public/images/brands/1.png" />
                                {/* /.modal-dialog */}
                              </div>
                            }
                          </td>
                          <td>
                            {" "}
                            <button
                              class="btn btn-info btn-sm"
                              data-toggle="modal"
                              data-target="#modal-update"
                              onClick={() => {
                                this.setState({ idProduct: product.id });
                                this.showFormImage();
                              }}
                            >
                              <i class="fas fa-pencil-alt"></i> Modifier
                            </button>
                            {this.state.showFormImage && (
                              <div className="modal fade" id="modal-update">
                                <ProductPopupUpdateAll
                                  idProduct={this.state.idProduct}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {" "}
                            <button
                              class="btn btn-danger btn-sm"
                              data-toggle="modal"
                              data-target="#modal-default"
                            >
                              <i class="fas fa-trash"></i> Archiver
                            </button>
                            {
                              <div className="modal fade" id="modal-default">
                                <BrandDelete />
                              </div>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }
  }
}
