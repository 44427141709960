import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import PictureService from "src/services/PictureService";

function ShowVariantStock(props) {
  const { setIsModalOpenShowStock, sizesVariantStock, setIsSaved } = props;

  return (
    <>
      <div className="card card-secondary">
        <ToastContainer />
        <div
          className="card-header"
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <h3 className="card-title">
            Consulter Stock
            <h4 className="my-3">{variantSelected?.codeABarre || ""}</h4>
          </h3> */}

          <div style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-dark mr-2"
              onClick={() => setIsModalOpenShowStock(false)}
            >
              Close
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="col-12 product-image-thumbs">
                {sizesVariantStock &&
                  sizesVariantStock.map((sizeVariant, index) => (
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">{sizeVariant?.size || ""}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{sizeVariant.stock}</td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowVariantStock;
