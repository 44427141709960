import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TestInput } from "src/common/staticMethodes";
import SizeService from "src/services/SizeService";

function AddSize() {
  const navigate = useNavigate();
  const [newSize, setNewSize] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewSize({ ...newSize, [name]: value });
  };

  const createSize = () => {
    const model = {
      sizeName: newSize?.libelle,
      size: newSize?.taille,
    };
    TestInput(model);
    console.log("model :", model);
    SizeService.createSize(model)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setNewSize("");
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <ToastContainer />
            <div className="col-md-12">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Ajouter taille</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Libelle</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="libelle"
                            placeholder="exp (small...)"
                            value={newSize?.libelle || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Taille</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-clock"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="taille"
                            placeholder="exp (XS,L...)"
                            value={newSize?.taille || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-default"
                    onClick={() => navigate("/AllSizesVariant")}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-secondary float-right"
                    onClick={createSize}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddSize;
