import { Component } from "react";
import CurrencyInput from "react-currency-input";
import NumericInput from "react-numeric-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Files from "react-files";
import BonPlanService from "../services/BonPlanService";

import makeAnimated from "react-select/animated";
import Select from "react-select";

export default class AddBonPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      price: "",
      type: "",
      visible: false,
      files: [],
      selectedOption: null,
    };
    this.changeTitle = this.changeTitle.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changePrice = this.changePrice.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addBonPlan = this.addBonPlan.bind(this);
  }

  handleChange(event) {
    if (this.state.visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }

    console.log("visible", this.state.visible);
  }
  changeTitle(event) {
    this.setState({ title: event.target.value });
    console.log("title", this.state.title);
  }

  changeDescription(event) {
    this.setState({ description: event.target.value });
    console.log("description", this.state.description);
  }

  changePrice(event) {
    this.setState({ price: event.target.value });
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log("onFilesChange", this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  handleChangeList = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({ type: selectedOption.label });
    console.log("type", this.state.type);
  };

  addBonPlan = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);
    let pric = this.state.price.replace(" DNT", "");

    let bonPlan = {
      name: this.state.title,
      description: this.state.description,
      prix: pric.replace(",", ""),
      type: this.state.type,
      visible: this.state.visible,
    };

    BonPlanService.addBonPlan(dataFile, JSON.stringify(bonPlan))
      .then((response) => {
        if (response.data["code"] === "201") {
          sessionStorage.setItem("idBonPlan", response.data["object"]);
          toast.success("Ajout de le bon plan avec succée");
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  pack() {
    console.log("type", this.state.type);
    if (this.state.type === "Pack") {
      return (
        <div className="col-md-3">
          <label>Prix du pack</label>

          <CurrencyInput
            suffix=" DNT"
            class="form-control"
            precision="3"
            value={this.state.price}
            onChangeEvent={this.changePrice}
          />
        </div>
      );
    }
  }

  render() {
    const animatedComponents = makeAnimated();
    const options = [
      { value: 1, label: "Pack" },
      { value: 2, label: "Bon Plan" },
    ];
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter un bon plan </h5>
              Deux étapes:
              <br /> 1-Ajouter le libellé
              <br /> 2-L'images de la caroussel (1366px * 607px)
            </div>
          </div>
        </section>

        <div className="col-12 col-sm-12">
          <div className="card card-success card-outline ">
            <div className="card-header">
              <h3 className="card-title">
                <b>Ajouter le bon plan</b>
              </h3>
            </div>

            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <label>Choisir la sous-famille</label>
                    <Select
                      id={"SelectMarque"}
                      value={this.state.selectedOption}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={options}
                      onChange={this.handleChangeList}
                      placeholder="Saisir..."
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Titre</label>

                    <input
                      type="title"
                      className="form-control"
                      placeholder="Ajouter Sous Titre"
                      value={this.state.title}
                      onChange={this.changeTitle}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Description:</label>

                    <input
                      type="title"
                      className="form-control"
                      placeholder="Ajouter texte en gras"
                      value={this.state.description}
                      onChange={this.changeDescription}
                    />
                  </div>
                  {this.pack()}
                </div>

                <br />
                <div className="col-md-12">
                  <div>
                    <Files
                      ref="files"
                      className="files-dropzone-list"
                      // style={{ height: "100px" }}
                      onChange={this.onFilesChange}
                      onError={this.onFilesError}
                      multiple
                      maxFiles={1}
                      maxFileSize={10000000}
                      minFileSize={0}
                      clickable
                    >
                      <label>Cliquer pour Ajouter une image</label>
                    </Files>

                    {this.state.files.length > 0 ? (
                      <div className="files-list">
                        <ul>
                          {this.state.files.map((file) => (
                            <li className="files-list-item" key={file.id}>
                              <div className="files-list-item-preview">
                                {file.preview.type === "image" ? (
                                  <div className="row">
                                    <img
                                      className="files-list-item-preview-image"
                                      src={file.preview.url}
                                      // style={{
                                      //   width: "10%",
                                      //   height: "10%",
                                      // }}
                                    />
                                    <button
                                      id={file.id}
                                      class="btn btn-navbar"
                                      type="button"
                                      // data-widget="navbar-search"
                                      onClick={this.filesRemoveOne.bind(
                                        this,
                                        file
                                      )}
                                    >
                                      <i class="fas fa-times"></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="files-list-item-preview-extension">
                                    {file.extension}
                                  </div>
                                )}
                              </div>
                              <div className="files-list-item-content">
                                <div className="files-list-item-content-item files-list-item-content-item-1">
                                  {file.name}
                                </div>
                                <div className="files-list-item-content-item files-list-item-content-item-2">
                                  {file.sizeReadable}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <h1></h1>
                    )}
                  </div>
                </div>

                <div className="col-md-12 ">
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={this.state.visible}
                        onChange={this.handleChange}
                      />{" "}
                      Rendre visible dans le site
                    </label>
                  </div>
                </div>
              </div>
            </form>
            {/* </div> */}
            <br />
            <div className="card-footer">
              <button
                type="submit"
                className="btn btn-success float-right"
                onClick={this.addBonPlan}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
