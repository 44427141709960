import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import NouveauteService from "src/services/NouveauteService";

function ModalConfirmationChangeNouveaute(props) {
  const {
    isModalConfirmationProductOpen,
    setIsModalConfirmationProductOpen,
    idProduct,
    nouveauteProduit,
  } = props;
  const RendreProductNotNew = (idProduct) => {
    NouveauteService.rendreProductNotNew(idProduct)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const RendreNotNewAllProducts = () => {
    NouveauteService.rendreNotNewAllProducts()
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const confirmerChangeNewProduct = (event) => {
    event.preventDefault();
    if (idProduct == "All_products") {
      RendreNotNewAllProducts();
    } else {
      RendreProductNotNew(idProduct);
    }
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {idProduct != "All_products" ? (
                  <>Rendre Produit Not New</>
                ) : (
                  <> Rendre All Produits Not New</>
                )}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Veuillez confimer de rendre{" "}
              {idProduct != "All_products" ? (
                <>
                  <b>
                    {nouveauteProduit?.nameToFo
                      ? nouveauteProduit?.nameToFo
                      : nouveauteProduit?.name}{" "}
                    - {nouveauteProduit?.codeArticle || ""}
                  </b>{" "}
                  Not New ?
                </>
              ) : (
                <>Rendre tous les Produits Not New</>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsModalConfirmationProductOpen(false);
                  window.location.href = "allNouveauteProduit";
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                class="btn btn-dark"
                onClick={confirmerChangeNewProduct}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalConfirmationChangeNouveaute;
