import api from "../common/api.jsx";
import currentUserId from "../common/currentUserId.jsx";

const addSociete = (societe) => {
  let currentUser = currentUserId();
  societe.idUserCreation = currentUser;

  return api.post("/retail/SocieteRestApi/addSociete", societe);
};

const getAllPartenaires = (isDeleted, typeClient) => {
  let currentUser = currentUserId();

  return api.get(
    `/retail/SocieteRestApi/getListSociete/${currentUser}/${typeClient}/${isDeleted}`
  );
};

const deleteAndRestaureSociete = (idSociete, isDeleted) => {
  let currentUser = currentUserId();

  return api.put(
    `/retail/SocieteRestApi/deleteAndRestoreSociete/${currentUser}/${idSociete}/${isDeleted}`
  );
};

const getAllRoles = () => {
  return api.get("/allRoles");
};
const getSocieteByIdUser = () => {
  let currentUser = currentUserId();
  return api.get(`/retail/SocieteRestApi/getSocieteByIdUser/${currentUser}`);
};

const addIwareClient = (user) => {
  let currentUser = currentUserId();
  user.idUserCreation = currentUser;

  return api.post("/addIwareClient", user);
};

const addAdminClient = (user) => {
  let currentUser = currentUserId();
  user.idUserCreation = currentUser;

  return api.post("/addUserPartner", user);
};

const getAllUserPartner = (isDeleted) => {
  let currentUser = currentUserId();
  return api.get(`/getAllUserPartner/${currentUser}/${isDeleted}`);
};
const getAllIwareClient = (isDeleted) => {
  let currentUser = currentUserId();
  return api.get(`/getAllIwareClient/${currentUser}/${isDeleted}`);
};

const isAvailableUserAndSociete = () => {
  let currentUser = currentUserId();
  return api.get(`/isAvailableUserAndSociete/${currentUser}`);
};

const getAllAvailableAdminSocieteCreatedBySuperAdmin = () => {
  let currentUser = currentUserId();
  return api.get(
    `/retail/SocieteRestApi/getAllAvailableAdminSocieteCreatedBySuperAdmin/${currentUser}`
  );
};

const deleteAndRestoreClientPartner = (idUserToDelete, isDeleted) => {
  let currentUser = currentUserId();
  return api.put(
    `/deleteAndRestoreClientPartner/${currentUser}/${idUserToDelete}/${isDeleted}`
  );
};

export default {
  addSociete,
  getAllPartenaires,
  deleteAndRestaureSociete,
  getAllRoles,
  getSocieteByIdUser,
  addAdminClient,
  getAllUserPartner,
  deleteAndRestoreClientPartner,
  getAllAvailableAdminSocieteCreatedBySuperAdmin,
  addIwareClient,
  getAllIwareClient,
  isAvailableUserAndSociete,
};
