import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faPlus,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import DiscountService from "src/services/DiscountService";
import Pagination from "src/common/Pagination";
import VoirProductsDiscount from "./VoirProductsDiscount";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router";
import VoirPromotionCartDetails from "./VoirPromotionCartDetails";
import withLoader from "src/common/progressBar/withLoader";
const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};
const VoirPrioritesByDeclencheur = ({ startLoading, stopLoading }) => {
  const [availablePriorities, setAvailablePriorities] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  //voir product of Promotions
  const [isViewProducts, setIsViewProducts] = useState(false);
  const [promotionPriority, setPromotionPriority] = useState("");
  const [promotionName, setPromotionName] = useState("");
  const [idPromotion, setIdPromotion] = useState("");
  const [availableProducts, setAvailableProducts] = useState([]);

  //voir promotion cart
  const [isViewPromotionCartDetails, setIsViewPromotionCartDetails] =
    useState(false);
  const [idPromotionCart, setIdPromotionCart] = useState("");

  //for add priorities
  const animatedComponents = makeAnimated();
  const [listAllPriorities, setListAllPriorities] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [ordreProduct, setOrdreProduct] = useState(1);
  const [extrats, setExtrats] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [allDiscounts, setAllDiscounts] = useState([]);

  const [selectedOptionDiscount, setSelectedOptionDiscount] = useState(null);
  const [discountSelectPlaceHolder, setDiscountSelectPlaceHolder] =
    useState(null);
  const [promotionId, setDiscountId] = useState(0);
  const [discountLabel, setDiscountLabel] = useState("");
  const [searchData, setSearchData] = useState("");
  const [searchDataInput, setSearchDataInput] = useState("");

  const [searchDataPriorities, setSearchDataPriorities] = useState("");

  const navigate = useNavigate();

  //taper promotion by search
  const selectAllPromotionContainsSearch = () => {
    setExtrats(
      allDiscounts.map((item, index) => ({
        ordert: index === 0 ? ordreProduct : ordreProduct + index,
        promotionName: item.label,
        promotionId: item.value,
      }))
    );
  };

  const handlePrioritiesChange = (selectedOptions) => {
    setSelectedPriorities(selectedOptions);
  };

  const handleInputChange = (searchData) => {
    if (searchData) {
      setSearchData(searchData);
      allpromotionsForAddToDeclencheur(searchData);
    } else {
      setAllDiscounts([]);
    }
  };
  const handlePlusieursReglesInputChange = (searchDataInput) => {
    if (searchDataInput) {
      setSearchDataInput(searchDataInput);
      allpromotionsForAddToDeclencheur(searchDataInput);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchDataPriorities]);

  const fetchData = () => {
    setIsViewProducts(false);
    setIsViewPromotionCartDetails(false);
    setAvailablePriorities([]);
    if (searchDataPriorities === "") {
      getPrioritiesbyDeclencheur(
        localStorage.getItem("idDeclencheur"),
        currentPage - 1,
        itemsPerPage
      );
    } else {
      searchPrioritieInDeclencheur(
        localStorage.getItem("idDeclencheur"),
        searchDataPriorities,
        currentPage - 1,
        itemsPerPage
      );
    }
  };

  const clearSearchValue = () => {
    setSearchDataPriorities("");
    getPrioritiesbyDeclencheur(
      localStorage.getItem("idDeclencheur"),
      currentPage - 1,
      itemsPerPage
    );
  };
  const clearAddPromotionhValue = () => {
    setSearchDataInput("");
  };
  const clearFiltre = () => {
    setSearchDataInput("");
    setSearchData("");
    setAllDiscounts([]);
    setExtrats([]);
  };
  const searchPrioritieInDeclencheur = (
    idDeclencheur,
    searchData,
    page,
    size
  ) => {
    DiscountService.searchPrioritieInDeclencheur(
      idDeclencheur,
      searchData,
      page,
      size
    )
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setAvailablePriorities(response.data.success.content);
        }
        // console.log("data", response.data.success);
      })
      .catch((error) => {
        console.log("response", error);
      });
  };

  const allpromotionsForAddToDeclencheur = async (searchData) => {
    try {
      const response = await DiscountService.allpromotionsForAddToDeclencheur(
        searchData
      );
      if (response.data.success) {
        setAllDiscounts(response.data.success);
      }
      console.log("data", response.data.success);
    } catch (error) {
      console.log("response", error);
    }
  };
  const getPrioritiesbyDeclencheur = (idDeclencheur, page, size) => {
    DiscountService.getPrioritiesbyDeclencheur(idDeclencheur, page, size)
      .then((response) => {
        if (response.data.success) {
          setTotalPages(response.data.success.totalPages);
          setTotalElements(response.data.success.totalElements);
          setAvailablePriorities(response.data.success.content);
          setOrdreProduct(response.data.last_order + 1);
        } else {
          toast.error("error");
        }
      })
      .catch((e) => {
        console.log("error in all Declencheur", e);
      });
  };

  //*******voir product In Promotions
  const allProduitsInPromotions = (idDeclencheur, orderPriority) => {
    DiscountService.allProduitsInPromotions(idDeclencheur, orderPriority)
      .then((response) => {
        if (response.data.success) {
          setAvailableProducts(response.data.success);
          console.log(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const onViewProductsHandler = (
    orderPriority,
    namePromotionSelected,
    promotionId
  ) => {
    if (isViewProducts == true && promotionPriority == orderPriority) {
      setIsViewProducts(false);
    } else {
      setIsViewProducts(true);
    }
    setPromotionPriority(orderPriority);
    setPromotionName(namePromotionSelected);
    setIdPromotion(promotionId);
    allProduitsInPromotions(
      localStorage.getItem("idDeclencheur"),
      orderPriority
    );
  };

  const onViewPromotionDetailsHandler = (id) => {
    setIsViewPromotionCartDetails(true);
    setIdPromotionCart(id);
    console.log("id :" + id);
  };

  function addClickParProduct() {
    setExtrats((oldArray) => [
      ...oldArray,
      {
        ordert: ordreProduct,
        promotionName: discountLabel,
        promotionId: promotionId,
        // percent: percent,
      },
    ]);
    setOrdreProduct(ordreProduct + 1);
  }
  const onChangeSelectedDiscount = (selectedOption) => {
    // setDiscountSelectPlaceHolder();
    setDiscountId(selectedOption.value);
    setDiscountLabel(selectedOption.label);
  };

  function createUIParProducts() {
    return (
      <div class="row">
        <div className="col-sm-5">
          <label>Priorité</label>
          <br />
          <label>{ordreProduct}</label>
        </div>
        <div className="col-sm-7">
          <div className="col">
            <label>Choisir une règle</label>
            <Select
              styles={styles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={selectedOptionDiscount}
              onInputChange={handleInputChange}
              onChange={onChangeSelectedDiscount}
              options={allDiscounts}
              placeholder={discountSelectPlaceHolder}
              // isMulti
              isDisabled={searchDataInput != ""}
            />
          </div>
          <div className="col mt-3">
            <label>Ajouter plusieurs règles</label>
            <div>
              <input
                className="form-control"
                type="search"
                placeholder="ajouter promotion"
                aria-label="Search"
                value={searchDataInput}
                onChange={(event) => {
                  handlePlusieursReglesInputChange(event.target.value);
                }}
                disabled={searchData != ""}
              />
              <i className="icon-class" onClick={clearAddPromotionhValue}></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function addExtratToViewParProduct() {
    if (extrats.length === 0) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else if (
      extrats.length === 1 &&
      extrats[0]["ordert"] === "" &&
      extrats[0]["promotionName"] === ""
    ) {
      return (
        <div class="row">
          <div class="col-xs-12 table-responsive">
            <h1></h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="text-center">
              <td>Priorité</td>
              <td>promotion Libellé</td>
              <td>Supprimer</td>
            </thead>
            <tbody>
              {extrats.map((extrat, i) => (
                <tr className="text-center">
                  <td>{extrat.ordert}</td>
                  <td>{extrat.promotionName}</td>

                  <td>
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={() => removeExtratParProduct(i)}
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
  const removeExtratParProduct = (index) => {
    var array = [...extrats];

    if (index !== -1) {
      array.splice(index, 1);
      setExtrats(array);
    }
  };

  const ActiverOrDesactiverPlusieursPromotion = (namePromotion) => {
    return (
      <>
        <button
          // style={{ width: "150px" }}
          className="btn  btn-secondary ml-2"
          onClick={() => onActivateOrDesactivatePriorities(false)}
        >
          Desactiver Priorites : <b>{namePromotion || ""}</b>
          <FontAwesomeIcon icon={faEyeSlash} className="ml-2" />
        </button>
        <button
          // style={{ width: "150px" }}
          className="btn  btn-success ml-2"
          onClick={() => onActivateOrDesactivatePriorities(true)}
        >
          Activer Priorites : <b>{namePromotion || ""}</b>
          <FontAwesomeIcon icon={faEye} className="ml-2" />
        </button>
      </>
    );
  };

  const ListPromotionsExiste = () => {
    return (
      <div className="col-sm-12">
        {createUIParProducts()}
        <br />
        <label></label>
        {searchDataInput ? (
          <>
            <button
              style={{ height: "40 px", width: "40 px" }}
              type="button"
              className="btn btn-dark float-right mb-3"
              onClick={selectAllPromotionContainsSearch}
            >
              <i class="fa fa-plus"></i>
            </button>
            <button
              style={{ height: "40px", width: "40px" }}
              type="button"
              className="btn btn-danger float-right mr-3"
              onClick={clearFiltre}
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        ) : searchData ? (
          <>
            <button
              style={{ height: "40 px", width: "40 px" }}
              type="button"
              className="btn btn-dark float-right  mb-3"
              onClick={addClickParProduct}
            >
              <i class="fa fa-plus"></i>
            </button>
            <button
              style={{ height: "40px", width: "40px" }}
              type="button"
              className="btn btn-danger float-right mr-3"
              onClick={clearFiltre}
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        ) : null}

        {addExtratToViewParProduct()}
      </div>
    );
  };

  const onAddPriorities = () => {
    startLoading();
    DiscountService.updatePrioritiesByDeclencheurId(
      localStorage.getItem("idDeclencheur"),
      extrats
    )
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.success);
          window.location.reload();
        } else if (result.data.error) {
          toast.error("error : " + result.data.error);
        } else {
          toast.error("error");
        }
      })
      .catch((e) => {
        console.log("error in addPromotionProduct", e);
        toast.error("error : " + "problème lors de l'ajout de priorities");
        stopLoading();
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onActivateOrDesactivatePriorities = (activate) => {
    DiscountService.desactiverOrActivatePrioritiesByDeclencheurId(
      activate,
      localStorage.getItem("idDeclencheur"),
      availablePriorities.map((item) => item.id)
    ).then((result) => {
      if (result.data.success) {
        toast.success(result.data.success);
        clearSearchValue();
      } else if (result.data.error) {
        toast.error("error : " + result.data.error);
      } else {
        toast.error("error");
      }
    });
  };
  const archiverById = (idPriorities) => {
    DiscountService.DeletePrioritiesByIdFromDeclencheur(idPriorities)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const visibledById = (idPriorities) => {
    DiscountService.VisibledPrioritiesByIdFromDeclencheur(idPriorities)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.success);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  return (
    <>
      <div className="content-wrapper" style={{ marginTop: "0" }}>
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row mb-2">
              <div className="col-sm-12">
                <div class="card  mt-3">
                  <div class="card-body">
                    <h5 class="card-title">
                      <b>List des Promotions dans ce declencheur </b>
                    </h5>
                    <br />
                    {ListPromotionsExiste()}
                    <br />
                    {extrats.length > 0 && (
                      <>
                        <button
                          // style={{ width: "150px" }}
                          className="btn  btn-dark "
                          onClick={() => onAddPriorities()}
                        >
                          Ajouter Priorites
                          <FontAwesomeIcon icon={faPlus} className="ml-2" />
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <section class="content">
                  <div class="card card-dark">
                    <div class="card-header">
                      <div className="row">
                        <h3 class="card-title col-4">
                          Les Priorities de Ce declencheur disponibles
                        </h3>
                        <div className="col">
                          <input
                            className="form-control"
                            type="search"
                            placeholder="Chercher Par Nom de promotion ou Reference Produit .."
                            aria-label="Search"
                            value={searchDataPriorities}
                            onChange={(event) =>
                              setSearchDataPriorities(event.target.value)
                            }
                          />
                          <i
                            className="icon-class"
                            onClick={clearSearchValue}
                          ></i>
                        </div>
                        <div class="card-tools ">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            title="Collapse"
                          >
                            <i class="fas fa-minus"></i>
                          </button>
                        </div>
                      </div>
                      <br />
                      {searchDataPriorities != "" &&
                      searchDataPriorities != null &&
                      availablePriorities &&
                      availablePriorities.length > 1
                        ? ActiverOrDesactiverPlusieursPromotion(
                            searchDataPriorities
                          )
                        : null}
                    </div>
                    <div class="card-body p-0">
                      <table class="table  projects">
                        <thead>
                          <tr>
                            <th>Nom Promotion</th>
                            <th>Ordre</th>
                            <th>Voir Produits</th>
                            <th>Visibilité</th>
                            <th>archiver</th>
                          </tr>
                        </thead>
                        <tbody>
                          {availablePriorities &&
                          availablePriorities.length > 0 &&
                          Array.isArray(availablePriorities) ? (
                            <>
                              {availablePriorities.map((element, index) => (
                                <tr key={index}>
                                  <td>{element.promotionName}</td>
                                  <td>{element.ordert}</td>
                                  {element.typePromotion == 1 ? (
                                    <td>
                                      <button
                                        style={{ width: "150px" }}
                                        className="btn btn-sm btn-dark"
                                        onClick={() =>
                                          onViewProductsHandler(
                                            element.ordert,
                                            element.promotionName,
                                            element.promotionId
                                          )
                                        }
                                      >
                                        Voir Produits
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="ml-2"
                                        />
                                      </button>
                                    </td>
                                  ) : (
                                    <td>
                                      <button
                                        style={{ width: "150px" }}
                                        className="btn btn-sm btn-dark"
                                        onClick={() =>
                                          onViewPromotionDetailsHandler(
                                            element?.promotionIdPromotionCart
                                          )
                                        }
                                      >
                                        Voir Promotion Panier
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="ml-2"
                                        />
                                      </button>
                                    </td>
                                  )}

                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className={
                                        element?.visible
                                          ? "btn btn-success btn-sm"
                                          : "btn btn-secondary btn-sm"
                                      }
                                      data-toggle="modal"
                                      data-target="#exampleModalArchiverOrVisible"
                                      onClick={() => visibledById(element?.id)}
                                    >
                                      {element?.visible ? (
                                        <>
                                          Rendre InVisible{" "}
                                          <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          Rendre Visible{" "}
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="ml-2"
                                          />
                                        </>
                                      )}
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      style={{ width: "150px" }}
                                      className={
                                        element?.isArchived
                                          ? "btn btn-danger btn-sm"
                                          : "btn btn-secondary btn-sm"
                                      }
                                      data-toggle="modal"
                                      data-target="#exampleModalArchiverOrVisible"
                                      onClick={() => {
                                        archiverById(element?.id);
                                      }}
                                    >
                                      {element?.archived ? (
                                        <>
                                          Restaurer{" "}
                                          <FontAwesomeIcon
                                            icon={faRecycle}
                                            className="ml-2"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          Rendre Archived{" "}
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            className="ml-2"
                                          />
                                        </>
                                      )}
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr className="text-center">
                                <td colSpan="5">
                                  Pas de Priorities dans ce déclencheur pour le
                                  moment
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                      {availablePriorities &&
                        availablePriorities.length > 0 && (
                          <>
                            <div className="d-flex" style={{ padding: "15px" }}>
                              <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(newPage) =>
                                  setCurrentPage(newPage)
                                }
                              />
                              <div className="ml-3">
                                <select
                                  className="form-control pagination-select float-right"
                                  id="itemsPerPageSelect"
                                  value={itemsPerPage}
                                  onChange={(e) =>
                                    setItemsPerPage(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                >
                                  {[4, 7, 10, 15, 20].map((value) => (
                                    <option key={value} value={value}>
                                      {value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </section>
                {isViewProducts ? (
                  <>
                    <VoirProductsDiscount
                      availableProducts={availableProducts}
                      setAvailableProducts={setAvailableProducts}
                      promotionName={promotionName}
                      idPromotion={idPromotion}
                    />
                  </>
                ) : null}
                {isViewPromotionCartDetails ? (
                  <>
                    <VoirPromotionCartDetails
                      idPromotionCart={idPromotionCart}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withLoader(VoirPrioritesByDeclencheur);
