import { Component } from "react";
import LivraisonService from "../services/LivraisonService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Files from "react-files";

export default class AddLivreur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      files: [],
    };
    this.changeLibelle = this.changeLibelle.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  //   handleChange(event) {
  //     if (this.state.livrable) {
  //       this.setState({ livrable: false });
  //     } else {
  //       this.setState({ livrable: true });
  //     }

  //     console.log("livrable", this.state.livrable);
  //   }

  changeLibelle(event) {
    this.setState({ libelle: event.target.value });
    console.log("libelle", this.state.libelle);
  }

  onFilesChange = (files) => {
    this.setState(
      {
        files,
      },
      () => {
        console.log(this.state.files);
      }
    );
  };

  onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  filesRemoveOne = (file) => {
    this.refs.files.removeFile(file);
  };

  filesRemoveAll = () => {
    this.refs.files.removeFiles();
  };

  saveLivreur = (e) => {
    e.preventDefault();
    const dataFile = new FormData();
    dataFile.append("picture", this.state.files[0]);

    console.log("picture", dataFile);
    console.log("livrable", this.state.livrable);
    LivraisonService.addLivreur(dataFile, this.state.libelle)
      .then((response) => {
        if (response.data["code"] === "201") {
          toast.success("Ajout du livreur avec succée");
        } else if (response.data["code"] === "400") {
          toast.error(response.data["object"]);
        }

        console.log("response", response);
      })
      .catch((error) => {
        toast.error("une erreur a survenue");
      });
  };

  render() {
    return (
      <div class="content-wrapper">
        <ToastContainer />
        <section class="content-header">
          <div class="container-fluid">
            <div class="callout callout-success">
              <h5>Ajouter un livreur</h5>
              Deux étapes:
              <br /> 1-Ajouter une image (1920px * 170px)
              <br /> 2-Ajouter le libellé et valider
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-md-6">
            <section class="content">
              <div class="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-outline card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          <b>1-AJOUTER UNE IMAGE</b>
                        </h3>
                      </div>

                      <form>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                <Files
                                  ref="files"
                                  className="files-dropzone-list"
                                  // style={{ height: "100px" }}
                                  onChange={this.onFilesChange}
                                  onError={this.onFilesError}
                                  multiple
                                  maxFiles={1}
                                  maxFileSize={10000000}
                                  minFileSize={0}
                                  clickable
                                >
                                  <label>Cliquer pour Ajouter une image</label>
                                </Files>

                                {this.state.files.length > 0 ? (
                                  <div className="files-list">
                                    <ul>
                                      {this.state.files.map((file) => (
                                        <li
                                          className="files-list-item"
                                          key={file.id}
                                        >
                                          <div className="files-list-item-preview">
                                            {file.preview.type === "image" ? (
                                              <div className="row">
                                                <img
                                                  className="files-list-item-preview-image"
                                                  src={file.preview.url}
                                                  style={{
                                                    width: "70%",
                                                    height: "70%",
                                                  }}
                                                />
                                                <button
                                                  id={file.id}
                                                  class="btn btn-navbar"
                                                  type="button"
                                                  // data-widget="navbar-search"
                                                  onClick={this.filesRemoveOne.bind(
                                                    this,
                                                    file
                                                  )}
                                                >
                                                  <i class="fas fa-times"></i>
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="files-list-item-preview-extension">
                                                {file.extension}
                                              </div>
                                            )}
                                          </div>
                                          <div className="files-list-item-content">
                                            <div className="files-list-item-content-item files-list-item-content-item-1">
                                              {file.name}
                                            </div>
                                            <div className="files-list-item-content-item files-list-item-content-item-2">
                                              {file.sizeReadable}
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : (
                                  <h1></h1>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="col-md-6">
            <section class="content">
              <div class="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-outline card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          <b>2-AJOUTER LE LIBELLE ET VALIDER</b>
                        </h3>
                      </div>

                      <form>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-2">
                              <label>Libellé</label>
                            </div>
                            <div className="col-md-10">
                              <input
                                type="libelle"
                                className="form-control"
                                placeholder="Ajouter libellé"
                                value={this.state.libelle}
                                onChange={this.changeLibelle}
                              />
                            </div>
                          </div>
                          <br />
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={this.saveLivreur}
                          >
                            Valider
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
